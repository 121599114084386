import React from 'react';
import { withTranslation } from 'react-i18next';
import { tlink } from 'utils/translationHelper';

import { TranslatedComponent, Btn, Title, Paragraph, Loading } from 'components/';
import { StyledPhotoLinkSection } from './PhotoLinkSection.styled';

class PhotoLinkSection extends TranslatedComponent {
  render() {
    const { className, title, subtitle, items } = this.props;

    return (
      <StyledPhotoLinkSection className="pt-3 pb-2">
        <div className="pt-2 pb-2 flex-centered">
          <Title type="h3" text={this.tt(title)} />
          <Paragraph className="lead" text={subtitle} />
        </div>
        <div className="flex-centered">
          {items.map(item => (
            <a href={this.tt(item.linkTo)}>
              <img className={className} src={this.tt(item.src)} alt={item.name || ''} />
            </a>
          ))}
        </div>
      </StyledPhotoLinkSection>
    );
  }
}

export default withTranslation()(PhotoLinkSection);
