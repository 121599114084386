export const transformDocTypesToSelectOptions = documentTypes => {
  if (!documentTypes) return [];

  return documentTypes.map(docType => {
    return { value: docType.id, text: docType.name };
  });
};

export const transformFiscalConditionsToSelectOptions = (fiscalConditions, t) => {
  if (!fiscalConditions) return [];

  return fiscalConditions.map(condition => {
    return {
      value: condition.id.toString(),
      text: t(`${condition.name}`),
    };
  });
};

export const isValidDocument = (tenantDocumentTypes, documentTypeId, documentId) => {
  const doc = findDocumentType(tenantDocumentTypes, documentTypeId);
  if (!doc) return true;

  const documentString = (doc.name || doc.value).toUpperCase();
  switch (documentString) {
    case 'CUIT':
    case 'CUIL':
      return isValidCUIT(documentId);
    case 'DNI':
      return isValidDNI(documentId);
    case 'RUT':
      return isValidRUT(documentId);
    default:
      return true;
  }
};

export const getDocumentSample = (tenantDocumentTypes, documentTypeId) => {
  const doc = findDocumentType(tenantDocumentTypes, documentTypeId);
  if (!doc) return null;

  const documentString = (doc.name || doc.value).toUpperCase();
  switch (documentString) {
    case 'CUIT':
    case 'CUIL':
      return '30111111112';
    case 'RUT':
      return '99.999.999-9';
    default:
      return null;
  }
};

const findDocumentType = (tenantDocumentTypes, documentTypeId) => {
  if (!tenantDocumentTypes) return [];
  return tenantDocumentTypes.find(
    docType => docType.id === Number(documentTypeId) || docType.value === documentTypeId,
  );
};

// source: https://elbotija.github.io/validar-cuit-javascript/
const isValidCUIT = cuit => {
  if (!cuit) return false;
  if (cuit.length !== 11) {
    return false;
  }

  let acumulado = 0;
  let digitos = cuit.split('');
  let digito = parseInt(digitos.pop());

  for (let i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }

  let verif = 11 - (acumulado % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }

  return digito === verif;
};

const isValidDNI = dni => {
  const regex = /^\d+$/;

  if (regex.test(dni)) {
    if (dni.length >= 6 && dni.length <= 8) return true;
  }

  return false;
};

const isValidRUT = rut => {
  if (!rut || rut.length <= 1) return false;

  let value = cleanRUT(rut);

  // Divide el valor ingresado en dígito verificador y resto del RUT.
  const body = value.slice(0, -1);
  let verifyDigit = value.slice(-1).toUpperCase();

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (body.length < 7) {
    return false;
  }

  // Calcular Dígito Verificador "Método del Módulo 11"
  let sum = 0;
  let multiple = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= body.length; i++) {
    // Obtener su Producto con el Múltiplo Correspondiente
    let index = multiple * value.charAt(body.length - i);

    // Sumar al Contador General
    sum = sum + index;

    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiple < 7) {
      multiple = multiple + 1;
    } else {
      multiple = 2;
    }
  }

  // Calcular Dígito Verificador en base al Módulo 11
  let expectedDigit = 11 - (sum % 11);

  // Casos Especiales (0 y K)
  expectedDigit = expectedDigit == 10 ? 'K' : expectedDigit;
  expectedDigit = expectedDigit == 11 ? 0 : expectedDigit;

  // Validar que el Cuerpo coincide con su Dígito Verificador
  if (expectedDigit != verifyDigit) {
    return false;
  } else {
    return true;
  }
};

export const cleanRUT = rut => {
  return typeof rut === 'string' ? rut.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() : '';
};
