import style from 'styled-components';

export const StyledPaymentMethod = style.div`

  .paymentRow{
    place-items: center;
  }

  .price{
    text-align: right;
  }

  .name {
    display: flex;
  }

  .image {
    margin-left: 20px;
    margin-top: 7px;
  }

  .error{
    width: 100%;
    padding-left: 10px;
    color: ${props => props.theme.colors.alert};
    font-style: italic;
    margin-bottom: 15px;
    margin-top:-5px;
  }
`;
