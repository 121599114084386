import React from 'react';
import { props, defaultProps } from './Flota.props';
import { TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { tlink } from '../../../utils/translationHelper';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import DefaultFlota from './Default';
import ModernFlota from './Modern';

class Flota extends TranslatedComponent {
  static propTypes = props;
  static defaultProps = defaultProps;

  constructor() {
    super();

    this.getFlotaItems = this.getFlotaItems.bind(this);
  }

  state = {
    loading: true,
  };

  async componentDidMount() {
    const { fetchCategories, i18n } = this.props;

    try {
      await fetchCategories(i18n.language);
    } catch {}
    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }));
  }

  handleButtonClick = () => {
    const { history, buttonLink, t, i18n, settings } = this.props;
    history.push(tlink(buttonLink, t, i18n, null, settings.configurations.langConfig));
    window.scrollTo(0, 0);
  };

  getFlotaItems = () => {
    const { listCategories, showOnlyDefaultModels } = this.props;
    let showOnlyDefaultModelsInverse = showOnlyDefaultModels == undefined ? true : showOnlyDefaultModels;

    if (!showOnlyDefaultModelsInverse) return listCategories;

    const defaultModels = [];

    listCategories.forEach(category => {
      const principalModel = category.models.find(m => m.id === category.principalModelId);
      if (principalModel) defaultModels.push(principalModel);
      else defaultModels.push(category.models[0]);
    });

    return defaultModels;
  };

  render() {
    const {
      title,
      disableTitleButton,
      disableTitle,
      subTitle,
      listCategories,
      t,
      btnDisplay,
      showOnlyDefaultModels,
      settings: { theme },
      slideSpeed,
      slidesToShow,
      autoplaySpeed,
      bookLink,
      titleType,
      showModelDetail,
    } = this.props;

    let showOnlyDefaultModelsInverse = showOnlyDefaultModels == undefined ? true : showOnlyDefaultModels;

    return theme.template === MODERN_THEME ? (
      <ModernFlota
        title={title}
        listCategories={listCategories}
        btnDisplay={btnDisplay}
        showOnlyDefaultModels={showOnlyDefaultModelsInverse}
        slideSpeed={slideSpeed}
        autoplaySpeed={autoplaySpeed}
        bookLink={bookLink}
        disableTitleButton={disableTitleButton}
        disableTitle={disableTitle}
        loading={this.state.loading}
        getFlotaItems={this.getFlotaItems}
        handleButtonClick={this.handleButtonClick}
        titleType={titleType}
        slidesToShow={slidesToShow}
        showModelDetail={showModelDetail}
      />
    ) : (
      <DefaultFlota
        title={title}
        subTitle={subTitle}
        listCategories={listCategories}
        btnDisplay={btnDisplay}
        showOnlyDefaultModels={showOnlyDefaultModelsInverse}
        slideSpeed={slideSpeed}
        autoplaySpeed={autoplaySpeed}
        bookLink={bookLink}
        disableTitleButton={disableTitleButton}
        disableTitle={disableTitle}
        loading={this.state.loading}
        getFlotaItems={this.getFlotaItems}
        handleButtonClick={this.handleButtonClick}
        titleType={titleType}
        slidesToShow={slidesToShow}
      />
    );
  }
}

export default withSettingsContext(withRouter(withTranslation()(Flota)));
