import styled from 'styled-components';

export const StyledSearchMap = styled.div`
  background-color: ${props => props.theme.colors.bg};
  box-shadow: 3px 3px 6px #00000016;

  p {
    font-weight: bold !important;
    font-size: 14px !important;
  }

  #mapPlaces {
    width: 100%;
    height: 200px;
  }
`;
