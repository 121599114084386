import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import UserIcon from '../../assets/images/user.svg';
import { TranslatedLink } from 'components';
import { StyledMyAccountNavLink } from './MyAccountNavLink.styled';
import { StyledModernMyAccountNavLink } from './MyAccountNavLink.style.modern';
import { MODERN_THEME } from 'styled/constants';
import { props } from './MyAccountNavLink.props';
import { handleLogOut } from 'actions';
import { tlink } from 'utils/translationHelper';
import { withRouter } from 'react-router-dom';

function MyAccountNavLink({
  t,
  settings: { theme, myAccountConfiguration, configurations },
  color,
  bgColor,
  hoverBkg,
  hoverColor,
  isAuthenticated,
  handleLogOut,
  profile,
  className = '',
  i18n,
  history,
}) {
  const StyledComponent =
    theme.template === MODERN_THEME ? StyledModernMyAccountNavLink : StyledMyAccountNavLink;

  const hideLoginBtn = myAccountConfiguration && myAccountConfiguration.hideLoginBtn;

  const showCompanyLoginBtn =
    myAccountConfiguration &&
    myAccountConfiguration.companyLoginEnable &&
    myAccountConfiguration.showCompanyLoginBtn;

  const showAgencyLoginBtn =
    myAccountConfiguration &&
    myAccountConfiguration.agencyLoginEnable &&
    myAccountConfiguration.showAgencyLoginBtn;

  const signOut = () => {
    handleLogOut();
    const link = tlink('/', t, i18n, null, configurations.langConfig);
    history.push(link);
  };

  return (
    <StyledComponent
      color={color}
      bgColor={bgColor}
      hover={hoverBkg}
      hoverColor={hoverColor}
      className={className}
    >
      {isAuthenticated ? (
        <Dropdown className="my-account-button">
          <Dropdown.Toggle id="my-account-dropdown">
            <img
              src={profile.picture || UserIcon}
              className={`profile-pic ${!profile.picture ? 'default-pic' : ''}`}
              alt={`${profile.firstName} ${profile.lastName}`}
            />
            <span>{`${profile.firstName} ${profile.lastName}`}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <TranslatedLink className="nav-link" name="myAccount.title" path="/my-account" />
            <TranslatedLink
              className="nav-link"
              name="myAccount.bookings"
              path="/my-account/bookings"
            />
            <button className="nav-link" onClick={signOut}>
              {t('myAccount.logOut')}
            </button>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          {!hideLoginBtn && (
            <Dropdown className="my-account-button notLogged">
              <Dropdown.Toggle id="my-account-dropdown">
                <div className="login-menu"> {t('myAccount.logIn')}</div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <TranslatedLink className="nav-link" name="myAccount.personLogin" path="/login" />
                {showCompanyLoginBtn && (
                  <TranslatedLink
                    className="nav-link"
                    name="myAccount.companyLogin"
                    path="/login?isCompany=true"
                  />
                )}
                {showAgencyLoginBtn && (
                  <TranslatedLink
                    className="nav-link"
                    name="myAccount.agencyLogin"
                    path="/login?isAgency=true"
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </>
      )}
    </StyledComponent>
  );
}

MyAccountNavLink.propTypes = props;

const mapStateToProps = ({ user, siteConfiguration }) => ({
  isAuthenticated: user.isAuthenticated,
  settings: siteConfiguration.settings,
  profile: user.profile,
});

export default withRouter(
  connect(mapStateToProps, { handleLogOut })(withTranslation()(MyAccountNavLink)),
);
