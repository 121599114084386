import React from 'react';
import { StyledContactCard } from '../PreHeader.styled';
import withSettingsContext from 'SettingsContext';
import { withTranslation } from 'react-i18next';

const getContactStyles = path => {
  if (path.includes('mailto')) {
    return { defaultIcon: 'far fa-envelope', defaultIconBg: '#34C759' };
  }
  if (path.includes('wa.me')) {
    return { defaultIcon: 'fab fa-whatsapp', defaultIconBg: '#00C7BE' };
  }
  if (path.includes('://')) {
    return { defaultIcon: 'fas fa-link', defaultIconBg: '#348cc7' };
  }
  if (path.includes('tel')) {
    return { defaultIcon: 'fas fa-phone', defaultIconBg: '#FF5F00' };
  }
  return { defaultIcon: 'fas fa-globe', defaultIconBg: '#c75879' };
};

function ContactCard({ t, contact }) {
  const { defaultIcon, defaultIconBg } = getContactStyles(contact.path);

  return (
    <StyledContactCard className="contact-card" defaultIconBg={contact.iconBg || defaultIconBg}>
      <a href={contact.path} className="contact-icon">
        <i className={contact.icon || defaultIcon} />
      </a>
      <div className="contact-body">
        {contact.legend && <div>{t(contact.legend) || contact.legend}</div>}
        <a href={contact.path}>{t(contact.name) || contact.name}</a>
      </div>
    </StyledContactCard>
  );
}

export default withSettingsContext(withTranslation()(ContactCard));
