import React from 'react';
import { CancelForm } from 'components/';
import { PropTypes } from 'prop-types';
import { StyledCancelSection } from './CancelSection.styled';
import { withTranslation } from 'react-i18next';

class CancelSection extends React.Component {
  static propTypes = {
    offices: PropTypes.array,
    t: PropTypes.func,
  };

  render() {
    return (
      <StyledCancelSection className="col-md-4 p-40">
        <CancelForm />
      </StyledCancelSection>
    );
  }
}

export default withTranslation()(CancelSection);
