import styled from 'styled-components';

export const StyledHeaderModern = styled.header`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: ${props => props.bgcolor || props.theme.colors.primary};
  color: ${props => props.theme.font.colors.primary};
  align-item: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  * {
    margin-block-end: 0;
    margin-block-start: 0;
  }

  .header-logo {
    padding: 10px 0 !important;
  }

  @media (min-width: 768px) {
    .desktop i {
      display: none;
    }
    .fa-ban {
      display: none;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .desktop i {
      display: none;
    }
    .fa-ban {
      display: none;
    }
  }

  @media (max-width: 767px) {
    border-bottom: none;

    .desktop {
      border: none !important;
      color: #fff !important;
    }

    .btnCancel {
      background: none !important;
      padding: 8px 36px !important;
    }
  }

  @media (max-width: 1400px) {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 0px !important;
    padding-top: 0.5rem !important;

    .w50 {
      width: 50%;
    }
  }

  &.row-p {
    @media (max-width: ${props => props.theme.screens.md}) {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  .mobileMenu {
    position: absolute;
    top: 0;
    padding: 10px;
    height: 100%;
    display: flex;
    left: 0;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    font-size: 25px;
    color: ${props => props.mobileMenuColor || props.theme.font.colors.secondary};

    @media (min-width: ${props => props.theme.screens.md}) {
      display: none;
    }
  }

  .header-item-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .header-item {
      max-width: 100%;
    }
  }

  .header-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: ${props => props.theme.screens.md}) {
      justify-content: center;
      img {
        width: 100px;
      }
    }
  }

  .header-slogan {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .header-associates-btn {
    margin-left: 50px;
  }

  .btnCancel {
    background: #ff6363;
    border-radius: 8px;
    color: #fff;
    padding: 8px 13px;
  }

  .btnCancel label {
    color: #fff;
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    .header-item-container {
      background-color: ${props => props.theme.colors.mobileHeader};
      margin: 0;

      .header-item {
        margin-right: 0;

        .icon {
          border: none;
          font-size: 1rem;
          color: ${props => props.theme.colors.mobileHeaderIcons || 'white'} !important;
          &:hover {
            color: ${props => props.theme.colors.buttonPrimaryBg};
          }
        }
      }
    }

    .header-logo {
      margin-bottom: 10px;
    }
  }
`;

export const StyledHeaderSlogan = styled.div`
  float: right;

  .label {
    color: ${props => props.labelColor || props.theme.colors.buttonPrimaryColor};
    font-size: ${props => props.labelFontSize || '23px'};
    font-weight: 700;
    font-style: ${props => props.labelStyle || 'normal'};
    transition: color 0.2s ease-out;
    cursor: pointer;

    i {
      display: ${props => props.displayIcon || 'none'};
      margin-left: 10px;
    }

    &:hover {
      color: ${props => props.labelColorHover || props.theme.colors.buttonPrimaryBgHover};
      text-decoration: none;
    }
  }

  .sub-label {
    font-size: 11.5px;
    font-weight: ${props => props.subLabelWeight || '400'};
    color: ${props => props.subLabelColor || props.theme.font.colors.secondary};
  }

  @media only screen and (max-width: ${props => props.theme.screens.md}) {
    display: none;
  }
`;

export const StyledHeaderItem = styled.div`
  .content-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${props => props.color || props.theme.font.colors.secondary};
    text-decoration: none;
    cursor: pointer;
    text-align: right;
    white-space: nowrap;

    p {
      color: ${props => props.color || props.theme.font.colors.secondary};  
      transition: color 0.3s;
    }

    @media (max-width: ${props => props.theme.screens.md}) {
      justify-content: center;
    }

    .content-text {
      display: flex;
      justify-content: center;
      align-items: left;
      font-size: 12px;
      text-align:left;
      margin-top:0 !important;
      margin-bottom:0 !important
    }

    &:hover {
      color: ${props => props.hover || props.theme.colors.secondary};
      p, label {
        color: ${props => props.hover || props.theme.colors.secondary};
      }

      .font-icon {
        border-color: ${props => props.iconHover || props.hover || props.theme.colors.secondary};
        color: ${props => props.iconHover || props.hover || props.theme.colors.secondary};
      }
    }

    .font-icon {
      border-radius: 40px;
      padding: 10px 11px 10px 10px;
      font-size: 16px;
      transition: color 0.2s ease-out;
      width: 38px;
    }

    .icon-img > img {
      width: 38px;
      height: 38px;
    }

    p {
      font-weight: 500;
      font-size: 13px;
      text-transform: none;
    }

    .label {
      font-weight: 400;
      font-size: 13px;
  }
`;
