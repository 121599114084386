import PropTypes from 'prop-types';

const placeShape = PropTypes.shape({
  address: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  rentlyEndpointName: PropTypes.string,
});

export const props = {
  params: PropTypes.shape({}),
  places: PropTypes.arrayOf(placeShape),
  setSearchCarParams: PropTypes.func.isRequired,
  fetchBookeableCars: PropTypes.func.isRequired,
  prefixForNumeric: PropTypes.string,
  showCity: PropTypes.bool,
};

export const defaultProps = {
  params: {},
};
