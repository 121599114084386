import React, { Component } from 'react';
import { Card, Accordion} from 'react-bootstrap';
import { props } from './AccordionCard.props';
import { StyledAccordionCard } from './AccordionCard.styled';
import { StyledAccordionCardModern } from './AccordionCard.styled.modern';
import { Paragraph, Title  } from 'components/';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

class AccordionCard extends Component {
  static propTypes = props;

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledAccordionCardModern;
    }
    return StyledAccordionCard;
  }

  render() {
    const { titleType, fontWeight, fontSize } = this.props;
    const StyledSection = this.getTemplateStyledComponent();

    return (
      <StyledSection>
        <Card>
          <Accordion.Toggle
            ref={this.props.refe}
            as={Card.Header}
            variant="link"
            eventKey={this.props.keyValue}
          >
            <i className="fas fa-info-circle" />
            <div className='accordion-title'>
            <Title type={titleType || 'h3'} fontSize={fontSize || '16'} weight={fontWeight || "400"} text={this.props.title} italic={false} />
            <i
              className="fas fa-angle-down float-right mt-1"
              data-toggle="collapse"
              data-target="#1"
              aria-expanded="false"
              aria-controls="1"
              id="collapseSearch"
            />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={this.props.keyValue}>
            <Card.Body>
              {this.props.body && <Paragraph text={this.props.body} nonTranslate />}
              {this.props.element}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </StyledSection>
    );
  }
}

export default withSettingsContext(AccordionCard);
