import React from 'react';
import { Title, PaymentMethods, Btn } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { StyledPaymentOptionsSection } from './PaymentOptionsSection.styled';
import { withTranslation } from 'react-i18next';
import { tt } from '../../../utils/translationHelper';
import { history } from '/store';
import * as yup from 'yup';
import { tlink } from 'utils/translationHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';

class PaymentOptionsSection extends BasePaymentSection {
  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
        qsamount: 0,
      },
      userInfo: {
        paymentMethod: false,
        paymentMethodObj: null,
      },
      formErrors: {
        paymentMethod: null,
      },
    };

    this.userInfoFormSchema = yup.object().shape({
      paymentMethod: yup
        .boolean()
        .required(t('mustHaveOnePaymentMethod'))
        .oneOf([true], t('mustHaveOnePaymentMethod')),
    });

    this.formRef = React.createRef(null);
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      let partial = parseFloat(parseQs.amount);
      if (partial != 0) {
        this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
      }
    }
  }

  componentDidMount() {
    const { bookings, infractions } = this.props;

    if (bookings.confirmation != null) {
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
        },
      }));
    } else if (infractions.current != null) {
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: infractions.current.amount,
          bookingId: infractions.current.act,
          email: infractions.current.booking?.customer?.emailAddress || '',
        },
      }));
    }
  }

  isBookingPay() {
    const { bookings } = this.props;
    return bookings.confirmation != null;
  }

  showIdMessage() {
    const { t } = this.props;
    return this.isBookingPay() ? t('orden') : String(t('payInfraction')).split(' - ')[0];
  }

  onPaymentMethodChange = (paymentMethod, paymentMethodObj) => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, paymentMethod, paymentMethodObj },
      formErrors: { ...prevState.formErrors, paymentMethod: null },
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const { t, i18n, settings } = this.props;
    const userInfo = this.state.userInfo;

    this.userInfoFormSchema
      .validate(userInfo, { abortEarly: false })
      .then(valid => {
        history.push(
          tlink('__Routes.onlinePayment', t, i18n, null, settings.configurations.langConfig) +
            this.props.location.search,
          { paymentMethod: userInfo.paymentMethodObj.component },
        );
        window.scrollTo(0, 0);
      })
      .catch(err => {
        const formErrors = err?.inner?.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        this.setState(prevState => ({ ...prevState, formErrors }));
      });
  };

  render() {
    const {
      t,
      title,
      subtitle,
      settings: { paymentConfiguration },
    } = this.props;

    const { paymentInfo } = this.state;

    return (
      <StyledPaymentOptionsSection>
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="row">
            <div className="col-md-12 payHead">
              <p>
                {this.showIdMessage()}{' '}
                {paymentInfo.bookingId ? paymentInfo.bookingId.toString().padStart(7, '0') : ''}
              </p>
              <p>
                <strong>{this.showTotalToPay()}</strong>
              </p>
            </div>
          </div>
          <div className="row adicionales-block mb-5">
            <div className="col-md-10 form-pago">
              <div className="col-md-12 mb-4">
                <form
                  id="bookingUserDataForm"
                  onSubmit={this.handleSubmit}
                  className="container-fluid p-0"
                >
                  <PaymentMethods
                    checked={this.state.userInfo.paymentMethod}
                    error={this.state.formErrors.paymentMethod}
                    onChange={this.onPaymentMethodChange}
                    paymentMethods={paymentConfiguration.paymentMethods}
                    booking={this.state.paymentInfo}
                  />
                  <Btn
                    form="bookingUserDataForm"
                    type="submit"
                    text={t('payBooking')}
                    className="mt-5"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </StyledPaymentOptionsSection>
    );
  }
}

const mapStateToProps = ({ bookings, siteConfiguration, infractions }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(PaymentOptionsSection)));
