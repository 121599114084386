import PropTypes from 'prop-types';

export const props = {
  className: PropTypes.string,
  color: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  date: PropTypes.shape({}),
  datePickerOptions: PropTypes.shape({}),
};
