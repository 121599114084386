import style from 'styled-components';

const basicFlex = `
  display: flex;
  height: 100%;
  box-sizing: border-box;
`;

export const StyledNavBarModern = style.nav`
  ${basicFlex}
  background:${props => props.theme.colors.third};
  color: ${props => props.color || props.theme.font.colors.primary};
  font-size: ${props => props.fontSize || '12px'};
  font-weight: ${props => props.fontWeight || 400};
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${props => props.height || '40px'};
  top: 0;
  left: 0;
  z-index: 10000;

  .dropdown {
    flex-direction: column;
  }

  .dropdown-menu {
    padding: 0;
    border-radius: ${props => props.dropDownborderRadius || '0'};
    box-shadow: 1px 1px 20px #0000001a;
    border: 1px solid #f3f4f7;
  }

  .nav-sublink{
    padding: 10px 22px;
    font-size: 13px;
    font-weight: 400;
    color: ${props => props.subItemColor || '#000'};
    border-bottom: 1px solid #f3f4f7;
    display: flex;
    align-items: center;
  }
  .nav-sublink:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    content: "\f105";
    margin-right: 10px;
    color: ${props => props.subItemHover || props.theme.colors.secondary};
  }
  .nav-sublink:hover {
    color: ${props => props.subItemHover || props.theme.colors.secondary} !important;
    background: ${props => props.dropdownBkgHover || '#f3f4f7'};
  }
  .nav-sublink:active {
    background-color: transparent;
  }
  
  .nav-list .nav-list-item:hover .nav-sublink {
    color: ${props => props.subItemColor || '#000'} !important;
  }
  
  @media (min-width: ${props => props.theme.screens.lg}) {
    &.sticky {
      position: fixed;
      width:100%;
      margin:0px;
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    transition: margin 100ms ease;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 256px;
    padding: 1rem;
    display: block;
    background: ${props => props.theme.colors.third} !important;
    color: ${props => props.theme.font.colors.third};
    border: none;
    a.nav-link{
      color: ${props => props.theme.font.colors.primary} !important;
    }
    margin-left:-256px !important;

    .nav-list .navLogo{
      padding:0px !important;
    }

    .dropdown-menu {
      position: relative !important;
      transform: none !important;
      background:  ${props => props.theme.colors.primary} !important;
      border: none;
      width: 100%;
    }
    .nav-sublink, .nav-list .nav-list-item:hover .nav-sublink{
      color: ${props => props.theme.font.colors.secondary} !important;
      padding: 10px 0;
      border-bottom: 1px solid #ffffff14;
    }
    .nav-sublink:last-child {
      border: none !important;
    }

    &.mobile-open {
      
      &.row-p {
        padding:1rem !important;
        margin-right:0px !important;
        margin-left:0px !important;    
        height: 100%;
        max-height: 100%;
      }  

      .nav-list .nav-list-item.active{
        background: ${props => props.active || props.theme.colors.primary} !important;
        border-radius:8px;
        padding:0 10px;
      }

      .navbarLang {
        color: ${props => props.theme.font.colors.primary} !important;

        .currentLang .currentCurrency i{
          color: ${props => props.theme.font.colors.third};
        }

        .dropdownOptions.open{
          margin-top:0.5rem;
          left:0px;
          width:100%;
          .dropdownItem{
           color: ${props => props.theme.font.colors.primary} !important;
          }
        }
      }
      
    }
    
  }

  .nav-list {
    ${basicFlex}
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
    width:100%;
    justify-content: flex-start;

    @media (max-width: ${props => props.theme.screens.md}) {
      flex-direction: column;
      width: 100%;
      font-size: 13px;
    }

    @media (min-width: ${props => props.theme.screens.lg}) {

      .navSocialList {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .navLogo {
      padding: 20px;
      display: initial;
      align-items: center;
      justify-content: center;

      @media (min-width: ${props => props.theme.screens.md}) {
        display: none;
      }
    }

    .mobileSeparator {
      border: 1px solid #ffffff14;
      width: 100%;

      @media (min-width: ${props => props.theme.screens.md}) {
        display: none;
      }
    }

    @media (max-width: ${props => props.theme.screens.lg}) {
      .navSocialList {
        display: none;
      }

      .navbarLang {
        color: ${props => props.theme.font.colors.primary}
        height: auto;
        position: absolute;
        right: 10px;

        .dropdownItem {
          color: ${props => props.theme.colors.secondary}
        }
      }
    }
    @media (max-width: ${props => props.theme.screens.md}) {
      .navbarLang {
        position: relative;
        right: 0;
      }
    }

    .nav-list-item {
      ${basicFlex}
      height: auto;
      list-style-type: none;
      margin: 0;
      padding: 2px 10px;
      transition: background-color 100ms ease-in-out;
      justify-content: center;
      align-items: center;
      white-space: nowrap;

      
      &.active{
        background: ${props => props.active || props.theme.colors.secondary} !important;
        a{
          color: ${props => props.hover || props.theme.font.colors.secondary} !important;
        }
      }

      &.onlyMobile {
        display: none;

        .fa-angle-right {
          margin-right: 10px;
          color: ${props => props.theme.font.colors.third};
        }

        @media (max-width: ${props => props.theme.screens.md}) {
          display: initial;
        }
      }

      .menuIcons {
        margin-right: 10px;
        display:none;

        .menuIcons.active {
          color: ${props => props.active || props.theme.colors.primary} !important;
        }
  
        @media (min-width: ${props => props.theme.screens.md}) {
          display: none;
        }
      }

      @media (max-width: ${props => props.theme.screens.lg}) {
        padding: 2px 5px;

        &:last-child {
          border-bottom: none;
        }
      }

      @media (max-width: ${props => props.theme.screens.md}) {
        width: auto;
        padding: 0;
      }

      @media (min-width: ${props => props.theme.screens.md}) {
        &:hover {
          background-color: ${props => props.active || props.theme.colors.secondary};
          a{
            color: ${props => props.hover || props.theme.font.colors.secondary} !important;
          }
        }
      }

      .nav-link {
        font-size:12px;
        color: ${props => props.color || props.theme.font.colors.primary};
        text-decoration: none;
        ${basicFlex}
        align-items: center;
        padding-right: .5rem;
        padding-left: .5rem;
        font-weight: ${props => props.fontWeight || 400};
        text-transform: ${props => props.textTransform || 'none'};

        @media (max-width: ${props => props.theme.screens.md}) {
          padding: 10px 0;
          width: 100%;
          font-size:14px;
        }
      }
    }
    .socialItem:hover{
      background: ${props => props.active || props.theme.colors.primary} !important;
      color: ${props => props.hover || props.theme.font.colors.secondary} !important;
    }


    .socialItem a {
      font-size: ${props => props.iconSize || '12px'};
    }
  }
`;
