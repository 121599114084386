import styled from 'styled-components';

export const StyledFlotaModern = styled.section`
background: ${props => props.theme.colors.bg};
overflow: hidden;
p.lead {
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 3rem;

  @media (max-width: ${props => props.theme.screens.lg}) {
    font-size: 1rem;
  }
}


@media (max-width: ${props => props.theme.screens.md}) {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.slick-next:before,
.slick-prev:before {
  color: #e1e1e1 !important;
}

.slick-next:before {
  content: '\f';
  font-family: 'Font Awesome 5 Free', 'FontAwesome';
  
}

.slick-prev:before {
  content: '\f' !important;
  font-family: 'Font Awesome 5 Free', 'FontAwesome';
}

.section-flota-title{
  margin-bottom:50px !important;
  margin:0 auto;
}

.section-flota-title h3{
  text-align:center;
  font-size:30px !important;
  font-weight:400;
  color: #2E2D35;
}

.section-flota-title button{
  background-color:transparent;
  color:${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius:5px;
  font-size:16px;
  margin:20px auto;
  font-weight:500;
}

.section-flota-title button:hover{
  color:${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
}

@media (max-width: ${props => props.theme.screens.xs}) {
  .section-flota-title h3{
    text-align:left;
    font-size:25px !important;
  }

  .section-flota-title button{
    float:left;
  }
}


@media (max-width: 767px) {
.section-flota-title h3{
  left:0
  margin-top:50px
}

.section-flota-title button{
  left:0
}
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .section-flota-title h3 {
    text-align: left;
}
.section-flota-title button{
  margin:0;
}
}

p.car-category {
  font-weight: 400;
  margin-bottom: .5rem;
  text-align: center;
  font-size:14px;
  color:#858C99;

}

.car-item {
  .car-details {
    .car-name p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      text-align: center;
      color: ##2E2D35;
      @media only screen and (max-width: ${props =>
        props.theme.screens.xxl}) and (min-width: ${props => props.theme.screens.xl}) {
        font-size: 18px;
      }
    }


    .btn{
      display: ${props => props.btnDisplay || 'flex'};
      padding:15px
      border-radius:8px;
      margin:0 auto;
    }
  }

  .car-image {
    border: none;
    height: fit-content;
    text-align: center;
    height: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    .lazy-load-image-loaded > img {
        object-fit: cover;
        height: 100%;
        min-height: 100%;
    }
  }

  .price p {
    font-size: 13px;
    text-transform:uppercase;
    span{
      font-weight: bold;
      font-size:15px;
    }
    strong span{
      font-size: 20px;
    }
  }

  .car-details {
    .details {
      margin-bottom: 5px;

      .details-item {
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        width: 10.5%;

        @media only screen and (max-width: 330px) {
            width: 16%;
        }

        p{
          margin-bottom:0 ;
        }

        img {
          width: 100%;
          display: block;
          border: 1px solid #cccccc;
          padding: 5px;
          border-radius: 7px;
          margin-bottom: 5px;

          @media only screen and (max-width: ${props =>
            props.theme.screens.xxl}) and (min-width: ${props => props.theme.screens.xl}) {
            width: 27px !important;
          }
        }
      }
    }
  }
  
}
`;
