import React from 'react';
import { props, defaultProps } from './Btn.props';
import { StyledBtn } from './Btn.styled';
import { StyledBtnModern } from './Btn.styled.modern';
import { Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { tt } from 'utils/translationHelper';
import { useSettingsContext } from 'SettingsContext';

import { tlink } from 'utils/translationHelper';
import { history } from '../../store';
import { MODERN_THEME } from 'styled/constants';

const Btn = ({
  onClick,
  className,
  text,
  subText,
  isCustom,
  color,
  textTransform,
  fontSize,
  background,
  borderColor,
  borderRadius,
  borderWidth,
  onlyQuote,
  hoverColor,
  height,
  width,
  loading,
  icon,
  iconColor,
  iconSize,
  style,
  disabled,
  link,
  noShadow,
  padding,
  selfLink,
  internalLink,
  secondary,
  paymentMethod,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const settings = useSettingsContext();

  const goToLink = link => {
    if (!link) return;

    if (internalLink) {
      history.push(tlink(link, t, i18n, null, settings.configurations.langConfig));
      window.scrollTo(0, 0);
    } else {
      window.open(tt(link, t), selfLink ? '_self' : '_blank');
    }
  };

  const handleRedirect = () => {
    return goToLink(link);
  };

  const getTemplateStyledComponent = () => {
    if (settings.theme.template === MODERN_THEME) {
      return StyledBtnModern;
    }
    return StyledBtn;
  };

  const getCustomButton = (text, icon, subText, t) => {
    return (
      <>
        <div className="button-container">
          {icon && (
            <div className="button-icon">
              <i className={icon} />
            </div>
          )}
          <div className={`button-text ${icon ? 'with-icon' : ' '}`}> {tt(text, t)}</div>
        </div>
        <div className="button-subtext">{tt(subText, t)}</div>
      </>
    );
  };

  const StyledComponent = getTemplateStyledComponent();

  return (
    <StyledComponent
      className={`${className} no-border-radius btn ${isCustom ? 'customButton' : ''} ${
        onlyQuote && paymentMethod === 'partial' ? 'only-quote' : ''
      }`}
      background={background}
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      color={color}
      height={height}
      textTransform={textTransform}
      fontSize={fontSize}
      hoverColor={hoverColor}
      onClick={onClick || handleRedirect}
      width={width}
      style={style}
      icon={icon}
      noShadow={noShadow}
      disabled={disabled}
      padding={padding}
      secondary={secondary}
      {...rest}
    >
      {loading ? (
        <Loading show={loading} size={iconSize || '2em'} color={iconColor} />
      ) : isCustom ? (
        getCustomButton(text, icon, subText, t)
      ) : (
        <span className="button-text"> {tt(text, t)}</span>
      )}
    </StyledComponent>
  );
};

Btn.propTypes = props;
Btn.defaultProps = defaultProps;

export default Btn;
