import userFetcher from 'utils/userFetcher';

const api = new userFetcher();
const apiUrl = process.env.REACT_APP_BACKEND_API;

export const validateInvitation = async token => {
  return await api.post(`${apiUrl}/UserCompany/validate-invitation?token=${token}`);
};

export const registerCompany = async (globalId, password) => {
  return await api.post(`${apiUrl}/UserCompany/register-company`, {
    globalId,
    password,
  });
};
