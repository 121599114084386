import style from 'styled-components';

export const StyledModernLoginForm = style.div`
  margin-bottom: 1rem;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
  }

  .forgotPassword {
    background: transparent;
    border: none;
    text-align: center;
    font-size: 0.9rem;
    color: #858C99;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    transition: all 0.3s;

    &:hover { 
      color: ${({ theme }) => theme.colors.buttonPrimaryBg || theme.font.colors.primary};
    }
  }

  .social-btn {
    background: transparent;
    border: none;
    text-align: center;
    font-size: 0.9rem;
    color: #858C99;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    transition: all 0.3s;

    &:hover { 
      color: ${({ theme }) => theme.colors.buttonPrimaryBg || theme.font.colors.primary};
    }
    
    i {
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.buttonPrimaryBg || theme.font.colors.primary};
    }
  }

  .input-wrapper, .input-wrapper input.form-control {
    border-radius: 8px !important;
  }

  .error-alert { 
    max-width: fit-content;
  }
`;
