import React from 'react';
import { Col } from 'react-bootstrap';
import { props } from './TopBar.props';
import { StyledHeader } from './TopBar.styled';
import SocialList from 'components/SocialList';
import TranslatedLink from 'components/TranslatedLink';
import withSettingsContext from 'SettingsContext';

function TopBar({
  bgColor,
  color,
  hoverColor,
  contactLinks,
  socialHoverColor,
  settings: {
    configurations: { socialNetworks },
  },
  includeSocialNetworks,
  showTopBarPhone,
}) {
  const getIcon = path => {
    if (path.includes('mailto')) {
      return 'far fa-envelope';
    }
    if (path.includes('wa.me')) {
      return 'fab fa-whatsapp';
    }
    if (path.includes('://')) {
      return 'fas fa-external-link-alt';
    }
    if (path.includes('tel')) {
      return 'fas fa-phone';
    }
    return 'fas fa-angle-right';
  };

  return (
    <StyledHeader className="row" bgColor={bgColor} hoverColor={hoverColor}>
      <Col>
        {contactLinks
          .filter(f => f.path.includes('mailto'))
          .map((contact, idx) => {
            return (
              <div className="link-wrapper" key={idx}>
                <i className={`link-icon ${getIcon(contact.path)}`} />
                <TranslatedLink {...contact} />
              </div>
            );
          })}
      </Col>
      <Col className="right-col">
        {showTopBarPhone &&
          contactLinks
            .filter(f => f.path.includes('tel'))
            .map((contact, idx) => {
              return (
                <li className="socialItem TopBarPhone" key={idx}>
                  <a
                    name="phone"
                    className="socialLink"
                    href={contact.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contact.path.slice(4)}
                  </a>
                </li>
              );
            })}
        <SocialList
          bgColor="transparent"
          className="navSocialList TopBarSocialList"
          links={contactLinks.filter(f => !f.path.includes('mailto'))}
          borderRadius="0"
          margins="0"
          color={color}
          hover={socialHoverColor}
          location="header"
        />
        {includeSocialNetworks && socialNetworks && (
          <SocialList
            bgColor="transparent"
            className="navSocialList"
            links={socialNetworks}
            borderRadius="0"
            margins="0"
            color={color}
            hover={socialHoverColor}
          />
        )}
      </Col>
    </StyledHeader>
  );
}

TopBar.propTypes = props;

export default withSettingsContext(TopBar);
