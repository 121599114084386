import React from 'react';
import { ColumnsSection, CustomLoading } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { GetBook, SetBook } from '../../../actions/bookings.actions';
import { history } from '../../../store';
import { tt, tlink } from 'utils/translationHelper';
import { BookingStatus } from '../../../utils/constants';
import { getPriceWithExchangeRate } from '../../../utils/priceHelper';

class BookingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    const {
      location,
      bookings: { confirmation },
      t,
      i18n,
      settings,
    } = this.props;
    const parseQs = queryString.parse(location.search);
    if (parseQs.bookingid) {
      this.setState({ loading: true });
    } else if (!confirmation) {
      this.setState({ loading: true });
      history.push(
        tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
      );
    }
  }

  componentDidMount() {
    const { location, GetBook, t, i18n, settings, isWidget, defaultCurrency, listCurrencies } = this.props;
    const parseQs = queryString.parse(location.search);
    if (parseQs.bookingid) {
      GetBook(parseQs.bookingid)
        .then(async x => {
          if (x.payload.currentStatus === BookingStatus.canceled) {
            history.push(
              tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
            );
          } else {
            var balance = x.payload.customerBalance || x.payload.balance;
            //si la moneda de la reserva es diferente a la moneda por defecto, se calcula el balance con el exchange rate

            if (defaultCurrency.isoCode !== x.payload.currency && settings.featureFlags.isNewApi){
              let currency = listCurrencies.find(c => c.isoCode === x.payload.currency);
              balance = getPriceWithExchangeRate(balance, defaultCurrency, defaultCurrency, currency);
              x.payload.balance = balance;
              x.payload.price = getPriceWithExchangeRate(x.payload.price, defaultCurrency, defaultCurrency, currency);
              x.payload.customerBalance = getPriceWithExchangeRate(x.payload.customerBalance, defaultCurrency, defaultCurrency, currency);
              x.payload.priceItems = x.payload.priceItems.map(item => {
                item.price = getPriceWithExchangeRate(item.price, defaultCurrency, defaultCurrency, currency);
                item.unitPrice = getPriceWithExchangeRate(item.unitPrice, defaultCurrency, defaultCurrency, currency);
                return item;
              });
              await SetBook(x.payload);
            }

            if ( balance < 0) {
              this.setState({ loading: false });
            } else {
              history.push(
                tlink('__Routes.bookingPaid', t, i18n, null, settings.configurations.langConfig),
              );
            }
          }
        })
        .catch(error => {
          history.push(
            tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
          );
        });
    }

    if (isWidget) {
      window.top.postMessage(
        { height: document.body.scrollHeight, width: document.body.scrollWidth },
        '*',
      );
    }
  }

  render() {
    const { loadingMessage, loadingGif, background } = this.props;

    if (this.state.loading === true) {
      return (
        <CustomLoading
          loadingMessage={loadingMessage}
          loadingGif={loadingGif}
          background={background}
        ></CustomLoading>
      );
    } else {
      return <ColumnsSection {...this.props}></ColumnsSection>;
    }
  }
}

const mapStateToProps = ({ bookings, siteConfiguration, global }) => ({
  bookings: bookings,
  settings: siteConfiguration.settings,
  listCurrencies: global.listCurrencies,
  defaultCurrency: global.defaultCurrency,
});
export default connect(mapStateToProps, { GetBook, SetBook })(withRouter(withTranslation()(BookingSection)));
