import style from 'styled-components';
import arrow from '../assets/arrow.png';
import { INPUT_BORDER_COLOR } from 'styled/constants';

export const StyledInputModern = style.div`
  .input-wrapper{
    border-width: ${props => props.borderWidth || '1px'};
    border-style: solid;
    border-color: ${props =>
      props.error ? props.theme.colors.alert : props.borderColor || INPUT_BORDER_COLOR};
    display: flex;
    width: 100%;
    height: ${props => props.height || '45px'}
    border-radius: 0;
    justify-content: center;
    align-items: center;
    font-weight: ${props => props.fontWeight || 500};
    font-size: ${props => props.fontSize || '1em'};
    color: ${props => props.color || props.theme.font.colors.secondary};
    background-color: ${props => props.backgroundColor || 'white'};

    span {
        display: flex;
        width: auto;
        height: 100%;
        color: ${props =>
          props.disabled ? INPUT_BORDER_COLOR : props.iconColor || props.theme.font.colors.primary};
        justify-content: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 5px;
        font-size: ${props => props.fontSize || '1.2em'}
     }
    select {
        background: url(${arrow}) no-repeat 97% 50%;
        width: 100%;
        height: 100%;
        border: none;
        font-size:14px;
        font-weight:400;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
    }
  }

  .input-wrapper:hover{
    border:1.3px solid black
  }


  .error{
    width: 100%;
    padding-left: 10px;
    color: ${props => props.theme.colors.alert};
    font-style: italic
  }
  .label{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px
  }
`;
