import React from 'react';
import { PropTypes } from 'prop-types';
import style from 'styled-components';
import { CompositeComponent } from 'components/';

const StyledParallaxSection = style.section`
    min-height: 300px;  
    margin-left:-4%;  
    margin-right:-4%;
    display:block;
    
    background: ${props => (props.background ? `url(${props.background}) no-repeat` : `black`)};   
    
    @media (max-width:${props => props.theme.screens.sm}){
      margin-left:-6%;  
      margin-right:-6%;
    }

    @media (min-width: ${props => props.theme.screens.xxxl}){
      margin-left:-8%;  
      margin-right:-8%;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

`;

export default class ParallaxSection extends CompositeComponent {
  static propTypes = {
    image: PropTypes.string,
  };

  render() {
    const { image } = this.props;

    return (
      <StyledParallaxSection className="parallax row row-p" background={image}>
        {this.renderChilds()}
      </StyledParallaxSection>
    );
  }
}
