import PropTypes from 'prop-types';

export const props = {
  wrapperClass: PropTypes.string,
  inputClass: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.any.isRequired,
  disable: PropTypes.bool,
};

export const defaultProps = {
  checked: false,
  text: 'default text',
  disable: false,
};
