import Promociones from './Promociones';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPromotions } from 'actions';

const mapStateToProps = ({ global }) => ({
  listPromotions: global.listPromotions,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchPromotions,
  })(Promociones),
);
