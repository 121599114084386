import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledStripeSection } from './StripeSection.styled';
import { withTranslation } from 'react-i18next';
import { CustomLoading } from 'components/';
import { tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import { payBooking, payInfraction, getBooking } from 'services/api';
import Storage from 'utils/storage';
import { is_gtag_enabled, gtag_purchase } from '../../../utils/googleTagsHelper';

class StripeSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
      },
    };
  }

  async componentDidMount() {
    const {
      location,
      t,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    const parseQs = queryString.parse(location.search);

    //const clientSecret = parseQs.payment_intent_client_secret;
    const paymentIntent = parseQs.payment_intent;
    const redirectStatus = parseQs.redirect_status;
    const storage = Storage.getElement('stripeTransactionParams', false);

    if (paymentIntent && redirectStatus == 'succeeded' && storage != '') {
      const transactionParams = JSON.parse(storage);
      Storage.setElement('stripeTransactionParams', '', false); //delete storage
      try {
        var params = {
          gatewayId: 'STRIPE',
          orderID: paymentIntent,
          bookingId: transactionParams.bookingId,
          infractionId: transactionParams.infractionId,
          price: transactionParams.price,
          currencyCode: transactionParams.currencyCode,
          exchangeRate: transactionParams.exchangeRate,
        };
        if (transactionParams.bookingId && transactionParams.bookingId > 0) {
          await payBooking(params);
          if (is_gtag_enabled(googleTagManager)) {
            const booking = await getBooking(null, transactionParams.bookingId);
            if (booking && booking.data) gtag_purchase(booking.data, settings);
          }
        } else {
          await payInfraction(params);
        }

        history.push(
          `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
        );
      } catch (error) {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          {
            error: t('payBookingGatewayFailGenericError', {
              bookingId: transactionParams.bookingId,
            }),
          },
        );
      }
    }
  }

  render() {
    const { t, loadingGif, loadingMessage } = this.props;
    return (
      <StyledStripeSection className="col-md-12">
        <CustomLoading
          loadingGif={loadingGif || t('loadingGif')}
          loadingMessage={loadingMessage}
        ></CustomLoading>
      </StyledStripeSection>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(StripeSuccess)));
