import style from 'styled-components';

export const StyledFlotaBigItemModern = style.div`
margin-top:50px;

    .flota-car-img, .car-item {
        @media only screen and (min-width: ${props => props.theme.screens.xl}){
            width: 50%;
            max-width: 50%;
            flex: 50%;
        }
    }

    .car-image{
        border: none;
        margin: 0 4%;
        padding: 4% 10%;

        @media only screen and (max-width: ${props => props.theme.screens.md}){
            margin-left: 0;
            margin-right: 0;
        }
    }

    p{
        font-size: 12px;
        margin-bottom: 1rem;
    }
    

   .details-item p{
        font-size: 12px;
        margin-bottom: 1rem;
        position:relative;
        top:10px;
    }

    .price span{
        font-weight: bold;
    }

    .price strong{
        font-size: 22px;
    }

    h2.car-name{
        font-size: 24px !important;
        font-weight: 700;
        margin-bottom: 5px;
        text-transform: uppercase;
        
        @media only screen and (max-width: ${props => props.theme.screens.md}){
            font-size: 16px;
        }
    }

    .car-category{
        font-weight: 600;
        font-size:14px;
    }

    .car-description {
        color: #6f6f6f;
        margin-bottom: 30px;
        font-size: 12px;
    }

    .details{
        margin-bottom: 12px;
    }

    .car-item .details-item {
        display: inline-flex;
        text-align: center;
        margin-right:20px;
        height: 100%;
    
        
        img{
            width: 100%;
            display: block;
            border: 1px solid #CCCCCC;
            padding: 5px;
            border-radius: 7px;
            margin-bottom: 5px;
        }
    }

    .price p{
        font-size: 16px;
    }

    button{
        width:auto;
        padding:15px 20px;
    }

`;
