import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Btn, TextInput, TextAreaInput, Icon, SelectInput, RangeDateInput } from 'components/';
import { sendContact, listStates } from 'services/api';
import { StyledContactForm } from './ContactForm.styled';
import { StyledContactFormModern } from './ContactForm.styled.modern';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

class ContactForm extends React.Component {
  static propTypes = {
    formType: PropTypes.string,
    askForAgency: PropTypes.bool,
    askForState: PropTypes.bool,
    askForGuestAmount: PropTypes.bool,
    askForRangeDate: PropTypes.bool,
    theme: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const { t } = props;
    this.handleContact = this.handleContact.bind(this);
    this.recaptchaVerify = this.recaptchaVerify.bind(this);
    this.state = {
      userInfo: {
        tcAccept: false,
        needAgencyName: false,
        needStateName: false,
        needGuestAmount: false,
        needRangeDate: false,
      },
      ReCAPTCHAError: false,
      loading: false,
      isVerified: false,
      step: 0,
      captcha: null,
      ReCAPTCHAErrorMessage: null,
      formErrors: {
        fullname: null,
        mail: null,
        leaveamessage: null,
        phone: null,
        agency: null,
        province: null,
        guestAmount: null,
      },
      listProvinces: [],
      rangeDatePicker: {
        orientation:
          window.screen.width < parseFloat(props.theme.screens.md.replace('px', ''))
            ? 'vertical'
            : 'horizontal',
        withFullScreenPortal:
          window.screen.width < parseFloat(props.theme.screens.md.replace('px', '')),
        focus: null,
      },
      pickUp: {
        date: null,
        moment: null,
      },
      dropOff: {
        date: null,
        moment: null,
      },
    };

    this.userInfoFormSchema = yup.object().shape({
      fullname: yup.string().required(t('inputIsRequired', { field: t('enterFullName') })),
      mail: yup
        .string()
        .email()
        .required(t('inputIsRequired', { field: t('enterEmail') })),
      leaveamessage: yup.string().required(t('inputIsRequired', { field: t('enterMessage') })),
      phone: yup
        .string()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          t('invalidPhone'),
        ),
      agency: yup.string().test('isAgencyRequired?', t('agencyNameRequired'), function(agencyName) {
        if (!this.parent.needAgencyName) return true;
        return agencyName && agencyName.length > 0;
      }),
      province: yup
        .string()
        .test('isProvinceRequired?', t('stateNameIsRequired'), function(stateName) {
          if (!this.parent.needStateName) return true;
          return stateName && stateName.length > 0;
        }),
      guestAmount: yup.number().test('needGuestAmount?', t('invalidNumber'), function(guestAmount) {
        if (!this.parent.needGuestAmount) return true;
        return guestAmount > 0;
      }),
    });

    this.formRef = React.createRef(null);
    this.ReCAPTCHARef = React.createRef();
  }

  async componentDidMount() {
    const { askForAgency, askForState, askForGuestAmount, askForRangeDate } = this.props;
    const { listProvinces } = this.state;

    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        needAgencyName: askForAgency,
        needStateName: askForState,
        needGuestAmount: askForGuestAmount,
        needRangeDate: askForRangeDate,
      },
    }));

    if (askForState && listProvinces.length === 0) {
      const response = await listStates();
      if (response.status === 200) this.setState({ listProvinces: response.data });
    }
  }

  recaptchaVerify(res) {
    this.setState({ isVerified: true, captcha: res });
  }

  onFullNameChange = fullname => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, fullname },
      formErrors: { ...prevState.formErrors, fullname: null },
    }));
  };

  onAgencyNameChange = agency => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, agency },
      formErrors: { ...prevState.formErrors, agency: null },
    }));
  };

  onProvinceChange = province => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, province },
      formErrors: { ...prevState.formErrors, province: null },
    }));
  };

  onMailChange = mail => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, mail },
      formErrors: { ...prevState.formErrors, mail: null },
    }));
  };

  onTelephoneChange = phone => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, phone },
      formErrors: { ...prevState.formErrors, phone: null },
    }));
  };

  onMessageChange = leaveamessage => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, leaveamessage },
      formErrors: { ...prevState.formErrors, leaveamessage: null },
    }));
  };

  onGuestAmountChange = guestAmount => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, guestAmount },
      formErrors: { ...prevState.formErrors, guestAmount: null },
    }));
  };

  handleDateFocus = focus => {
    this.setState(prevState => ({
      ...prevState,
      rangeDatePicker: { ...prevState.rangeDatePicker, focus },
    }));
  };

  handleDateSelect = (startDate, endDate) => {
    if (!startDate) return false;
    const pickUpDate = startDate.format('DD-MM-YYYY');
    let dropOffDate = '';
    if (endDate) dropOffDate = endDate.format('DD-MM-YYYY');

    this.setState(prevState => ({
      ...prevState,
      pickUp: {
        ...prevState.pickUp,
        date: pickUpDate,
        moment: startDate,
      },
      dropOff: {
        ...prevState.dropOff,
        date: dropOffDate,
        moment: endDate || prevState.dropOff.moment,
      },
    }));
  };

  isDayBlocked = () => {
    return false;
  };

  handleContact = event => {
    event.preventDefault();
    const { formType } = this.props;
    const userInfo = this.state.userInfo;

    this.userInfoFormSchema
      .validate(userInfo, { abortEarly: false })
      .then(valid => {
        if (valid && this.state.isVerified) {
          const parsedContact = {
            fullName: this.state.userInfo.fullname,
            telephone: this.state.userInfo.phone,
            email: this.state.userInfo.mail,
            agency: this.state.userInfo.agency,
            state: this.state.userInfo.province,
            guestAmount: this.state.userInfo.guestAmount,
            fromDate: this.state.pickUp.moment ? this.state.pickUp.moment.format('DD-MM-YYYY') : '',
            toDate: this.state.dropOff.moment ? this.state.dropOff.moment.format('DD-MM-YYYY') : '',
            message: this.state.userInfo.leaveamessage,
            reCAPTCHA: this.state.captcha,
            formType: formType || 'Contact',
          };
          this.setState({ loading: true });
          sendContact(parsedContact)
            .then(res => {
              this.setState({ step: 1 });
            })
            .catch(error => {
              if (
                error.response == null ||
                error.response.data == null ||
                error.response.data.code !== 100
              ) {
                this.setState({ step: 2 });
              } else {
                this.setState({
                  loading: false,
                  ReCAPTCHAErrorMessage: error.response.data.message,
                  ReCAPTCHAError: true,
                });
                this.ReCAPTCHARef.current.reset();
              }
            });
        }
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });

    return;
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledContactFormModern;
    }
    return StyledContactForm;
  }

  contactForm() {
    const {
      t,
      className,
      leaveamessage,
      i18n,
      askForAgency,
      askForState,
      askForGuestAmount,
      askForRangeDate,
    } = this.props;
    const {
      rangeDatePicker: { orientation, focus, withFullScreenPortal },
    } = this.state;

    const StyledComponent = this.getTemplateStyledComponent();

    return (
      <StyledComponent className={`${className || ''}`} leaveamessage={leaveamessage}>
        <div ref={this.formRef}>
          <TextInput
            inputClass="contact-form_input form-control"
            className="form-control mb-3"
            type="text"
            placeholder={t('fullname')}
            error={this.state.formErrors.fullname}
            onChange={this.onFullNameChange}
            options={{ autoComplete: 'name' }}
          />
          {askForAgency && (
            <TextInput
              inputClass="contact-form_input form-control"
              className="form-control mb-3"
              type="text"
              placeholder={t('agencyName')}
              error={this.state.formErrors.agency}
              onChange={this.onAgencyNameChange}
              options={{ autoComplete: 'agencyname' }}
            />
          )}
          <div className="row">
            <div className="col">
              <TextInput
                inputClass="contact-form_input form-control"
                type="mail"
                className="form-control"
                error={this.state.formErrors.mail}
                placeholder={t('mail')}
                onChange={this.onMailChange}
                options={{ autoComplete: 'email' }}
              />
            </div>
            <div className="col">
              <TextInput
                inputClass="contact-form_input form-control"
                type="text"
                className="form-control"
                error={this.state.formErrors.phone}
                placeholder={t('phone')}
                onChange={this.onTelephoneChange}
                options={{ autoComplete: 'tel' }}
              />
            </div>
          </div>
          {askForState && (
            <SelectInput
              inputClass="form-control"
              name="province"
              selected={this.state.userInfo.province || ''}
              options={this.state.listProvinces.map(prov => prov.name)}
              onChange={this.onProvinceChange}
              error={this.state.formErrors.province}
            />
          )}
          {askForGuestAmount && (
            <TextInput
              inputClass="contact-form_input form-control number"
              className="form-control mb-3"
              type="number"
              placeholder={t('guestAmount')}
              error={this.state.formErrors.guestAmount}
              onChange={this.onGuestAmountChange}
              options={{ pattern: '[0-9]*', inputMode: 'numeric' }}
            />
          )}
          {askForRangeDate && (
            <div className="row mb-3">
              {' '}
              <div className="col">
                <RangeDateInput
                  onDatesChange={this.handleDateSelect}
                  onFocusChange={this.handleDateFocus}
                  startDateId="drop-up-date-start"
                  startDate={this.state.pickUp.moment}
                  endDate={this.state.dropOff.moment}
                  icon="far fa-calendar-alt"
                  iconColor="#000"
                  isDayBlocked={this.isDayBlocked}
                  datePickerOptions={{
                    minimumNights: 1,
                    focusedInput: focus,
                    orientation: orientation,
                    withFullScreenPortal: withFullScreenPortal,
                    customArrowIcon: <span className="fas fa-arrow-right p-0 mr-2" />,
                    startDatePlaceholderText: t('deliveryDate'),
                    endDatePlaceholderText: t('return'),
                    hideKeyboardShortcutsPanel: true,
                  }}
                />
              </div>
            </div>
          )}

          <TextAreaInput
            inputClass="form-control"
            className="form-control mb-4"
            rows={5}
            placeholder={leaveamessage || t('leaveamessage')}
            onChange={this.onMessageChange}
            error={this.state.formErrors.leaveamessage}
            value={this.state.userInfo.leaveamessage}
          />
          <ReCAPTCHA
            // TODO: take this from site configuration
            sitekey="6LePeSUTAAAAANnXGXzC52BIeAMN8f7MHhFX86oO"
            onChange={this.recaptchaVerify}
            className="recaptcha"
            ref={this.ReCAPTCHARef}
            hl={i18n.language}
          />
          {this.state.ReCAPTCHAError && (
            <p style={{ color: '#FF0000' }}>{this.state.ReCAPTCHAErrorMessage}</p>
          )}
          <Btn
            className="btnContact"
            onClick={this.handleContact}
            disabled={!this.state.isVerified || this.state.loading}
            text={this.state.loading ? t('sending') : t('send')}
          />
        </div>
      </StyledComponent>
    );
  }

  errorMessage() {
    const { t } = this.props;
    return (
      <div className="col-md-8 offset-md-2 text-center">
        <Icon className="fas fa-times-circle display-3 mb-5 text-danger"></Icon>
        <h4>{t('contactFormFail')}</h4>
      </div>
    );
  }

  successMessage() {
    const { t } = this.props;
    return (
      <div className="col-md-8 offset-md-2 text-center">
        <Icon className="fas fa-check-circle display-3 mb-5 text-success"></Icon>
        <h4>{t('contactFormSucces')}</h4>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    if (this.state.step === 0) {
      return this.contactForm();
    } else if (this.state.step === 1) {
      const thanksUrl = this.props.history.location.pathname + '/' + t('graciasUrl');
      window.history.pushState('', '', thanksUrl);

      return this.successMessage();
    } else {
      const errorUrl = this.props.history.location.pathname + '/error';
      window.history.pushState('', '', errorUrl);
      return this.errorMessage();
    }
  }
}

export default withRouter(withSettingsContext(withTheme(withTranslation()(ContactForm))));
