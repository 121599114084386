import React from 'react';
import { PropTypes } from 'prop-types';
import style from 'styled-components';

const StyledAfipLogo = style.a`
  img{
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
  }
`;

export default class AfipLogo extends React.Component {
  static propTypes = {
    url: PropTypes.string,
  };

  render() {
    return (
      <StyledAfipLogo {...this.props} href={this.props.url} target="_F960AFIPInfo">
        <img src="https://rently.azureedge.net/street/Web/afip.jpg" alt="AFIP" />
      </StyledAfipLogo>
    );
  }
}
