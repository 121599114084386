import styled from 'styled-components';

export const StyledPromotions = styled.section`
  background-color: ${props => props.bgColor || '#f2f2f2'};
  color: ${props => props.color || '#6f6f6f'} !important;

  h3,
  div h3 {
    font-weight: ${props => props.titleWeight || 'bold'};
    font-style: ${props => props.titleStyle || 'normal'};
    text-transform: ${props => props.titleTransform || 'uppercase'};
    color: ${props => props.color || '#6f6f6f'} !important;
  }

  p.lead {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 3rem;
    color: ${props => props.color || '#6f6f6f'} !important;

    @media (max-width: ${props => props.theme.screens.lg}) {
      font-size: 1rem;
    }
  }

  .section-promo-carousel-container {
    margin: 3rem 0;
  }

  .promo-image {
    max-height: 500px;
  }
  button {
    color: ${props => props.buttonColor || '#fff'};
    background: ${props => props.buttonBkg || props.theme.colors.primary};
    &:hover {
      background: ${props => props.buttonBkgHover || 'inhearit'};
      color: ${props => props.buttonColorHover || '#inhearit'};
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding-top: 2rem !important;

    div.promo-title {
      padding: 4% !important;
    }
  }

  p,
  button {
    @media (max-width: ${props => props.theme.screens.md}) {
      display: none;
    }
  }
`;
