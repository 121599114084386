import React, { useState, useEffect } from 'react';
import { StyledCurrenciesDropdown } from './CurrenciesDropdown.styled';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Storage from 'utils/storage';
import { withRouter } from 'react-router-dom';

const CurrenciesDropdown = ({
  currentCurrency,
  currencies,
  handleOnChange,
  classNames,
  color,
  bgColor,
  hoverBkg,
  hoverColor,
  location,
}) => {
  const [dropOpen, setDropOpen] = useState(false);

  const handleChangeCurrency = currency => {
    handleOnChange(currency);
    Storage.setElement('currency', JSON.stringify(currency));
  };

  useEffect(() => {
    const urlCurrency = queryString.parse(location.search).currencyCode;
    if (urlCurrency) {
      const currency = currencies.find(c => c.isoCode === urlCurrency.toUpperCase());
      if (currency) {
        return handleChangeCurrency(currency);
      }
    }

    const userCurrency = Storage.getElement('currency');
    if (userCurrency) {
      return handleOnChange(JSON.parse(userCurrency));
    }
  }, []);

  const toggleDropdown = () => setDropOpen(!dropOpen);

  const checkCurrency = currency => currentCurrency === currency;

  const getCurrencyName = currency => currency?.name || '';

  const changeCurrency = currency => {
    if (!checkCurrency(currency)) {
      handleChangeCurrency(currency);
    }
    toggleDropdown();
  };

  return (
    currencies.length > 1 && (
      <StyledCurrenciesDropdown
        className={`${classNames}`}
        color={color}
        bgColor={bgColor}
        hoverBkg={hoverBkg}
        hoverColor={hoverColor}
        onClick={toggleDropdown}
      >
        <span className="currentCurrency">
          <span className="baseLang">{getCurrencyName(currentCurrency)}</span>
        </span>
        <div className={`dropdownOptions ${dropOpen && 'open'}`}>
          {currencies.map(currency => {
            if (currentCurrency === currency) return null;

            return (
              <span
                key={`index_${currency.name}`}
                className={`${checkCurrency(currency) ? 'active' : ''} dropdownItem`}
                onClick={() => changeCurrency(currency)}
              >
                <span className="baseLang">{getCurrencyName(currency)}</span>
              </span>
            );
          })}
        </div>
      </StyledCurrenciesDropdown>
    )
  );
};

const mapStateToProps = ({ global }) => ({
  listCurrencies: global.listCurrencies,
  currentCurrency: global.currentCurrency,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(CurrenciesDropdown)));
