import React from 'react';
import { StyledLandingSection } from './LandingSection.styled';
import { props } from './LandingSection.props';
import { Title, TranslatedComponent, PageTitle, Separator, Image, Btn } from 'components/';
import { HeroBanner, ContactSection } from 'sections/';
import { withTranslation } from 'react-i18next';
import Paragraph from 'components/CMS/Paragraph/Paragraph';
import { StyledLandingSectionModern } from './LandingSection.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import withSettingsContext from 'SettingsContext';

class LandingSection extends TranslatedComponent {
  static propTypes = props;

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledLandingSectionModern;
    }
    return StyledLandingSection;
  }

  getFirstColumnWidth() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-lg-6';
    }
    return 'col-lg-4 offset-lg-2';
  }

  getSndColumnWidth() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-lg-6';
    }
    return 'col-lg-4';
  }

  render() {
    const {
      offices,
      description,
      formBkg,
      formBkgAlt,
      formTitle,
      title,
      subTitle,
      placeId,
      pickUpEndpoint,
      relatedRoutes,
      t,
      kmColor,
      settings,
      showCity,
      formOrientation,
    } = this.props;

    const formSection = [
      {
        component: 'SearchForm',
        searchRoute: '__Routes.search',
        formOrientation: formOrientation || 'vertical',
        pickUpEndpoint: pickUpEndpoint,
        placeId: placeId,
        showCity: showCity,
      },
    ];

    const theme = settings.theme.template;

    const StyledSection = this.getTemplateStyledComponent();

    return (
      <>
        <StyledSection kmColor={kmColor}>
          <HeroBanner
            background={formBkg}
            backgroundAlt={formBkgAlt}
            className="img-webp"
            title={this.tt(formTitle)}
            type="h2"
            sections={formSection}
          />
          <div className="mt-4 pt-1 mb-5 landing-title-wrapper">
            <PageTitle
              title={this.tt(title)}
              titleType="h1"
              subTitle={this.tt(subTitle)}
              subtitleType="h2"
              className="mt-5 pb-3"
            />
          </div>
          <ContactSection className="mb-5" offices={offices} form={false} />
          <div className="pt-5 pb-5 description row">
            {description.map((description, i) => (
              <div className="col-12 row pr-0 pl-4" key={i}>
                <div className={this.getFirstColumnWidth()}>
                  {description.title && (
                    <Title
                      fontSize={theme === MODERN_THEME ? '30' : '16'}
                      italic={false}
                      type="h3"
                      weight="400"
                      text={description.title}
                      className="mt-4 titleInfo"
                    />
                  )}
                  {theme !== MODERN_THEME && description.title && <Separator className="pl-3" />}
                  {description.subtitle_1 && (
                    <Title
                      fontSize={theme === MODERN_THEME ? '28' : '14'}
                      italic={false}
                      type="h1"
                      weight="600"
                      text={description.subtitle_1}
                      className="description-subtitle"
                    />
                  )}
                  {description.subtitle_2 && (
                    <Title
                      fontSize={theme === MODERN_THEME ? '28' : '14'}
                      italic={false}
                      type="h2"
                      weight="600"
                      text={description.subtitle_2}
                      className="description-subtitle"
                    />
                  )}
                  {description.subtitle_3 && (
                    <Title
                      fontSize={theme === MODERN_THEME ? '28' : '14'}
                      italic={false}
                      type="h3"
                      weight="600"
                      text={description.subtitle_3}
                      className="description-subtitle"
                    />
                  )}
                  {description.p1 && <Paragraph text={description.p1} />}
                  {description.p2 && <Paragraph text={description.p2} />}
                </div>
                {description.img && (
                  <div className={`imgText ${this.getSndColumnWidth()}`}>
                    <Image
                      alt={description.img.alt}
                      className="img-fluid d-md-none d-lg-block"
                      src={description.img.src}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          {relatedRoutes && (
            <div className="container routes pt-5 pb-3">
              <div className="row">
                <div className="w-100">
                  <h3 className="text-center w-100">{this.tt(relatedRoutes.title)}</h3>
                  <p className="text-center w-100 mb-4">{this.tt(relatedRoutes.subtitle)}</p>
                </div>
                <div className="routes-container w-100">
                  {relatedRoutes.routes.map(item => (
                    <div className="routes-item mb-5 pl-1 pr-1">
                      <p className="km">{item.km}</p>
                      <p className="location mb-2">
                        {item.customLocation
                          ? this.tt(item.location)
                          : `${t('to')} ${item.location}`}
                      </p>
                      <Btn
                        text={relatedRoutes.btnText || t('seeRoute')}
                        link={item.maplink}
                        fontSize="14px"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </StyledSection>
      </>
    );
  }
}

export default withSettingsContext(withTranslation()(LandingSection));
