import React from 'react';
import { props } from './Promociones.props';
import { Carousel } from 'react-bootstrap';
import { TranslatedComponent, Btn, Title, Paragraph, Loading } from 'components/';
import { StyledPromotions } from './Promotions.styled';
import { withTranslation } from 'react-i18next';
import { tlink } from 'utils/translationHelper';
import withSettingsContext from 'SettingsContext';
import { StyledPromotionsModern } from './Promotions.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Promotions extends TranslatedComponent {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const { fetchPromotions, i18n } = this.props;

    try {
      await fetchPromotions(i18n.language);
    } catch {}

    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }));
  }

  handleButtonClick = () => {
    const { history, buttonLink, t, i18n, settings } = this.props;
    history.push(tlink(buttonLink, t, i18n, null, settings.configurations.langConfig));
    window.scrollTo(0, 0);
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledPromotionsModern;
    }
    return StyledPromotions;
  }

  render() {
    const {
      title,
      titleType,
      titleSecondLine,
      subTitle,
      buttonText,
      color,
      listPromotions,
      bgColor,
      buttonBkg,
      buttonColor,
      buttonBkgHover,
      buttonColorHover,
      titleWeight,
      titleStyle,
      interval,
      titleTransform,
      secondaryBtn,
      excludedPromotions,
      includedPromotions,
    } = this.props;

    const StyledSection = this.getTemplateStyledComponent();

    let listPromotionsAux = listPromotions;

    if (
      listPromotionsAux != null &&
      listPromotionsAux.length > 0 &&
      includedPromotions !== undefined &&
      includedPromotions.length > 0
    ) {
      listPromotionsAux = listPromotions.filter(f => includedPromotions.includes(f.id));
    } else if (
      listPromotionsAux != null &&
      listPromotionsAux.length > 0 &&
      excludedPromotions !== undefined
    ) {
      listPromotionsAux = listPromotions.filter(f => !excludedPromotions.includes(f.id));
    } else {
      listPromotionsAux = listPromotions;
    }

    return (
      <StyledSection
        className="row row-p pt-5 pb-5"
        color={color}
        bgColor={bgColor}
        buttonBkg={buttonBkg}
        buttonBkgHover={buttonBkgHover}
        buttonColor={buttonColor}
        buttonColorHover={buttonColorHover}
        titleWeight={titleWeight}
        titleStyle={titleStyle}
        titleTransform={titleTransform}
        secondaryBtn={secondaryBtn}
      >
        <div className="col-md-4 pt-5 pb-5 promo-title">
          <Title type={titleType || 'h3'} text={this.tt(title)} />
          <Title type="h3" text={this.tt(titleSecondLine)} />
          <Paragraph className="mb-5 lead" text={subTitle} />
          {buttonText && <Btn text={this.tt(buttonText)} onClick={this.handleButtonClick} />}
        </div>
        <div className="col-md-7 offset-md-1 carousel-wrapper">
          <Carousel controls={false} interval={interval || 2000}>
            {this.state.loading ? (
              <Loading show />
            ) : (
              listPromotionsAux &&
              listPromotionsAux.map((promo, key) => (
                <Carousel.Item key={`${key}-${promo.name}`}>
                     <LazyLoadImage
                        alt={`${key}-${promo.name}`}
                        src={promo.imagePath}
                        threshold={100}
                        effect="blur"
                        wrapperClassName="d-block w-100 promo-image"
                      />
                </Carousel.Item>
              ))
            )}
          </Carousel>
        </div>
      </StyledSection>
    );
  }
}

Promotions.propTypes = props;
export default withSettingsContext(withTranslation()(Promotions));
