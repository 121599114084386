import { useLayoutEffect, useRef } from "react";
import * as React from 'react'
import {
  Btn,
} from 'components/';

const PoyntCollect = ({ url, businessId, applicationId, onSuccess, getPayButtonText }) => {
  const collect = useRef();

  const onSubmit = () => {
    collect.current.getNonce({});
  };

  useLayoutEffect(() => {
    const poyntCollect = document.createElement("script");
    // poyntCollect.src = "https://cdn.poynt.net/ote/collect-ote.js"; //staging
    // poyntCollect.src = "https://cdn.poynt.net/ci/collect-ci.js"; //develop
    // poyntCollect.src = "https://cdn.poynt.net/collect.js"; //prod
    poyntCollect.src = url;
    poyntCollect.async = true;

    const options = {
      displayComponents: {
        firstName: true,
        lastName: true,
        emailAddress: true,
        labels: true,
      },
      iFrame: {
        width: "100%",
        height: "250px",
        border: "1px",
        borderRadius: "4px",
      },
      style: {
        theme: "default",
      },
      locale: "en-US",
      fields: {
        emailAddress: "",
      },
      additionalFieldsToValidate: ["firstName", "lastName", "emailAddress"],
      customCss: {
        container: {
          "color": "#111",
          "font-family": "Roboto, sans-serif",
          "height": "auto",
          "flex-flow": "row wrap",
          "justify-content": "normal",
          "align-content": "center",
          "margin": "0 auto",
        },
        inputDefault: {
          "color": "#111",
          "font-family": "Roboto, sans-serif",
          "font-size": "15px",
          "line-height": "20px",
        },
        rowFirstName: {
          "width": "50%"
        },
        rowLastName: {
          "width": "50%"
        },
        rowCardNumber: {
          "width": "50%"
        },
        rowExpiration: {
          "width": "25%"
        },
        rowCVV: {
          "width": "25%"
        },
        rowEmailAddress: {
          "width": "100%"
        }
      },
    };

    poyntCollect.onload = () => {
      // collect.current = new window.TokenizeJs(
      //   '38684928-b670-47f8-aec0-03382a9cd593', // Business ID
      //   "urn:aid:12315d2f-6235-40a0-a5cd-634250845540", //Application ID
      // );

      collect.current = new window.TokenizeJs(
        businessId,
        applicationId,
      );

      collect.current.mount("card-element", document, options);

      collect.current.on("ready", event => {
        console.log("collect ready");
      });

      collect.current.on("nonce", event => {
        console.log("collect nonce", event);
        onSuccess(event.data.nonce);
      });

      collect.current.on("error", event => {
        console.log("collect error", event);
        if (event.data.error.source == "submit") {
          alert(event.data.error.message);
        }
      });
    };

    document.head.appendChild(poyntCollect);

    return () => {
      collect.current.unmount("card-element", document);
      collect.current = null;
    }
  }, []);

  return (
    <>
      <div id="card-element"></div>
      <Btn
        id="submit"
        type="submit"
        text={getPayButtonText()}
        className="ml-2 poynt-pay_button"
        onClick={onSubmit}
      />
    </>
  )
};

export default PoyntCollect;
