import React, { Component } from 'react';
import { props } from './RangeDateInput.props';
import { StyledInput } from './RangeDateInput.styled';
import RangeDatePicker from '../range-date-picker/RangeDatePicker';

class RangeDateInput extends Component {
  static propTypes = props;
  render() {
    const {
      wrapperClass,
      color,
      onDatesChange,
      onFocusChange,
      borderColor,
      iconColor,
      icon,
      error,
      label,
      datePickerOptions,
      focusedInput,
      startDateId,
      endDateId,
      startDate,
      endDate,
      isDayBlocked,
      isOutsideRange,
    } = this.props;
    return (
      <StyledInput
        color={color}
        className={wrapperClass}
        borderColor={borderColor}
        iconColor={iconColor}
        error={error}
      >
        {label && (
          <label htmlFor="drop-up-date-start" className="label">
            {label}
          </label>
        )}
        <div className="input-wrapper">
          {icon && <span className={icon} />}
          <div className="date-wrapper">
            <RangeDatePicker
              startDateId={startDateId || `${Math.random()}`}
              startDate={startDate}
              endDate={endDate}
              endDateId={endDateId || `${Math.random()}`}
              noBorder
              block
              onDatesChange={onDatesChange}
              onFocusChange={onFocusChange}
              settings={datePickerOptions}
              focusedInput={focusedInput}
              isDayBlocked={isDayBlocked}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>
        {error && <div className="error">{error}</div>}
      </StyledInput>
    );
  }
}
export default RangeDateInput;
