export default class Storage {
  /*
   * store an element on the local/session storage
   * @param {string} index
   * @param {string} data
   * @param {bool} isLocal
   * @return {void}
   * */
  static setElement(index, data, isLocal = true) {
    if (isLocal) {
      window.localStorage.setItem(index, data);
    } else {
      window.sessionStorage.setItem(index, data);
    }
  }

  /*
   * get an element on the local/session storage
   * @param {string} index
   * @param {bool} isLocal
   * @return {string}
   * */
  static getElement(index, isLocal = true) {
    if (isLocal) {
      return window.localStorage.getItem(index);
    } else {
      return window.sessionStorage.getItem(index);
    }
  }

  /*
   * remove an element on the local/session storage
   * @param {string} index
   * @param {bool} useSession
   * @return {Promise<any>}
   * */
  static removeElement(index, useSession = false) {
    var storage = useSession ? sessionStorage : localStorage;

    return new Promise((resolve, reject) => {
      storage.removeItem(index);
      resolve(true);
    });
  }

  /*
   * clear the local/session storage
   * @param {bool} isLocal
   * @return {void}
   * */
  static clear(isLocal = true) {
    if (isLocal) {
      window.localStorage.clear();
    } else {
      window.sessionStorage.clear();
    }
  }

  /*
  Gets the item from the localstorage. If not exists or its expired (30 minutes by default)
  use the callback (async) to get the item and save it in the storage.
  If the callback fails or return null, the expired item will be used.
  */
  static async getOrUpdateItem(name, callback, expiresIn = 30, useSession = false) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCK === 'true')
      return callback();

    var storage = useSession ? sessionStorage : localStorage;

    var sItem = JSON.parse(storage.getItem(name));

    if (!sItem || sItem.expiresOn <= Date.now()) {
      try {
        let newItem = await callback();

        if (newItem) {
          let expiresOn = new Date();

          sItem = {
            value: newItem,
            expiresOn: expiresOn.setMinutes(expiresOn.getMinutes() + 30),
          };

          storage.setItem(name, JSON.stringify(sItem));
        }
      } catch (err) {
        // TODO log
        throw err;
      }
    }
    return sItem == null ? null : sItem.value;
  }
}
