import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import global from './global.reducer';
import siteConfiguration from './siteConfiguration.reducer';
import searchCars from './searchCars.reducer';
import bookings from './bookings.reducer';
import infractions from './infractions.reducer';
import user from './user.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    global,
    searchCars,
    siteConfiguration,
    bookings,
    infractions,
    user,
  });
