import React from 'react';
import { props } from './NumberInput.props';
import { StyledInput } from './NumberInput.styled';

const NumberInput = ({
  wrapperClass,
  inputClass,
  color,
  onChange,
  borderColor,
  fontSize,
  iconColor,
  placeholder,
  icon,
  error,
  label,
}) => (
  <StyledInput
    color={color}
    fontSize={fontSize}
    className={wrapperClass}
    borderColor={borderColor}
    iconColor={iconColor}
    error={error}
  >
    {label && <div className="label">{label}</div>}
    <div className="input-wrapper">
      {icon && <span className={icon} />}
      <input
        className={inputClass}
        type="number"
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
    {error && <div className="error">{error}</div>}
  </StyledInput>
);

NumberInput.propTypes = props;
export default NumberInput;
