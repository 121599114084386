import style from 'styled-components';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: 100%;
  height: ${props => props.slideHeight || '600px'};
  object-fit: cover;
  object-position: center right;

  @media only screen and (max-width: ${props => props.theme.screens.md}) {
    height: ${props => props.slideMobileHeight || '250px'};
  }
`;

export const StyledSlider = style.section`
    margin-right: -15px;
    margin-left: -15px;   

    @media only screen and (max-width: ${props => props.theme.screens.md}){
        display: flex;
        flex-direction: column;
    }

    .slider{
        height: ${props => props.slideHeight || '600px'};
        z-index: 0;
        order: 1;
        overflow: hidden;

        @media only screen and (max-width: ${props => props.theme.screens.md}){
            height: ${props => props.slideMobileHeight || '250px'};
           
        }
    }
    .row{
        order: 2;
    }
    .home-form {
        position: absolute;
        z-index: 1;
        max-width: fit-content;
    
        @media only screen and (max-width: 1800px) {
            flex: auto !important;
        }
        @media only screen and (max-width: ${props => props.theme.screens.md}){
          position: relative; 
          padding: 0;
          margin-top: 2rem !important;

          .quoted {
            color: ${props => props.quotedMobileColor || props.theme.font.colors.primary};
            text-align: center;    
            border-bottom: 1px solid #f2f2f2;
            width: 87%;
            margin: auto;
          }
          form {
            box-shadow: 0px 8px 11px rgba(0,0,0,0.05);
          }
        }
    }
    .quoted {
        color: ${props => props.quotedColor || '#fff'};
    }

    .lazy-load-image-background.blur.lazy-load-image-loaded {
        width: 100% !important;
    }

    .slick-dots {
        bottom: 25px;

        li button:before {
            color: ${props => props.dotsColor || '#fff'};
            font-size: ${props => props.dotsSize || '11px'};
        }
        @media only screen and (max-width: ${props => props.theme.screens.md}){
            bottom: 15px;

            li button:before {
                font-size: 9px;
            }
        }
    }
`;

export const StyledItem = style.div`
    width: 100%;
    background-image: url(${props => props['data-src'] || ''});
    background-size: cover;
    height: ${props => props.slideHeight || '600px'};
    background-position: center right;

    @media only screen and (max-width: ${props => props.theme.screens.md}){
        height: ${props => props.slideMobileHeight || '250px'};
       
    }
`;
