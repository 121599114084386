import React from 'react';
import { StyledHeaderSlogan } from './header.styled';
import { sloganProps, sloganDefaultProps } from './header.props';
import { useTranslation } from 'react-i18next';
import { tt } from '../../utils/translationHelper';

const HeaderSlogan = ({
  subLabel,
  subLabelColor,
  subLabelHover,
  subLabelWeight,
  label,
  label2,
  labelColor,
  labelColorHover,
  labelFontSize,
  className,
  labelStyle,
  displayIcon,
}) => {
  const { t } = useTranslation();

  return (
    <StyledHeaderSlogan
      className={`header-slogan ${className}`}
      labelColor={labelColor}
      labelColorHover={labelColorHover}
      labelFontSize={labelFontSize}
      subLabelColor={subLabelColor}
      subLabelHover={subLabelHover}
      subLabelWeight={subLabelWeight}
      labelStyle={labelStyle}
      displayIcon={displayIcon}
      label2={label2}
    >
      {label2 ? (
        <div>
          <a href={`tel:${label2}`} className="label">
            {label2} <i className="fa fa-phone ml-1"></i>
          </a>
        </div>
      ) : (
        ''
      )}
      <a href={`tel:${label}`} className="label">
        {label}
        <i className="fa fa-phone"></i>
      </a>
      <br />
      <p className="sub-label">{tt(subLabel, t)}</p>
    </StyledHeaderSlogan>
  );
};

HeaderSlogan.propTypes = sloganProps;
HeaderSlogan.defaultProps = sloganDefaultProps;

export default HeaderSlogan;
