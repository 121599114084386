import style from 'styled-components';

export const StyledSearchForm = style.form`
  background-color: ${props =>
    props.transparent ? props.theme.colors.transparent : props.theme.colors.bg};
  box-shadow: -2px 10px 12px 1px rgba(46, 45, 53, 0.05);
  font-size: 0.93em;
  min-height: auto;
  font-size: 13px;
  border-radius: 12px;
  padding: 24px; 

  .no-right-padding {
    padding-right: 0 !important;
  }
    
  .no-gutters > [class*='col-'] {
    padding-right: 5px;
    padding-left: 0;
  }

  .diff-drop-place {
    color: #2e2d35;
    cursor: pointer;
    display: block;
    font-size: 14px;
  }

  .diff-drop-place:hover {
    color: ${props => props.subItemHover || props.theme.colors.secondary};
  }

  .DateRangePicker_picker {
    z-index: 10000;
  }

  .DateRangePickerInput {
    background-color: transparent;
  }

  .input-wrapper {
    height: 48px;
    background: transparent;
    border: 1px solid #d8dce2;
    border-radius: 8px;
  }

  .fa-arrow-right {
    color: #e5e5e5 !important;
    font-size: 12px !important;
  }

  .range-date-picker {
    .DateInput {
      width: 40%;
    }

    .DateInput_input {
      font-size: 14px;
      padding-right: 0;
      font-weight: 400;
    }
  }

  .label {
    font-size: 13px;
    font-weight: 500;
    position: relative;
    top: 18px;
    left: 7px;
    background-color: #fff;
    padding: 5px;
    z-index: 1;
    color: #656970;
  }

  .category-select select {
    padding-left: 12px;
  }

  .hour-select {
    white-space: nowrap;
    .input-wrapper {
      height: 48px;
      margin-bottom: 0.5rem !important;
    }
  }

  p {
    font-size: 13px !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
  }

  .checkboxes {
    display: flex;
    align-items: flex-end;
  }
  .checkboxes > div:first-child {
    padding-right: 10px;
    margin-right: 10px;
  }

  .submit-btn-row {
    justify-content: center;
  }

  .submit-btn {
    width: 100%;
    white-space: nowrap;
    padding: 15px 15px;
    border-radius: 8px;
    margin-top: 35px;
  }

  .label-container {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .form-check-input {
      //position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border-radius: 50%;
      &:after {
        content: '';
        position: absolute;
        display: none;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: white;
      }
    }
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    input:checked ~ .checkmark {
      background-color: ${props => props.theme.colors.buttonPrimaryBgHover};
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  .driver-age {
    .special-checkbox {
      display: flex;
      align-items: center;
      margin-top: -7px;

      & > div {
        margin-right: 10px;
      }

      i {
        font-size: 1rem;
        position: relative;
        top: -2px;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }

    .driver-age-input {
      margin-top: -30px;
      margin-bottom: 20px;

      input {
        padding: 0 12px;
      }
    }

    .special-label {
      label {
        top: 0px;
        margin-right: 10px;
        font-size: 13px;
        font-weight: 600;
      }

      i {
        font-size: 1rem;
        position: relative;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  .error {
    color: red;
  }
  @media (max-width: 992px) {
    .submit-btn {
      position: relative;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: ${props => props.theme.screens.lg}) {
    .return-place-picker {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    .row > div {
      margin-bottom: 0;
    }
    .curstomer-info-row div {
      margin-top: 5px;
    }
    .row {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${props => props.theme.screens.xs}) {
    font-size: 13px;
    .checkboxes {
      flex-direction: column;
      align-items: baseline;
      div {
        margin-bottom: 10px;
      }
    }
    .input-wrapper {
      margin-bottom: 0 !important;
    }

    .label {
      font-size: 12px;
      font-weight: 500;
    }
    .pickup-place-picker,
    .filtro-km,
    .submit-block,
    .submit-block .col-md-12 {
      margin-bottom: 0 !important;
    }

    .diff-drop-place {
      font-size: 13px;
      margin-bottom: 0;
    }
    .hour-select {
      width: 50%;
      display: inline-block;
      margin-bottom: 0 !important;
    }
    .hour-select-from {
      padding-right: 0.5rem !important;
    }
    .hour-select-to {
      padding-left: 0.5rem !important;
    }

    .submit-btn {
      margin-top: 0;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .input-wrapper {
      margin-bottom: 0 !important;
    }
  }

`;
