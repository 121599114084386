import React from 'react';
import { StyledAssociatesSection } from './AssociatesSection.styled';
import { Title, TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';

class AssociatesSection extends TranslatedComponent {
  renderHeader = header => {
    return (
      <div className="associates-header">
        <div>
          <span className="header-icon">
            <img src={header.logoUrl} alt={header.logoAlt} />
          </span>
          <span className="header-title">{this.tt(header.title)}</span>
        </div>
        <p className="header-subtitle">{this.tt(header.subTitle)}</p>
      </div>
    );
  };

  renderItem = (item, key) => {
    return (
      <div className="col mb-3 associate-item" key={`item-${key}`}>
        <div className="row mb-3">
          <div className="item-number">{key + 1}</div>
          <div className="item-icon">icon</div>
        </div>
        <div className="item-title">{this.tt(item.title)}</div>
        <div className="item-description">{this.tt(item.description)}</div>
      </div>
    );
  };

  renderFooter = footer => {
    return <p>{this.tt(footer.text)}</p>;
  };

  render() {
    const { header, items, footer } = this.props;

    return (
      <StyledAssociatesSection highlightColor={this.props.highlightColor}>
        <div className="associate-page col-md-12 p-0">
          {this.renderHeader(header)}
          <div className="container-fluid">
            <div className="row mt-3">
              {items && items.map((item, key) => this.renderItem(item, key))}
            </div>
          </div>
        </div>
        <div className="associate-footer">{this.renderFooter(footer)}</div>
      </StyledAssociatesSection>
    );
  }
}

export default withTranslation()(AssociatesSection);
