import React from 'react';
import { Title, NumberDisplay, Paragraph } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledPaypalSection } from './PaypalSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import PaypalButton from './PaypalButton';
import { history } from '/store';
import { payBooking, payInfraction } from 'services/api';
import { BookingStatus } from '../../../utils/constants';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import { handleGetTotalToPay } from 'utils/dataHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';

import { fetchFiscalConditions } from '../../../actions/global.actions';
import PartialPayWarning from '../partialPayWarning';

class PaypalSection extends BasePaymentSection {
  constructor(props) {
    super(props);

    this.state = {
      paymentInfo: {
        price: 0,
        qsamount: 0,
        bookingId: '0',
        infractionId: '0',
        customerId: '0',
        act: '',
        promotionId: '',
        currencyCode: '',
        exchangeRate: 1,
      },
      forcedCurrencyPrice: 0,
      forcedExchangeRate: 1,
      forcedCurrencyCode: '',
      fiscalConditions: [],
    };
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      fetchFiscalConditions,
      listTaxPayerTypes,
      i18n,
      defaultCurrency,
    } = this.props;

    const tenantFiscalConditions =
      listTaxPayerTypes || (await fetchFiscalConditions(i18n.language))?.payload;

    this.setState(prevState => ({
      ...prevState,
      fiscalConditions: tenantFiscalConditions,
    }));

    const parseQs = queryString.parse(location.search);

    if (this.isPayingBooking() && bookings.confirmation != null) {
      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Paypal' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          promotionId: parseQs.promotionid || null,
          currencyCode: bookings.confirmation.currency,
          //exchangeRate: 1, -> setExchangeRate
        },
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          currencyCode: defaultCurrency.isoCode,
          exchangeRate: 1,
        },
      }));
    }
    this.useForcedCurrencyIfNecessary();
  }

  getTotalToPay() {
    const {
      bookings: { confirmation },
      settings: { paymentConfiguration },
    } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    let totalToPay = handleGetTotalToPay(
      confirmation.customerBalance,
      paymentConfiguration,
      qsamount,
    );
    if (paymentConfiguration?.percentageToPay) {
      const numberOfDecimals = this.getCashBoxConfigurationNumberOfDecimals();
      if (numberOfDecimals == 0) return Math.round(totalToPay);
      else if (numberOfDecimals == 1) return Math.round(totalToPay * 10) / 10;
      else if (numberOfDecimals > 1) return Math.round(totalToPay * 100) / 100;
    }

    return totalToPay;
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= qsamount) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  getCashBoxConfigurationNumberOfDecimals() {
    const { generalSettings } = this.props;
    return generalSettings && generalSettings.cashBoxConfiguration
      ? generalSettings.cashBoxConfiguration.numberOfDecimals
      : 0;
  }

  paypalIsValidISOCode = currencyCode => {
    const validCurrencies = [
      'AUD',
      'BRL',
      'CAD',
      'CZK',
      'DKK',
      'EUR',
      'HKD',
      'HUF',
      'ILS',
      'JPY',
      'MYR',
      'MXN',
      'TWD',
      'NZD',
      'NOK',
      'PHP',
      'PLN',
      'GBP',
      'RUB',
      'SGD',
      'SEK',
      'CHF',
      'THB',
      'USD',
    ];

    if (validCurrencies.some(i => i === currencyCode)) return true;

    return false;
  };

  useForcedCurrencyIfNecessary = () => {
    const { listCurrencies, currencyISOForcedToPay } = this.props;
    const {
      paymentInfo: { currencyCode },
    } = this.state;

    const currencies = listCurrencies;
    const bookingCurrency = currencies.find(currency => currency.isoCode == currencyCode);

    let forced = false;
    let currentIsoCode = bookingCurrency.isoCode;

    //if forced currency has value and is valid on PP, use it.
    if (
      currencyISOForcedToPay != undefined &&
      currencyISOForcedToPay != '' &&
      this.paypalIsValidISOCode(currencyISOForcedToPay)
    ) {
      currentIsoCode = currencyISOForcedToPay;
      forced = true;
    }

    //if forced currency or booking currency are not valid on PP, use USD as default currency.
    if (!this.paypalIsValidISOCode(currentIsoCode)) {
      forced = true;
      currentIsoCode = 'USD';
    }

    const forcedCurrency = currencies.find(currency => currency.isoCode === currentIsoCode);

    //Use forced currency
    if (forced && forcedCurrency != null && bookingCurrency.isoCode !== forcedCurrency.isoCode) {
      const numberOfDecimals = this.getCashBoxConfigurationNumberOfDecimals();
      let convertedPrice = Math.round(this.state.paymentInfo.price * forcedCurrency.exchangeRate);

      if (numberOfDecimals == 1)
        convertedPrice =
          Math.round(this.state.paymentInfo.price * forcedCurrency.exchangeRate * 10) / 10;
      else if (numberOfDecimals == 2)
        convertedPrice =
          Math.round(this.state.paymentInfo.price * forcedCurrency.exchangeRate * 100) / 100;
      else if (numberOfDecimals > 2)
        convertedPrice =
          Math.round(this.state.paymentInfo.price * forcedCurrency.exchangeRate * 1000) / 1000;

      const exchangeRate =
        Math.round((this.state.paymentInfo.price / convertedPrice) * 10000) / 10000;
      this.setState(() => ({
        forcedCurrencyPrice: convertedPrice,
        forcedExchangeRate:
          forcedCurrency.exchangeRate == 1 ? forcedCurrency.exchangeRate : exchangeRate,
        forcedCurrencyCode: forcedCurrency.isoCode,
      }));
    }
  };

  onSuccess = async payment => {
    const {
      t,
      i18n,
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;
    const { forcedCurrencyPrice, forcedExchangeRate, forcedCurrencyCode } = this.state;

    let paymentInfo = this.state.paymentInfo;
    paymentInfo.orderID = payment.id;
    paymentInfo.gatewayId = 'PL';
    if (forcedCurrencyPrice > 0) {
      paymentInfo.price = forcedCurrencyPrice;
      paymentInfo.currencyCode = forcedCurrencyCode;
      paymentInfo.exchangeRate = forcedExchangeRate;
    } else {
      paymentInfo.price = paymentInfo.price;
      paymentInfo.currencyCode = paymentInfo.currencyCode;
      paymentInfo.exchangeRate = paymentInfo.exchangeRate;
    }

    try {
      const res = await this.executePayment(paymentInfo);
      let subtitle;
      if (res.bookingId !== 0)
        subtitle = t('feedbackBoxMessages.book.successSubtitle', {
          booking_id: res.bookingId,
        });

      if (is_gtag_enabled(googleTagManager)) gtag_purchase(bookings.confirmation, settings);
      history.push(
        `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
        { subtitle },
      );
    } catch (error) {
      let messageObject = {};
      let hereLink;
      const errorData = error.config.data ? JSON.parse(error.config.data) : null;

      if (this.isPayingBooking()) {
        messageObject['subtitle'] = t('feedbackBoxMessages.pay.failButConfirmed', {
          booking_id: errorData.bookingId,
        });
        hereLink = tlink(
          '__Routes.onlinePayment',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
          { bookingid: errorData.bookingId },
        );
      }

      if (this.isPayingInfraction()) {
        hereLink = tlink(
          '__Routes.onlinePayment',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
          {
            infractionid: errorData.infractionId,
            customerid: errorData.customerId,
          },
        );
      }

      messageObject['buttonInfo'] = { text: t('tryAgain'), link_to: hereLink };
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        messageObject,
      );
    }
  };

  executePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }

      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction()) {
      if (!this.state.paymentInfo.act) return null;
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
    }
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  buildNewUrl(paypalScript, credentials, currencyCode) {
    const id = credentials?.clientId;
    return id && this.paypalIsValidISOCode(currencyCode)
      ? `${paypalScript}?client-id=${id}&currency=${currencyCode}`
      : null;
  }

  render() {
    const {
      t,
      title,
      subtitle,
      credentials,
      currentCurrency,
      defaultCurrency,
      webCurrency,
    } = this.props;
    const { paymentInfo, forcedCurrencyPrice, forcedCurrencyCode } = this.state;
    const { i18n } = this.props;
    const lang = i18n.language.toUpperCase();
    const paypalScript = 'https://www.paypal.com/sdk/js';

    const newUrl = this.buildNewUrl(
      paypalScript,
      credentials,
      forcedCurrencyPrice > 0 ? forcedCurrencyCode : paymentInfo.currencyCode,
    );

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    //takes the value of the current language and parses it. It will then be used as the language parameter of the PayPal checkout.
    function handleChangeLanguage(newUrl, lang) {
      let parsedLang = lang.split('-').join('_');
      let firstTwo = parsedLang.slice(0, 2).toLowerCase();
      let restOfStr = parsedLang.slice(2);
      let parsedCurrentLang = firstTwo + restOfStr;

      return `${newUrl}&locale=${parsedCurrentLang}`;
    }

    return (
      <StyledPaypalSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            {forcedCurrencyPrice != 0 ? (
              <div className="alert alert-danger" role="alert">
                <i className="fas fa-exclamation-circle mr-2"></i>
                {t('paypalConvertionAlert', forcedCurrencyCode)}
              </div>
            ) : (
              ''
            )}
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
                {forcedCurrencyPrice != 0 ? (
                  <strong>
                    {' '}
                    = {forcedCurrencyCode} <NumberDisplay value={forcedCurrencyPrice} />
                  </strong>
                ) : (
                  ''
                )}
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              {newUrl && (
                <PaypalButton
                  script={newUrl ? handleChangeLanguage(newUrl, lang) : ''}
                  total={forcedCurrencyPrice > 0 ? forcedCurrencyPrice : paymentInfo.price}
                  currencyCode={
                    forcedCurrencyPrice > 0 ? forcedCurrencyCode : paymentInfo.currencyCode
                  }
                  onSuccess={this.onSuccess}
                />
              )}
            </div>
          </div>
        </div>
      </StyledPaypalSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  listCurrencies: global.listCurrencies,
  generalSettings: global.generalSettings,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
  listTaxPayerTypes: global.listTaxPayerTypes,
});
export default connect(mapStateToProps, { fetchFiscalConditions })(
  withRouter(withTranslation()(PaypalSection)),
);
