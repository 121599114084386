import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoadingWrapperStyled } from './CecabankSection.styled';
import { withTranslation } from 'react-i18next';
import { CustomLoading } from 'components/';
import { tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import { payBooking, getBooking } from 'services/api';
import Storage from 'utils/storage';
import { is_gtag_enabled, gtag_purchase } from '../../../utils/googleTagsHelper';

class CecabankSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
      },
    };
  }

  async componentDidMount() {
    const {
      location,
      t,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    const parseQs = queryString.parse(location.search);
    if (parseQs.bookingid) {
      const operationnumber =
        parseQs.operationnumber || Storage.getElement('cecabankoperationnumber');
      const price = parseQs.amount || Storage.getElement('cecabankprice');
      try {
        await payBooking({
          bookingId: parseQs.bookingid,
          gatewayId: 'CECABANK',
          orderID: operationnumber,
          price: price,
        });

        if (is_gtag_enabled(googleTagManager)) {
          const booking = await getBooking(null, parseQs.bookingid);
          if (booking && booking.data) gtag_purchase(booking.data, settings);
        }
        history.push(
          `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
        );
      } catch (error) {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          { error: t('payBookingGatewayFailGenericError', { bookingId: parseQs.bookingid }) },
        );
      }
    } else {
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        {
          error: t('paymentGenericError'),
        },
      );
    }
  }

  render() {
    const { t, loadingGif, loadingMessage } = this.props;
    return (
      <LoadingWrapperStyled className="col-md-12">
        <CustomLoading
          loadingGif={loadingGif || t('loadingGif')}
          loadingMessage={loadingMessage}
        ></CustomLoading>
      </LoadingWrapperStyled>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(CecabankSuccess)));
