import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TextInput, NumberDisplay } from 'components';
import { Collapse, Button } from 'react-bootstrap';
import { MODERN_THEME } from 'styled/constants';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from '../../../utils/priceHelper';

class AdditionalRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleRowClick = (additional, event) => {
    const { seeMore, seeLess } = this.props;
    const buttonsRendered = seeMore && seeLess;

    if (
      buttonsRendered &&
      (event.target.classList.contains('additional-name-button') ||
        event.target.classList.contains('additional-name') ||
        event.target.classList.contains('additional-image'))
    ) {
      return;
    }

    if (additional.isRequired || additional.availableStock < 1) return;
    if (additional.maxQuantityPerBooking === 1) this.onRowCheckedChange();
    else this.onRowValueChange();
  };

  onRowValueChange = () => {
    const { value } = this.props;

    let number = value && value.number > 0 ? value.number - 1 : 1;

    const { additional, listAdditionals, onAdditionalChange } = this.props;
    const maxValue = listAdditionals.find(a => a.id === additional.id).maxQuantityPerBooking;

    const input = document.getElementById(additional.id);
    input.focus();
    input.select();

    if (number > maxValue) return;
    onAdditionalChange({ id: additional.id, number, type: additional.type });
  };

  onRowCheckedChange = async () => {
    const { value, additional, onAdditionalChange } = this.props;

    onAdditionalChange({
      id: additional.id,
      number: !value || value === 0 ? 1 : 0,
      type: additional.type,
    });
  };

  getPrice(additional) {
    const { t, currentCurrency, defaultCurrency, webCurrency } = this.props;
    if (additional.priceWithoutTaxes > 0) {
      let price = additional.isPriceByDay ? additional.dailyPrice : additional.priceWithoutTaxes;

      if (applyCurrencyExchangeRate(currentCurrency, webCurrency)) {
        price = getPriceWithExchangeRate(price, currentCurrency, defaultCurrency, webCurrency);
      }

      return (
        <>
          <NumberDisplay value={price} />
          {' ' + (additional.isPriceByDay ? t('perDay') : t('perBooking'))}
        </>
      );
    } else {
      return t('freeAdditional');
    }
  }

  getAdditionalName(additional, nameMaxLength, nameSplitNumber) {
    const { name, description } = additional;
    const fullName = description ? `${name} - ${description}` : name;
    const collapsedName =
      fullName.length > nameMaxLength ? fullName.slice(0, nameSplitNumber) + '...' : fullName;

    return { fullName, collapsedName };
  }

  onNumberChange = newValue => {
    const { additional, onAdditionalChange } = this.props;
    let number = newValue;

    if (newValue < 0) {
      number = 0;
    }

    if (newValue > additional.maxQuantityPerBooking) {
      number = additional.maxQuantityPerBooking;
    }

    onAdditionalChange({ id: additional.id, number, type: additional.type });
  };

  render() {
    const {
      additional,
      t,
      settings: { theme },
      nameMaxLength,
      nameSplitNumber,
      seeMore,
      seeLess,
    } = this.props;
    const { value, onAdditionalChange } = this.props;
    const { fullName, collapsedName } = this.getAdditionalName(
      additional,
      nameMaxLength,
      nameSplitNumber,
    );
    const { expanded } = this.state;

    return (
      <tr
        id={`additional-${additional.id}`}
        className={
          additional.isRequired || additional.availableStock < 1 ? 'mandatory-additional' : ''
        }
      >
        <td>
          {additional.maxQuantityPerBooking === 1 ? (
            <label className="special-checkbox d-inline">
              <input
                type="checkbox"
                checked={value && value.number > 0 ? true : false}
                onChange={({ target }) =>
                  onAdditionalChange({
                    id: additional.id,
                    number: target.checked ? 1 : 0,
                    type: additional.type,
                  })
                }
                disabled={additional.isRequired || additional.availableStock < 1}
              />
              <span className="checkmark" />
            </label>
          ) : (
            <TextInput
              type="number"
              value={value ? value.number : ''}
              placeholder={''}
              onChange={value => {
                this.onNumberChange(value ? parseInt(value) : 0);
              }}
              inputClass="form-control number"
              wrapperClass="quantity"
              min={0}
              id={additional.id}
              options={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                min: 0,
                max: additional.maxQuantityPerBooking,
              }}
              disabled={additional.isRequired || additional.availableStock < 1}
            />
          )}
        </td>
        <td className="additional-image" onClick={event => this.handleRowClick(additional, event)}>
          <img
            width={theme.template === MODERN_THEME ? '32' : '40'}
            className="d-inline"
            src={additional.imagePath}
            title={additional.name}
            alt={additional.name}
          />
        </td>
        <td onClick={event => this.handleRowClick(additional, event)}>
          <div className="additional-name">
            <Collapse in={expanded}>
              <div>{expanded ? fullName : collapsedName}</div>
            </Collapse>
            {!expanded && (fullName.length > nameMaxLength ? collapsedName : fullName)}
            {fullName.length > nameMaxLength && (
              <Button
                variant="link"
                onClick={() => this.setState({ expanded: !expanded })}
                aria-controls="additional-name-collapse"
                aria-expanded={expanded}
                className={`additional-name-button ${expanded && seeLess ? 'see-less' : ''}`}
              >
                {expanded ? seeLess : seeMore}
              </Button>
            )}
            {additional.isRequired && (
              <span className="mandatory-legend">{t('additionalMandatoryLegend')}</span>
            )}
            {additional.availableStock < 1 && (
              <span className="mandatory-legend">{t('noStock')}</span>
            )}
          </div>
        </td>
        <td
          className="text-right adicional-price"
          onClick={event => this.handleRowClick(additional, event)}
        >
          {this.getPrice(additional)}
        </td>
      </tr>
    );
  }
}

AdditionalRow.propTypes = {
  additional: PropTypes.shape({}),
  listAdditionals: PropTypes.array,
  onAdditionalChange: PropTypes.func,
  seeMore: PropTypes.string,
  seeLess: PropTypes.string,
  nameMaxLength: PropTypes.number,
  nameCharsSplit: PropTypes.number,
  t: PropTypes.func,
};

const mapStateToProps = ({ bookings, siteConfiguration, global }) => ({
  listAdditionals: bookings.listAdditionals,
  settings: siteConfiguration.settings,
  currentCurrency: global.currentCurrency,
  defaultCurrency: global.defaultCurrency,
  webCurrency: global.webCurrency,
});

export default connect(mapStateToProps)(withTranslation()(AdditionalRow));
