import Paragraph from 'components/CMS/Paragraph/Paragraph';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { tt } from 'utils/translationHelper';

const ExchangeMessage = ({ defaultCurrency, currentCurrency, chargedMsg, chargedMsg2, t }) => {
  const firstLine = tt(chargedMsg, t) || t('chargedMsg');
  const secondLine = tt(chargedMsg2, t) || t('chargedMsg2');

  function getExchangeMessage(defaultCurrencyIsoCode, exchangeCurrencyIsoCode, exchangeRate) {
    // the exchange rate is formatted to have the more valuable currency first for more readability
    if (exchangeRate < 1) {
      const exchangeAmount = parseFloat((1 / exchangeRate).toFixed(2));
      return `1 ${exchangeCurrencyIsoCode} = ${exchangeAmount} ${defaultCurrencyIsoCode}`;
    }
    return `1 ${defaultCurrencyIsoCode} = ${exchangeRate} ${exchangeCurrencyIsoCode}`;
  }

  const exchangeMessage = getExchangeMessage(
    defaultCurrency?.isoCode,
    currentCurrency?.isoCode,
    currentCurrency?.exchangeRate,
  );

  return (
    <Paragraph className="rent-days mb-0 mt-2 md-hidden" color={'red'} bold>
      {`${firstLine} ${currentCurrency?.isoCode} ${secondLine} ${exchangeMessage}`}
    </Paragraph>
  );
};

export default withTranslation()(ExchangeMessage);
