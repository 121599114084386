import React from 'react';
import { withTranslation } from 'react-i18next';
import { props, defaultProps } from './CheckoutFilter.props';
import { StyledCheckoutFilter } from './CheckoutFilter.styled';
import { SearchForm, AccordionCard } from 'components/';
import { Accordion } from 'react-bootstrap';
import { withTheme } from 'styled-components';
import withSettingsContext from 'SettingsContext';
import { StyledCheckoutFilterModern } from './CheckoutFilter.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import useWindowDimensions from 'utils/windowHelper';

class CheckoutFilter extends React.Component {
  constructor(props) {
    super(props);
    this.accordion = React.createRef();
  }

  componentDidMount() {
    const { setSearchCarParams, params } = this.props;

    setSearchCarParams(params);
  }

  closeAccordion = () => {
    this.accordion.current.click();
  };

  shapeCarName = name => {
    if (name.length > 50) {
      return name.substring(0, 50) + '...';
    } else {
      return name;
    }
  };

  getModelName(model) {
    const {
      t,
      settings: { featureFlags },
    } = this.props;
    const availabilityGroup =
      featureFlags.availabilityGroup === undefined ? 'model' : featureFlags.availabilityGroup;
    const showOrSimilar =
      featureFlags.ShowOrSimilar === undefined ? true : featureFlags.ShowOrSimilar;
    const legend = availabilityGroup === 'category' && showOrSimilar ? t('orSimilar') : '';

    return `${model.brand.name} ${model.name} ${legend}`;
  }

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledCheckoutFilterModern;
    }
    return StyledCheckoutFilter;
  }

  render() {
    const {
      searchCars: {
        params: { dateFrom, dateTo, categories },
        checkout: { booking },
      },
      t,
      onClick,
      className,
      width,
    } = this.props;

    const StyledComponent = this.getTemplateStyledComponent();

    const mobileSearchForm = (
      <>
        <SearchForm
          className="home-form w-100 mt-5"
          categories={categories}
          onSubmit={this.closeAccordion}
        />
        <div className="overlay"></div>
      </>
    );

    const accordionHeader = (
      <span className="date">
        <i className="far fa-calendar-alt mr-3"></i> {dateFrom}{' '}
        <span className="divider ml-2 mr-2">|</span> {dateTo}
      </span>
    );
    const model = booking.car.model;

    return (
      <StyledComponent className={`${className} col-md-3`} background={this.props.background}>
        {width < 768 && (
          <Accordion className="searchFilter sticky">
            <AccordionCard
              refe={this.accordion}
              title={accordionHeader}
              element={mobileSearchForm}
              key="search"
              keyValue="search"
            />
          </Accordion>
        )}
        <div className="car-block">
          <div className="car-details">
            <div className="car-image">
              <img className="img-fluid" src={model.imagePath} alt={model.name} />
            </div>
            <p>
              {this.shapeCarName(this.getModelName(model))}
              <br />
              <span>
                {t('categoria')} {model.category.name}
              </span>
            </p>
          </div>
          <a className="d-block othercar" onClick={onClick}>
            <i className="fas fa-pencil-alt" />
          </a>
        </div>
      </StyledComponent>
    );
  }
}

const FunctionalCheckoutFilter = props => {
  const { width } = useWindowDimensions();
  return <CheckoutFilter width={width} {...props} />;
};

CheckoutFilter.propTypes = props;
CheckoutFilter.defaultProps = defaultProps;
export default withSettingsContext(withTheme(withTranslation()(FunctionalCheckoutFilter)));
