import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatedComponent } from 'components/';
import { ColumnsSection } from 'components/';

class FeedbackBox extends TranslatedComponent {
  componentDidMount() {
    const { isWidget } = this.props;

    if (isWidget) {
      window.top.postMessage(
        { height: document.body.scrollHeight, width: document.body.scrollWidth },
        '*',
      );
    }
  }

  createBoxItems = () => {
    let { buttonInfo } = this.props;
    const { title, subtitle, message, messageType, routerProps } = this.props;

    let defaultSubtitle, detailedError, bookingId;

    if (routerProps && routerProps.state && routerProps.state.subtitle)
      defaultSubtitle = routerProps.state.subtitle;

    if (routerProps && routerProps.state && routerProps.state.bookingId)
      bookingId = `#${routerProps.state.bookingId}`;

    if (routerProps && routerProps.state && routerProps.state.detailedError)
      detailedError = routerProps.state.detailedError;

    if (routerProps && routerProps.state && routerProps.state.buttonInfo)
      buttonInfo = routerProps.state.buttonInfo;

    let items = [];

    items.push({
      component: 'Icon',
      className:
        messageType === 'success'
          ? 'fas fa-check-circle display-3 mb-5 text-success'
          : 'fas fa-times-circle display-3 mb-5 text-danger',
    });

    items.push({
      component: 'Title',
      type: 'h2',
      text: title,
      paramsObj: bookingId ? { bookingId } : {},
    });

    if (subtitle || defaultSubtitle) {
      items.push({
        component: 'Paragraph',
        text: subtitle ? subtitle : defaultSubtitle,
        weight: 300,
        fontSize: '1.25rem',
        className: 'mb-2',
      });
    }

    if (buttonInfo) {
      items.push({
        component: 'Btn',
        type: 'submit',
        className: 'mt-2 mb-2',
        style: { display: 'inline' },
        text: buttonInfo.text,
        link: buttonInfo.link_to,
        selfLink: buttonInfo.selfLink || true,
      });
    }

    if (messageType === 'error' && detailedError) {
      items.push({
        component: 'Paragraph',
        text: detailedError,
        color: 'red',
      });
    }

    items.push({
      component: 'Paragraph',
      text: message,
      className: 'mb-5 pb-5',
    });

    return items;
  };

  render() {
    const sections = [
      {
        component: 'Column',
        className: 'col-md-8 offset-md-2 text-center mt-5 pt-5',
        sections: this.createBoxItems(),
      },
    ];

    const props = { ...this.props, sections };
    return <ColumnsSection {...props} />;
  }
}

export default withTranslation()(FeedbackBox);
