import style from 'styled-components';

export const StyledContactForm = style.form`
    .recaptcha {
        div {
            margin-top: 10px;
        }
    }

    textarea{
        resize: none;
    }
`;
