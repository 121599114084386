import style from 'styled-components';

export const CustomAlertModern = style.div`
    .alert {
        padding: ${({ dismissible }) => `0.75rem ${dismissible ? '3rem' : '1rem'} 0.75rem 0.7rem`};
        background: #fff;   
        border-radius: 8px;
        margin: 0;

        p {
            color: ${({ theme }) => theme.colors.alert};
            font-size: 0.8rem; 
            font-weight: 400;
            display: flex;
            align-items: center;
        }

        i {
            margin-right: 10px;
        }

        button {
            color: ${({ theme }) => theme.font.colors.secondary};
            top: 0;
            bottom: 0;
        }

        &:hover {
            background: #fff;
        }


    }

    .alert-dismissible .close {
        opacity: 1;
    }
  
    .alert.alert-danger {
        border: ${({ theme }) => `1px solid ${theme.colors.alert}`};

        i {
            color: ${({ theme }) => theme.colors.alert};
        }
        
    }

  
`;
