import React from 'react';
import { PropTypes } from 'prop-types';

class ScriptComponent extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    async: PropTypes.bool,
    scriptType: PropTypes.string,
    content: PropTypes.string,
    loadLibrary: PropTypes.bool,
  };

  state = {
    id: 'script' + Math.random(),
  };

  componentDidMount() {
    if (this.props.loadLibrary) {
      document.body.appendChild(this.getScript());
    } else if (this.props.loadLibraryInHead) {
      document.head.appendChild(this.getScript());
    } else {
      const scriptNode = document.getElementById(this.state.id);
      if (scriptNode) {
        scriptNode.appendChild(this.getScript());
        // eslint-disable-next-line no-eval
        eval(scriptNode.textContent);
      }
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  getScript() {
    const script = document.createElement('script');

    if (this.props.url) script.src = this.props.url;

    if (this.props.async !== undefined) script.async = this.props.async;

    if (this.props.scriptType) script.type = this.props.scriptType;

    if (this.props.content) script.innerHTML = this.props.content;

    if (this.props.onLoaded)
      script.onload =
        typeof this.props.onLoaded === 'string' ? eval(this.props.onLoaded) : this.props.onLoaded;

    return script;
  }

  render() {
    return <div id={this.state.id}></div>;
  }
}

export default ScriptComponent;
