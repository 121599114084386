import PropTypes from 'prop-types';

export const props = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  route: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
};

export const defaults = {
  src: '',
  alt: 'company name',
};
