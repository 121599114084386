import React from 'react';
import { StyledFranchiseSection } from './FranchiseSection.styled';
import { withTranslation } from 'react-i18next';
import { NumberDisplay } from 'components';

class FranchiseSection extends React.Component {
  state = {
    loading: true,
    categories: [],
  };

  async componentDidMount() {
    const { fetchCategories, i18n } = this.props;
    const response = await fetchCategories(i18n.language);
    const categories = this.parseFranchises(response.payload);

    this.setState(prevState => ({
      ...prevState,
      loading: false,
      categories,
    }));
  }

  parseFranchises = categories => {
    if (!categories) return [];
    const {
      settings: { featureFlags },
    } = this.props;
    const availabilityGroup = featureFlags.availabilityGroup || 'model';

    return availabilityGroup === 'category'
      ? this.parseByCategory(categories)
      : this.parseByModel(categories);
  };

  parseByCategory = categories => {
    return categories.map(category => {
      return {
        name: category.name,
        franchise: category.franchise,
        damage: category.franchiseDamage,
        accidents: category.franchiseRollover,
        hail: category.franchiseHail,
      };
    });
  };

  parseByModel = categories => {
    return categories.map(category => {
      const principalModel = this.findPrincipalModel(category.models, category.principalModelId);
      return {
        name: category.name,
        franchise: principalModel.franchise,
        damage: principalModel.franchiseDamage,
        accidents: principalModel.franchiseRollover,
        hail: principalModel.franchiseHail,
      };
    });
  };

  findPrincipalModel = (models, principalModelId) => {
    if (!principalModelId) return models[0];
    return models.find(m => m.id === Number(principalModelId)) || models[0];
  };

  render() {
    const {
      t,
      franchiseTitle,
      franchiseDescription,
      creditTitle,
      creditDescription,
      accidentsTitle,
      accidentsDescription,
      hailTitle,
      hailDescription,
    } = this.props;

    const categories = this.state.categories;

    return (
      <StyledFranchiseSection>
        <div className="col-md-12 p-0 mb-5">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <h5>Cat.</h5>
                </th>
                <th scope="col">
                  <h5>
                    <strong>{t(franchiseTitle)}</strong> {t(franchiseDescription)}
                  </h5>
                </th>
                <th scope="col">
                  <h5>
                    <strong>{t(creditTitle)}</strong> {t(creditDescription)}
                  </h5>
                </th>
                <th scope="col">
                  <h5>
                    <strong>{t(accidentsTitle)}</strong> {t(accidentsDescription)}
                  </h5>
                </th>
                {hailTitle && (
                  <th scope="col">
                    <h5>
                      <strong>{t(hailTitle)}</strong> {t(hailDescription)}
                    </h5>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {categories &&
                categories.map(category => (
                  <tr>
                    <th scope="row" align="left">
                      <span>{category.name}</span>
                    </th>
                    <td>
                      <NumberDisplay value={category.damage || ''} />
                    </td>
                    <td>
                      <NumberDisplay value={category.franchise || ''} />
                    </td>
                    <td>
                      <NumberDisplay value={category.accidents || ''} />
                    </td>
                    {hailTitle && (
                      <td>
                        <NumberDisplay value={category.hail || ''} />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </StyledFranchiseSection>
    );
  }
}

export default withTranslation()(FranchiseSection);
