import React from 'react';
import { defaults, props } from './Card.props';
import { StyledCard } from './Card.styled';

function Card({ children, className, padding, background }) {
  return (
    <StyledCard className={className} padding={padding} background={background}>
      {children}
    </StyledCard>
  );
}

Card.propTypes = props;
Card.defaultProps = defaults;

export default Card;
