import style from 'styled-components';

export const StyledDetails = style.section`

  @media (max-width: ${props => props.theme.screens.md}) {
    display:none;
  }

  @media (min-width: ${props => props.theme.screens.md}){
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) and (min-width: ${props =>
  props.theme.screens.md}){
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xxl}) 
  and (min-width: ${props => props.theme.screens.xl}) {
    flex: 0 0 32%;
    max-width: 32%;
  }

  .checkout-details {
    .title-col1 { margin: 5px; }
    .title-col1 p { font-size: 18px; }

    .title-col1 h3 {
      margin-bottom: 0px !important;

      @media only screen and (max-width: ${props => props.theme.screens.xxl}) 
      and (min-width: ${props => props.theme.screens.xl}){
        font-size: 20px;
        margin-top: 2.5%;
      }
    }

    .img-fluid {
      max-width: 100%;
      height: auto;
      max-height: 164px;
    }

    .car-item .car-name { 
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      margin-top: 0px;
      margin-bottom: 5px;

      @media only screen and (max-width: ${props => props.theme.screens.xxl}) 
      and (min-width: ${props => props.theme.screens.xl}){
        font-size: 18px; 
        margin-bottom: 3px;
      }
    }

    .car-category{
      font-weight: 600;
      span{
        text-transform: uppercase;
      }
    }

    .car-item .details {
      margin-bottom: 0;
    }

    .car-item .details-item {
      display: inline-block;
      text-align: center;
      margin-right: 6px;
    }

    .car-item .details-item img {
      width: 42px;
      border: 1px solid #CCCCCC;
      padding: 5px;
      border-radius: 7px;
      margin-bottom: 5px;
    }

    .pickup-details {
      font-size: 13px;
    }

    .rent-details {
      background-color: #fff;
      padding: 6% 7% 3% 7%;
    }

    .rent-details .payment-details li {
      font-size: 13px;
      margin-bottom: 5px;

      i {font-size: 8px; display: inline-block; margin-right: 5px;}
          
      @media only screen and (max-width: ${props => props.theme.screens.md}) {
        font-size: 11px;
        margin-bottom: 7px;
      }

      @media only screen and (min-width: ${props => props.theme.screens.xl}) 
      and (max-width: ${props => props.theme.screens.xxl}){
        font-size: 11px;
        margin-bottom: 5px;
      }
    }

    .rent-details .payment-details .item-name {
      font-weight: bold;
    }

    .payment {
      background-color: ${props => props.priceBackground || props.theme.colors.primary};
      color: ${props => props.priceColor || props.theme.font.colors.secondary};
      padding: 5% 7% 3% 7%;
      margin-bottom: 2em !important;

      .total-price {
        font-size: 40px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 8px;
        margin-top: 10px;
        white-space: nowrap;
        
        @media only screen and (max-width: ${props => props.theme.screens.md}) {
          font-size: 30px;
        }
  
        @media only screen and (min-width: ${props => props.theme.screens.xl}) 
        and (max-width: ${props => props.theme.screens.xxl}){
          font-size: 35px;
        }
      }
  
      .days{
        font-size:12px;
      }

      .diario {
        font-size:12px;
        font-weight: lighter;
      }
    }

    .payment .total-name h3{
      font-size: 18px;
      margin-bottom: 0px !important;
      margin-top: 6% !important;

      @media only screen and (min-width: ${props => props.theme.screens.xl}) 
      and (max-width: ${props => props.theme.screens.xxl}){
        font-size: 16px;
      }
    }
    
  }
`;

export const NoBookingDivStyled = style.div`
  text-align: center;

  p{
    margin-bottom: 20px;
  }

  a {
    transition: all 0.4s;
    cursor: pointer;
    text-align: center;
    margin: 40px auto;
  }

  a:hover, a i { color: ${props => props.theme.colors.buttonPrimaryBg} !important; }

  .error{
    font-size:90px;
    margin: 40px auto;
    text-align: center;
  }
`;
