import style from 'styled-components';

export const StyledModernResetPasswordForm = style.div`
  margin-bottom: 1rem;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
  }

  .title {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.font.colors.primary};
    margin-bottom: 1.2rem;
  } 

  .subtitle {
    font-size: 14px;
    color: ${({ theme }) => theme.font.colors.primary};
    margin-bottom: 1.2rem;
  }
  
  .submit_btn {
    min-width: 160px;
  }

  .input-wrapper, .input-wrapper input.form-control {
    border-radius: 8px !important;
  }
  
  .error-alert { 
    max-width: fit-content;
  }
`;
