import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { defaults, props } from './BookingPreview.props';
import { StyledBookingPreview } from './BookingPreview.styled';
import { Tooltip } from '@material-ui/core';
import { NumberDisplay, BookingStatus, TranslatedLink } from 'components';
import { invertValueSign } from 'utils/dataHelper';

function BookingPreview({ t, booking, onCancel, i18n }) {
  const balance = booking?.customerBalance || booking?.balance;
  const isCancelDisabled = () => {
    // Disable if booking status is Delivered, Closed or Canceled
    if ([2, 3, 4].includes(booking.currentStatus)) return true;
    return false;
  };

  const deliveryInfo = () => {
    if (booking.deliveryInfo && [2, 3].includes(booking.currentStatus)) {
      return (
        <p className="delivery-info">{`${t('delivery')} ${moment(booking.deliveryInfo.date).format(
          t('bookingDateFormat'),
        )} hs - ${booking.deliveryPlace.name}`}</p>
      );
    } else {
      return (
        <p className="delivery-info">{`${t('delivery')} ${moment(booking.fromDate).format(
          t('bookingDateFormat'),
        )} hs - ${booking.deliveryPlace.name}`}</p>
      );
    }
  };

  const returnInfo = () => {
    if (booking.dropoffInfo && booking.currentStatus == 3) {
      return (
        <p className="delivery-info">{`${t('return')} ${moment(booking.dropoffInfo.date).format(
          t('bookingDateFormat'),
        )}hs - ${booking.returnPlace.name}`}</p>
      );
    } else {
      return null;
    }
  };

  return (
    <StyledBookingPreview status={booking.currentStatus} balance={balance}>
      <Row>
        <Col className="booking-id" lg={1} md={2} sm={12}>
          <p>#{booking.id}</p>
        </Col>

        <Col lg={7} md={5} sm={12} className="booking-info">
          <BookingStatus status={booking.currentStatus} />
          {deliveryInfo()}
          {returnInfo()}
        </Col>

        <Col lg={4} md={5} sm={12} className="booking-actions">
          <div className="actions-wrapper">
            <TranslatedLink
              className="nav-link"
              name="myAccount.bookings"
              path={`/my-account/bookings/${booking.id}`}
            >
              <Tooltip placement="top" title={t('details')}>
                <i className="far fa-file-alt"></i>
              </Tooltip>
            </TranslatedLink>

            <button className="delete" onClick={onCancel} disabled={isCancelDisabled()}>
              <Tooltip placement="top" title={t('cancel')}>
                <i className="fas fa-times-circle"></i>
              </Tooltip>
            </button>
          </div>
          <div className="booking-balance">
            <p>
              <span>
                <strong>{t('balance.total')}:</strong>
              </span>
              <NumberDisplay className="float-right ml-2" value={booking.price} />
            </p>
            <p>
              <span>{t('balance.payed')}:</span>
              <NumberDisplay className="float-right ml-2" value={booking.totalPayed} />
            </p>
            <p>
              <span>{t('balance.balance')}:</span>
              <NumberDisplay className="amount float-right ml-2" value={invertValueSign(balance)} />
            </p>
          </div>
        </Col>
      </Row>
    </StyledBookingPreview>
  );
}

BookingPreview.propTypes = props;
BookingPreview.defaultProps = defaults;

export default withTranslation()(BookingPreview);
