import React from 'react';
import { Title, Paragraph } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledPoyntSection } from './PoyntSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import PoyntCollect from './PoyntCollect';
import { history } from '/store';
import { payBooking, payInfraction } from 'services/api';
import { BookingStatus } from '../../../utils/constants';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';

import { fetchFiscalConditions } from '../../../actions/global.actions';
import PartialPayWarning from '../partialPayWarning';

class PoyntSection extends BasePaymentSection {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        qsamount: 0,
        bookingId: '0',
        infractionId: '0',
        customerId: '0',
        act: '',
        branchOfficeId: 0,
        promotionId: '',
        currencyCode: '',
        exchangeRate: 1,
      },
      dolarPrice: 0,
      exchangeRate: 1,
      fiscalConditions: [],
    };
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      fetchFiscalConditions,
      listTaxPayerTypes,
      i18n,
      defaultCurrency,
    } = this.props;
    const parseQs = queryString.parse(location.search);

    const tenantFiscalConditions =
      listTaxPayerTypes || (await fetchFiscalConditions(i18n.language))?.payload;

    this.setState(prevState => ({
      ...prevState,
      fiscalConditions: tenantFiscalConditions,
    }));

    if (this.isPayingBooking() && bookings.confirmation != null) {
      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Poynt' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          customerId: bookings.confirmation.customer.id,
          branchOfficeId: bookings.confirmation.deliveryPlace.branchOfficeId,
          promotionId: parseQs.promotionid || null,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
        },
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          branchOfficeId: infraction.booking?.deliveryPlace?.branchOfficeId || null,
          currencyCode: infraction.booking?.currency || defaultCurrency.isoCode, // TODO: get currency in infraction object
          exchangeRate: 1,
        },
      }));
    }
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= qsamount) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  currencyConversion = () => {
    const { listCurrencies, settings, generalSettings } = this.props;

    const webCurrency = settings.currency ? settings.currency.symbol : null;
    const currencies = listCurrencies;
    const defaultCurrency = currencies.find(currency => currency.isSystemDefault === true);
    const dolarRate = currencies.find(currency => currency.symbol === 'USD');

    if (
      webCurrency != undefined &&
      webCurrency !== 'USD' &&
      defaultCurrency.symbol !== 'USD' &&
      dolarRate != null
    ) {
      const numberOfDecimals =
        generalSettings && generalSettings.cashBoxConfiguration
          ? generalSettings.cashBoxConfiguration.numberOfDecimals
          : 0;
      let convertedPrice = Math.round(this.state.paymentInfo.price * dolarRate.exchangeRate);

      if (numberOfDecimals == 1)
        convertedPrice =
          Math.round(this.state.paymentInfo.price * dolarRate.exchangeRate * 10) / 10;
      else if (numberOfDecimals == 2)
        convertedPrice =
          Math.round(this.state.paymentInfo.price * dolarRate.exchangeRate * 100) / 100;
      else if (numberOfDecimals > 2)
        convertedPrice =
          Math.round(this.state.paymentInfo.price * dolarRate.exchangeRate * 1000) / 1000;

      const exchangeRate =
        Math.round((this.state.paymentInfo.price / convertedPrice) * 10000) / 10000;
      this.setState(() => ({
        dolarPrice: convertedPrice,
        exchangeRate: dolarRate.exchangeRate == 1 ? dolarRate.exchangeRate : exchangeRate,
      }));
    }
  };

  onSuccess = async paymentNonce => {
    const {
      t,
      i18n,
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;
    //const { dolarPrice, exchangeRate } = this.state;

    let paymentInfo = this.state.paymentInfo;
    paymentInfo.token = paymentNonce;
    paymentInfo.gatewayId = 'POYNT';
    // paymentInfo.price = dolarPrice || paymentInfo.price;
    paymentInfo.price = paymentInfo.price;

    try {
      const res = await this.executePayment(paymentInfo);
      let subtitle;
      if (res.bookingId !== 0)
        subtitle = t('feedbackBoxMessages.book.successSubtitle', {
          booking_id: res.bookingId,
        });

      if (is_gtag_enabled(googleTagManager)) gtag_purchase(bookings.confirmation, settings);
      history.push(
        `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
        { subtitle },
      );
    } catch (error) {
      let messageObject = {};
      let hereLink;
      const errorData = error.config.data ? JSON.parse(error.config.data) : null;

      if (this.isPayingBooking()) {
        messageObject['subtitle'] = t('feedbackBoxMessages.pay.failButConfirmed', {
          booking_id: errorData.bookingId,
        });
        hereLink = tlink(
          '__Routes.onlinePayment',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
          { bookingid: errorData.bookingId },
        );
      }

      if (this.isPayingInfraction()) {
        hereLink = tlink(
          '__Routes.onlinePayment',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
          {
            infractionid: errorData.infractionId,
            customerid: errorData.customerId,
          },
        );
      }

      messageObject['buttonInfo'] = { text: t('tryAgain'), link_to: hereLink };
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        messageObject,
      );
    }
  };

  executePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }

      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction())
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('pagandoReserva', { booking_id })
        : t('pagarReserva', { booking_id });
    }

    if (this.isPayingInfraction()) {
      const act = this.state.paymentInfo.act?.toString().padStart(7, '0');
      return this.state.formLoading ? t('payingInfraction', { act }) : t('payInfraction', { act });
    }
  };

  render() {
    const {
      t,
      title,
      subtitle,
      script,
      credentials,
      currentCurrency,
      defaultCurrency,
      webCurrency,
    } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    const { paymentInfo, dolarPrice } = this.state;

    const { i18n } = this.props;
    const lang = i18n.language.toUpperCase();

    if (!credentials) return null;

    return (
      <StyledPoyntSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <PoyntCollect
                url={script}
                businessId={credentials.businessId}
                applicationId={credentials.applicationId}
                onSuccess={this.onSuccess}
                getPayButtonText={this.getPayButtonText}
              />
            </div>
          </div>
        </div>
      </StyledPoyntSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  listCurrencies: global.listCurrencies,
  generalSettings: global.generalSettings,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
  listTaxPayerTypes: global.listTaxPayerTypes,
});
export default connect(mapStateToProps, { fetchFiscalConditions })(
  withRouter(withTranslation()(PoyntSection)),
);
