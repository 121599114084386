/* eslint-disable no-undef */
import React from 'react';
import { Title, TextInput, SelectInput, Btn, Paragraph } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledPromericaSection } from './PromericaSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from '../../../utils/translationHelper';
import * as yup from 'yup';
import { payBooking, payInfraction } from 'services/api';
import { getConfiguration } from 'services/promericaService';
import queryString from 'query-string';
import { history } from '/store';
import Select from 'react-select';
import { fetchCountries } from 'actions';
import { BookingStatus } from '../../../utils/constants';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';

var CreditCardValidator = require('card-validator');

class PromericaSection extends BasePaymentSection {
  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        qsamount: 0,
        bookingId: '0',
        clientIpAddress: '',
        sessionId: '',
        infractionId: '0',
        customerId: '0',
        act: '',
        promotionId: '',
        currencyCode: '',
        exchangeRate: 1,
      },
      formErrors: {
        cardNumber: '',
        year: '',
        month: '',
        secCode: '',
        cardholderName: '',
        firstName: '',
        lastName: '',
        country: '',
        state: '',
        city: '',
        street: '',
        postalCode: '',
        email: '',
        phone: '',
      },
      cardIcon: 'far fa-credit-card',
      formLoading: false,
    };

    yup.addMethod(yup.number, 'isValidCreditCard', function(creditCardNumber) {
      return this.test(
        'test-cc-number',
        t('promerica_cc_rejected_bad_filled_card_number'),
        function(ccNumber) {
          const cardObject = CreditCardValidator.number(ccNumber);
          if (cardObject.card && !['visa', 'mastercard'].includes(cardObject.card.type))
            return false;
          return cardObject.isValid;
        },
      );
    });

    yup.addMethod(yup.number, 'isValidSecCode', function(secCode) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_security_code'), function(
        secCode,
      ) {
        const ccObject = CreditCardValidator.number(this.parent.cardNumber);
        if (!secCode || !ccObject.card) return false;
        return secCode.toString().length === ccObject.card.code.size;
      });
    });

    yup.addMethod(yup.object, 'isValidCountry', function(countryObject) {
      return this.test('test-country', t('cc_rejected_bad_filled_country'), function(
        countryObject,
      ) {
        if (countryObject === undefined) return false;
        return true;
      });
    });

    yup.addMethod(yup.string, 'isValidState', function(stateName) {
      return this.test('test-state', t('cc_rejected_bad_filled_state'), function(stateName) {
        const selectedCountry = this.parent.country;
        if (stateName === undefined) return false;
        if (selectedCountry === undefined && stateName !== undefined) return true;
        if (
          ['US', 'CA'].some(c => c === selectedCountry.value) &&
          stateName.toString().length !== 2
        )
          return false;

        return true;
      });
    });

    yup.addMethod(yup.string, 'isValidCardHolderName', function(firstName) {
      return this.test(
        'test-cardholder',
        t('promerica.form_validation.cardholder_length'),
        function(firstName) {
          const currentLastName = this.parent.lastName;
          return (firstName + currentLastName).length <= 30;
        },
      );
    });

    this.paymentInfoFormSchema = yup.object().shape({
      cardNumber: yup
        .number()
        .isValidCreditCard()
        .required(t('inputIsRequired', { field: t('numeroTarjeta') })),
      year: yup.number().required(t('inputIsRequired', { field: t('año') })),
      month: yup.number().required(t('inputIsRequired', { field: t('mes') })),
      secCode: yup
        .number()
        .isValidSecCode()
        .required(t('inputIsRequired', { field: t('codigo') })),
      firstName: yup
        .string()
        .isValidCardHolderName()
        .required(t('inputIsRequired', { field: t('firstname') })),
      lastName: yup.string().required(t('inputIsRequired', { field: t('lastname') })),
      country: yup
        .object()
        .required(t('inputIsRequired', { field: t('country') }))
        .isValidCountry(),
      state: yup
        .string()
        .isValidState()
        .required(t('inputIsRequired', { field: t('state') })),
      city: yup
        .string()
        .test('len', t('promerica.form_validation.city_length'), val => val && val.length <= 30)
        .required(t('inputIsRequired', { field: t('city') })),
      street: yup
        .string()
        .test('len', t('promerica.form_validation.address_length'), val => val && val.length <= 60)
        .required(t('inputIsRequired', { field: t('address') })),
      postalCode: yup
        .string()
        .test(
          'len',
          t('promerica.form_validation.postal_code_length'),
          val => val && val.length >= 5 && val.length <= 9,
        )
        .required(t('inputIsRequired', { field: t('postalCode') })),
      email: yup
        .string()
        .email()
        .required(t('inputIsRequired', { field: t('email') })),
      phone: yup
        .string()
        .test('len', t('promerica.form_validation.phone_length'), val => val && val.length <= 15)
        .required(t('inputIsRequired', { field: t('phone') })),
    });

    this.formRef = React.createRef(null);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(15), (val, index) => index + year);
    this.months = Array.from(new Array(12), (val, index) => index + 1);
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      countries,
      bookings,
      infractions,
      i18n,
      fetchCountries,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;
    const parseQs = queryString.parse(location.search);

    if (!countries) fetchCountries(i18n.language);

    this.setClientIpAddress();
    this.setClientSessionId();

    if (this.isPayingBooking() && bookings.confirmation != null) {
      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Promerica' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          promotionId: parseQs.promotionid || null,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
        },
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          currencyCode: defaultCurrency.isoCode,
          exchangeRate: 1,
        },
      }));
    }
  }

  async setClientIpAddress() {
    const publicIp = require('public-ip');
    const clientIpAddress = await publicIp.v4();

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, clientIpAddress },
    }));
  }

  async setClientSessionId() {
    const configuration = await getConfiguration();
    let sessionId = '';
    let url = '';

    if (configuration.status === 200) {
      sessionId = configuration.data.sessionId;
      url = configuration.data.url;
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, sessionId },
    }));

    return new Promise((resolve, reject) => {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject('cannot load CyberSource script ' + sourceUrl);
      };
      document.head.appendChild(script);
    });
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= Math.abs(qsamount)) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  handleCardNumber = cardNumber => {
    const ccObject = CreditCardValidator.number(cardNumber);
    const ccIcon = this.getCreditCardIcon(ccObject.card ? ccObject.card.type : '');

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, cardNumber },
      formErrors: { ...prevState.formErrors, cardNumber: null, secCode: null },
      cardIcon: ccIcon,
    }));
  };

  getCreditCardIcon = ccType => {
    let icon = '';

    switch (ccType) {
      case 'visa':
        icon = 'fab fa-cc-visa';
        break;
      case 'american-express':
        icon = 'fab fa-cc-amex';
        break;
      case 'mastercard':
        icon = 'fab fa-cc-mastercard';
        break;
      default:
        icon = 'far fa-credit-card';
        break;
    }

    return icon;
  };

  handleSecCode = secCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, secCode },
      formErrors: { ...prevState.formErrors, secCode: null },
    }));
  };

  handleMonth = month => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, month },
      formErrors: { ...prevState.formErrors, month: null },
    }));
  };

  handleYear = year => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, year },
      formErrors: { ...prevState.formErrors, year: null },
    }));
  };

  handleFirstName = firstName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, firstName },
      formErrors: { ...prevState.formErrors, firstName: null },
    }));
  };

  handleLastName = lastName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, lastName },
      formErrors: { ...prevState.formErrors, lastName: null },
    }));
  };

  handleCountry = country => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, country },
      formErrors: { ...prevState.formErrors, country: null },
    }));
  };

  handleState = state => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, state },
      formErrors: { ...prevState.formErrors, state: null },
    }));
  };

  handleCity = city => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, city },
      formErrors: { ...prevState.formErrors, city: null },
    }));
  };

  handleStreet = street => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, street },
      formErrors: { ...prevState.formErrors, street: null },
    }));
  };

  handlePostalCode = postalCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, postalCode },
      formErrors: { ...prevState.formErrors, postalCode: null },
    }));
  };

  handleEmail = email => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, email },
      formErrors: { ...prevState.formErrors, email: null },
    }));
  };

  handlePhone = phone => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, phone },
      formErrors: { ...prevState.formErrors, phone: null },
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const paymentInfo = this.state.paymentInfo;
    this.setState({ formLoading: true });

    this.paymentInfoFormSchema
      .validate(paymentInfo, { abortEarly: false })
      .then(valid => {
        if (valid) this.executePayment(paymentInfo);
      })
      .catch(err => {
        this.setState({ formLoading: false });
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });
  };

  executePayment = async params => {
    const {
      t,
      i18n,
      settings,
      bookings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    let paymentParams = this.state.paymentInfo;
    paymentParams.gatewayId = 'PROMERICA';
    const cardObject = CreditCardValidator.number(paymentParams.cardNumber);
    paymentParams.type = {
      code: cardObject.card.type === 'visa' ? '001' : '002',
      name: cardObject.card.type,
    };

    try {
      await makePayment(paymentParams);

      if (is_gtag_enabled(googleTagManager)) gtag_purchase(bookings.confirmation, settings);
      history.push(
        `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
      );
    } catch (error) {
      let errorMessage = t('promerica.error_codes.generic');
      let codeError = '';
      let transactionId = '';

      if (error && error.response && error.response.data && error.response.data.code) {
        transactionId = error.response.data.message.split('|')[0];
        codeError = error.response.data.code;
      }

      switch (codeError) {
        case 301:
          errorMessage = t('promerica.error_codes.payment_denied');
          break;
        case 302:
          errorMessage = t('promerica.error_codes.payment_error');
          break;
        case 303:
          errorMessage = t('promerica.error_codes.unregistered_payment', {
            transactionId,
          });
          break;
        case 304:
          errorMessage = t('promerica.error_codes.cybersource_failed', {
            transactionId,
          });
          break;
        default:
          break;
      }

      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        { error: errorMessage },
      );
    }
  };

  makePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }

      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction())
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getCountries = () => {
    const { countries } = this.props;
    if (!countries) return [];

    return countries.map(country => {
      return { value: country.isoCode3166, label: country.name };
    });
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('pagandoReserva', { booking_id })
        : t('pagarReserva', { booking_id });
    }

    if (this.isPayingInfraction()) {
      const act = this.state.paymentInfo.act?.toString().padStart(7, '0');
      return this.state.formLoading ? t('payingInfraction', { act }) : t('payInfraction', { act });
    }
  };

  render() {
    const { t, title, subtitle, currentCurrency, defaultCurrency, webCurrency } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    return (
      <StyledPromericaSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form onSubmit={this.handleSubmit} ref={this.formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('numeroTarjeta')}</span>
                      <TextInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleCardNumber}
                        value={this.state.paymentInfo.cardNumber}
                        icon={this.state.cardIcon}
                        options={{ 'data-checkout': 'cardNumber' }}
                        error={this.state.formErrors.cardNumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('codigo')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleSecCode}
                        value={this.state.paymentInfo.secCode}
                        options={{ 'data-checkout': 'securityCode' }}
                        error={this.state.formErrors.secCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('mes')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleMonth}
                        selected={this.state.paymentInfo.month}
                        error={this.state.formErrors.month}
                        props={{ 'data-checkout': 'cardExpirationMonth' }}
                        options={this.months}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('año')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleYear}
                        error={this.state.formErrors.year}
                        selected={this.state.paymentInfo.year}
                        props={{ 'data-checkout': 'cardExpirationYear' }}
                        options={this.years}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('firstname')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleFirstName}
                        value={this.state.paymentInfo.firstName}
                        options={{
                          'data-checkout': 'firstName',
                          id: 'firstName',
                        }}
                        error={this.state.formErrors.firstName}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('lastname')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleLastName}
                        value={this.state.paymentInfo.lastName}
                        options={{
                          'data-checkout': 'lastName',
                          id: 'lastName',
                        }}
                        error={this.state.formErrors.lastName}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('country')}</span>
                      <Select
                        className="country-select"
                        name="country"
                        placeholder={t('select')}
                        value={this.state.paymentInfo.country}
                        options={this.getCountries()}
                        onChange={this.handleCountry}
                      />
                      <div className="error">{this.state.formErrors.country}</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('state')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleState}
                        value={this.state.paymentInfo.state}
                        options={{ 'data-checkout': 'state', id: 'state' }}
                        error={this.state.formErrors.state}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('city')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleCity}
                        value={this.state.paymentInfo.city}
                        options={{ 'data-checkout': 'city', id: 'city' }}
                        error={this.state.formErrors.city}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('address')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleStreet}
                        value={this.state.paymentInfo.street}
                        options={{ 'data-checkout': 'street', id: 'street' }}
                        error={this.state.formErrors.street}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('postalCode')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handlePostalCode}
                        value={this.state.paymentInfo.postalCode}
                        options={{
                          'data-checkout': 'postalCode',
                          id: 'postalCode',
                        }}
                        error={this.state.formErrors.postalCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('phone')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handlePhone}
                        value={this.state.paymentInfo.phone}
                        options={{ 'data-checkout': 'phone', id: 'phone' }}
                        error={this.state.formErrors.phone}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('email')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleEmail}
                        value={this.state.paymentInfo.email}
                        options={{ 'data-checkout': 'email', id: 'email' }}
                        error={this.state.formErrors.email}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <Btn
                      type="submit"
                      text={this.getPayButtonText()}
                      disabled={this.state.formLoading}
                      className="mt-5 promerica-pay_button"
                    />
                  </div>
                  <div className="logo-container">
                    <span>{t('providedBy')}</span>
                    <img
                      width="151"
                      height="52"
                      className="promerica-logo"
                      src="https://rently.azureedge.net/rently/Web/promerica.png"
                      alt="Promerica"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </StyledPromericaSection>
    );
  }
}

const mapStateToProps = ({ global, bookings, infractions, siteConfiguration }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  countries: global.listCountries,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps, { fetchCountries })(
  withRouter(withTranslation()(PromericaSection)),
);
