import React from 'react';
import { PropTypes } from 'prop-types';
import style from 'styled-components';

const StyledGoDaddyLogo = style.span`
  img{
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
  }
`;

export default class GoDaddyLogo extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  componentDidMount() {
    const script = document.createElement('script');

    script.src = this.props.url;
    script.async = true;

    document.getElementById('siteseal').appendChild(script);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <StyledGoDaddyLogo id="siteseal" width={this.props.width} height={this.props.height} />;
  }
}
