import styled from 'styled-components';

export const StyledHeader = styled.div`
  background: ${({ bgColor }) => bgColor || '#EFEFEF'};
  z-index: 1;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  padding: 0 15px;

  .col {
    display: flex;
    align-items: center;
  }

  .right-col {
    justify-content: flex-end;
  }

  .link-wrapper {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    margin-right: 10px;

    &:hover {
      .linkItem,
      .link-icon {
        color: ${({ hoverColor, theme }) => hoverColor || theme.colors.primary};
      }
    }
  }

  .linkItem {
    font-size: 0.9rem;
    color: ${({ color }) => color || '#5F6368'};
  }

  .TopBarPhone {
    list-style: none;
  }

  .TopBarSocialList {
    padding-left: 0px;
  }

  .link-icon {
    margin-right: 5px;
    font-size: 0.8rem;
    color: ${({ color }) => color || '#5F6368'};
    transition: color 0.3s;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    padding: 3px 10px;

    .col {
      justify-content: center;
      flex-wrap: wrap;
    }
    .navSocialList {
      padding: 0;
    }
  }
`;
