import PropTypes from 'prop-types';

export const props = {
  className: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  fontSize: PropTypes.string,
  placeholder: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
};

export const defaultProps = {
  cols: 4,
  rows: 4,
};
