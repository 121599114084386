import styled from 'styled-components';

export const StyledFlota = styled.section`
  background: ${props => props.theme.colors.bg};
  overflow: hidden;
  p.lead {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 3rem;

    @media (max-width: ${props => props.theme.screens.lg}) {
      font-size: 1rem;
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #e1e1e1 !important;
  }

  .slick-next:before {
    content: '\f105';
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
  }

  .slick-prev:before {
    content: '\f104' !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
  }

  .section-flota-title p,
  .section-flota-title button {
    @media (max-width: ${props => props.theme.screens.md}) {
      display: none;
    }
  }

  p.car-category {
    font-weight: 300;
    margin-bottom: 0.5rem;
  }

  .car-item.one-slide {
    width: 80%;
    margin: 0 auto;
  }

  .car-item {
    .car-details {
      .car-name h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;

        @media only screen and (max-width: ${props =>
            props.theme.screens.xxl}) and (min-width: ${props => props.theme.screens.xl}) {
          font-size: 18px;
        }
      }
      .btn {
        display: ${props => props.btnDisplay || 'flex'};
      }
    }

    .car-image.one-slide {
      border: none !important;
      height: 350px;
      background-size: contain;
    }

    .car-image {
      border: 1px solid #cbcbcb;
      height: fit-content;
      text-align: center;
      height: 170px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      .lazy-load-image-loaded > img {
        object-fit: cover;
        height: 100%;
        min-height: 100%;
      }
    }

    .car-image-wrapper {
      img {
        height: 100%;
      }
    }

    .price p {
      font-size: 13px;
      text-transform: uppercase;
      span {
        font-weight: bold;
        font-size: 15px;
      }
      strong span {
        font-size: 20px;
      }
    }

    .car-details {
      .details {
        margin-bottom: 5px;

        .details-item {
          display: inline-block;
          text-align: center;
          margin-right: 5px;
          width: 10.5%;

          @media only screen and (max-width: 330px) {
            width: 16%;
          }

          p {
            margin-bottom: 0;
          }

          img {
            width: 100%;
            display: block;
            border: 1px solid #cccccc;
            padding: 5px;
            border-radius: 7px;
            margin-bottom: 5px;

            @media only screen and (max-width: ${props =>
                props.theme.screens.xxl}) and (min-width: ${props => props.theme.screens.xl}) {
              width: 27px !important;
            }
          }
        }
      }
    }
  }
`;
