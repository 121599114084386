/* eslint-disable no-console */
import {
  GLOBAL_SET_SPINNER,
  GLOBAL_SET_LIST_CATEGORIES,
  GLOBAL_SET_LIST_ADDITIONALS,
  GLOBAL_SET_LIST_PLACES,
  GLOBAL_SET_LIST_PROMOTIONS,
  GLOBAL_SET_LIST_SCHEDULES,
  GLOBAL_SET_LIST_HOLIDAYS,
  GLOBAL_SET_LIST_LANGUAGES,
  GLOBAL_SET_LIST_CURRENCIES,
  GLOBAL_SET_LIST_LANGUAGES_INFO,
  GLOBAL_SET_LIST_COUNTRIES,
  GLOBAL_SET_LIST_DOCUMENT_TYPES,
  GLOBAL_SET_LIST_FISCAL_CONDITIONS,
  GLOBAL_SET_LIST_CITIES,
  GLOBAL_SET_GENERAL_SETTINGS,
  GLOBAL_SET_WEB_CURRENCY,
  CHANGE_CURRENCY,
} from 'utils/types';
import {
  listCategories,
  listPlaces,
  listPromotions,
  listAdditionals,
  listHolidays,
  listSchedules,
  listLanguages,
  listCurrencies,
  listLanguagesInfo,
  listCountries,
  listDocumentTypes,
  listTaxPayerTypes,
  listCities,
  getGeneralSettings,
} from 'services/api';
import Storage from 'utils/storage';

export const setSpinner = loading => dispatch => {
  dispatch({
    type: GLOBAL_SET_SPINNER,
    payload: loading,
  });
};

export const changeCurrencyAction = currency => ({
  type: CHANGE_CURRENCY,
  payload: currency,
});

export const setWebCurrencyAction = webCurrency => ({
  type: GLOBAL_SET_WEB_CURRENCY,
  payload: webCurrency,
});

export const fetchCategories = (language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      `fetchCategories.${language}`,
      async () => {
        return (await listCategories(language)).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_CATEGORIES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_CATEGORIES,
      payload: null,
    });
  }
};

export const fetchPlaces = (language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      `listPlaces.${language}`,
      async () => {
        return (await listPlaces(language)).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_PLACES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_PLACES,
      payload: null,
    });
  }
};

export const fetchCurrencies = () => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listCurrencies',
      async () => {
        return (await listCurrencies()).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_CURRENCIES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_CURRENCIES,
      payload: null,
    });
  }
};

export const fetchPromotions = (language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      `fetchPromotions.${language}`,
      async () => {
        return (await listPromotions(language)).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_PROMOTIONS,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_PROMOTIONS,
      payload: null,
    });
  }
};

export const fetchAdditionals = (rentlyEndpointName = '', language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      `listAdditionals.${rentlyEndpointName}.${language}`,
      async () => {
        return (await listAdditionals(rentlyEndpointName, language)).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_ADDITIONALS,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_ADDITIONALS,
      payload: null,
    });
  }
};

export const fetchHolidays = () => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listHolidays',
      async () => {
        return (await listHolidays()).data;
      },
      30,
      true,
    );

    const parsedData = data.map(d => ({ ...d, date: d.date.split('T')[0] }));
    return dispatch({
      type: GLOBAL_SET_LIST_HOLIDAYS,
      payload: parsedData,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_HOLIDAYS,
      payload: null,
    });
  }
};

export const fetchSchedules = () => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listSchedules',
      async () => {
        return (await listSchedules()).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_SCHEDULES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_SCHEDULES,
      payload: null,
    });
  }
};

export const fetchLanguages = () => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listLanguages',
      async () => {
        return (await listLanguages()).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_LANGUAGES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_LANGUAGES,
      payload: null,
    });
  }
};

export const fetchLanguagesInfo = () => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listLanguagesInfo',
      async () => {
        return (await listLanguagesInfo()).data;
      },
      30,
      true,
    );
    return dispatch({
      type: GLOBAL_SET_LIST_LANGUAGES_INFO,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_LANGUAGES_INFO,
      payload: null,
    });
  }
};

export const fetchCountries = (language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listCountries',
      async () => {
        return (await listCountries(language)).data;
      },
      30,
      true,
    );

    return dispatch({
      type: GLOBAL_SET_LIST_COUNTRIES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response && error.response.data);
    return dispatch({
      type: GLOBAL_SET_LIST_COUNTRIES,
      payload: null,
    });
  }
};

export const fetchCities = countryIsoCode => async dispatch => {
  try {
    const { data } = await listCities(countryIsoCode);

    return Promise.resolve(
      dispatch({
        type: GLOBAL_SET_LIST_CITIES,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchDocumentTypes = (language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listDocumentTypes',
      async () => {
        return (await listDocumentTypes(language)).data;
      },
      30,
      true,
    );

    return dispatch({
      type: GLOBAL_SET_LIST_DOCUMENT_TYPES,
      payload: data,
    });
  } catch (error) {
    console.error(error.response && error.response.data);
    return dispatch({
      type: GLOBAL_SET_LIST_DOCUMENT_TYPES,
      payload: null,
    });
  }
};

export const fetchFiscalConditions = (language = '') => async dispatch => {
  try {
    const data = await Storage.getOrUpdateItem(
      'listTaxPayerTypes',
      async () => {
        return (await listTaxPayerTypes(language)).data;
      },
      30,
      true,
    );

    return dispatch({
      type: GLOBAL_SET_LIST_FISCAL_CONDITIONS,
      payload: data,
    });
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_LIST_FISCAL_CONDITIONS,
      payload: null,
    });
  }
};

export const fetchGeneralSettings = () => async dispatch => {
  try {
    const { data } = await getGeneralSettings();
    return Promise.resolve(
      dispatch({
        type: GLOBAL_SET_GENERAL_SETTINGS,
        payload: data,
      }),
    );
  } catch (error) {
    console.error(error.response);
    return dispatch({
      type: GLOBAL_SET_GENERAL_SETTINGS,
      payload: null,
    });
  }
};
