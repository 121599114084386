import PropTypes from 'prop-types';

export const props = {
  items: PropTypes.array,
  className: PropTypes.string,
  logo: PropTypes.string,
  slogan: PropTypes.object,
  color: PropTypes.string,
  background: PropTypes.string,
  menuCallback: PropTypes.func,
  location: PropTypes.string,
};

export const defaults = {
  items: [],
  background: '#000',
  color: 'white',
};

export const sloganProps = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  labelFontSize: PropTypes.string,
  subLabelWeight: PropTypes.string,
};

export const sloganDefaultProps = {};

export const HeaderDefaultProps = {
  color: '#fff',
};

export const HeaderItemProps = {
  label: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    path: PropTypes.string.isRequired,
  }),
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  callback: PropTypes.func,
  color: PropTypes.string,
  hover: PropTypes.string,
  iconHover: PropTypes.string,
};

export const HeaderItemDefaultProps = {
  color: '#fff',
};
