import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { tlink } from 'utils/translationHelper';

import Popup from 'reactjs-popup';
import { CustomHtml } from 'components';
import { StyledPromotionModal } from './PromotionModal.styled';

class PromotionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  render() {
    const { t, i18n, content, link_to, settings } = this.props;

    return (
      <StyledPromotionModal>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={() => this.setState({ open: false })}
        >
          <a href={tlink(link_to, t, i18n, null, settings.configurations.langConfig)}>
            <CustomHtml customContent={content} />
          </a>
        </Popup>
      </StyledPromotionModal>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(PromotionModal)));
