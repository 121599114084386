import style from 'styled-components';

export const StyledCustomerCarrouselModern = style.div`
    width:100%;

    h4{
        font-size: 20px;
        font-weight: 600;
    }

    .customer-image{
        height: fit-content;
        text-align: center;
        height: 100px;
        width: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 15px;
    }

    .slick-track{
        margin: auto auto;
    }
`;
