import PropTypes from 'prop-types';

const placeShape = PropTypes.shape({
  address: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  rentlyEndpointName: PropTypes.string,
});

export const props = {
  data: PropTypes.arrayOf(placeShape),
  placeholder: PropTypes.string,
  placeSelected: PropTypes.oneOfType([placeShape, PropTypes.string]),
  placeChangeHandler: PropTypes.func.isRequired,
  showCity: PropTypes.bool,
};

export const defaultProps = {
  options: [],
  disabled: false,
  placeholder: 'Select',
  showCity: false,
  showIATA: false,
};
