import React from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { TranslatedComponent, Title, Paragraph } from 'components/';
import { StyledOurPeople } from './OurPeople.styled';
import { startCase } from 'lodash';

const MAX_STARS_SCORE = 5;

class OurPeople extends TranslatedComponent {
  getItems = () => {
    const { className, items } = this.props;
    const res = [];

    items.forEach((item, index) => {
      res.push(
        <div className="person-box" key={`people-${index}`}>
          <div className="person-photo">
            <img className={className} src={this.tt(item.picture)} alt={this.tt(item.name) || ''} />
          </div>
          <div className="person-description">
            <p className="person-name">{this.tt(item.name)}</p>
            <p>{this.renderStars(item.stars)}</p>
            <Paragraph className="person-review" text={this.tt(item.description)} />
          </div>
        </div>,
      );
    });

    return res;
  };

  renderStars = amount => {
    const stars = [];

    for (let i = 0; i < amount; i++) stars.push(<i class="fa fa-star" aria-hidden="true"></i>);

    for (let i = stars.length; i < MAX_STARS_SCORE; i++)
      stars.push(<i class="far fa-star" aria-hidden="true"></i>);

    return stars;
  };

  render() {
    const { title, subtitle } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
    };

    return (
      <StyledOurPeople className="pt-3 pb-2">
        <div className="pt-2 pb-2 flex-centered">
          <Title type="h3" text={this.tt(title)} />
          <Paragraph className="lead" text={subtitle} />
        </div>
        <div className="people-slider text-center">
          <Slider {...settings} style={{ height: '500px' }}>
            {this.getItems()}
          </Slider>
        </div>
      </StyledOurPeople>
    );
  }
}

export default withTranslation()(OurPeople);
