import style from 'styled-components';

export const StyledOurPeople = style.div`
	.flex-centered {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
	}

	.person-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: rgb(255, 255, 255);
		width: 550px !important;
		height: 400px !important;
		margin-bottom: 20px;
		font-size: 25px;


		box-shadow: 9px 7px 22px -6px rgba(25,25,25,0.11);
		-webkit-box-shadow: 9px 7px 22px -6px rgba(25,25,25,0.11);
		-moz-box-shadow: 9px 7px 22px -6px rgba(25,25,25,0.11);
	}

	.person-description {
    display: flex;
    flex-direction: column;
		align-items: center;
	}

	p.person-name {
		font-weight: bold;
	}

	.person-photo {
		padding-bottom: 5px;
    display: flex;
    justify-content: center;
	}

	.person-review {
		font-size: 17px;
	}

	.people-slider {
		display: block;
		padding: 20px;
		margin-bottom: 20px;
	}
`;
