import React from 'react';
import { props } from './SliderComponent.prop';
import Slider from 'react-slick';
import { StyledSlider, StyledItem, StyledLazyLoadImage } from './SliderComponent.styled';
import { withTranslation } from 'react-i18next';
import { CompositeComponent, Title } from 'components/';
import { Link } from 'react-router-dom';
import { tt } from 'utils/translationHelper';

const sliderSettings = {
  dots: true,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  autoplay: true,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class SliderComponent extends React.Component {
  static propTypes = props;

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.itemsRef = [];
  }

  handleResize() {
    const isMobile = window.innerWidth <= 600;
    this.setState({ isMobile });
  }

  setContentPosition = () => {
    switch (this.props.contentPosition) {
      case 'right':
        return 'justify-content-end';
      case 'center':
        return 'justify-content-center';
      default:
        return 'justify-content-start';
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.itemsRef.forEach(itemRef => {
      if (itemRef) {
        const itemSrc = itemRef.getAttribute('data-src');
        const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                // Load the background image
                itemRef.style.backgroundImage = `url(${itemSrc})`;
                observer.unobserve(entry.target);
              }
            });
          },
          { threshold: 0.9 },
        );
        observer.observe(itemRef);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {
      t,
      items,
      className,
      type,
      title,
      italic,
      weight,
      slideHeight,
      quotedColor,
      dotsColor,
      dotsSize,
      slideMobileHeight,
      quotedMobileColor,
      sliderCustomSettings,
    } = this.props;

    const { isMobile } = this.state;

    const settings = {
      ...sliderSettings,
      ...(sliderCustomSettings ? sliderCustomSettings : {}),
    };

    return (
      <StyledSlider
        slideHeight={slideHeight}
        quotedColor={quotedColor}
        dotsColor={dotsColor}
        dotsSize={dotsSize}
        slideMobileHeight={slideMobileHeight}
        quotedMobileColor={quotedMobileColor}
        className={`${className && className} `}
      >
        <div className={`row row-p ${this.setContentPosition()}`}>
          <div className="home-form col-md-5 mt-5">
            <Title
              type={type ? type : 'h3'}
              text={title}
              italic={italic ? italic : false}
              weight={weight}
              className="quoted"
            />
            <CompositeComponent sections={this.props.sections} />
          </div>
        </div>
        <Slider {...settings} className="slider">
          {items.map((item, i) => (
            <Link key={i} to={tt(item.link, t) || '/'}>
              <StyledItem ref={el => (this.itemsRef[i] = el)}>
                <StyledLazyLoadImage
                  alt={t(item.alt)}
                  src={(isMobile && t(item.mobileImg)) || t(item.image)}
                  effect="blur"
                />
              </StyledItem>
            </Link>
          ))}
        </Slider>
      </StyledSlider>
    );
  }
}

export default withTranslation()(SliderComponent);
