import React, { Component } from 'react';
import { props } from './DateInput.props';
import { StyledInput } from './DateInput.styled';
import DatePicker from '../date-picker/DatePicker';

class DateInput extends Component {
  static propTypes = props;
  render() {
    const {
      wrapperId,
      wrapperClass,
      color,
      onDateChange,
      onFocusChange,
      borderColor,
      iconColor,
      icon,
      error,
      label,
      placeholder,
      id,
      date,
      small,
      datePickerOptions,
    } = this.props;
    return (
      <StyledInput
        color={color}
        id={wrapperId}
        className={wrapperClass}
        borderColor={borderColor}
        iconColor={iconColor}
        error={error}
      >
        {label && <div className="label">{label}</div>}
        <div className={small ? 'input-wrapper small-input-padding' : 'input-wrapper'}>
          {icon && <span className={icon} />}
          <div className={'date-wrapper'}>
            <DatePicker
              placeholder={placeholder}
              id={id}
              noBorder
              block
              onDateChange={onDateChange}
              onFocusChange={onFocusChange}
              date={date}
              settings={datePickerOptions}
              small={small}
            />
          </div>
        </div>
        {error && <div className="error">{error}</div>}
      </StyledInput>
    );
  }
}
export default DateInput;
