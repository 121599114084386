import style from 'styled-components';

export const StyledCecabankSection = style.section`

  .alert {
    width: fit-content;
  }
  
  @media only screen and (max-width: ${props => props.theme.screens.md}){
    width: 100%;
    flex: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  .mr-4, .mx-4 {
    margin-right: 1.5rem!important;
  }

   .mt-2, .my-2 {
     margin-top: .5rem!important;
    }

    .mb-1, .my-1 {
      margin-bottom: .25rem!important;
    }

   .payment-block {
      background-color: #fff;
      padding: 4% 3%;
    }

    .pt-3, .py-3 {
      padding-top: 1rem!important;
    }

  .pb-1, .py-1 {
    padding-bottom: .25rem!important;
  }

  .label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 7px;
  }

  .LogosContainer{
    display: flex;
    align-items: center;

    .SecureLogoContainer{
      display: flex;
      align-items: center;
      max-width: 279px;
      width: 100%;
      justify-content: flex-start;
      border-radius: 8px;
      border: 1px solid #2BCE81;
      background: #E1FFEB;
      margin: 1rem 0.5rem;
      color: #2BCE81;
      padding: 10px;

      .CompraTitle{
        font-weight: 800;
      }
      p{
          font-size: 12px;
      }
      .LogoContainer {
          margin: 0 0.5rem;
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) 
  and (min-width: ${props => props.theme.screens.md}){
    width: 100%;
    flex: 100%;
    max-width: 100%;
    margin-right: 0;

    .quantity {
      width: 46px;
    }

    a.othercar, .print-btn {
      display: none !important;
    }

    .send-budget{
      text-align: center;
    }

    .adicionales-table td {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: ${props => props.theme.screens.xxl}) 
  and (min-width: ${props => props.theme.screens.xl}){
    width: 68%;
    flex: 68%;
    max-width: 68%;
    margin-right: 0;
  }

  .table td, .table th {
    padding: .6rem .2rem;
    vertical-align: middle;
  }

  .table tr:first-child td{
    border-width: 0;
  }

  .table tr td:first-child {
    margin-right: 10px;
    max-height: 100px;
  }

  .checkout-additionals {

    h2{
      margin-bottom: 2px !important;
    }

    .adicionales{
      background-color: #fff;
      padding: 4% 3%;
    }

    .adicionales-table tr {
      cursor: pointer;

      :first {
        width: 3.5%;

        input {
          width: 100%;
        }
      }
    }

    a.othercar {
      cursor: pointer;
    }

    /* Custom Checkbox */
    /* The container */
    .special-checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 16px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .special-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .special-checkbox input:checked ~ .checkmark {
      background-color: ${props => props.theme.colors.buttonPrimaryBg};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .special-checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .special-checkbox .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    a {
      transition: all 0.4s;
      margin-top:10px;
      margin-bottom:10px;
    }
    
    a:hover, a i { color: ${props => props.theme.colors.buttonPrimaryBg}; }

    .user-info {
      padding: 4% 3%;
      background-color: #fff;
    }

    form input {
      font-weight: 400;
    }

    form input:focus {
      border-color: #FFBB3C;
      box-shadow: none;
    }

    .form-datos{
      h2{
        @media only screen and (max-width: 1480px) and (min-width: 1271px){
          font-size: 18px;
        }
      }
    }

    .quantity {
      width: 96px;
      input{
        text-align: center;
      }
    }
  }
`;

export const LoadingWrapperStyled = style.div`
  text-align: center;

  p{
    margin-bottom: 20px;
  }

  a {
    transition: all 0.4s;
    cursor: pointer;
    text-align: center;
    margin: 40px auto;
  }

  a:hover, a i { color: ${props => props.theme.colors.buttonPrimaryBg} !important; }

  .error{
    font-size:90px;
    margin: 40px auto;
    text-align: center;
  }
`;
