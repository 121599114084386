import style from 'styled-components';

export const StyledServiciosSection = style.section`
  background: ${props => props.theme.colors.bg};
  min-height: 600px;
  width: 100%;

  .service-description-button{
    font-size: 0.8rem;
    text-decoration: none!important;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.secondary}!important;

    &:hover, & > i { 
      text-decoration: none!important; 
    }
  }

  .see-less{
    padding-left: 0px;
  }

  .group-title{
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 20px;
    padding: .75rem 1.25rem;
    
    h5{
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 700;
        color: ${props => props.theme.font.colors.primary};
    }

    
  }

`;
