import style from 'styled-components';
import { THIRD } from 'styled/constants';

export const StyledCalidadServicio = style.section`
    background: ${props => (props.background ? `url(${props.background}) no-repeat` : `black`)};
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
	min-height: 360px;
    padding: 4rem 0px;
    text-align: center;
    color: ${THIRD};
    display: flex;
    justify-content: center;
    align-item: center; 
    padding-right: 2% !important;
    padding-left: 2% !important;

    .contentBox {
        padding: 24px 25px;
        display: flex;
        flex-direction: column;

        .stars {
            color: ${props => props.color || props.theme.colors.buttonPrimaryBg};
            font-size: 17px;
            margin-bottom: 18px;
            line-height: 26px;
    
            .fa-star {
                margin: 0 3px;
            }
        }
    
        .textContent{
            text-align: center;
            font-weight: 400;
            font-size: ${props => props.textContentSize || '12px'};
            line-height: 20px;
            text-size-adjust: 100%;
            color: #fff;
        }
        .statement  {
            text-align: center;
            font-weight: 400;
            font-size: ${props => props.statementSize || '12px'};
            line-height: 20px;
            text-size-adjust: 100%;
            color: #fff;
        }
    }
`;
