import style from 'styled-components';

export const StyledPagaEnCuotas = style.section`
  background: ${props => props.bg || props.theme.colors.bg};
  text-align: center;
  overflow-x: hidden;

  .home-title {
    margin-top: 5em;
    line-height: 1.5;
    position:relative;
    left:50px;

    h1{
      text-align:left;
      font-size:30px !important;
      font-weight:400;
      color:${props => props.theme.colors.primary};
    }

    p {
      line-height: 22px;
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    @media (max-width: ${props => props.theme.screens.xs}){
      padding:10px;
      left:0;
    }
    
  @media(max-width:767px){
    h1{
      font-size:25px !important;
    }
  }
`;

export const StyledBanksSection = style.section`

  border-bottom: ${props => (props.showBorder ? '3px' : 0)} solid ${props =>
  props.borderColor || props.theme.colors.primary};
  padding-bottom: 100px;
  
  h4 {
    margin-bottom:50px;
    text-align:left;
    font-size:20px !important;
    font-weight:400;
    color:${props => props.theme.colors.primary};

    strong {
      font-weight: 700;
    }
  }
  p{
    font-size: 0.9rem;
    padding: 0 30px;
    text-align:left !important;
    color:#2E2D35;
  }

  .textbold{
    font-weight:bold;
    font-size:17px;
    text-align:center !important;
    border:1px solid #2E2D35;
    border-radius: 20px;
    margin: 15px 30px;
    padding:2px 0;
  }

  .textbold:hover{
    background: #ECEEF0;
    border:1px solid ${props => props.theme.colors.primary};
    transition: 0.2s
  }

  a {
    font-size: 13px !important;
    color: ${props => props.theme.font.colors.third};
    transition: all .2s;
  }

  a:hover {
    text-decoration: none;
    color: ${props => props.leftLinkHover || props.theme.colors.primary} !important; 
  }

  img {
    cursor: grab;
  }

  div.img {
    height: fit-content;
    text-align: center;
    height: 100px;
    width: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;

    img{
      width:100%;
    }
  }
   
  .slick-track{
    margin: auto auto;
  }

  .slick-next{
    right: -10px;
  }
  
  .slick-prev{
    left: -10px;
  }

  .slick-next:before {
    content: "\f105" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    color: #ccc
    
  }

  .slick-prev:before {
    content: "\f104" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    color: #ccc
  }

  
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .home-title {
    left:30px;
}
}
`;
