import React from 'react';
import { connect } from 'react-redux';
import { props } from './PromocionesSection.props';
import { Title, Loading } from 'components/';
import { StyledPromocionesSection } from './PromocionesSection.styled';
import { StyledPromocionesModernSection } from './PromocionesSection.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class PromocionesSection extends React.Component {
  static propTypes = props;

  state = {
    loading: true,
  };

  async componentDidMount() {
    const { fetchPromotions, i18n } = this.props;

    await fetchPromotions(i18n.language);

    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }));
  }

  noPromotionText() {
    const { t, noPromotionText } = this.props;
    return (
      <div className="col-md-12">
        <p className="no-promotion-text"> {t(noPromotionText || 'noPromotionsText')} </p>
      </div>
    );
  }

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledPromocionesModernSection;
    }
    return StyledPromocionesSection;
  }

  getColumnWidth() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-lg-6 col-md-6';
    }
    return 'col-lg-4 col-md-6';
  }

  getTitleSize() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 21;
    }
    return 16;
  }

  render() {
    const { listPromotions, excludedPromotions, includedPromotions } = this.props;
    const StyledSection = this.getTemplateStyledComponent();

    let listPromotionsAux = listPromotions;

    if (
      listPromotionsAux != null &&
      listPromotionsAux.length > 0 &&
      includedPromotions !== undefined &&
      includedPromotions.length > 0
    ) {
      listPromotionsAux = listPromotions.filter(f => includedPromotions.includes(f.id));
    } else if (
      listPromotionsAux != null &&
      listPromotionsAux.length > 0 &&
      excludedPromotions !== undefined
    ) {
      listPromotionsAux = listPromotions.filter(f => !excludedPromotions.includes(f.id));
    } else {
      listPromotionsAux = listPromotions;
    }

    return (
      <StyledSection>
        <div className="col-md-12 p-0 mb-5">
          <div className="">
            <div className="row">
              {this.state.loading ? (
                <Loading show />
              ) : listPromotionsAux.length === 0 ? (
                this.noPromotionText()
              ) : (
                listPromotionsAux.map((promo, key) => (
                  <div
                    key={`${key}-${promo.name}`}
                    className={`${this.getColumnWidth()} mb-4 card-promotion`}
                  >
                    <div className="card-promotion__img">
                      <LazyLoadImage
                        src={promo.imagePath}
                        alt={promo.name}
                        threshold={100}
                        effect="blur"
                        width={'100%!important'}
                        height={'50px'}
                        wrapperClassName="img-fluid mb-4"
                      />
                    </div>
                    <Title
                      type="h4"
                      className="mb-3 mt-2"
                      text={promo.name}
                      weight="700"
                      fontSize={this.getTitleSize()}
                      noMedia
                      italic={false}
                    />
                    <div className="card-promotion__text">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${promo.description}`,
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </StyledSection>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps)(PromocionesSection);
