import React from 'react';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import { props, defaults } from './SocialList.props';
import { StyledSocialList } from './SocialList.styled';
import { StyledSocialListModern } from './SocialList.styled.modern';
import { ReactComponent as TikTokIcon } from '../../assets/images/logos/tiktok.svg';
import { ReactComponent as XIcon } from '../../assets/images/logos/x.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/logos/linkedin.svg';
import { ReactComponent as TripAdvisorIcon } from '../../assets/images/logos/tripadvisor.svg';

const SocialList = props => {
  const getIcon = name => (name === 'facebook' ? `fa-${name}-f` : `fa-${name}`);

  const renderNewIcons = name => {
    switch (name) {
      case 'tiktok':
        return <TikTokIcon className="tiktok-logo" />;
      case 'twitter':
        return <XIcon className="x-logo" />;
      case 'linkedin':
        return <LinkedInIcon className="linkedin-logo" />;
      case 'tripadvisor':
        return <TripAdvisorIcon className="tripadvisor-logo" />;
      default:
        return <i className={name === 'phone' ? `fas ${getIcon(name)}` : `fab ${getIcon(name)}`} />;
    }
  };

  const { className, links, settings, location } = props;

  const getTemplateStyledComponent = () => {
    if (settings.theme.template === MODERN_THEME) {
      return StyledSocialListModern;
    }
    return StyledSocialList;
  };

  const StyledComponent = getTemplateStyledComponent();

  return (
    <StyledComponent {...props} className={className}>
      {links.map((item, index) => (
        <li className="socialItem" key={index}>
          <a
            name={item.name}
            className="socialLink"
            href={item.path || '/'}
            target="_blank"
            rel="noopener noreferrer"
            id={item.name === 'whatsapp' ? `whatsapp-button-${location}` : ''}
          >
            <p className="IconContainer">{renderNewIcons(item.name)}</p>
          </a>
        </li>
      ))}
    </StyledComponent>
  );
};

SocialList.propTypes = props;
SocialList.defaultProps = defaults;

export default withSettingsContext(SocialList);
