import styled from 'styled-components';

export const StyledHeader = styled.div`
  background: ${({ bgColor }) => bgColor || '#fff'};

  .nav-item {
    &.active .nav-link {
      color: ${({ activeColor, theme }) => activeColor || theme.colors.primary};
    }

    .nav-link {
      font-size: 0.9rem;
      color: ${({ color }) => color || '#656970'};

      &:hover {
        color: ${({ activeColor, theme }) => activeColor || theme.colors.primary};
      }
    }
  }

  .col {
    box-shadow: -1px 5px 13px -4px rgba(0, 0, 0, 0.1);
  }

  .navbar-light .navbar-toggler {
    border: none !important;
  }

  .right-navbar {
    .action-button .dropdown-toggle {
      border: ${({ theme, rightBtnColor }) =>
        `1px solid ${rightBtnColor || theme.colors.secondary}`};
      border-radius: 8px;
      color: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.primary};
      padding: 7px 16px;
      transition: all 0.3s;

      &:hover {
        background: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.secondary};
        color: white;
      }

      &::after {
        display: none;
      }
    }

    .cancel-button {
      border: ${({ theme, rightBtnColor }) =>
        `1px solid ${rightBtnColor || theme.colors.secondary}`};
      border-radius: 8px;
      color: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.primary};
      transition: all 0.3s;

      .nav-link {
        color: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.primary};
      }

      &:hover {
        background: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.secondary};
        color: white;
        .nav-link {
          color: white;
        }
      }
    }

    .dropdown-menu {
      padding: 0;
      margin-top: 7px;
      min-width: 12rem;
      border-radius: 8px;

      & > div:last-child .nav-link {
        border-bottom: none;
      }

      .nav-link {
        font-size: 0.9rem;
        padding: 8px 16px;
        border-bottom: 1px solid #ecf0f5;
        color: ${({ theme }) => theme.font.colors.secondary};
      }
    }
  }

  .my-account_mobile {
    margin-top: 0.2rem;
    width: 100%;
    flex-wrap: wrap;
    margin: 0px 0px 1rem 0px;

    .login-button {
      margin-left: 0;
      margin-top: 18px;
      width: 100%;
    }

    .my-account-button {
      width: 100%;
      background-color: #fff;

      .dropdown-menu {
        border: none;

        .nav-link {
          font-size: 0.9rem;
          color: #656970;
        }
      }

      .dropdown-toggle {
        padding-left: 0;

        &:active span,
        span {
          max-width: 100%;
          overflow: visible;
          text-overflow: inherit;
          white-space: inherit;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .navbar-nav {
      margin: 20px 0;
    }

    .cancel-button {
      width: 25%;
      text-align: center;
    }
  }
`;
