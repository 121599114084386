import React from 'react';
import { PropTypes } from 'prop-types';
import { translate } from 'utils/translationHelper';

class TranslatedComponent extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  };

  tt(key, forceTranslate = true) {
    const { t } = this.props;

    return translate(key, t, forceTranslate);
  }
}

export default TranslatedComponent;
