import PropTypes from 'prop-types';

export const props = {
  bgcolor: PropTypes.string,
  borderColor: PropTypes.string,
  borderSize: PropTypes.string,
  borderStyle: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
  configuration: PropTypes.object,
  companyInfo: PropTypes.object,
  collapsible: PropTypes.bool,
  seeMore: PropTypes.string,
  seeLess: PropTypes.string,
};
