import { fetchInitialSettings } from 'services/api';
import { SITE_CONFIGURATION_SET_SETTINGS } from 'utils/types';
import Storage from 'utils/storage';

export const fetchSettings = (
  refreshCache = false,
  version = null,
  isdraft = false,
) => async dispatch => {
  try {
    if (refreshCache) {
      await Storage.removeElement('fetchInitialSettings', true);
    }

    const data = await Storage.getOrUpdateItem(
      'fetchInitialSettings',
      async () => {
        return (await fetchInitialSettings(version, isdraft)).data;
      },
      30,
      true,
    );

    return dispatch({
      type: SITE_CONFIGURATION_SET_SETTINGS,
      payload: data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
