import PropTypes from 'prop-types';

export const props = {
  background: PropTypes.string,
  title: PropTypes.string,
  titleFontWeight: PropTypes.string,
  titleFontSize: PropTypes.string,
  titleColor: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleFontWeight: PropTypes.string,
  subTitleFontSize: PropTypes.string,
  subTitleColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonIconColor: PropTypes.string,
  buttonIconSize: PropTypes.string,
  buttonVariant: PropTypes.string,
  mobileBackground: PropTypes.string,
  titleType: PropTypes.string,
  subtitleType: PropTypes.string,
  contentAlignment: PropTypes.string,
  t: PropTypes.func,
  noParallax: PropTypes.bool,
  showSecondColumn: PropTypes.bool,
};
