import React from 'react';
import AuthService from '../../auth/AuthService';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const isLoggedIn = AuthService.isAuthenticated();

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={AuthService.login()} />
      }
    />
  );
};

export default PrivateRoute;
