import React from 'react';
import { withTranslation } from 'react-i18next';
import { props, defaultProps } from './SearchFilter.prop';
import { StyledFilter } from './SearchFilter.styled';
import { SearchForm, CategoriesFilter, AccordionCard, Title, Btn } from 'components/';
import { Accordion } from 'react-bootstrap';
import { withTheme } from 'styled-components';
import Popup from 'reactjs-popup';
import withSettingsContext from 'SettingsContext';
import { StyledFilterModern } from './SearchFilter.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { dateFormatEdition } from 'utils/dataHelper';
import useWindowDimensions from 'utils/windowHelper';

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.accordion = React.createRef();
  }
  state = {
    categoriesSelected: [],
  };

  handleCategoryChange = value => {
    this.setState(
      prevState => ({
        ...prevState,
        categoriesSelected: value,
      }),

      this.updateParametersState,
    );
  };

  closeAccordion = () => {
    const element = document.querySelector('#collapseSearch');
    element && element.click();
  };

  updateParametersState = () => {
    const { setSearchCarParams, params } = this.props;

    setSearchCarParams(params);
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledFilterModern;
    }
    return StyledFilter;
  }

  render() {
    const {
      searchCars: {
        params: { dateFrom, dateTo, categories },
      },
      t,
      showCity,
      promotionCodeText,
      customText,
      width,
    } = this.props;

    const StyledSection = this.getTemplateStyledComponent();

    const mobileSearchForm = (
      <>
        <SearchForm
          className="home-form w-100 mt-5"
          categories={categories}
          onSubmit={this.closeAccordion}
          showCity={showCity || false}
          promotionCodeText={promotionCodeText}
          customText={customText}
        />
        <div className="overlay"></div>
      </>
    );

    const accordionHeader = (
      <span className="date">
        <i className="far fa-calendar-alt mr-3"></i> {dateFormatEdition(dateFrom, t('dateFormat'))}
        <span className="divider ml-2 mr-2">|</span> {dateFormatEdition(dateTo, t('dateFormat'))}
      </span>
    );

    const categoriesFilter = <CategoriesFilter onCategoriesChanged={this.handleCategoryChange} />;

    return (
      <StyledSection className="col-md-3" background={this.props.background}>
        {width < 768 ? (
          // Mobile Search Form
          <Accordion className="searchFilter sticky">
            <AccordionCard
              ref={this.accordion}
              title={accordionHeader}
              element={mobileSearchForm}
              key="search"
              keyValue="search"
            />
          </Accordion>
        ) : (
          // Desktop Search Form
          <SearchForm
            className="home-form search-form w-100 mt-5"
            categories={categories}
            showCity={showCity || false}
            resultsPage
            promotionCodeText={promotionCodeText}
            customText={customText}
          />
        )}

        <div className="categoryBlock">{categoriesFilter}</div>

        <Popup
          className="categoryPopup"
          trigger={
            <button className="button categoriesFilter">
              <i className="fa fa-filter mr-2"></i> {t('categorias')}
            </button>
          }
          modal
        >
          {close => (
            <div className="modal">
              <div className="header">
                <Title
                  type="h4"
                  className="d-inline-block"
                  text={t('filters')}
                  weight="700"
                  fontSize={15}
                  italic={false}
                  noMedia
                />
                <a className="close" onClick={close}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
              <div className="content">
                {categoriesFilter}
                <Btn text={t('applyFilter')} onClick={close} className="mt-4 w-100" />
              </div>
            </div>
          )}
        </Popup>
        {/* <SearchMap /> */}
      </StyledSection>
    );
  }
}

const FunctionalSearchFilter = props => {
  const { width } = useWindowDimensions();
  return <SearchFilter width={width} {...props} />;
};

SearchFilter.propTypes = props;
SearchFilter.defaultProps = defaultProps;
export default withSettingsContext(withTheme(withTranslation()(FunctionalSearchFilter)));
