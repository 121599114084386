import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { StyledForgotPasswordForm } from './ForgotPasswordForm.styled';
import { StyledModernForgotPasswordForm } from './ForgotPasswordForm.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { TextInput, Card, Btn, Icon } from 'components/';
import { isValidEmail } from 'utils/dataHelper';
import { handleForgotPassword } from 'actions';
import { history } from 'store';
import { tlink } from 'utils/translationHelper';

function ForgotPasswordForm({
  t,
  i18n,
  settings: { theme, configurations },
  isAuthenticated,
  handleForgotPassword,
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailBlur, setEmailBlur] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const StyledComponent =
    theme.template === MODERN_THEME ? StyledModernForgotPasswordForm : StyledForgotPasswordForm;

  const getLink = path => {
    return tlink(path, t, i18n, null, configurations.langConfig);
  };

  useEffect(() => {
    if (isAuthenticated) history.replace(getLink('/my-account'));
  }, [isAuthenticated]);

  const submit = async e => {
    e.preventDefault();
    setError('');
    try {
      setLoading(true);
      const result = await handleForgotPassword({
        email,
      });
      if (result.payload && result.payload.success) {
        setSuccess(true);
      } else {
        setError(t(result.payload.data));
      }
    } catch (error) {
      const message = error.response && error.response.data.message;
      setError(message || t('registerError'));
    } finally {
      setLoading(false);
    }
  };

  const disableRegister = !isValidEmail(email);

  return (
    <StyledComponent>
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible className="error-alert">
          {error}
        </Alert>
      )}
      {success && (
        <div className="col-md-8 offset-md-2 text-center">
          <Icon className="fas fa-check-circle display-3 mb-5 text-success"></Icon>
          <h4>{t('myAccount.recoverPasswordEmailSuccess')}</h4>
        </div>
      )}
      {!success && (
        <Card>
          <Form>
            <Row>
              <Col>
                <h2 className="title">{t('myAccount.recoverPassword')}</h2>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <TextInput
                  inputClass="form-control"
                  type="mail"
                  className="form-control"
                  placeholder={t('myAccount.email')}
                  value={email}
                  onChange={setEmail}
                  options={{ onBlur: () => setEmailBlur(true) }}
                  error={emailBlur && !isValidEmail(email) && t('validEmail')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Btn
                  className="m-auto submit_btn"
                  text={t('myAccount.recoverPassword')}
                  disabled={disableRegister || loading}
                  loading={loading}
                  iconColor="white"
                  iconSize="1.2rem"
                  onClick={submit}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </StyledComponent>
  );
}

const mapStateToProps = ({ siteConfiguration, user }) => ({
  settings: siteConfiguration.settings,
  isAuthenticated: user.isAuthenticated,
});

export default connect(mapStateToProps, {
  handleForgotPassword,
})(withTranslation()(ForgotPasswordForm));
