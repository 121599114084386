export const BookingStatus = {
  reserved: 0,
  confirmed: 1,
  delivered: 2,
  closed: 3,
  canceled: 4,
  quoted: 5,
};

export const InfractionStatus = {
  collected: 0,
  notCollected: 1,
  collectionNotPossible: 2,
};

export const AirportNames = ['airport', 'aeropuertos', 'aeroparques', 'aeropuerto'];

export const OfficeNames = [
  'oficina',
  'oficinas',
  'sucursales',
  'sucursal',
  'branchoffice',
  'branch',
];

export const PointNames = ['delivery', 'punto', 'encuentro', 'domicilios'];

export const BusNames = ['terminales', 'terminal'];

export const PortNames = ['puerto', 'port', 'ferry'];

export const FileTypesList = [
  {
    name: 'documentTypes.booking',
    id: 0,
  },
  {
    name: 'documentTypes.delivery',
    id: 1,
  },
  {
    name: 'documentTypes.return',
    id: 2,
  },
  {
    name: 'documentTypes.contract',
    id: 3,
  },
  {
    name: 'documentTypes.tickets',
    id: 4,
  },
  {
    name: 'documentTypes.bill',
    id: 5,
  },
  {
    name: 'documentTypes.accident',
    id: 6,
  },
  {
    name: 'documentTypes.other',
    id: 7,
  },
  {
    name: 'documentTypes.webFiles',
    id: 8,
  },
  {
    name: 'documentTypes.purchaseOrder',
    id: 9,
  },
];
