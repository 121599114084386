import moment from 'moment';

const DEFAULT_GAP = 0;
const DEFAULT_GAP_FOR_BOOKING_TIME = 15;
const AFTER_HOURS_MAX_MINUTES = 2147483647;

export const getSchedule = (schedules, branchOfficeId) => {
  if (!branchOfficeId) return null;
  const schedulesForBranch = schedules.find(s => s.id === branchOfficeId);
  return schedulesForBranch || null;
};

export const calculateStartDate = (
  globalSchedules,
  holidays,
  pickUpBranchOfficeId,
  tentativeDay = null,
  checkTimes = true,
) => {
  tentativeDay = tentativeDay || moment();
  const branchSchedules = getSchedule(globalSchedules, pickUpBranchOfficeId);
  if (!branchSchedules) return tentativeDay;

  if (!isDayAvailable(tentativeDay, branchSchedules, holidays, 'start', checkTimes)) {
    tentativeDay = calculateStartDate(
      globalSchedules,
      holidays,
      pickUpBranchOfficeId,
      tentativeDay.clone().add(1, 'day'),
      false,
    );
  }

  return tentativeDay;
};

export const isDayAvailable = (tentativeDay, scheduleConf, holidays, type, checkTimes = true) => {
  if (!scheduleConf) return false;
  const gap = scheduleConf.gap || DEFAULT_GAP;
  const branchSchedule = scheduleConf.schedule;

  const blockedByHoliday = isBlockedByHoliday(
    tentativeDay,
    branchSchedule,
    holidays,
    scheduleConf.id,
  );
  if (blockedByHoliday) return false;

  const blockedByClosedDay = isBlockedByCloseDay(tentativeDay, branchSchedule);
  if (blockedByClosedDay) return false;

  const daySchedules = getScheduleForDay(tentativeDay, scheduleConf, holidays);
  if (!daySchedules.length > 0) return false;

  if (checkTimes) {
    const utcOffsetDiff = getTimeZoneDiff(tentativeDay.format('Z'), scheduleConf.timezoneUTCOffset);
    const timeAvailable = isTimeAvailable(
      tentativeDay,
      daySchedules,
      utcOffsetDiff + gap,
      type,
      scheduleConf,
    );
    if (!timeAvailable) return false;
  }

  return true;
};

const isBlockedByHoliday = (day, schedules, holidays, branchOfficeId) => {
  const holiday = holidays.find(h => h.date === day.format('YYYY-MM-DD'));
  if (!holiday) return false;

  const holidayAppliesToBranch = holiday.ids.length === 0 || holiday.ids.includes(branchOfficeId);

  // check if branch defined  schedule for holidays (workable holiday)
  if (holidayAppliesToBranch) {
    return schedules['holiday'].length !== 0 ? false : true;
  }

  return false;
};

const isBlockedByCloseDay = (day, scheduleConf) => {
  const { closedDays } = scheduleConf;
  if (!closedDays) return false;

  return closedDays.map(d => moment(d)).some(cd => cd.isSame(day, 'day'));
};

const getScheduleForDay = (date, schedulesConf, holidays) => {
  const schedules = schedulesConf.schedule;
  const scheduledDates = schedules.scheduleDates;

  if (scheduledDates) {
    const specificScheduleForDay = scheduledDates.find(sch => moment(sch.date).isSame(date, 'day'));
    if (specificScheduleForDay) return specificScheduleForDay.range;
  }

  const branchOfficeId = schedulesConf.id;
  const holiday = holidays.find(
    h => h.date === date.format('YYYY-MM-DD') && h.ids.includes(branchOfficeId),
  );
  if (holiday) return schedules['holiday'];

  return schedules[date.format('dddd').toLowerCase()];
};

const isTimeAvailable = (day, daySchedules, originalGap, type, scheduleConf) => {
  if (daySchedules.length === 0) return false;
  let limitHour;
  let hoursToAdd = originalGap;
  const sameDay = isSameDay(day);
  const afterHoursConf = scheduleConf.allowAfterHours ? scheduleConf.afterHoursMaxMinutes / 60 : 0;

  switch (type) {
    case 'start':
      if (afterHoursConf !== 0 && !sameDay) hoursToAdd = Number(afterHoursConf) * -1;
      const modifiedDay = sameDay ? day.clone().add(hoursToAdd, 'h') : day;
      // if date was changed after adding the hoursToAdd, day is not available.
      if (modifiedDay.isAfter(day, 'day')) return false;
      limitHour = Number(modifiedDay.format('HHmm'));
      break;
    case 'end':
      // while end date is not today we want to check using morning hours. the first one found in the schedule.
      const firstOpenedFrom = daySchedules[0]['openedFrom'].split(':')[0];
      limitHour = isSameDay(day) ? day.format('HHmm') : firstOpenedFrom;
      break;
  }

  const lastOpenedTo = getLastOpenedForDay(daySchedules, scheduleConf.afterHoursMaxMinutes);
  return limitHour > Number(lastOpenedTo) ? false : true;
};

const getLastOpenedForDay = (daySchedules, afterHoursConf) => {
  let originalOpenedTo = daySchedules[daySchedules.length - 1]['openedTo']
    .slice(0, -3)
    .replace(':', '');
  if (!afterHoursConf) return originalOpenedTo;

  const openedToHour = originalOpenedTo.slice(0, -2);
  const openedToMinutes = originalOpenedTo.slice(2);

  const dummyDate = moment()
    .hour(Number(openedToHour))
    .minute(Number(openedToMinutes));
  const dummyDateModified = dummyDate.clone().add(afterHoursConf, 'm');

  if (dummyDateModified.isAfter(dummyDate, 'day')) return '2359';
  return dummyDateModified.format('HHmm');
};

const isSameDay = day => {
  return moment().isSame(day, 'day');
};

// En la primer entrada, con el startDate y daysInBetween (min-stay) define el 1er endDate posible
// si la fecha no está disponible: debe ir sumando de a 1 dia y ver si está disponible
export const calculateEndDate = (
  startDate,
  daysInBetween,
  globalSchedules,
  holidays,
  branchOfficeId,
  tentativeEndDate = null,
) => {
  if (!startDate) return null;
  if (!tentativeEndDate) tentativeEndDate = startDate.clone().add(daysInBetween, 'd');

  const branchSchedules = getSchedule(globalSchedules, branchOfficeId);
  if (!branchSchedules) return tentativeEndDate;

  if (!isDayAvailable(tentativeEndDate, branchSchedules, holidays, 'end')) {
    tentativeEndDate = calculateEndDate(
      startDate,
      daysInBetween,
      globalSchedules,
      holidays,
      branchOfficeId,
      tentativeEndDate.clone().add(1, 'day'),
    );
  }

  return tentativeEndDate;
};

export const _calculatePickDropHours = ({
  pickUpDate,
  dropOffDate,
  pickUpBranchOfficeId,
  dropOffBranchOfficeId,
  schedules,
  holidays,
} = {}) => {
  let pickUpHours = [];
  let dropOffHours = [];

  if (pickUpDate && dropOffDate) {
    const pickUpSchedules = getSchedule(schedules, pickUpBranchOfficeId);
    const dropOffSchedules = getSchedule(schedules, dropOffBranchOfficeId);

    const pickUpSchedulesConfigured = areSchedulesConfigured(pickUpSchedules);
    const dropOffSchedulesConfigured = areSchedulesConfigured(dropOffSchedules);

    if (pickUpSchedulesConfigured) {
      const pickUpRanges = getScheduleForDay(pickUpDate, pickUpSchedules, holidays);
      const pickUpIsToday = moment(pickUpDate).isSame(moment(), 'day');
      const afterHoursConfig = pickUpSchedules.allowAfterHours
        ? pickUpSchedules.afterHoursMaxMinutes
        : null;
      const beforeHoursConfig = pickUpSchedules.allowAfterHours
        ? pickUpSchedules.beforeHoursMaxMinutes
        : null;
      const hoursGap = pickUpSchedules.gap || DEFAULT_GAP;
      const minutesBetween = pickUpSchedules
        ? pickUpSchedules.gapForBookingTime
        : DEFAULT_GAP_FOR_BOOKING_TIME;

      pickUpHours = pickUpRanges
        .map(timeRange =>
          getHoursFromDay(
            'pickup',
            calculatePickUpTime(
              pickUpIsToday,
              timeRange.openedFrom,
              pickUpSchedules.timezoneUTCOffset,
              minutesBetween,
              hoursGap,
            ),
            timeRange.openedTo,
            minutesBetween,
            afterHoursConfig,
            beforeHoursConfig,
            pickUpIsToday,
          ),
        )
        .flat();
    }

    if (dropOffSchedulesConfigured) {
      const dropOffRanges = getScheduleForDay(dropOffDate, dropOffSchedules, holidays);
      const afterHoursConfig = dropOffSchedules.allowAfterHours
        ? dropOffSchedules.afterHoursMaxMinutes
        : null;
      const beforeHoursConfig = dropOffSchedules.allowAfterHours
        ? dropOffSchedules.beforeHoursMaxMinutes
        : null;

      dropOffHours = dropOffRanges
        .map(timeRange =>
          getHoursFromDay(
            'dropoff',
            timeRange.openedFrom,
            timeRange.openedTo,
            dropOffSchedules ? dropOffSchedules.gapForBookingTime : DEFAULT_GAP_FOR_BOOKING_TIME,
            afterHoursConfig,
            beforeHoursConfig,
          ),
        )
        .flat();
    }
  }

  // removal of possible duplicates
  pickUpHours = [...new Set(pickUpHours)];
  dropOffHours = [...new Set(dropOffHours)];

  return { pickUpHours, dropOffHours };
};

const areSchedulesConfigured = scheduleConf => {
  if (!scheduleConf) return false;
  const schedules = scheduleConf.schedule;

  for (const day in schedules) {
    if (schedules[day].length > 0) return true;
  }

  return false;
};

const calculatePickUpTime = (
  pickUpIsToday,
  openedFrom,
  branchUtcOffset,
  minutesBetween,
  hoursFromNow,
) => {
  if (!pickUpIsToday) return openedFrom;

  const utcOffsetDiff = getTimeZoneDiff(moment().format('Z'), branchUtcOffset);

  if (pickUpIsToday) {
    const tentativeHour = moment().add(utcOffsetDiff + hoursFromNow, 'h');
    const minOpenFrom = openedFrom.split(':')[0];
    const minsDiff = minutesBetween - (tentativeHour.minute() % minutesBetween);
    const tentativeFrom = moment(tentativeHour)
      .add(minsDiff, 'minutes')
      .format('HH:mm');

    return tentativeHour.format('HH') >= Number(minOpenFrom) ? tentativeFrom : openedFrom;
  }

  const dummyDate = moment()
    .hour(Number(openedFrom.split(':')[0]))
    .minute(Number(openedFrom.split(':')[1]));
  const modifiedTime = dummyDate.clone();

  const time = modifiedTime.isBefore(dummyDate, 'd')
    ? dummyDate
        .clone()
        .hour(0)
        .minute(0)
    : modifiedTime;

  const minsDiff =
    time.minute() % minutesBetween === 0 ? 0 : minutesBetween - (time.minute() % minutesBetween);

  return moment(time)
    .add(minsDiff, 'minutes')
    .format('HH:mm');
};

const getTimeZoneDiff = (localOffset, branchOffset) => {
  const local = `${localOffset.split(':')[0]}.${localOffset.split(':')[1]}`;
  const branch = `${branchOffset.split(':')[0]}.${branchOffset.split(':')[1]}`;
  const result = Number(branch) - Number(local);

  if (Number.isInteger(result)) return result;

  // used to deal with timezones like 09:30,  09:45.
  const splitResult = result
    .toFixed(2)
    .toString()
    .split('.');
  const decimalToMin = splitResult[1] / 60;

  return Number(splitResult[0] + '.' + decimalToMin.toString().split('.')[1]);
};

const getHoursFromDay = (
  type,
  hourlyFrom,
  hourlyTo,
  gap,
  afterHoursMaxMinutes,
  beforeHoursMaxMinutes,
  pickUpIsToday,
) => {
  const hourFrom = moment()
    .hour(hourlyFrom.split(':')[0])
    .minute(hourlyFrom.split(':')[1]);
  const hourTo = moment()
    .hour(hourlyTo.split(':')[0])
    .minute(hourlyTo.split(':')[1]);

  let finalFrom, finalTo, modifiedFrom, modifiedTo;

  if (beforeHoursMaxMinutes && !pickUpIsToday) {
    modifiedFrom = hourFrom.clone().add(beforeHoursMaxMinutes * -1, 'm');
  } else if (afterHoursMaxMinutes) {
    modifiedFrom =
      type === 'dropoff' ? hourFrom.clone().add(afterHoursMaxMinutes * -1, 'm') : hourFrom.clone();
  }

  if (afterHoursMaxMinutes) {
    modifiedTo = hourTo.clone().add(afterHoursMaxMinutes, 'm');
  }

  if (modifiedFrom) {
    finalFrom = modifiedFrom.isBefore(hourFrom, 'd')
      ? hourFrom
          .clone()
          .hour(0)
          .minute(0)
      : modifiedFrom;
  }

  if (modifiedTo) {
    finalTo = modifiedTo.isAfter(hourTo, 'd')
      ? hourTo
          .clone()
          .hour(23)
          .minute(45)
      : modifiedTo;
  }

  if (afterHoursMaxMinutes === AFTER_HOURS_MAX_MINUTES) {
    finalFrom = moment()
      .hour(0)
      .minute(0);
    finalTo = moment()
      .hour(23)
      .minute(45);
  }

  if (!modifiedFrom && !modifiedTo) {
    finalFrom = hourFrom;
    finalTo = hourTo;
  }

  const hours = [];
  hours.push(
    `${String(finalFrom.hour()).padStart(2, '0')}:${String(finalFrom.minutes()).padStart(2, '0')}`,
  );

  while (stillHasAvailableTime(finalFrom, finalTo)) {
    finalFrom.add(gap, 'm');
    if (finalFrom.isAfter(finalTo, 'day')) continue;
    hours.push(
      `${String(finalFrom.hour()).padStart(2, '0')}:${String(finalFrom.minutes()).padStart(
        2,
        '0',
      )}`,
    );
  }

  hours.push(
    `${String(finalTo.hour()).padStart(2, '0')}:${String(finalTo.minutes()).padStart(2, '0')}`,
  );
  return hours.sort();
};

const stillHasAvailableTime = (from, to) => {
  return from.isBefore(to, 'time');
};

export const parseHourToMoment = (hour, moment) => {
  if (!hour) return moment;
  const [newHour, newMin] = hour.split(':');
  return moment.set({
    hour: parseFloat(newHour),
    minute: parseFloat(newMin),
    second: 0,
  });
};
