import style from 'styled-components';

export const StyledCompanyInfo = style.div`
  color: ${props => props.theme.font.colors.secondary} !important;

  .companyInfoTitle {
    margin-top: 20px;
    margin-bottom: .5rem;
    color: ${props => props.color || props.theme.font.colors.secondary};
  }

  p, .adress {
    color: ${props => props.color || props.theme.font.colors.secondary};
    margin-block-end: 0;
    margin-block-start: 0;
    line-height: 20px;
    font-size: 12px;
    font-weight: 200;
  }
`;
