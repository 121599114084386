import React from 'react';
import { Title, TextInput, SelectInput, Btn, ScriptTagComponent, CheckboxInput } from 'components/';
import { connect } from 'react-redux';
import { history } from '/store';
import { withRouter } from 'react-router-dom';
import { StyledFiservIpgSection } from './FiservIpgSection.styled';
import { withTranslation } from 'react-i18next';
import { Paragraph } from 'components/';
import { tt, tlink } from '../../../utils/translationHelper';
import queryString from 'query-string';
import * as yup from 'yup';
import { payBooking, payInfraction } from 'services/api';
import { fetchDocumentTypes, fetchFiscalConditions } from '../../../actions/global.actions';
import { BookingStatus } from '../../../utils/constants';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  getDocumentSample,
  isValidDocument,
  transformDocTypesToSelectOptions,
  transformFiscalConditionsToSelectOptions,
} from '../../../utils/documentHelper';

import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from '../../../utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';

var CreditCardValidator = require('card-validator');

class FiservIpgSection extends BasePaymentSection {
  constructor(props) {
    const {
      t,
      withBillingInformation,
      settings: {
        configurations: { fiscalConditionValidation },
      },
    } = props;
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
        qsamount: 0,
        infractionId: '0',
        customerId: '0',
        act: '',
        branchOfficeId: 0,
        promotionId: '',
        noPendingPayTransactions: true,
        showBillingInfo: withBillingInformation !== undefined ? withBillingInformation : false,
        fiscalConditionId: null,
        currencyCode: '',
        exchangeRate: 1,
        installment: 1,
      },
      formErrors: {
        documentType: '',
        cardNumber: '',
        document: '',
        cardholderName: '',
        year: '',
        month: '',
        secCode: '',
        firstName: '',
        lastName: '',
        fiscalConditionId: null,
        billingDocumentType: '',
        documentId: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        cellPhone: '',
        emailAddress: '',
      },
      documents: [
        {
          value: 'dni',
          text: 'DNI',
        },
        {
          value: 'cuil',
          text: 'CUIL',
        },
      ],
      cardIcon: 'far fa-credit-card',
      formLoading: false,
      disableBillingFields: false,
      fiscalConditions: [],
    };

    yup.addMethod(yup.number, 'isValidCreditCard', function(creditCardNumber) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_card_number'), function(
        ccNumber,
      ) {
        return CreditCardValidator.number(ccNumber).isValid;
      });
    });

    yup.addMethod(yup.number, 'isValidSecCode', function(secCode) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_security_code'), function(
        secCode,
      ) {
        const ccObject = CreditCardValidator.number(this.parent.cardNumber);
        if (!secCode || !ccObject.card) return false;

        if (ccObject.card.code.size == 4) return secCode >= 1 && secCode <= 9999;
        if (ccObject.card.code.size == 3) return secCode >= 1 && secCode <= 999;

        return secCode.toString().length === ccObject.card.code.size;
      });
    });

    yup.addMethod(yup.string, 'isValidDocument', function(documentId) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongDocumentFormat'),
        function(documentId) {
          const { tenantDocumentTypes, billingDocumentType } = this.parent;
          return isValidDocument(tenantDocumentTypes, billingDocumentType, documentId);
        },
      );
    });

    yup.addMethod(yup.string, 'hasValidDocumentType', function(fiscalCondition) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongFiscalCondition'),
        function(fiscalCondition) {
          const taxPayerValidation = (fiscalConditionValidation || [])?.find(
            i => `${i.id}` === fiscalCondition,
          );

          if (!taxPayerValidation?.validDocumentIds) return true;
          else {
            const validIds = taxPayerValidation?.validDocumentIds;
            if (validIds.includes(this.parent.billingDocumentType)) return true;
            return false;
          }
        },
      );
    });

    this.paymentInfoFormSchema = yup.object().shape({
      documentType: yup.string().required(t('inputIsRequired', { field: t('tipodocumento') })),
      cardNumber: yup
        .number()
        .isValidCreditCard()
        .required(t('inputIsRequired', { field: t('numeroTarjeta') })),
      document: yup.string().required(t('inputIsRequired', { field: t('documento') })),
      cardholderName: yup.string().required(t('inputIsRequired', { field: t('titular') })),
      year: yup.number().required(t('inputIsRequired', { field: t('año') })),
      month: yup.number().required(t('inputIsRequired', { field: t('mes') })),
      secCode: yup
        .number()
        .isValidSecCode()
        .required(t('inputIsRequired', { field: t('codigo') })),
      firstName: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(
          t('inputIsRequired', {
            field: t('billingInformation.fields.firstName'),
          }),
        ),
      }),
      lastName: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(
          t('inputIsRequired', {
            field: t('billingInformation.fields.lastName'),
          }),
        ),
      }),
      fiscalConditionId: yup
        .string()
        .nullable()
        .when('showBillingInfo', {
          is: true,
          then: yup
            .string()
            .hasValidDocumentType()
            .required(
              t('inputIsRequired', {
                field: t('billingInformation.fields.fiscalCondition'),
              }),
            ),
        }),
      billingDocumentType: yup.number().when('showBillingInfo', {
        is: true,
        then: yup.number().required(t('inputIsRequired', { field: t('tipodocumento') })),
      }),
      documentId: yup.string().when('showBillingInfo', {
        is: true,
        then: yup
          .string()
          .isValidDocument()
          .required(
            t('inputIsRequired', {
              field: t('billingInformation.fields.document'),
            }),
          ),
      }),
      address: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('addressName') })),
      }),
      addressNumber: yup
        .string()
        .nullable(true)
        .when('showBillingInfo', {
          is: true,
          then: yup
            .string()
            .typeError(t('invalidNumber'))
            .required(t('inputIsRequired', { field: t('addressNumber') })),
        }),
      zipCode: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('zipCode') })),
      }),
      cellPhone: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('phone') })),
      }),
      emailAddress: yup
        .string()
        .email(t('validEmail'))
        .when('showBillingInfo', {
          is: true,
          then: yup.string().required(t('inputIsRequired', { field: t('mail') })),
        }),
    });

    this.formRef = React.createRef(null);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(15), (val, index) => index + year);
    this.months = Array.from(new Array(12), (val, index) => index + 1);
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      noPendingPayTransactions,
      fetchDocumentTypes,
      fetchFiscalConditions,
      listDocumentTypes,
      listTaxPayerTypes,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;
    let tenantDocumentTypes = listDocumentTypes || [];

    if (this.state.paymentInfo.showBillingInfo && tenantDocumentTypes.length === 0)
      tenantDocumentTypes = (await fetchDocumentTypes('', i18n.language)).payload;

    const parseQs = queryString.parse(location.search);

    const customer = this.getCustomerInfo();

    const tenantFiscalConditions =
      listTaxPayerTypes || (await fetchFiscalConditions(i18n.language))?.payload;

    this.setState(prevState => ({
      ...prevState,
      fiscalConditions: tenantFiscalConditions,
    }));

    if (this.isPayingBooking() && bookings.confirmation != null) {
      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 18, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 18, option: 'Fiserv' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          branchOfficeId: bookings.confirmation.deliveryPlace.branchOfficeId,
          promotionId: parseQs.promotionid || null,
          noPendingPayTransactions:
            noPendingPayTransactions !== undefined ? noPendingPayTransactions : true,
          installment: '1',
          firstName: '',
          lastName: '',
          fiscalConditionId: null,
          billingDocumentType: 0,
          documentId: '',
          address: '',
          addressNumber: null,
          zipCode: '',
          cellPhone: '',
          emailAddress: '',
          tenantDocumentTypes,
          billingInfoCheckbox: false,
          disableBillingFields: false,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
        },
        sampleDocFormat: getDocumentSample(tenantDocumentTypes, customer.documentType),
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          branchOfficeId: infraction.booking?.deliveryPlace?.branchOfficeId || null,
          firstName: '',
          lastName: '',
          fiscalConditionId: null,
          billingDocumentType: 0,
          documentId: '',
          address: '',
          addressNumber: null,
          zipCode: '',
          cellPhone: '',
          emailAddress: '',
          tenantDocumentTypes,
          billingInfoCheckbox: false,
          disableBillingFields: false,
          currencyCode: defaultCurrency.isoCode,
          exchangeRate: 1,
        },
      }));
    }
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= Math.abs(qsamount)) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  handleDocumentType = documentType => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, documentType },
      formErrors: { ...prevState.formErrors, documentType: null },
    }));
  };

  handleCardNumber = cardNumber => {
    const ccObject = CreditCardValidator.number(cardNumber);
    const ccIcon = this.getCreditCardIcon(ccObject.card ? ccObject.card.type : '');

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        cardNumber,
        paymentMethodID: ccObject.card ? ccObject.card.type : '',
      },
      formErrors: { ...prevState.formErrors, cardNumber: null, secCode: null },
      cardIcon: ccIcon,
    }));
  };

  getCreditCardIcon = ccType => {
    let icon = '';

    switch (ccType) {
      case 'visa':
        icon = 'fab fa-cc-visa';
        break;
      case 'amex':
      case 'american-express':
        icon = 'fab fa-cc-amex';
        break;
      case 'mastercard':
        icon = 'fab fa-cc-mastercard';
        break;
      default:
        icon = 'far fa-credit-card';
        break;
    }

    return icon;
  };

  handleSecCode = secCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, secCode },
      formErrors: { ...prevState.formErrors, secCode: null },
    }));
  };

  handleMonth = month => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, month },
      formErrors: { ...prevState.formErrors, month: null },
    }));
  };

  handleYear = year => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, year },
      formErrors: { ...prevState.formErrors, year: null },
    }));
  };

  handleCardholderName = cardholderName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, cardholderName },
      formErrors: { ...prevState.formErrors, cardholderName: null },
    }));
  };

  handleDocument = document => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, document },
      formErrors: { ...prevState.formErrors, document: null },
    }));
  };

  handleFirstName = firstName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        firstName,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, firstName: '' },
    }));
  };

  handleLastName = lastName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        lastName,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, lastName: '' },
    }));
  };

  handleEmailAddress = emailAddress => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        emailAddress,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, emailAddress: '' },
    }));
  };

  handleAddress = address => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        address,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, address: '' },
    }));
  };

  handleAddressNumber = addressNumber => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        addressNumber,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, addressNumber: '' },
    }));
  };

  handleAddressDepartment = addressDepartment => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        addressDepartment,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, addressDepartment: '' },
    }));
  };

  handleZipCode = zipCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        zipCode,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, zipCode: '' },
    }));
  };

  handleCellphone = cellPhone => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        cellPhone,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, cellPhone: '' },
    }));
  };

  handleFiscalCondition = fiscalConditionId => {
    const {
      settings: { configurations },
    } = this.props;
    const validDocumentIds = (configurations?.fiscalConditionValidation || []).find(
      i => `${i.id}` === fiscalConditionId,
    )?.validDocumentIds;
    let billingDocumentType = this.state.paymentInfo.billingDocumentType;

    if (
      validDocumentIds &&
      (!billingDocumentType || !validDocumentIds?.includes(billingDocumentType))
    ) {
      billingDocumentType = validDocumentIds[0];
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        fiscalConditionId,
        billingDocumentType,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, fiscalConditionId: null },
    }));
  };

  handleBillingDocumentType = billingDocumentType => {
    const { listDocumentTypes } = this.props;

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        billingDocumentType,
        billingInfoCheckbox: false,
      },
      formErrors: {
        ...prevState.formErrors,
        billingDocumentType: '',
        fiscalConditionId: null,
      },
      sampleDocFormat: getDocumentSample(listDocumentTypes, billingDocumentType),
    }));
  };

  handleDocumentId = documentId => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        documentId,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, documentId: '' },
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const paymentInfo = this.state.paymentInfo;
    this.setState({ formLoading: true });

    this.paymentInfoFormSchema
      .validate(paymentInfo, { abortEarly: false })
      .then(valid => {
        if (valid) this.executePayment(paymentInfo);
      })
      .catch(err => {
        this.setState({ formLoading: false });
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });
  };

  executePayment = async params => {
    const {
      t,
      i18n,
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    let paymentParams = this.state.paymentInfo;
    paymentParams.gatewayId = 'FISERVIPG';

    try {
      await this.makePayment(paymentParams);

      if (is_gtag_enabled(googleTagManager)) gtag_purchase(bookings.confirmation, settings);
      history.push(
        `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
      );
    } catch (error) {
      let errorMessage;

      if (error.response == null || error.response.data == null) {
        errorMessage = t('paymentGenericError');
      } else if (error.response.data.code === 500) {
        errorMessage = t('payBookingGatewayFailGenericError', {
          bookingId: paymentParams.bookingId,
        });
      } else if (error.response.data.code === 501) {
        errorMessage = t('payBookingRentlyFailGenericError', {
          transactionId: error.response.data.message,
        });
      }
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        { error: errorMessage },
      );
    }
  };

  makePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }
      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction()) {
      if (!this.state.paymentInfo.act) return null;
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
    }
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('pagandoReserva', { booking_id })
        : t('pagarReserva', { booking_id });
    }

    if (this.isPayingInfraction()) {
      const act = this.state.paymentInfo.act?.toString().padStart(7, '0');
      return this.state.formLoading ? t('payingInfraction', { act }) : t('payInfraction', { act });
    }
  };

  parseToFiservMonths = months => {
    return months.map(m => m.toString().padStart(2, '0'));
  };

  parseToFiservYears = years => {
    return years.map(y => y.toString());
  };

  handleCheckboxUseContactInfoChange = checked => {
    const customer = this.getCustomerInfo();

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        billingInfoCheckbox: checked,
        disableBillingFields: checked,
        firstName: checked ? customer.firstName : prevState.paymentInfo.firstName,
        lastName: checked ? customer.lastName : prevState.paymentInfo.lastName,
        fiscalConditionId: checked
          ? customer.fiscalConditionId
          : prevState.paymentInfo.fiscalConditionId,
        billingDocumentType: checked
          ? customer.documentTypeId || 0
          : prevState.paymentInfo.billingDocumentType,
        documentId: checked ? customer.documentId : prevState.paymentInfo.documentId,
        address: checked ? customer.address : prevState.paymentInfo.address,
        addressNumber: checked
          ? customer.addressNumber || null
          : prevState.paymentInfo.addressNumber,
        addressDepartment: checked ? customer.addressDepartment || '' : '',
        zipCode: checked ? customer.zipCode : prevState.paymentInfo.zipCode,
        cellPhone: checked ? customer.cellPhone : prevState.paymentInfo.cellPhone,
        emailAddress: checked ? customer.emailAddress : prevState.paymentInfo.emailAddress,
      },
      formErrors: {
        firstName: '',
        lastName: '',
        fiscalConditionId: null,
        billingDocumentType: '',
        documentId: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        cellPhone: '',
        emailAddress: '',
      },
    }));
  };

  parseBillingInfoSection = () => {
    const { t, billingSubtitle, billingConditions } = this.props;

    return (
      <>
        <div className="billing-form pb-1 pt-3 payment-code">
          <hr />
          <div className="col-md-12 p-0">
            <p className="mb-2">
              <strong>{t('billingInformation.title')}</strong>
            </p>
            {billingSubtitle && <p className="label mb-2">{t(billingSubtitle)}</p>}
            <CheckboxInput
              onChange={this.handleCheckboxUseContactInfoChange}
              checked={this.state.paymentInfo.billingInfoCheckbox}
              text={<p className="mb-1">{t('billingInformation.equalToContactInfo')}</p>}
            />
            {this.parseBillingFields()}
          </div>
          {billingConditions && <Paragraph text={billingConditions} />}
        </div>
      </>
    );
  };

  parseBillingFields = () => {
    const { t } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('billingInformation.fields.firstName')}
                value={this.state.paymentInfo.firstName}
                error={this.state.formErrors.firstName}
                onChange={this.handleFirstName}
                options={{ autoComplete: 'billingInfo.firstName' }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('billingInformation.fields.lastName')}
                value={this.state.paymentInfo.lastName}
                error={this.state.formErrors.lastName}
                onChange={this.handleLastName}
                options={{ autoComplete: 'billingInfo.lastName' }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('mail')}
                value={this.state.paymentInfo.emailAddress}
                error={this.state.formErrors.emailAddress}
                onChange={this.handleEmailAddress}
                options={{ autoComplete: 'billingInfo.emailAddress' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressName')}
                value={this.state.paymentInfo.address}
                error={this.state.formErrors.address}
                onChange={this.handleAddress}
                options={{ autoComplete: 'address-line1' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressNumber')}
                value={this.state.paymentInfo.addressNumber}
                error={this.state.formErrors.addressNumber}
                onChange={this.handleAddressNumber}
                options={{ autoComplete: 'addressNumber' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressDepartment')}
                value={this.state.paymentInfo.addressDepartment}
                error={this.state.formErrors.addressDepartment}
                onChange={this.handleAddressDepartment}
                options={{ autoComplete: 'addressDepartment' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('zipCode')}
                value={this.state.paymentInfo.zipCode}
                error={this.state.formErrors.zipCode}
                onChange={this.handleZipCode}
                options={{ autoComplete: 'zipCode' }}
              />
            </div>
          </div>
          <div className={!this.state.paymentInfo.showBillingInfo ? 'col-md-4' : 'col-md-3'}>
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('phone')}
                value={this.state.paymentInfo.cellPhone}
                error={this.state.formErrors.cellPhone}
                onChange={this.handleCellphone}
                options={{ autoComplete: 'tel' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <SelectInput
                inputClass="form-control"
                onChange={this.handleFiscalCondition}
                selected={this.state.paymentInfo.fiscalConditionId}
                options={transformFiscalConditionsToSelectOptions(this.state.fiscalConditions, t)}
                error={this.state.formErrors.fiscalConditionId}
                defaultOption={t('billingInformation.fields.fiscalCondition')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <SelectInput
                inputClass="form-control"
                onChange={this.handleBillingDocumentType}
                options={transformDocTypesToSelectOptions(this.props.listDocumentTypes) || []}
                selected={this.state.paymentInfo.billingDocumentType}
                error={this.state.formErrors.billingDocumentType}
                defaultOption={t('tipodocumento')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <TextInput
              inputClass="form-control"
              placeholder={t('billingInformation.fields.document')}
              value={this.state.paymentInfo.documentId}
              error={this.state.formErrors.documentId}
              onChange={this.handleDocumentId}
              options={{ autoComplete: 'document' }}
              legend={
                this.state.sampleDocFormat
                  ? t('billingInformation.fields.formatExpected', {
                      format_expected: this.state.sampleDocFormat,
                    })
                  : null
              }
            />
          </div>
        </div>
      </>
    );
  };

  getCustomerInfo = () => {
    if (this.isPayingBooking()) return this.props.bookings.confirmation.customer;
    if (this.isPayingInfraction() && this.props.infractions.current) {
      return this.props.infractions.current.booking
        ? this.props.infractions.current.booking.customer
        : {};
    }
  };

  render() {
    const {
      t,
      title,
      subtitle,
      script,
      currentCurrency,
      defaultCurrency,
      webCurrency,
    } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    return (
      <StyledFiservIpgSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form id="fiserv-form" onSubmit={this.handleSubmit} ref={this.formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('numeroTarjeta')}</span>
                      <TextInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleCardNumber}
                        value={this.state.paymentInfo.cardNumber}
                        icon={this.state.cardIcon}
                        error={this.state.formErrors.cardNumber}
                        options={{ 'data-decidir': 'card_number' }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('codigo')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleSecCode}
                        value={this.state.paymentInfo.secCode}
                        error={this.state.formErrors.secCode}
                        options={{ 'data-decidir': 'security_code' }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('mes')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleMonth}
                        selected={this.state.paymentInfo.month}
                        error={this.state.formErrors.month}
                        props={{ 'data-decidir': 'card_expiration_month' }}
                        options={this.parseToFiservMonths(this.months)}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('año')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleYear}
                        error={this.state.formErrors.year}
                        selected={this.state.paymentInfo.year}
                        props={{ 'data-decidir': 'card_expiration_year' }}
                        options={this.parseToFiservYears(this.years)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('titular')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleCardholderName}
                        value={this.state.paymentInfo.cardholderName}
                        options={{ 'data-decidir': 'card_holder_name' }}
                        error={this.state.formErrors.cardholderName}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('tipodocumento')}</span>
                      <SelectInput
                        inputClass="form-control"
                        onChange={this.handleDocumentType}
                        selected={this.state.paymentInfo.documentType}
                        options={this.state.documents}
                        props={{ 'data-checkout': 'card_holder_doc_type' }}
                        error={this.state.formErrors.documentType}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <span className="label">{t('documento')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleDocument}
                        value={this.state.paymentInfo.document}
                        options={{ 'data-decidir': 'card_holder_doc_number' }}
                        error={this.state.formErrors.document}
                      />
                    </div>
                  </div>
                </div>
                {this.state.paymentInfo.showBillingInfo && this.parseBillingInfoSection()}
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <Btn
                      type="submit"
                      text={this.getPayButtonText()}
                      disabled={this.state.formLoading}
                      className="mt-5 fiserv-pay_button"
                    />
                  </div>
                  <div className="logo-container">
                    <span>{t('providedBy')}</span>
                    <img
                      width="151"
                      height="52"
                      className="fiserv-logo"
                      src="/images/payments/fiservipg-img.png"
                      alt="Fiserv"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ScriptTagComponent url={script} loadLibrary={true} async={true} />
      </StyledFiservIpgSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  listDocumentTypes: global.listDocumentTypes,
  listTaxPayerTypes: global.listTaxPayerTypes,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps, { fetchDocumentTypes, fetchFiscalConditions })(
  withRouter(withTranslation()(FiservIpgSection)),
);
