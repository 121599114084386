import { connect } from 'react-redux';
import CheckoutFilter from './CheckoutFilter';
import { setSearchCarParams, fetchBookeableCars } from 'actions/';

const mapStateToProps = ({ global, searchCars, siteConfiguration }) => ({
  listCategories: global.listCategories,
  places: global.listPlaces,
  params: searchCars.params,
  searchCars,
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { setSearchCarParams, fetchBookeableCars })(CheckoutFilter);
