import style from 'styled-components';

export const StyledPromotionModal = style.div`
	.promotion-modal {
		width: 100%;
	}
	.popup-content {
		width: unset !important; 
	}
`;
