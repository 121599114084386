import React from 'react';
import { PropTypes } from 'prop-types';
import { Accordion, Card, Nav } from 'react-bootstrap';
import { StyledFlotaSection, StyledFlotaGrid } from './FlotaSection.styled';
import Slider from 'react-slick';
import FlotaBigItem from './FlotaBigItem';
import { Loading } from 'components/';
import FlotaItem from 'sections/home/Flota/FlotaItem/FlotaItem';
import { getAllCategoryIdsInCollection } from '../../../utils/categoryGroupHelper';
import { StyledFlotaGridModern, StyledFlotaSectionModern } from './FlotaSection.styled.modern';
import { MODERN_THEME } from 'styled/constants';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class FlotaSection extends React.Component {
  static propTypes = {
    bookLink: PropTypes.string,
    showOnlyDefaultModels: PropTypes.bool,
    showModelDetail: PropTypes.bool,
  };

  state = {
    loading: true,
    filteredModels: [],
    filteredEntities: [],
  };

  async componentDidMount() {
    const {
      fetchCategories,
      i18n,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    const categories = (await fetchCategories(i18n.language)).payload;

    const filteredEntities = categoryGroups
      ? categoryGroups
      : this.getFilteredCategories(categories);

    this.setState(prevState => ({
      ...prevState,
      loading: false,
      filteredEntities,
    }));

    this.showAllModels();
  }

  showAllModels = () => {
    const {
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    let filteredModels = [];

    if (categoryGroups) {
      filteredModels = this.getAllModelsFromCategoryGroups();
    } else {
      filteredModels = this.getModelsFromCategoriesList();
    }

    this.setState({ filteredModels });
  };

  getFilteredCategories = list => {
    const {
      listCategories,
      settings: { configurations },
    } = this.props;

    let categories = list || [...listCategories];

    if (configurations.filteredCategories) {
      const { includedCategoryIds, excludedCategoryIds } = configurations.filteredCategories;
      if (includedCategoryIds && includedCategoryIds.length > 0) {
        categories = categories.filter(item => includedCategoryIds.includes(item.id));
      }
      if (excludedCategoryIds && excludedCategoryIds.length > 0) {
        categories = categories.filter(item => !excludedCategoryIds.includes(item.id));
      }
    }
    return categories;
  };

  getModelsFromCategoriesList = () => {
    const { showOnlyDefaultModels } = this.props;
    const listCategories = this.getFilteredCategories();

    if (!showOnlyDefaultModels) return listCategories.flatMap(category => category.models);

    const defaultModels = [];
    listCategories.forEach(cat => {
      const principalModel = cat.models.find(m => m.id === cat.principalModelId);
      if (principalModel) defaultModels.push(principalModel);
      else defaultModels.push(cat.models[0]);
    });

    return defaultModels;
  };

  getModelsFromCategory = entityIndex => {
    const { showOnlyDefaultModels } = this.props;
    const listCategories = this.getFilteredCategories();
    if (!showOnlyDefaultModels) return listCategories[entityIndex].models;

    const category = listCategories[entityIndex];
    const principalModel = category.models.find(model => model.id === category.principalModelId);
    if (principalModel) return [principalModel];
    return [category.models[0]] || [];
  };

  getAllModelsFromCategoryGroups = () => {
    const {
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    if (!categoryGroups) return [];

    const categoryIds = getAllCategoryIdsInCollection(categoryGroups);
    const listCategories = this.getFilteredCategories();
    const categories = listCategories.filter(c => categoryIds.includes(c.id));

    return categories.flatMap(c => c.models);
  };

  getAllModelsFromCategoryGroup = categoryGroupIndex => {
    const {
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;

    const listCategories = this.getFilteredCategories();
    const categoryIds = categoryGroups[categoryGroupIndex].categories.flatMap(c => c.ids);
    const categories = listCategories.filter(c => categoryIds.includes(c.id));

    return categories.flatMap(c => c.models);
  };

  handleFilterChange = entityIndex => {
    const {
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    let filteredModels = [];

    if (categoryGroups) {
      const selectedCategoryGroup = categoryGroups[entityIndex];
      const categoryIds = selectedCategoryGroup.categories.flatMap(cat => cat.ids);
      const listCategories = this.getFilteredCategories();
      const filteredEntities = listCategories.filter(c => categoryIds.includes(c.id));
      filteredModels = filteredEntities.flatMap(c => c.models);
    } else {
      filteredModels = this.getModelsFromCategory(entityIndex);
    }

    this.setState({ filteredModels });
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledFlotaSectionModern;
    }
    return StyledFlotaSection;
  }

  getTemplateStyledGrid() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledFlotaGridModern;
    }
    return StyledFlotaGrid;
  }

  render() {
    const {
      bookLink,
      bookingButtonText,
      type,
      t,
      showSipp,
      settings: {
        configurations: { categoryGroups },
      },
      showOnlyDefaultModels,
      oneSlide,
      showModelDetail,
      bookingsConfiguration,
    } = this.props;
    const { filteredModels, filteredEntities } = this.state;

    if (this.state.loading) return <Loading show />;
    var ins = -1;
    const centerItems = showOnlyDefaultModels ? 'justify-content-center' : '';

    const StyledSection = this.getTemplateStyledComponent();
    const StyledGrid = this.getTemplateStyledGrid();

    const distanceUnit = bookingsConfiguration?.distanceUnit || 'Kilometers';

    return type === 'grid' ? (
      <StyledGrid className="row">
        <div className="col-lg-12 mb-5">
          <Nav className="justify-content-center" defaultActiveKey="all">
            <Nav.Item>
              <Nav.Link eventKey="all" onClick={() => this.showAllModels()}>
                {t('todos')}
              </Nav.Link>
            </Nav.Item>
            {filteredEntities &&
              filteredEntities.map((entity, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    eventKey={index}
                    onClick={() => this.handleFilterChange(filteredEntities.indexOf(entity))}
                  >
                    {entity.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
        </div>
        <div className="col-lg-12 mb-5">
          <div className={`flex-container ${centerItems}`}>
            {filteredModels &&
              filteredModels.map((model, index) => (
                <div className="car-item mb-5 col-xl-3 col-lg-4 col-md-6" key={`div_${index}`}>
                  <FlotaItem
                    key={index}
                    model={model}
                    index={index}
                    bookLink={bookLink}
                    showSipp={showSipp}
                    bookingButtonText={bookingButtonText}
                    showModelDetail={showModelDetail}
                    distanceUnit={distanceUnit}
                  />
                </div>
              ))}
          </div>
        </div>
      </StyledGrid>
    ) : (
      <StyledSection>
        <div className="col-md-12 mb-5 p-0">
          <div className="row mb-5">
            <div className="col-lg-3 mb-5 category-filter">
              <Accordion defaultActiveKey={filteredEntities && filteredEntities.lenght > 0}>
                {filteredEntities &&
                  filteredEntities.map((entity, index) => {
                    const models = categoryGroups
                      ? this.getAllModelsFromCategoryGroup(index)
                      : entity.models;
                    return (
                      <Card key={entity.name}>
                        <Accordion.Toggle
                          as={Card.Header}
                          variant="link"
                          eventKey={entity.id || index}
                        >
                          {entity.name}
                          <i
                            className="fas fa-angle-down float-right mt-1"
                            data-toggle="collapse"
                            data-target="#1"
                            aria-expanded="false"
                            aria-controls="1"
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={entity.id || index}>
                          <Card.Body>
                            <ul className="list-unstyled">
                              {models.map((model, j) => {
                                ins++;
                                return (
                                  <li
                                    key={ins}
                                    id={ins}
                                    onClick={e => this.slider.slickGoTo(e.target.id)}
                                  >
                                    <i className="fas fa-angle-right" />
                                    {model.brand.name} {model.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
              </Accordion>
            </div>
            <div className="col-lg-9">
              <Slider {...sliderSettings} ref={slider => (this.slider = slider)}>
                {filteredEntities &&
                  filteredEntities.map((entity, entityIndex) => {
                    const models = categoryGroups
                      ? this.getAllModelsFromCategoryGroup(entityIndex)
                      : entity.models;
                    return models.map((model, index) => (
                      <FlotaBigItem
                        key={index}
                        model={model}
                        bookLink={bookLink}
                        showSipp={showSipp}
                        oneSlide={oneSlide}
                        showModelDetail={showModelDetail}
                      />
                    ));
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </StyledSection>
    );
  }
}

export default FlotaSection;
