import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoadingWrapperStyled } from './CecabankSection.styled';
import { withTranslation } from 'react-i18next';
import { CustomLoading } from 'components/';
import { tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';

class CecabankFail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {
      location,
      t,
      i18n,
      settings,
    } = this.props;

    const parseQs = queryString.parse(location.search);
    if (parseQs.bookingid) {
      let errorMessage;
      errorMessage = t('payBookingGatewayFailGenericError', {
          bookingId: parseQs.bookingid,
        });

      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        { error: errorMessage },
      );
    } else {
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        {
          error: t('paymentGenericError'),
        },
      );
    }
  }

  render() {
    const { t, loadingGif, loadingMessage } = this.props;

    return (
      <LoadingWrapperStyled className="col-md-12">
        <CustomLoading
          loadingGif={loadingGif || t('loadingGif')}
          loadingMessage={loadingMessage}
        ></CustomLoading>
      </LoadingWrapperStyled>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(CecabankFail)));
