import style from 'styled-components';

export const StyledDetailsModern = style.section`
  @media print {
    #checkout-print {
        background-color: white;
        height: 100% !important;
        width: 100% !important;
        position: fixed !important;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }
  }


  .total-price-text-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .total-price-number-wrapper {
    flex: 0 0 100%;
    max-width: 100%; 
  }

  @media only screen and (max-width: ${props => props.theme.screens.lg}) {
    display:none;
  }

  @media only screen and (min-width: ${props => props.theme.screens.md}){
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) and (min-width: ${props =>
  props.theme.screens.md}){
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xxl})
  and (min-width: ${props => props.theme.screens.xl}) {
    flex: 0 0 32%;
    max-width: 32%;
  }

  .checkout-details {
    .title-col1 p { font-size: 18px; }

    .title-col1 h3 {
      margin-bottom: 0px !important;

      @media only screen and (max-width: ${props => props.theme.screens.xxl})
      and (min-width: ${props => props.theme.screens.xl}){
        font-size: 20px;
        margin-top: 2.5%;
      }
    }

    .img-fluid {
      max-width: 80%;
      height: auto;
      max-height: 164px;
    }

    .car-item .car-name {
      font-size: 18px;
      font-weight: 700;
      font-style: normal;
      margin-top: 0px;
      margin-bottom: 5px;

      @media only screen and (max-width: ${props => props.theme.screens.xxl})
      and (min-width: ${props => props.theme.screens.xl}){
        font-size: 18px;
        margin-bottom: 3px;
      }
    }

    .car-category{
      font-weight: 400;
      span{
        text-transform: uppercase;
      }
      .tachometer {
        color: #1a73e8;
      }
    }

    .car-item .details {
      margin-bottom: 0;
    }

    .car-item .details-item {
        text-align: center;
        margin-right: 10px;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: auto;
    }

    .car-item .details-item p {
        position:relative;
        top:10px;
  }

    .car-item .details-item img {
      width: 100%;
      border: 1px solid #CCCCCC;
      padding: 5px;
      border-radius: 7px;
      margin-bottom: 5px;
      max-width: 65px;
    }

    .pickup-details {
      font-size: 13px;
    }

    .pickup-details h4 {
     color:#1a73e8;
     font-weight:400;
    }

    .franchise {
      padding: 10px 0;
      font-size: 13px;
      color: #2BCE81;
    }

    .rent-details {
      margin-top:115px;
      background-color: #fff;
      border-radius:12px;
      box-shadow:3px 3px 6px #00000016;
      @media only screen {
        padding: 6% 7% 3% 7%;
      }
    }

    .rent-details .payment-details li {
      font-size: 13px;
      margin-bottom: 5px;

      i {font-size: 8px; display: inline-block; margin-right: 5px;}

      @media only screen and (max-width: ${props => props.theme.screens.md}) {
        font-size: 11px;
        margin-bottom: 7px;
      }

      @media only screen and (min-width: ${props => props.theme.screens.xl})
      and (max-width: ${props => props.theme.screens.xxl}){
        font-size: 11px;
        margin-bottom: 5px;
      }
    }

    .rent-details .payment-details .item-name {
      font-weight: bold;
    }

    .payment {
      background-color: #FFF;
      box-shadow:3px 3px 6px #00000016;
      border-radius:12px;
      @media only screen {
        padding: 0 40px;
      }

      .long-value{
        font-size: 28px;
      }

      .total-price {
        font-size: 30px !important;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 8px;
        margin-top: 10px;
        white-space: nowrap;
        color: #2E2D35;

        @media only screen and (max-width: ${props => props.theme.screens.md}) {
          font-size: 30px;
        }

        @media only screen and (min-width: ${props => props.theme.screens.xl})
        and (max-width: ${props => props.theme.screens.xxl}){
          font-size: 35px;
        }
      }

      .days{
        font-size:12px;
        color: #1a73e8;
        margin:0;
      }

      .diario {
        font-size:14px;
        font-weight:400;
        color: #2E2D35;
      }
      
      .diario span {
        font-size:14px;
      }
    }

    .payment .total-name h3{
      font-size: 28px !important;
      margin-bottom: 0px !important;
      margin-top: 6% !important;
      font-weight:400;
      color: #2E2D35;

      @media only screen and (min-width: ${props => props.theme.screens.xl})
      and (max-width: ${props => props.theme.screens.xxl}){
        font-size: 16px;
      }
    }

    .flex-booking {
      padding: 20px;
      border: 1px solid #ccc;

      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0.7em;
      }

      span {
        background-color: #270570;
        padding: 5px 10px;
        border-radius: 7px;
        color: white;
        font-weight: bold;
      }

      ul {
        margin-top: 20px;
        margin-left: 1em !important;
        color: #03a691
      }

      i {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
        ;
      }
    }

  }
`;
