import style from 'styled-components';

export const StyledCookiesConsentContainter = style.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: ${props => props.color || props.theme.font.colors.primary}
  background: ${props => props.bgColor || props.theme.colors.third};
  z-index: 10;
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.5);
  
  .container {
    display: flex;
    gap: 20px;
    min-height: 60px;
  }
`;
