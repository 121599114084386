import React, { Component } from 'react';
import { props, defaultProps } from './langDropdown.props';
import { StyledLangDropdown } from './langDropdown.styled';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getLanguagePath } from 'utils/translationHelper';

class LangDropdown extends Component {
  static propTypes = props;
  static defaultProps = defaultProps;

  state = {
    currentLang: '',
    dropOpen: false,
  };

  componentWillMount = () => {
    const { i18n, langs } = this.props;

    if (langs.length > 1) {
      this.setState({
        currentLang: i18n.language,
      });
    }
  };

  toggleDropdown = () =>
    this.setState({
      dropOpen: !this.state.dropOpen,
    });

  changeLanguague = lang => {
    const { handleOnChange } = this.props;
    if (!this.checkLang(lang)) {
      this.setState({
        currentLang: lang,
      });
      handleOnChange(lang);
    }
    this.toggleDropdown();
  };

  checkLang = lang => this.state.currentLang === lang;

  getLanguageText = lang => {
    const { langConfig } = this.props;

    if (langConfig && langConfig[lang]) {
      return langConfig[lang].text || lang;
    }
    return lang;
  };

  render() {
    const { langs, classNames, langConfig, bgColor, hoverBkg, hoverColor } = this.props;
    return (
      langs.length > 1 && (
        <StyledLangDropdown
          className={`${classNames}`}
          bgColor={bgColor}
          hoverBkg={hoverBkg}
          hoverColor={hoverColor}
          onClick={() => this.toggleDropdown()}
        >
          <span className="currentLang">
            <i className="fas fa-comment" />
            <span className="shortLang">{getLanguagePath(langConfig, this.state.currentLang)}</span>
            <span className="baseLang">{getLanguagePath(langConfig, this.state.currentLang)}</span>
          </span>
          <div className={`dropdownOptions ${this.state.dropOpen && 'open'}`}>
            {langs.map(lang => {
              if (this.state.currentLang === lang) return null;

              return (
                <span
                  key={`index_${lang}`}
                  className={`${this.checkLang(lang) ? `active` : ''} dropdownItem`}
                  onClick={() => this.changeLanguague(lang)}
                >
                  <i className="fas fa-comment" />
                  <span className="shortLang">{this.getLanguageText(lang)}</span>
                  <span className="baseLang">{this.getLanguageText(lang)}</span>
                </span>
              );
            })}
          </div>
        </StyledLangDropdown>
      )
    );
  }
}

const mapStateToProps = ({ siteConfiguration, searchCars }) => ({
  langConfig: siteConfiguration.settings.configurations.langConfig,
});

export default connect(mapStateToProps)(withTranslation()(LangDropdown));
