import PropTypes from 'prop-types';

export const props = {
  className: PropTypes.string,
  color: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  datePickerOptions: PropTypes.shape({}),
  focusedInput: PropTypes.string,
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  isDayBlocked: PropTypes.func,
};

export const defaultProps = {
  datePickerOptions: {},
  focusedInput: 'startDate',
  isDayBlocked: null,
};
