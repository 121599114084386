import style from 'styled-components';

export const StyledPaymentMethodModern = style.div`

  .paymentRow{
    place-items: center;
    background: #FFF;
    padding: 5px;
    border-radius: 12px;
    margin: 5px 0;
  }

  .paymentRow:hover{
    box-shadow:3px 3px 6px #00000016;
  }


  .price{
    text-align: right;
  }

  .name {
    display: flex;
  }

  .name span{
    font-size:16px;
  }

  .image {
    margin-left: 20px;
    margin-top: 7px;
  }

  .error{
    width: 100%;
    padding-left: 10px;
    color: ${props => props.theme.colors.alert};
    margin-bottom: 15px;
    margin-top:-5px;
  }

  @media(max-width:767px){
    .name span{
      font-size:14px;
    }
    .image {
      width: 25%;
      height: 25%;
    }
  }
`;
