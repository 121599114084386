import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';

class CustomHtmlComponent extends React.Component {
  static propTypes = {
    customContent: PropTypes.string,
    className: PropTypes.string,
    withoutTranslation: PropTypes.bool,
  };

  render() {
    const { t, className, customContent, withoutTranslation } = this.props;

    return (
      <div
        className={`${className ? className : ''} customContent`}
        dangerouslySetInnerHTML={{ __html: withoutTranslation ? customContent : t(customContent) }}
      />
    );
  }
}

export default withTranslation()(CustomHtmlComponent);
