import PropTypes from 'prop-types';

export const props = {
  t: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.array,
  className: PropTypes.string,
  settings: PropTypes.object,
  slideHeight: PropTypes.string,
  slideMobileHeight: PropTypes.string,
  quotedColor: PropTypes.string,
  quotedMobileColor: PropTypes.string,
  dotsColor: PropTypes.string,
  dotsSize: PropTypes.string,
  sliderCustomSettings: PropTypes.object,
};
