import React from 'react';
import { Modal } from 'react-bootstrap';
import { StyledCancelModal } from './CancelModal.styled';
import Btn from '../Btn/Btn';
import { props } from './CancelModal.props';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { cancelBooking } from 'services/api';

class CancelModal extends React.Component {
  static propTypes = props;

  onChange(value) {}

  async onCancelBooking() {
    try {
      await cancelBooking('');
    } catch {}
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <StyledCancelModal>
        <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{t('cancelbooking')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Centered Modal</h4>
            <input type="text" />
            <input type="text" />
            <ReCAPTCHA sitekey="Your client site key" onChange={this.onChange} hl={i18n.language} />
          </Modal.Body>
          <Modal.Footer>
            <Btn onClick={this.props.onHide} text={t('cancel')} />
          </Modal.Footer>
        </Modal>
      </StyledCancelModal>
    );
  }
}

const mapStateToProps = ({ global, router }) => ({
  listCategories: global.listCategories,
  router: router,
});

const CancelModalComp = withTranslation()(CancelModal);

export default connect(mapStateToProps, null)(CancelModalComp);
