import React from 'react';
import { props } from './PageWithTitle.props';
import {
  StyledPageWithTitle,
  StyledPageWithTitleModern,
  StyledBanner,
  StyledMap,
} from './PageWithTitle.styled';
import { BasePage, PageTitle, Breadcrumb, CompositeComponent } from 'components/';
import { StyledPage } from 'styled/page';
import { withTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

class PageWithTitle extends CompositeComponent {
  static propTypes = props;

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledPageWithTitleModern;
    }
    return StyledPageWithTitle;
  }

  render() {
    const {
      height,
      bannerImage,
      breadCrumItems,
      title,
      titleType,
      subTitle,
      subtitleType,
      showBreadcrumb,
      showBanner,
      showMap,
      mapUrl,
      mapHeight,
      mapContainerHeight,
      hideGradient,
      subTitleColor,
      titleColor,
      subTitleSize,
      titleSize,
    } = this.props;

    const StyledComponent = this.getTemplateStyledComponent();

    return (
      <>
        {showBanner && <StyledBanner className="row" height={height} bannerImage={bannerImage} />}
        {showMap && (
          <StyledMap className="row" mapContainerHeight={mapContainerHeight}>
            {hideGradient ? '' : <div className="gradient"></div>}
            <div className="map-container">
              <iframe
                title="street cars map"
                src={mapUrl}
                height={mapHeight || '410'}
                frameBorder="0"
                style={{
                  border: '0',
                  width: '100%',
                  position: 'relative',
                  top: '-55px',
                }}
                allowFullScreen
              />
            </div>
          </StyledMap>
        )}
        <StyledComponent className="row row-p page">
          {showBreadcrumb && <Breadcrumb showHome items={breadCrumItems} />}
          <PageTitle
            title={this.tt(title)}
            titleType={titleType}
            subTitle={this.tt(subTitle)}
            subTitleType={subtitleType}
            className="mb-4 mt-2"
            subTitleColor={subTitleColor}
            titleColor={titleColor}
            subTitleSize={subTitleSize}
            titleSize={titleSize}
          />
          <StyledPage className="container-fluid">
            <BasePage {...this.props} />
          </StyledPage>
        </StyledComponent>
      </>
    );
  }
}

export default withTranslation()(withSettingsContext(PageWithTitle));
