import style from 'styled-components';

export const getBackgroundColor = (secondary, disabled, background, theme) => {
  if (secondary) return 'transparent';
  if (disabled) return theme.colors.disabled;
  return background || theme.colors.buttonPrimaryBg;
};

export const getColor = (color, secondary, disabled, background, theme) => {
  if (color) return color;
  if (secondary) {
    if (disabled) return theme.colors.disabled;
    return background || theme.colors.buttonPrimaryBg;
  }
  return theme.colors.buttonPrimaryColor;
};

export const getBorderColor = (secondary, disabled, borderColor, background, theme) => {
  if (disabled) return theme.colors.disabled;
  if (borderColor) return borderColor;
  if (secondary) return background || theme.colors.buttonPrimaryBg;
  return theme.colors.buttonPrimaryBorder;
};

export const StyledBtn = style.button`
  display: flex;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  line-height: 1;
  border-radius: ${props => props.borderRadius || '0'};
  padding-top: ${props => props.padding || '7px'};
  padding-bottom: ${props => props.padding || '7px'};
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border-color: ${({ secondary, disabled, borderColor, background, theme }) =>
    getBorderColor(secondary, disabled, borderColor, background, theme)};
  border-width: ${({ secondary, borderWidth }) => (borderWidth || secondary ? '1.5px' : 'inherit')};
  box-shadow: ${props => (props.noShadow ? 'none' : '2px 2px 6px rgba(0,0,0,0.2)')};
  background: ${({ secondary, disabled, background, theme }) =>
    getBackgroundColor(secondary, disabled, background, theme)};
  font-weight: 700;
  font-size: ${props => props.fontSize || '15px'};
  text-transform: ${props => props.textTransform || 'uppercase'};
  color: ${({ color, secondary, disabled, background, theme }) =>
    getColor(color, secondary, disabled, background, theme)};
  
  &:disabled { 
    color: ${({ color, secondary, disabled, background, theme }) =>
      getColor(color, secondary, disabled, background, theme)};
  }

  &.only-quote {
    display: none;
  }

  &:not([disabled]):hover {
    background-color: ${props =>
      props.disabled
        ? props.theme.colors.disabled
        : props.hoverColor || props.theme.colors.buttonPrimaryBgHover};
    color: ${props =>
      props.theme.colors.buttonPrimaryColorHover || props.theme.colors.buttonPrimaryColor};    
    border-color: ${props =>
      props.disabled
        ? props.theme.colors.disabled
        : props.hoverColor || props.theme.colors.buttonPrimaryBorder};
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  .loading {
    color: ${props => props.iconColor || props.theme.font.colors.secondary};
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) and (min-width: ${props =>
  props.theme.screens.md}){
    padding: 7px 13px;
    font-size: 13px;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xxl}) and (min-width: ${props =>
  props.theme.screens.xl}){
    font-size: 10px;
    padding: 7px 15px;
  }

  @media only screen and (max-width: 1740px) and (min-width: ${props => props.theme.screens.xl}){
    white-space: pre-wrap;
    padding: 7px 15px;
  }
`;
