import style from 'styled-components';

export const StyledCategoriesFilter = style.div`

  @media (max-width: ${props => props.theme.screens.xs}) {
  }

  background-color: ${props => props.theme.colors.bg};
  box-shadow: 3px 3px 6px #00000016;

  p{
    font-weight: bold !important;
    font-size: 14px !important;
  }

  label{
    text-transform: capitalize;
  }

  .special-checkbox{
    display: inline-block;
    margin-bottom: 5px;
    width: 100%;
  }
  
`;
