import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';
import { props } from './Breadcrumb.props';
import { StyledBreadcrumb } from './Breadcrumb.styled';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { tlink } from 'utils/translationHelper';
import withSettingsContext from 'SettingsContext';

class Breadcrumb extends React.Component {
  static propTypes = props;

  getLocation() {
    const { i18n } = this.props;

    return this.props.location.pathname
      .replace('/', '')
      .replace(`${i18n.language}/`, '')
      .replace(/\w+/g, function(w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
  }

  render() {
    const { showHome, items, background, className, t, i18n, settings } = this.props;

    return (
      <StyledBreadcrumb background={background} className={(className || '') + ' col-md-12 pl-0'}>
        <div>
          <ol className="breadcrumb">
            {showHome && (
              <BreadcrumbItem
                link={tlink('/', t, i18n, null, settings.configurations.langConfig)}
                text={'Home'}
              />
            )}
            {items && items.length > 0 ? (
              items.map((item, i) => (
                <BreadcrumbItem
                  key={i}
                  link={item.link || '#'}
                  text={item.text}
                  isActive={!item.link || item.link === this.getLocation()}
                  disabled={item.disabled}
                />
              ))
            ) : (
              <BreadcrumbItem link={'#'} text={this.getLocation()} isActive />
            )}
          </ol>
        </div>
      </StyledBreadcrumb>
    );
  }
}

export default withSettingsContext(withRouter(withTranslation()(Breadcrumb)));
