import style from 'styled-components';

export const StyledContactSection = style.div`
    h3{
        font-weight: 600;
        font-style: normal;
        font-size: 18px;
        margin-top: 10px;
    }
    @media only screen and (max-width: ${props => props.theme.screens.lg}) {    
        .row:first-child{
            -webkit-flex-flow: column;
            flex-flow: column;

            .map {
                -webkit-flex: 1 6 20%;
                flex: 1 6 20%;
                -webkit-order: 2;
                order: 2;
            }

            .office-details{
                -webkit-flex: 1 6 20%;
                flex: 1 6 20%;
                -webkit-order: 3;
                order: 3;
            }

            .form-div {
                -webkit-flex: 3 1 60%;
                flex: 3 1 60%;
                -webkit-order: 1;
                order: 1;
                margin-bottom: 60px;
            }
        }
    }   
`;
