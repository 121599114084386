/* eslint-disable no-undef */
import React from 'react';
import { Title, TextInput, SelectInput, Btn } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledCreditCardConfirmationSection } from './CreditCardConfirmationSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from '../../../utils/translationHelper';
import * as yup from 'yup';
import { confirmBooking } from 'services/api';
import queryString from 'query-string';
import { history } from '/store';
import { handleGetTotalToPay } from 'utils/dataHelper';

var CreditCardValidator = require('card-validator');

class CreditCardConfirmationSection extends React.Component {
  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      paymentInfo: {
        bookingId: '0',
        qsamount: 0,
      },
      formErrors: {
        creditCardNumber: '',
        creditCardExpirationYear: '',
        creditCardExpirationMonth: '',
        creditCardCode: '',
        creditCardPlaceHolder: '',
      },
      cardIcon: 'far fa-credit-card',
      formLoading: false,
    };

    yup.addMethod(yup.number, 'isValidCreditCard', function(creditCardNumber) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_card_number'), function(
        ccNumber,
      ) {
        return CreditCardValidator.number(ccNumber).isValid;
      });
    });

    yup.addMethod(yup.number, 'isValidSecCode', function(secCode) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_security_code'), function(
        secCode,
      ) {
        const ccObject = CreditCardValidator.number(this.parent.creditCardNumber);
        if (!secCode || !ccObject.card) return false;
        return secCode.toString().length === ccObject.card.code.size;
      });
    });

    this.paymentInfoFormSchema = yup.object().shape({
      creditCardNumber: yup
        .number()
        .isValidCreditCard()
        .required(t('inputIsRequired', { field: t('numeroTarjeta') })),
      creditCardExpirationYear: yup.number().required(t('inputIsRequired', { field: t('año') })),
      creditCardExpirationMonth: yup.number().required(t('inputIsRequired', { field: t('mes') })),
      creditCardCode: yup
        .number()
        .isValidSecCode()
        .required(t('inputIsRequired', { field: t('codigo') })),
      creditCardPlaceHolder: yup.string().required(t('inputIsRequired', { field: t('titular') })),
    });

    this.formRef = React.createRef(null);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(15), (val, index) => index + year);
    this.months = Array.from(new Array(12), (val, index) => index + 1);
  }

  componentDidMount() {
    const { location, bookings } = this.props;
    const parseQs = queryString.parse(location.search);
    let partial = 0;
    let bookingId = '0';

    if (parseQs.amount) {
      partial = parseFloat(parseQs.amount);
    }

    if (this.isPayingBooking() && bookings.confirmation != null) {
      bookingId = bookings.confirmation.id;
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, bookingId, qsamount: partial },
    }));
  }

  getTotalToPay() {
    const {
      bookings: { confirmation },
      settings: { paymentConfiguration },
    } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    return handleGetTotalToPay(confirmation.customerBalance, paymentConfiguration, qsamount);
  }

  handleCardNumber = creditCardNumber => {
    const ccObject = CreditCardValidator.number(creditCardNumber);
    const ccIcon = this.getCreditCardIcon(ccObject.card ? ccObject.card.type : '');

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, creditCardNumber },
      formErrors: { ...prevState.formErrors, creditCardNumber: null, creditCardCode: null },
      cardIcon: ccIcon,
    }));
  };

  getCreditCardIcon = ccType => {
    let icon = '';

    switch (ccType) {
      case 'visa':
        icon = 'fab fa-cc-visa';
        break;
      case 'american-express':
        icon = 'fab fa-cc-amex';
        break;
      case 'mastercard':
        icon = 'fab fa-cc-mastercard';
        break;
      default:
        icon = 'far fa-credit-card';
        break;
    }

    return icon;
  };

  handleSecCode = creditCardCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, creditCardCode },
      formErrors: { ...prevState.formErrors, creditCardCode: null },
    }));
  };

  handleMonth = creditCardExpirationMonth => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, creditCardExpirationMonth },
      formErrors: { ...prevState.formErrors, creditCardExpirationMonth: null },
    }));
  };

  handleYear = creditCardExpirationYear => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, creditCardExpirationYear },
      formErrors: { ...prevState.formErrors, creditCardExpirationYear: null },
    }));
  };

  handleCardholderName = creditCardPlaceHolder => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, creditCardPlaceHolder },
      formErrors: { ...prevState.formErrors, creditCardPlaceHolder: null },
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    const paymentInfo = this.state.paymentInfo;
    const form = e.target;

    this.setState({ formLoading: true });

    this.paymentInfoFormSchema
      .validate(paymentInfo, { abortEarly: false })
      .then(valid => {
        if (valid) this.executePayment(paymentInfo);
      })
      .catch(err => {
        this.setState({ formLoading: false });
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });
  };

  executePayment = async params => {
    const { t, i18n, settings } = this.props;

    try {
      await confirmBooking(this.state.paymentInfo);

      history.push(
        `${tlink(
          '__Routes.bookConfirmSuccess',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
        )}`,
      );
    } catch (error) {
      history.push(
        `${tlink('__Routes.bookConfirmFailed', t, i18n, null, settings.configurations.langConfig)}`,
      );
    }
  };

  showOrderIdentifier = () => {
    const { t } = this.props;

    const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
    return t('orden', { booking_id });
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('confirmandoReserva', { booking_id })
        : t('confirmarReserva', { booking_id });
    }
  };

  render() {
    const { t, title, subtitle, confirmMessage } = this.props;

    return (
      <StyledCreditCardConfirmationSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="adicionales-block pb-1 payment-code">
            <div className="col-md-12 p-0">
              <p>
                {confirmMessage
                  ? tt(confirmMessage, t)
                  : t('creditCardBookConfirmationExplanation')}
              </p>
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <hr />
            </div>
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form onSubmit={this.handleSubmit} ref={this.formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('numeroTarjeta')}</span>
                      <TextInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleCardNumber}
                        value={this.state.paymentInfo.creditCardNumber}
                        icon={this.state.cardIcon}
                        options={{ 'data-checkout': 'creditCardNumber' }}
                        error={this.state.formErrors.creditCardNumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('codigo')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleSecCode}
                        value={this.state.paymentInfo.creditCardCode}
                        options={{ 'data-checkout': 'creditCardCode' }}
                        error={this.state.formErrors.creditCardCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('mes')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleMonth}
                        selected={this.state.paymentInfo.creditCardExpirationMonth}
                        error={this.state.formErrors.creditCardExpirationMonth}
                        props={{ 'data-checkout': 'creditCardExpirationMonth' }}
                        options={this.months}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('año')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleYear}
                        error={this.state.formErrors.creditCardExpirationYear}
                        selected={this.state.paymentInfo.creditCardExpirationYear}
                        props={{ 'data-checkout': 'creditCardExpirationYear' }}
                        options={this.years}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('titular')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleCardholderName}
                        value={this.state.paymentInfo.creditCardPlaceHolder}
                        options={{
                          'data-checkout': 'creditCardPlaceHolder',
                          id: 'creditCardPlaceHolder',
                        }}
                        error={this.state.formErrors.creditCardPlaceHolder}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <Btn
                      type="submit"
                      text={this.getPayButtonText()}
                      disabled={this.state.formLoading}
                      className="mt-5"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </StyledCreditCardConfirmationSection>
    );
  }
}

const mapStateToProps = ({ bookings, siteConfiguration }) => ({
  bookings: bookings,
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(
  withRouter(withTranslation()(CreditCardConfirmationSection)),
);
