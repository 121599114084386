import { SITE_CONFIGURATION_SET_SETTINGS } from '../utils/types';

const INITIAL_STATE = { settings: {} };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SITE_CONFIGURATION_SET_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }
    default:
      return state;
  }
};
