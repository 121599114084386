import {
  BOOKING_SET_BOOK,
  BOOKING_SET_ADDITIONALS,
  SET_BOOKING_PAY_INSTALLMENT,
  BOOKING_SET_CONFIGURATION,
  BOOKING_GET_ATTACHMENTS,
} from '../utils/types';

const INITIAL_STATE = {
  confirmation: null,
  listAdditionals: null,
  installment: null,
  configuration: null,
  bookingAttachments: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOOKING_SET_BOOK: {
      return {
        ...state,
        confirmation: action.payload,
      };
    }

    case BOOKING_SET_ADDITIONALS: {
      return {
        ...state,
        listAdditionals: action.payload,
      };
    }

    case SET_BOOKING_PAY_INSTALLMENT: {
      return {
        ...state,
        installment: action.payload,
      };
    }

    case BOOKING_SET_CONFIGURATION: {
      return {
        ...state,
        configuration: action.payload,
      };
    }

    case BOOKING_GET_ATTACHMENTS: {
      return {
        ...state,
        bookingAttachments: action.payload,
      };
    }

    default:
      return state;
  }
};
