import style from 'styled-components';

export const StyledBottomFooterModern = style.div`
background-color: #2C2C2C;
position: absolute;
clear: both;
right: 0;
left: 0;
bottom: 0;
padding: ${props => props.padding || '0 6%'};

p{  
  font-size: 11px !important;
  color: #888888 !important;

  @media (max-width: ${props => props.theme.screens.md}) {
      text-align: center;
  }
}
a{
  color: #ffa300 !important;
  font-weight: 500;

  &:hover{
    text-decoration: none;
  }
}
`;
