import React from 'react';
import { TranslatedComponent } from 'components';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import DefaultModelDetail from './Default';
import ModernModelDetail from './Modern';

export class ModelDetail extends TranslatedComponent {
  constructor() {
    super();

    this.sanatizeDetailText = this.sanatizeDetailText.bind(this);
  }

  static propType = {
    model: PropTypes.shape({
      passengers: PropTypes.number,
      bigLuggage: PropTypes.number,
      smallLuggage: PropTypes.number,
      gearbox: PropTypes.string,
      airConditioner: PropTypes.string,
      steering: PropTypes.string,
    }),
    prefixForNumeric: PropTypes.string,
    showModelDetail: PropTypes.bool,
  };

  sanatizeDetailText(text) {
    if (text) return text.length > 3 ? text.substring(0, 3) + '.' : text;
    return '';
  }

  render() {
    const {
      model,
      prefixForNumeric,
      settings: { theme, featureFlags },
      maxAllowedDistanceByDay,
      ilimitedKm,
      fleetSection,
      showModelDetail,
      distanceUnit,
    } = this.props;

    return theme.template === MODERN_THEME ? (
      <ModernModelDetail
        model={model}
        prefixForNumeric={prefixForNumeric}
        maxAllowedDistanceByDay={maxAllowedDistanceByDay}
        ilimitedKm={ilimitedKm}
        sanatizeDetailText={this.sanatizeDetailText}
        HideCarDetailIcons={featureFlags && featureFlags.HideCarDetailIcons}
        fleetSection={fleetSection}
        showModelDetail={showModelDetail}
        distanceUnit={distanceUnit}
      />
    ) : (
      <DefaultModelDetail
        model={model}
        prefixForNumeric={prefixForNumeric}
        sanatizeDetailText={this.sanatizeDetailText}
        HideCarDetailIcons={featureFlags && featureFlags.HideCarDetailIcons}
        showModelDetail={showModelDetail}
      />
    );
  }
}

export default withSettingsContext(withTranslation()(ModelDetail));
