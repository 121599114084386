import style from 'styled-components';

export const StyledAdditionals = style.section`
  @media print {
    .searchFilter, .car-block {
      display:none !important;
      visibility: hidden;
    }
  }

  .constant-arrows::-webkit-inner-spin-button,
  .constant-arrows::-webkit-outer-spin-button {
   opacity: 1;
  }

  .additionals-loader {
    width: 100px;
    height: 100px;
  }

  input.form-control {
    text-overflow: ellipsis;
  }

  .mandatory-additional {
    cursor: auto !important;

    label {
      cursor: auto !important;

      .checkmark {
        background-color: #BBBBBB !important;
      }
    }

    .additional-name {
      display: flex;
      flex-direction: column;
      padding-left: 30px !important;

      .additional-name-button{
        font-size: 0.8rem;
        text-decoration: none!important;
        vertical-align: middle;
        color: ${({ theme }) => theme.colors.secondary}!important;

        &:hover, & > i { 
          text-decoration: none!important; 
        }
      }

      .see-less{
        padding-left: 0px;
      }

      .mandatory-legend {
        font-size: 0.8em;
        color: #f80;
      }
    }
  }

  .conditions-legend {
    font-weight: bold;
    font-size: 0.9em;
  }

  width: 73%;
  flex: 73%;
  max-width: 73%;
  margin-right: 2%;
  .table-hover tbody tr:hover {
    background-color: #f2f2f27d;
  }

  .checkout-additionals{
    .table tr .input-wrapper {
      border:none;
      width: 32px;
      height: 32px;
      margin-top: 5px;
      margin-bottom: 5px !important;

      input[type=number]{
        background: #f2f2f2;
        border: none !important;
        font-size: 12px;
      }
    }
  }

  .additional-title {
    margin: 20px 0px 10px 0px;
  }

  .must-have-insurance-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;

    i.fas.fa-exclamation-circle {
      margin: 0 0.5rem;
      color: red;
    }

    p {
      margin: 0;
      font-weight: bold;
    }
  }

  .additional-image {
    text-align: center;
    width: 50px;
  }

  .additional-name {
    .additional-name-button{
      font-size: 0.8rem;
      text-decoration: none!important;
      vertical-align: middle;
      color: ${({ theme }) => theme.colors.secondary}!important;

      &:hover, & > i { 
        text-decoration: none!important; 
      }
    }

    .see-less{
      padding-left: 0px;
    }

  }

  .checkout-submit-button  {
    margin-top: 3rem;
  }

  .btn-reservation {
    margin-top: 3rem;
  }

  .log-in-out-btn {
    background: transparent;
    padding: 0;
    border: none;
    transition: all 0.3s;
    outline: none;

    &:hover, & > i { 
      color: ${({ theme }) => theme.colors.buttonPrimaryBg}; 
    }

    i {
      margin-right: 10px;
    }
  }

  .form-subtitle { 
    font-size: 1rem;
    margin-top: 11px;
  }


  .file-input input { 
    padding: 10px;
    background: white;
    margin-bottom: 1rem;
  }


  .text-error {
    width: 100%;
    padding-left: 10px;
    color: ${props => props.theme.colors.alert};
    font-style: italic;
  }

  .file-input {
    display: flex;  
    align-items: center;
    justify-content: center;

    & > div {
      width: 100%;
    }

    .input-wrapper, .form-control {
      margin-bottom: 0 !important;
    }
  }

  .delete-file {
    background: transparent;
    border: none;
    margin-left: 10px;
    padding: 0;
    transition: all 0.3s;
    color: #656970;

    &:hover { 
      color: ${props => props.theme.colors.alert};
    }
  }

  .additional-row-v2-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .additional-row-v2 {
      max-width: 30%;
      min-width: 30%;
      box-shadow:3px 3px 6px #00000016;
      margin: 1rem;
      background-color: ${props => props.theme.colors.bg}
      padding: 1rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      span.checkmark.disabled {
        background-color: ${props => props.theme.colors.disabled};
      }

      .checkout-additionals .special-checkbox {
        bottom: 80%!important;
      }

      .additional-image{
        p {
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
          font-size: 0.9rem;

          img {
            max-width: 10%;
            margin-right: 1rem;
            text-align: center;
          }
        }
      }

      .additional-name {
        font-size: 0.9rem;
      }

      .withQuantity {
        margin-top:1rem;
      }

      .adicional-price{
        span {
          font-size: 1.2rem;
          color: #4cd695;
          font-weight: bold;
        }
      }

      .quantity-wrapper{
        text-align: center;
        margin: 1rem 0;

        .special-checkbox {
          bottom: 80%!important;
        }

        span.checkmark.disabled {
          background-color: ${props => props.theme.colors.disabled};
        }
      }

      .withImage{
        .additional-image.typeAdditional {
        background: transparent;
        padding: 0px;
        justify-content: center;
        height: 230px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;      
          }
        }
      }

      .typeAdditional{
        .additional-image{
          background: #ffefe6;
          padding: 5px;
        }
      }

      .typeInsurance{
        .additional-image{
          background: #ffefe6;
          padding: 5px;
        }
      }

      .withImage{
        .additional-image.typeInsurance {
        background: transparent;
        padding: 0px;
        justify-content: center;
        height: 230px;
        margin-bottom: 5rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;      
          }
        }
      }


      div{
        .additional-image,.addButton{
          width: 100%;
        }

        .quantity input {
          text-align: center;
          padding: 0;
          background: aliceblue;
          width: 20%;
        }

        .additional-name,.adicional-price{
          width: 80%;
          margin: 1rem auto;
          text-align: center;
        }

        .addButton.typeAdditional.no-border-radius.btn {
          color: ${props => props.theme.colors.primary}!important;
          background-color: ${props => props.theme.colors.bg}!important;
          border: 3px solid ${props => props.theme.colors.primary}!important;
        }
      }

      .additional-image.typeAdditional {
        background: #ffefe6;
        border-radius: 0.3rem;
        padding: 10px;
        justify-content: center;
        
        p {
          margin: 0;
          font-size: 0.9rem;

          img {
            width:30px;
            height: 30px;
            margin-right: 1rem;
            text-align: center;
          }
        }
      }
    }
  }  

  @media only screen and (max-width: ${props => props.theme.screens.xs}){
    .checkout-additionals{
      .table tr td:first-child{
        max-width: 15px;
        padding-right: 45px;
      }
    }
    .send-budget a{
      width: 100%;
      display:block;
        i{
          margin-left: 0 !important;
        }
    }
    .additional-name {
      padding-left: 0px !important;

      .additional-name-button{
        font-size: 0.8rem;
        text-decoration: none!important;
        vertical-align: middle;
        color: ${({ theme }) => theme.colors.secondary}!important;

        &:hover, & > i { 
          text-decoration: none!important; 
        }
      }

      .see-less{
        padding-left: 0px;
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.screens.md}){
    width: 100%;
    flex: 100%;
    max-width: 100%;
    margin-right: 0;

    .checkout-additionals{
      table{
        font-size: 14px;
        .adicional-price{
          font-size: 13px;
          span {
            display: block;
            font-size: 16px;
          }
        }
      }

      .additional-row-v2-wrapper {
        flex-direction: column;

        .additional-row-v2 {
          max-width: 100%;
          }
        }
      }
      
      .othercar {
      display: none !important;
    }
  }





  @media only screen and (max-width: ${props => props.theme.screens.xl})
  and (min-width: ${props => props.theme.screens.md}){
    width: 100%;
    flex: 100%;
    max-width: 100%;
    margin-right: 0;

    a.othercar, .print-btn {
      display: none !important;
    }

    .send-budget{
      text-align: center;
    }

    .adicionales-table td {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: ${props => props.theme.screens.xxl})
  and (min-width: ${props => props.theme.screens.xl}){
    width: 68%;
    flex: 68%;
    max-width: 68%;
    margin-right: 0;
  }

  .table td, .table th {
    padding: .6rem .2rem;
    vertical-align: middle;
  }

  .table tr:first-child td{
    border-width: 0;
  }

  .table tr td:first-child {
    padding-right: 20px;
    max-height: 100px;
    width: 15px;
  }

  .checkout-additionals {

    h2{
      margin-bottom: 2px !important;
    }

    .adicionales{
      background-color: #fff;
      padding: 2% 3%;
    }

    .adicionales-table tr {
      cursor: pointer;

      :first {
        width: 3.5%;

        input {
          width: 100%;
        }
      }
    }

    a.othercar {
      cursor: pointer;
    }

    /* Custom Checkbox */
    /* The container */
    .special-checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 16px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .special-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .special-checkbox input:checked ~ .checkmark {
      background-color: ${props => props.theme.colors.buttonPrimaryBg};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .special-checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .special-checkbox .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    a {
      transition: all 0.4s;
      margin-top:10px;
      margin-bottom:10px;
    }

    a:hover, a i { color: ${props => props.theme.colors.buttonPrimaryBg}; }

    .user-info {
      padding: 4% 3%;
      background-color: #fff;
    }

    form input {
      font-weight: 400;
    }

    form input:focus {
      border-color: #FFBB3C;
      box-shadow: none;
    }

    .form-datos{
      h2{
        @media only screen and (max-width: 1480px) and (min-width: 1271px){
          font-size: 18px;
        }
      }
    }

    .CustomAdressesContainer {
      display: flex;

      @media only screen and (max-width: ${props => props.theme.screens.md}){
        flex-direction: column;
        div {
          width: 100%;
        }
      }
    }

    .alert-warning {
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .quantity {
      input{
        text-align: center;
        padding: 0;
      }
      .input-wrapper {
        border:none!important;
      }
    }
  }
`;
