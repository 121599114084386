import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  leftTitle: PropTypes.string,
  leftStrongTitle: PropTypes.string,
  leftLinkText: PropTypes.string,
  leftLinkUrl: PropTypes.string,
  leftLinkHover: PropTypes.string,
  items: PropTypes.array.isRequired,
};
