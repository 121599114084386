import moment from 'moment';
import {
  SEARCH_CARS_SET_CARS,
  SEARCH_CARS_SET_PARAMS,
  SEARCH_CARS_SET_CHECKOUT,
} from '../utils/types';

const START_DATE = moment(new Date()).add(3, 'd');
const START_HOUR = '12';

const INITIAL_STATE = {
  cars: [],
  params: {
    categories: [],
    pickUp: {
      moment: moment(START_DATE),
      hour: START_HOUR,
    },
    dropOff: {
      moment: moment(START_DATE).add(4, 'd'),
      hour: START_HOUR,
    },
    customPromotionCode: null,
  },
  checkout: {
    booking: null,
  },
  prices: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_CARS_SET_CARS: {
      return {
        ...state,
        cars: action.payload.cars,
        loading: action.payload.loading,
        error: action.payload.error,
      };
    }
    case SEARCH_CARS_SET_PARAMS: {
      return {
        ...state,
        params: action.payload,
      };
    }
    case SEARCH_CARS_SET_CHECKOUT: {
      return {
        ...state,
        checkout: action.payload,
      };
    }
    default:
      return state;
  }
};
