import React, { useState, useEffect, useRef  } from 'react';
import { props } from './LinkList.props';
import { StyledLinkList } from './LinkList.styled';
import { TranslatedLink, Title, CompositeComponent } from 'components/';
import { useTranslation } from 'react-i18next';
import { tt } from '../../utils/translationHelper';
import { Collapse, Button } from 'react-bootstrap';

const LinkList = ({
  links = [],
  title,
  className,
  type,
  certificates,
  sections,
  collapsible,
  seeLess,
  seeMore
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (collapsible) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      window.scroll(0, 99999999);
    }
  }, [expanded, collapsible])  

  const getListItems = () =>
    links.map((item, index) => (
      <li className="listItem" key={index}>
        <TranslatedLink {...item} />
      </li>
    ));

  const getImageList = () =>
    certificates.map((path, index) => (
      <li className="imageItem" key={index}>
        <img src={tt(path, t)} alt={`path: ${path}`} />
      </li>
    ));

  const getComponentList = () => {
    const comps = { sections: sections };
    const branchOffices = comps.sections.slice(0, 1);
    const logos = comps.sections.slice(1);

    const getCollapsibleItems = () => {
      return (
        <>
          <Button
            variant="link"
            type="button"
            onClick={e => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
            aria-controls="footer-button-collapsible-collapse"
            aria-expanded={expanded}
            className={`footer-button-collapsible ${expanded && seeLess ? 'see-less' : ''}`}
          >
            {expanded ? seeMore : seeLess}
          </Button>

          <CompositeComponent
            sections={branchOffices} 
            withWrapper={(section, comp, cProps, i) => (
              <Collapse in={!expanded}>
                <li key={i}>{comp}</li>
              </Collapse>
            )}
          />
          <CompositeComponent
            sections={logos} 
            withWrapper={(section, comp, cProps, i) => <li key={i}>{comp}</li>}
          />
        </>
      );
    };

    const getNormalItems = () => {
      return (
        <CompositeComponent
          {...comps}
          withWrapper={(section, comp, cProps, i) => {
            return <li key={i}>{comp}</li>;
          }}
        />
      );
    };

    return <>{collapsible ? getCollapsibleItems() : getNormalItems()}</>;
  };

  const getList = () => {
    if (type === 'images') return getImageList();

    if (type === 'components') return getComponentList();

    return getListItems();
  };

  return (
    <div className={className}>
      <Title type="h3" text={title} weight="700" fontSize={14} noMedia className="mb-10" />
      <StyledLinkList>{getList()}</StyledLinkList>
    </div>
  );
};

LinkList.propTypes = props;

export default LinkList;
