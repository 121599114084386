import styled from 'styled-components';

export const StyledLinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: space-between;

  .listItem {
    width: 100%;

    .linkItem {
      color: ${props => props.color || props.theme.font.colors.secondary};
      font-weight: 400;
      font-size: 13px;
      text-transform: none;
      transition: color 200ms;
      font-family: 'Montserrat', Segoe, Arial, sans-serif;

      &:hover {
        color: ${props => props.hoverColor || props.theme.colors.secondary};
        text-decoration: none;
      }
    }
  }

  button.footer-button-collapsible.btn.btn-link {
    text-decoration: none;
    margin-bottom: 0.5rem;
    color: ${props => props.color || props.theme.font.colors.secondary};
    width: 100%;
    font-size: 0.8rem;
    &:hover {
      border: 1px solid ${props => props.hoverColor || props.theme.colors.secondary};
      color: ${props => props.hoverColor || props.theme.colors.secondary};
    }
  }

  .imageItem {
    img {
      height: ${props => props.imgHeight || '30px'};
    }
  }
`;
