import React from 'react';
import { props, defaults } from './Logo.props';
import { StyledLogo } from './Logo.styled';
import { Link } from 'react-router-dom';
import { tlink } from 'utils/translationHelper';
import { withTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Logo = ({ alt, className, height, route, src, width, t, i18n, settings }) => (
  <React.Fragment>
    {settings.configurations.logoNavigateLink ? (
      <a href={settings.configurations.logoNavigateLink}>
        <StyledLogo className={className} height={height} width={width}>
          <LazyLoadImage
            src={src}
            alt={alt}
            threshold={100}
            effect="blur"
            width="100%"
            height="auto"
            wrapperClassName="logoImage"
          />
        </StyledLogo>
      </a>
    ) : (
      <Link to={tlink(route || '/', t, i18n, null, settings.configurations.langConfig)}>
        <StyledLogo className={className} height={height} width={width}>
          <LazyLoadImage
            src={src}
            alt={alt}
            threshold={100}
            effect="blur"
            width="100%"
            height="auto"
            wrapperClassName="logoImage"
          />
        </StyledLogo>
      </Link>
    )}
  </React.Fragment>
);

Logo.propTypes = props;
Logo.defaultProps = defaults;
export default withSettingsContext(withTranslation()(Logo));
