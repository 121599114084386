import React from 'react';
import { Link } from 'react-router-dom';
import { props } from './BreadcrumbItem.props';
import { TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';

class BreadcrumbItem extends TranslatedComponent {
  static propTypes = props;

  render() {
    return (
      <li
        className={
          'breadcrumb-item ' + (this.props.isActive && !this.props.disabled ? 'active' : '')
        }
      >
        {this.props.link && !this.props.disabled ? (
          <Link to={this.props.link}>{this.tt(this.props.text).toLowerCase()}</Link>
        ) : (
          this.tt(this.props.text).toLowerCase()
        )}
      </li>
    );
  }
}

export default withTranslation()(BreadcrumbItem);
