import axios from 'axios';
import mockApi from 'utils/mockApi'; // uncomment this to try mocks

class UserFetcher {
  constructor() {
    this.apiInstance =
      process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCK === 'true'
        ? mockApi
        : axios; // uncomment this to try mocks
    this.config = {
      credentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    // headers
    this.dispatch = false;
  }

  /**
   *  add headers
   * @param { Object } headersArray
   * @return { Headers }
   */
  addHeaders(newHeaders = {}) {
    //
    let { headersArray } = this;

    // add headers
    Object.keys(headersArray).length > 0 &&
      headersArray.forEach((prop, value) => {
        headersArray.append(prop, value);
      });
  }

  /**
   * perform  get request
   * @param { String } endpoint
   * @return { Promise }
   */
  async get(endpoint) {
    return await this.apiInstance.get(endpoint, this.config);
  }

  /**
   * perform a post
   * @param  { String } endpoint
   * @param  { Object } data
   * @return { Promise }
   */
  async post(endpoint, payload, needsAuthentication) {
    const { data } = await this.apiInstance.post(endpoint, payload, this.config);
    return data;
  }

  /**
   * perform a put
   * @param  { String } endpoint
   * @param  { Object } data
   * @return { Promise }
   */
  async put(endpoint, payload, needsAuthentication) {
    const { data } = await this.apiInstance.put(endpoint, payload, this.config);
    return data;
  }

  /**
   * perform a remove request
   * @param { String } endpoint
   * @return { Promise }
   */
  async remove(endpoint) {
    let { data } = await this.apiInstance.delete(endpoint, this.config);
    return data;
  }
}

export default UserFetcher;
