import PropTypes from 'prop-types';

export const props = {
  policyLink: PropTypes.string,
  customMessage: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  btnBackground: PropTypes.string,
  btnColor: PropTypes.string,
  disabled: PropTypes.bool,
};
