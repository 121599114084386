import style from 'styled-components';

export const StyledPromocionesModernSection = style.div`
background: #FFF;
  min-height: 600px;
  padding-left: -15px;
  padding-right: -15px;
  
  h2 {
    margin-bottom: 16px;
  }
  
  .card-promotion {
    padding:50px;

    &:hover {
      padding:45px;
      border-radius:12px;
      transition: all 0.2s ease 0s;
    } 

    .card-promotion__img {
      span {
        display: flex!important;
        height: 100%!important;
        position: relative;
          img {
            max-width: 100%;
            height: auto;
            border-radius:12px;

            &:hover {
              box-shadow: rgb(45 62 80 / 35%) 0px 1px 6px 0px;
              border-radius:12px;
            }
          }
        }
      }
    }
  }

  h4{
    font-size:21px;
    font-weight:bold;
    color:${props => props.theme.colors.primary};
  }

  h1 {
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
  }

  .no-promotion-text{
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.screens.xs}) {

    .card-promotion{
      padding:10px;
    }

    .card-promotion:hover{
      padding:12px;
    }
  
  }

  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .card-promotion{
      padding:10px;
    }

    .card-promotion:hover{
      padding:12px;
    }
  
  }

`;
