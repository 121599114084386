import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { props, defaults } from './header.props';
import { StyledHeader } from './header.styled';
import { StyledHeaderModern } from './header.styled.modern';
import HeaderItem from './HeaderItem';
import HeaderSlogan from './HeaderSlogan';
import Logo from '../logo';
import { MODERN_THEME } from 'styled/constants';
import useWindowDimensions from 'utils/windowHelper';

const Header = ({
  items,
  logo,
  mobileLogo,
  slogan,
  associates,
  className,
  comm,
  logoWidth,
  settings,
  bgcolor,
  mobileMenuColor,
  stickyMenu,
}) => {
  const getTemplateStyledComponent = () => {
    if (settings.theme.template === MODERN_THEME) {
      return StyledHeaderModern;
    }
    return StyledHeader;
  };
  const StyledComponent = getTemplateStyledComponent();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const headerRef = useRef(null);
  const isOpen = comm.isNavBarOpen();

  const handleOutsideClick = event => {
    if (isOpen && !isMobile && headerRef.current && !headerRef.current.contains(event.target)) {
      comm.toogleNavBar();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  function handleMobileLogo() {
    if (!isMobile) {
      return <Logo src={logo} alt={settings.configurations.title || 'logo'} width={logoWidth} />;
    } else {
      return (
        <Logo
          src={mobileLogo || logo}
          alt={settings.configurations.title || 'logo'}
          width={logoWidth}
        />
      );
    }
  }

  return (
    <StyledComponent
      ref={headerRef}
      className={`row ${className} header row-p ${stickyMenu && 'sticky'}`}
      bgcolor={bgcolor}
      mobileMenuColor={mobileMenuColor}
    >
      <div className="col-sm-12 col-md-4 p-0 header-logo">
        <div className="mobileMenu" onClick={comm.toogleNavBar}>
          <i className="fas fa-bars" />
        </div>
        {handleMobileLogo()}
      </div>
      <div className="col-xs-12 col-md-8 p-0 header-item-container text-right">
        {items.map(item => (
          <HeaderItem className="w50" key={item.label} {...item} location="header" />
        ))}
        <HeaderItem
          className="w50"
          key="bphone"
          {...{ content: { type: 'bphone', path: slogan.label }, icon: '' }}
        />
        <HeaderSlogan className="" {...slogan} />
        {associates && <HeaderItem className="w50" key="assoc-button" {...associates} />}
      </div>
    </StyledComponent>
  );
};

Header.propTypes = props;
Header.defaultProps = defaults;
Header.Item = HeaderItem;
Header.Slogan = HeaderSlogan;

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps)(Header);
