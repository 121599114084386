import React from 'react';
import { StyledPreHeaderContainer } from './PreHeader.styled';
import withSettingsContext from 'SettingsContext';
import { withTranslation } from 'react-i18next';
import PreHeaderContactCard from './ContactCard/PreHeaderContactCard';

function PreHeader({ t, color, bgColor, headerText, contactLinks }) {
  return (
    <StyledPreHeaderContainer className="row" bgColor={bgColor} color={color}>
      {headerText && <div className="header-title">{t(headerText) || headerText}</div>}
      <div className="contact-list">
        {contactLinks.map((contact, idx) => {
          return <PreHeaderContactCard contact={contact} key={idx} />;
        })}
      </div>
    </StyledPreHeaderContainer>
  );
}

export default withSettingsContext(withTranslation()(PreHeader));
