import React, { useEffect, useState } from 'react';
import Btn from 'components/Btn';
import withSettingsContext from 'SettingsContext';
import Storage from 'utils/storage';
import { tlink, tt } from 'utils/translationHelper';
import { Trans, withTranslation } from 'react-i18next';
import { StyledCookiesConsentContainter } from './CookiesConsent.styled';
import { props } from './CookiesConsent.props';

const CookiesConsent = ({
  bgColor,
  color,
  btnBackground,
  btnColor,
  customMessage,
  policyLink,
  disabled,
  t,
  i18n,
  settings,
}) => {
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    const storedValue = Storage.getElement('cookiesAccepted');
    if (storedValue) {
      setIsAccepted(JSON.parse(storedValue));
    }
  }, []);

  if (disabled || isAccepted) return null;

  function handleAcceptCookies() {
    Storage.setElement('cookiesAccepted', JSON.stringify(true));
    setIsAccepted(true);
  }

  return (
    <StyledCookiesConsentContainter id="cookies-modal" bgColor={bgColor} color={color}>
      <div className="container my-3">
        {customMessage ? (
          <div
            style={{ flexGrow: 1 }}
            dangerouslySetInnerHTML={{ __html: tt(customMessage, t) }}
          ></div>
        ) : (
          <p>
            <Trans i18nKey="cookies.consentMessage">
              This site uses cookies. Visit our
              <a
                href={tlink(
                  policyLink || '/cookies',
                  t,
                  i18n,
                  null,
                  settings.configurations.langConfig,
                )}
              >
                cookies policy page
              </a>
              or click the link in any footer for more information and to change your preferences.
            </Trans>
          </p>
        )}
        <Btn
          text={t('cookies.acceptBtn')}
          background={btnBackground}
          color={btnColor}
          onClick={handleAcceptCookies}
        />
      </div>
    </StyledCookiesConsentContainter>
  );
};

CookiesConsent.propTypes = props;

export default withSettingsContext(withTranslation()(CookiesConsent));
