import style from 'styled-components';
import { INPUT_BORDER_COLOR, THIRD } from 'styled/constants';

export const StyledInputModern = style.div`
  .input-wrapper{
    display: flex;
    width: 100%;
    height: ${props => props.height || '45px'}
    border-radius: 0;
    justify-content: flex-start;
    align-items: center;
    font-weight:  500;
    color: ${props => props.color || props.theme.font.colors.primary};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    border:none !important;

    label{
      font-size: ${props => props.fontSize || '13px'};
      position: relative;
      padding:0;
      margin:0;
      padding-left: 35px;
      cursor: ${props => (props.disabled ? 'arrow' : 'pointer')};
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
   
    input{
      position: absolute;
      opacity: 0;
      cursor: ${props => (props.disabled ? 'arrow' : 'pointer')};
      height: 0;
      width: 0;
    }
    
    .check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius:20px;
      background-color: #ccc;
      border:1x
      :after {
        content: "";
        display: none;
        position:relative;
        left:7px
      }
    }
   
    .disabled {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: ${INPUT_BORDER_COLOR};
      :after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
  label:hover input ~ .check-mark{
    background-color: ${props => props.theme.colors.buttonPrimaryBg};
  }
  label input:checked ~ .check-mark{
    background-color: ${props => props.theme.colors.buttonPrimaryBg};
  }
  label input:checked ~ .check-mark:after{
    display: block;
  }
  label .check-mark:after{
    left: 10px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${THIRD};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .error{
    width: 100%;
    padding-left: 10px;
    color: ${props => props.theme.colors.alert};
    font-style: italic
  }
  .label{
    font-weight: bold;
    margin-bottom: 10px;
  }

  &.special-checkbox .input-wrapper label{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

  @media only screen and (max-width: 400px){
    &.special-checkbox .input-wrapper label{font-size: 11px !important;}
  }

  @media only screen and (min-width: ${props => props.theme.screens.xl}) and (max-width: ${props =>
  props.theme.screens.xxl}){
    &.special-checkbox .input-wrapper label{font-size: 13px;}
  }

`;
