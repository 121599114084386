import FranchiseSection from './FranchiseSection';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchCategories } from 'actions';

const mapStateToProps = ({ global, siteConfiguration }) => ({
  listCategories: global.listCategories,
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { fetchCategories })(withTranslation()(FranchiseSection));
