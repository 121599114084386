import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { props, defaultProps } from './DatePicker.props';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.date || null,
      focused: false,
    };
  }

  handleDateChange(date) {
    const { onDateChange } = this.props;
    onDateChange(date);
    this.setState({ date });
  }

  handleFocusChange(focused) {
    const { onFocusChange } = this.props;
    onFocusChange(focused);
    this.setState({ focused });
  }

  returnYears = () => {
    let years = [];

    const currentYear = moment().year();

    for (let i = currentYear - 100; i <= currentYear + 30; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onYearSelect }) => {
    return (
      <div className="date-picker-month">
        <strong>{month.format('MMMM')}</strong>
        <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    );
  };

  render() {
    const {
      id,
      placeholder,
      small,
      height,
      numberOfMonths,
      showDefaultInputIcon,
      block,
      noBorder,
      verticalHeight,
      settings,
    } = this.props;
    return (
      <SingleDatePicker
        placeholder={placeholder}
        date={this.state.date}
        focused={this.state.focused}
        id={id}
        inputIconPosition="after"
        numberOfMonths={numberOfMonths}
        isOutsideRange={() => false}
        displayFormat="DD/MM/YYYY"
        onDateChange={date => this.handleDateChange(date)}
        onFocusChange={({ focused }) => this.handleFocusChange(focused)}
        showDefaultInputIcon={showDefaultInputIcon}
        block={block}
        small={small}
        noBorder={noBorder}
        verticalHeight={height || verticalHeight}
        renderMonthElement={this.renderMonthElement}
        {...settings}
      />
    );
  }
}

DatePicker.propTypes = props;
DatePicker.defaultProps = defaultProps;
export default DatePicker;
