import moment from 'moment';

export const transformCountriesToSelectOptions = countries => {
  if (!countries) return [];

  return countries.map(country => {
    return { value: country.name, text: country.name };
  });
};

export const isValidEmail = email => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return String(email)
    .toLowerCase()
    .match(regExp);
};

export const getDocumentTypeName = (listDocumentTypes, id) => {
  const type = (listDocumentTypes || []).find(d => d.id === parseFloat(id) || d.value === id);
  if (type) return (type.name || type.value).toUpperCase();
  return null;
};

export const getDocumentMask = (id, type, isFocused, listDocumentTypes) => {
  if (!id || getDocumentTypeName(listDocumentTypes, type) !== 'RUT') {
    return '******************************';
  }

  if (isFocused) return '9999999**';

  if (id.length > 8) return '99.999.999-*';

  return '9.999.999-*';
};

export const invertValueSign = value => {
  if (value) {
    if (value > 0) return `-${value}`;
    return Math.abs(value);
  }
  return 0;
};

export const useQuery = location => {
  return new URLSearchParams(location.search);
};

export const isCompanyUser = profile => {
  if (!profile) return false;

  return profile.isCompany || profile.isAgency || profile.isProvider || profile.isHotel;
};

export const isAgencyUser = profile => {
  if (!profile) return false;

  return profile.isAgency;
};

export const convertToBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
};

const getCommissionAmount = (price, paymentConfiguration) => {
  // commissionPercentage is a round number for instance 30 = 30%
  return paymentConfiguration?.commissionPercentage
    ? (paymentConfiguration.commissionPercentage / 100) * price
    : 0;
};

export const getPriceWithCommission = (price, paymentConfiguration) => {
  const commissionAmount = getCommissionAmount(price, paymentConfiguration);

  return price + commissionAmount;
};

export const getPriceWithoutVat = priceItems => {
  const totalWithoutVat = priceItems.reduce((acc, item) => {
    if (item.type !== 15) {
      return acc + item.price;
    }
    return acc;
  }, 0);

  return totalWithoutVat;
};

export const dateFormatEdition = (stringDate, dateFormat) => {
  const day = moment(stringDate, 'DD/MM/YYYY');
  const formattedDate = day.format(dateFormat);
  return formattedDate;
};

export const handleGetTotalToPay = (balance, paymentConfiguration, qsamount) => {
  const amount = Math.abs(balance);
  const price = getPriceWithCommission(amount, paymentConfiguration);

  if (qsamount && Math.abs(price) >= Math.abs(qsamount)) {
    return Math.abs(qsamount);
  } else {
    if (paymentConfiguration?.onlyPayCommission && paymentConfiguration?.commissionPercentage) {
      return Math.abs(getCommissionAmount(amount, paymentConfiguration));
    }
    if (paymentConfiguration?.percentageToPay) {
      return Math.abs((price / 100) * paymentConfiguration.percentageToPay);
    } else {
      return Math.abs(price);
    }
  }
};
