import React, { useEffect } from 'react';
import { props } from './HubspotForm.props';

const HubspotForm = ({ src, config }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt && config) {
        window.hbspt.forms.create({ ...config, target: '#hubspot-form' });
      }
    });
  }, []);

  return (
    <div>
      <div id="hubspot-form"></div>
    </div>
  );
};

HubspotForm.propTypes = props;

export default HubspotForm;
