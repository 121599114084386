import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button } from 'react-bootstrap';
import { StyledServiceItem } from './ServiceItem.styled';
import { NumberDisplay, Btn, TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { StyledServiceItemModern } from './ServiceItem.styled.modern';
import { MODERN_THEME } from 'styled/constants';

class ServiceItem extends TranslatedComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledServiceItemModern;
    }
    return StyledServiceItem;
  }

  getComponentClasses() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-lg-4 text-center service-item';
    }
    return 'col-md-4 col-lg-3 text-center service-item';
  }

  getServiceDescription(service, descriptionMaxLength, descriptionCharsSplit) {
    const { name, description } = service;
    const fullDescription = description
      ? `${this.tt(name)} - ${this.tt(description)}`
      : description;
    const collapsedDescription =
      fullDescription.length > descriptionMaxLength
        ? fullDescription.slice(0, descriptionCharsSplit) + '...'
        : fullDescription;
    return { fullDescription, collapsedDescription };
  }

  render() {
    const {
      item,
      t,
      size,
      padding,
      sizeHover,
      borderRadius,
      descriptionMaxLength,
      descriptionCharsSplit,
      seeMore,
      seeLess,
    } = this.props;
    const StyledSection = this.getTemplateStyledComponent();
    const { fullDescription, collapsedDescription } = this.getServiceDescription(
      item,
      descriptionMaxLength,
      descriptionCharsSplit,
    );
    const { expanded } = this.state;

    return (
      <StyledSection
        className={this.getComponentClasses()}
        size={size}
        padding={padding}
        sizeHover={sizeHover}
        borderRadius={borderRadius}
      >
        <img className="mb-4" src={item.imagePath} alt={this.tt(item.name)} />
        <h4>{this.tt(item.name)}</h4>
        <Collapse in={expanded}>
          <div>{expanded ? fullDescription : collapsedDescription}</div>
        </Collapse>
        {!expanded && (
          <div className="description">
            {fullDescription.length > descriptionMaxLength ? collapsedDescription : fullDescription}
          </div>
        )}
        {fullDescription.length > descriptionMaxLength && (
          <Button
            variant="link"
            onClick={() => this.setState({ expanded: !expanded })}
            aria-controls="service-description-collapse"
            aria-expanded={expanded}
            className={`service-description-button ${expanded && seeLess ? 'see-less' : ''}`}
          >
            {expanded ? seeLess : seeMore}
          </Button>
        )}
        {item.price != null && item.price > 0 && (
          <Btn
            className="mb-5"
            text={
              <>
                <NumberDisplay value={item.price} />
                {' ' + (item.isPriceByDay ? t('perDay') : t('perBooking'))}
              </>
            }
          />
        )}
      </StyledSection>
    );
  }
}

export default withSettingsContext(withTranslation()(ServiceItem));
