import { SET_INFRACTION, SET_INFRACTION_PAY_INSTALLMENT } from '../utils/types';

const INITIAL_STATE = {
  infraction: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INFRACTION: {
      return {
        ...state,
        current: action.payload,
      };
    }

    case SET_INFRACTION_PAY_INSTALLMENT: {
      return {
        ...state,
        installment: action.payload,
      };
    }

    default:
      return state;
  }
};
