import React from 'react';
import { props, defaultProps } from './CheckoutDetails.props';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { tt } from 'utils/translationHelper';
import { getSippByCategory } from '../../../utils/categoryGroupHelper';
import { StyledDetails, NoBookingDivStyled } from './CheckoutDetails.styled';
import { StyledDetailsModern } from './CheckoutDetails.styled.modern';
import { Title, Paragraph, ModelDetail, Icon, NumberDisplay, CustomLoading } from 'components/';
import { dateFormatEdition } from 'utils/dataHelper';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import { getPriceWithCommission, isAgencyUser } from 'utils/dataHelper';
import { sumBy } from 'lodash';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import ExchangeMessage from 'components/ExchangeMessage';

const mapStateToProps = state => ({
  currentCurrency: state.global.currentCurrency,
  defaultCurrency: state.global.defaultCurrency,
  webCurrency: state.global.webCurrency,
  profile: state.user.profile,
  bookingsConfiguration: state.bookings.configuration,
});

const PriceComponent = ({ name, price }) => {
  return (
    <li>
      <i className="fas fa-plus" /> <span className="item-name">{name} </span>{' '}
      <span className="item-price">
        <NumberDisplay value={price} />
      </span>
    </li>
  );
};

class CheckoutDetails extends React.Component {
  goBack = () => {
    this.props.history.goBack();
  };

  getModelName(model) {
    const {
      t,
      settings: { featureFlags },
    } = this.props;
    const availabilityGroup =
      featureFlags.availabilityGroup === undefined ? 'model' : featureFlags.availabilityGroup;
    const showOrSimilar =
      featureFlags.ShowOrSimilar === undefined ? true : featureFlags.ShowOrSimilar;
    const legend = availabilityGroup === 'category' && showOrSimilar ? t('orSimilar') : '';

    return `${model.brand.name} ${model.name} ${legend}`;
  }

  getCategoryOrGroupName = carModel => {
    const {
      showSipp,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    if (!categoryGroups) return carModel.category.name;

    const categoryGroupName = getSippByCategory(categoryGroups, carModel.category).toUpperCase();
    return showSipp && carModel.sipp
      ? `${categoryGroupName} - ${carModel.sipp}`
      : categoryGroupName;
  };

  getDailyFinalPrice = booking => {
    if (!booking) return null;

    return getPriceWithCommission(booking.price) / booking.totalDays;
  };

  showBookingLenght = booking => {
    const {
      t,
      diasDeAlquiler,
      settings: { configurations },
    } = this.props;
    const bookingLength = Number.isInteger(booking.totalDays)
      ? booking.totalDays
      : booking.totalDaysString;

    const bookingText = diasDeAlquiler
      ? `Por ${bookingLength} ${diasDeAlquiler}`
      : t('forDaysOfRent', { totalDays: bookingLength });

    const openRateText = configurations.openRate ? ` ${t('from').toLowerCase()}` : '';

    return bookingText + openRateText;
  };

  showFranchiseValues = booking => {
    const {
      settings: { featureFlags },
    } = this.props;

    if (!booking) return;
    const {
      t,
      franchiseDamagesText,
      franchiseRolloverText,
      franchiseTheftText,
      franchiseHailText,
    } = this.props;

    const showFranchiseDetails = featureFlags?.showFranchiseDetails || false;
    const showFranchiseDamages = featureFlags?.showFranchiseDamages || false;
    const showFranchiseRollover = featureFlags?.showFranchiseRollover || false;
    const showFranchiseTheft = featureFlags?.showFranchiseTheft || false;
    const showFranchiseHail = featureFlags?.showFranchiseHail || false;

    if (!showFranchiseDetails) return null;

    return (
      <div className="franchise-details">
        <hr />
        <ul className="list-unstyled">
          {showFranchiseDamages && (
            <li>
              {t(franchiseDamagesText) || t('franchises.franchiseDamages')}:{' '}
              <NumberDisplay value={booking.franchiseDamage || 0} />
            </li>
          )}
          {showFranchiseRollover && (
            <li>
              {t(franchiseRolloverText) || t('franchises.franchiseRollover')}:{' '}
              <NumberDisplay value={booking.franchiseRollover || 0} />
            </li>
          )}
          {showFranchiseTheft && (
            <li>
              {t(franchiseTheftText) || t('franchises.franchiseTheft')}:{' '}
              <NumberDisplay value={booking.franchiseTheft || 0} />
            </li>
          )}
          {showFranchiseHail && (
            <li>
              {t(franchiseHailText) || t('franchises.franchiseHail')}:{' '}
              <NumberDisplay value={booking.franchiseHail || 0} />
            </li>
          )}
        </ul>
      </div>
    );
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledDetailsModern;
    }
    return StyledDetails;
  }

  getPriceItemsContent = () => {
    const {
      searchCars: {
        checkout: { booking },
      },
      settings: { configurations, paymentConfiguration },
      t,
      currentCurrency,
      defaultCurrency,
      webCurrency,
    } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const getDescription = item => {
      if (item.isBookingPrice && configurations.openRate) {
        let unitPrice = applyExchangeRate
          ? getPriceWithExchangeRate(item.unitPrice, currentCurrency, defaultCurrency, webCurrency)
          : item.unitPrice;

        return (
          <>
            {t('openRatePriceItem', { days: item.quantity })}
            (
            <NumberDisplay value={unitPrice} /> {t('xDay')})
          </>
        );
      }
      return item.description;
    };

    if (paymentConfiguration && paymentConfiguration.commissionPercentage > 0) {
      const bookingPriceItems = booking.priceItems.filter(i => i.isBookingPrice);
      const extraPriceItems = booking.priceItems.filter(i => !i.isBookingPrice);

      const bookingPriceItemsAmount = getPriceWithCommission(
        sumBy(bookingPriceItems, 'price'),
        paymentConfiguration,
      );

      return (
        <>
          <PriceComponent
            name={`${t('alquilerPor')} ${booking.totalDaysString}`}
            price={
              applyExchangeRate
                ? getPriceWithExchangeRate(
                    bookingPriceItemsAmount,
                    currentCurrency,
                    defaultCurrency,
                    webCurrency,
                  )
                : bookingPriceItemsAmount
            }
          />
          {extraPriceItems.map((item, i) => {
            return (
              <PriceComponent
                key={i}
                name={getDescription(item)}
                price={
                  applyExchangeRate
                    ? getPriceWithCommission(
                        getPriceWithExchangeRate(
                          item.price,
                          currentCurrency,
                          defaultCurrency,
                          webCurrency,
                        ),
                        paymentConfiguration,
                      )
                    : getPriceWithCommission(item.price, paymentConfiguration)
                }
              />
            );
          })}
        </>
      );
    }

    return booking.priceItems.map((item, i) => {
      return (
        <PriceComponent
          key={i}
          name={getDescription(item)}
          price={
            applyExchangeRate
              ? getPriceWithExchangeRate(item.price, currentCurrency, defaultCurrency, webCurrency)
              : item.price
          }
        />
      );
    });
  };

  render() {
    const {
      searchCars: {
        checkout: { booking, isLoading, isError, errorMessage },
        params: { dateFrom, dateTo, hourFrom, hourTo, commercialAgreementCode },
      },
      settings: { featureFlags, paymentConfiguration },
      t,
      title,
      subtitle,
      prefixForNumeric,
      loadingGif,
      loadingMessage,
      franquicia,
      detailsLegend,
      totalPayText,
      currentCurrency,
      defaultCurrency,
      webCurrency,
      chargedMsg,
      chargedMsg2,
      profile,
      bookingsConfiguration,
    } = this.props;

    const loggedAsAgency = isAgencyUser(profile);

    const StyledComponent = this.getTemplateStyledComponent();

    if (isLoading) {
      return (
        <NoBookingDivStyled className="col-md-12">
          <CustomLoading
            loadingGif={loadingGif || t('loadingGif')}
            loadingMessage={loadingMessage}
          ></CustomLoading>
        </NoBookingDivStyled>
      );
    }

    if (isError) {
      return (
        <NoBookingDivStyled className="col-md-12">
          <div className="col-md-12 mb-5">
            <Icon className="fas fa-times-circle error" color="red"></Icon>
          </div>
          <div className="col-md-12 mb-5 pb-5">
            <Title
              type="h2"
              text={errorMessage}
              weight="900"
              fontSize={30}
              className={`${errorMessage != null ? '' : 'd-none'}`}
            />
          </div>
        </NoBookingDivStyled>
      );
    }

    if (booking == null) {
      return (
        <NoBookingDivStyled className="col-md-12">
          <Icon className="fas fa-times-circle error" color="red"></Icon>
          <p>{t('bookingNotFound')}</p>
          <a className="d-block" onClick={this.goBack}>
            <i className="fas fa-angle-double-left" />
            {t('chooseOtherCar')}
          </a>
        </NoBookingDivStyled>
      );
    }

    const model = booking.car.model;
    const showDeposit = featureFlags.showDeposit === undefined ? true : featureFlags.showDeposit;
    const showFranchiseDetails =
      featureFlags.showFranchiseDetails === undefined ? false : featureFlags.showFranchiseDetails;
    const showFranchiseDamages =
      featureFlags.showFranchiseDamages === undefined ? false : featureFlags.showFranchiseDamages;
    const showFranchiseRollover =
      featureFlags.showFranchiseRollover === undefined ? false : featureFlags.showFranchiseRollover;
    const showFranchiseTheft =
      featureFlags.showFranchiseTheft === undefined ? false : featureFlags.showFranchiseTheft;
    const showCovidBox =
      featureFlags.showCovidBox === undefined ? false : featureFlags.showCovidBox;
    const dailyPrice = featureFlags.showDailyFinalPrice
      ? this.getDailyFinalPrice(booking)
      : getPriceWithCommission(booking.averageDayPrice, paymentConfiguration);
    const totalPrice = getPriceWithCommission(booking.price, paymentConfiguration);
    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);
    const showExchangeMsg = applyExchangeRate;

    const kmText =
      booking.ilimitedKm === true
        ? t(
            bookingsConfiguration?.distanceUnit == 'Kilometers'
              ? 'KmIlimitados'
              : 'MillasIlimitados',
          )
        : t(bookingsConfiguration?.distanceUnit == 'Kilometers' ? 'KmDiarios' : 'MillasDiarios', {
            km: booking.maxAllowedDistanceByDay,
          });

    return (
      <StyledComponent className="col-md-3" priceBackground={this.props.priceBackground}>
        <div id="checkout-print" className="checkout-details">
          <div className="row">
            <div className="col-md-12 title-col1">
              <Title
                type="h2"
                text={title}
                italic={false}
                weight="700"
                fontSize={20}
                className="mt-1"
              />
              <Paragraph>{tt(subtitle, t)}</Paragraph>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="rent-details">
                <div className="car-item">
                  <div className="car-details">
                    <h2 className="car-name mt-1">{this.getModelName(model)}</h2>
                    <Paragraph className="car-category mb-0">
                      {t('categoria')}&nbsp;
                      <span>{this.getCategoryOrGroupName(model)}</span>
                      <div className="d-inline-block">
                        <span className="mr-2 ml-2">|</span>
                        <span className="tachometer text-capitalize">
                          <i className="fas fa-tachometer-alt mr-1"></i>
                          {kmText}
                        </span>
                      </div>
                    </Paragraph>
                    {showDeposit && (
                      <p className="franchise mb-3">
                        <i
                          className="fas fa-info-circle"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={t('franchiseHelpText')}
                        />
                        <strong> {t(franquicia) || t('franquicia')} </strong>
                        <span>
                          <NumberDisplay value={booking.franchise} useWebCurrency={true} />
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="car-image text-center mb-3">
                    <img
                      className="img-fluid"
                      src={model.imagePath}
                      alt={this.getModelName(model)}
                    />
                  </div>
                  <ModelDetail model={model} prefixForNumeric={prefixForNumeric} />
                </div>
                <hr />
                <div className="pickup-details">
                  <Title
                    type="h4"
                    text={t('delivery')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>
                    {booking.deliveryPlace.name} -
                    <span className="date">{dateFormatEdition(dateFrom, t('dateFormat'))}</span>{' '}
                    <span className="hour">{hourFrom} hs</span>
                  </p>
                  <Title
                    type="h4"
                    text={t('return')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>
                    {booking.returnPlace.name} -
                    <span className="date">{dateFormatEdition(dateTo, t('dateFormat'))}</span>{' '}
                    <span className="hour">{hourTo} hs</span>
                  </p>
                </div>
                <hr />
                <div className="payment-details">
                  <Title
                    type="h4"
                    className="mb-3"
                    text={t('paymentDetail')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <ul className="list-unstyled">
                    {this.getPriceItemsContent()}

                    {commercialAgreementCode && (
                      <li>
                        <i className="fas fa-plus" />{' '}
                        <span className="item-name">{t('commercialAgreement')} </span>{' '}
                        <span className="item-price">{commercialAgreementCode}</span>
                      </li>
                    )}
                  </ul>
                </div>
                {(showFranchiseDetails ||
                  showFranchiseDamages ||
                  showFranchiseRollover ||
                  showFranchiseTheft) &&
                  this.showFranchiseValues(booking)}
              </div>
              <div className="payment mt-3 mb-3">
                <div className="row text-light">
                  <div className="total-price-text-wrapper">
                    <Title
                      type="h3"
                      className="total-name text-light"
                      text={totalPayText ? tt(totalPayText, t) : t('totalToPay')}
                    />
                    <p className="days">{this.showBookingLenght(booking)}</p>
                  </div>
                  <div className="total-price-number-wrapper">
                    <p className="total-price">
                      <NumberDisplay
                        value={
                          applyExchangeRate
                            ? getPriceWithExchangeRate(
                                totalPrice,
                                currentCurrency,
                                defaultCurrency,
                                webCurrency,
                              )
                            : totalPrice
                        }
                      />
                    </p>
                    <p className="diario">
                      {t('dailyPrice')}{' '}
                      <span>
                        <NumberDisplay
                          value={
                            applyExchangeRate
                              ? getPriceWithExchangeRate(
                                  dailyPrice,
                                  currentCurrency,
                                  defaultCurrency,
                                  webCurrency,
                                )
                              : dailyPrice
                          }
                        />
                      </span>
                    </p>
                    {loggedAsAgency && (
                      <>
                        <p className="diario">
                          {t('myAccount.customer')}:{' '}
                          <strong>
                            <NumberDisplay value={booking.customerPrice} />
                          </strong>
                          <br></br>
                          {t('myAccount.agency')}:{' '}
                          <strong>
                            <NumberDisplay value={booking.agencyPrice} />
                          </strong>
                          <br></br>
                          {t('myAccount.commision')}:{' '}
                          <strong>
                            <NumberDisplay value={booking.salesCommision} />
                          </strong>
                        </p>
                      </>
                    )}
                  </div>
                  {detailsLegend && (
                    <div className="col-md-12">
                      <Paragraph className="text-light">{tt(detailsLegend, t)}</Paragraph>
                    </div>
                  )}
                </div>
              </div>
              {showExchangeMsg && (
                <ExchangeMessage
                  defaultCurrency={defaultCurrency}
                  currentCurrency={currentCurrency}
                  chargedMsg={chargedMsg}
                  chargedMsg2={chargedMsg2}
                />
              )}
              {showCovidBox && (
                <div className="rent-details mt-3 mb-3 flex-booking">
                  <h4>{t('flexibleBooking.includes')}</h4>
                  <span>{t('flexibleBooking.flexBooking')}</span>
                  <ul className="fa-ul">
                    <li>
                      <i class="fas fa-check"></i>
                      {t('flexibleBooking.cancel48hs')}
                    </li>
                    <li>
                      <i class="fas fa-check"></i>
                      {t('flexibleBooking.accidents')}
                    </li>
                    <li>
                      <i class="fas fa-check"></i>
                      {t('flexibleBooking.civilEnsurance')}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </StyledComponent>
    );
  }
}

CheckoutDetails.propTypes = props;
CheckoutDetails.defaultProps = defaultProps;
export default connect(mapStateToProps)(
  withSettingsContext(withRouter(withTranslation()(CheckoutDetails))),
);
