import React from 'react';
import { connect } from 'react-redux';
import { fetchAdditionals } from 'actions';
import { Loading, TranslatedComponent } from 'components/';
import ServiceItem from './ServiceItem';
import { withTranslation } from 'react-i18next';
import { StyledServiciosSection } from './ServiciosSection.styled';
import { PropTypes } from 'prop-types';
import { StyledServiciosSectionModern } from './ServiciosSection.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { tt } from '../../../utils/translationHelper';

class ServiciosSection extends TranslatedComponent {
  static propTypes = {
    groups: PropTypes.array,
    listAdditionals: PropTypes.array,
    t: PropTypes.func,
  };

  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      const { fetchAdditionals, i18n } = this.props;

      await fetchAdditionals('', i18n.language);

      this.setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    } catch (error) {}
  }

  GroupTitle(title) {
    return (
      <div className="group-title">
        <h5>{title}</h5>
      </div>
    );
  }

  getService(service, additionals) {
    if (additionals && additionals.length > 0 && service.additionalId) {
      var additional = additionals.find(ad => ad.id === service.additionalId);

      return { ...additional, ...service };
    }

    return service;
  }

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledServiciosSectionModern;
    }
    return StyledServiciosSection;
  }

  render() {
    const {
      groups,
      listAdditionals,
      t,
      size,
      padding,
      sizeHover,
      borderRadius,
      seeLess,
      seeMore,
      descriptionMaxLength,
      descriptionCharsSplit,
    } = this.props;
    const StyledSection = this.getTemplateStyledComponent();

    if (this.state.loading) return <Loading show />;

    if (!groups) {
      return (
        <StyledSection>
          {this.GroupTitle(t('additionals'))}
          <div className="row card-body">
            {listAdditionals.map((service, j) => (
              <ServiceItem
                item={service}
                key={j}
                size={size}
                sizeHover={sizeHover}
                padding={padding}
                borderRadius={borderRadius}
                seeMore={tt(seeMore, t)}
                seeLess={tt(seeLess, t)}
                descriptionMaxLength={tt(descriptionMaxLength, t)}
                nameSplitNumber={tt(descriptionCharsSplit, t)}
              />
            ))}
          </div>
        </StyledSection>
      );
    }

    return (
      <StyledSection size={size} padding={padding}>
        {groups.map((group, index) => (
          <div className="mb-4" key={index}>
            {this.GroupTitle(this.tt(group.name))}
            <div className="row card-body">
              {group.services.map((service, j) => (
                <ServiceItem
                  item={this.getService(service, listAdditionals)}
                  key={j}
                  size={size}
                  sizeHover={sizeHover}
                  padding={padding}
                  borderRadius={borderRadius}
                  seeMore={tt(seeMore, t)}
                  seeLess={tt(seeLess, t)}
                  descriptionMaxLength={tt(descriptionMaxLength, t)}
                  nameSplitNumber={tt(descriptionCharsSplit, t)}
                />
              ))}
            </div>
          </div>
        ))}
      </StyledSection>
    );
  }
}

const mapStateToProps = ({ global, router, siteConfiguration }) => ({
  listAdditionals: global.listAdditionals,
  router: router,
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { fetchAdditionals })(withTranslation()(ServiciosSection));
