import PropTypes from 'prop-types';

export const props = {
  background: PropTypes.string,
  active: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.string,
  fontSize: PropTypes.string,
  iconSize: PropTypes.string,
  fontWeight: PropTypes.string,
  textTransform: PropTypes.string,
  height: PropTypes.string,
  isNavOpen: PropTypes.bool,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      path: PropTypes.string,
      callback: PropTypes.func,
    }),
  ),
  padding: PropTypes.string,
  socialNetworks: PropTypes.array,
  width: PropTypes.string,
  mobileMenuItems: PropTypes.array,
  langs: PropTypes.array,
  currency: PropTypes.array,
  handleLangChange: PropTypes.func,
  sticky: PropTypes.bool,
  currentCurrency: PropTypes.object,
};

export const defaultProps = {
  langs: [],
  navItems: [],
  socialNetworks: [],
  mobileMenuItems: [],
  handleLangChange: () => {},
  sticky: true,
};
