import styled from 'styled-components';

export const StyledMyAccountNavLink = styled.div`
  display: flex;
  color: ${props => props.theme.font.colors.primary};
  background: ${({ bgColor }) => bgColor};
  margin-left: 5px;

  .login-button {
    border: none;
    color: ${({ color, theme }) => color || theme.font.colors.primary};
    padding: 7px 16px;
    transition: all 0.3s;
    font-size: 0.9rem;
    white-space: nowrap;

    &:hover {
      color: white;
      background-color: ${({ theme, hover }) => hover || theme.colors.secondary};
    }
  }

  .my-account-button {
    .profile-pic {
      width: 18px;
      aspect-ratio: 1;
      border-radius: 50px;
      margin-right: 8px;
    }

    .dropdown-toggle {
      padding: 9.5px 10px;

      &:hover {
        background-color: ${props => props.bgColor || props.theme.colors.secondary};
      }
    }

    .dropdown-toggle,
    .dropdown-toggle:active {
      background: transparent;
      color: ${({ color, theme }) => color || theme.font.colors.primary};
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      border: none;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none;
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100px;
        display: inline-block;
      }
    }

    button {
      background: transparent;
      border: none;
      width: 100%;
      text-align: left;
    }

    .nav-link {
      font-size: 12px;
      text-transform: uppercase;
      color: ${props => props.color || props.theme.font.colors.primary};
      background: ${({ theme }) => theme.colors.bg};
      padding: 8px 16px;
      border-bottom: 1px solid #ecf0f5;
      transition: all 0.3s;

      &:hover {
        background-color: ${({ theme, hoverBkg }) => hoverBkg || theme.colors.primary};
        color: ${({ hoverColor, theme }) => hoverColor || theme.font.colors.secondary};
      }

      &:last-child {
        border: none;
      }
    }
  }
`;
