import PropTypes from 'prop-types';

export const props = {
  bgColor: PropTypes.string,
  bgImg: PropTypes.string,
  bgSize: PropTypes.string,
  bgPosition: PropTypes.string,
  bgAttachment: PropTypes.string,
  bgRepeat: PropTypes.string,
  titleSize: PropTypes.string,
  titleColor: PropTypes.string,
  descriptionSize: PropTypes.string,
  descriptionColor: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  rate: PropTypes.number,
  showControls: PropTypes.bool,
  showIndicators: PropTypes.bool,
  interval: PropTypes.number,
  height: PropTypes.string,
  itemMargin: PropTypes.string,
  fade: PropTypes.bool,
};
