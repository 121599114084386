import style from 'styled-components';

export const StyledBreadcrumb = style.section`
	background: ${props => (props.background ? props.background : `none`)};
  padding-top: 10px;
  width: 100%;
  

  @media only screen and (max-width: ${props => props.theme.screens.lg}){
    display: none;
  }

  .breadcrumb {
    background: ${props => (props.background ? props.background : `none`)};
    margin-bottom: 1rem;
  }

  .breadcrumb li, .breadcrumb li a {
    color: #969696;
    font-size: 13px;
    text-transform: capitalize;
  }

  .breadcrumb li a:hover{
    text-decoration:none;
  }
  
  .breadcrumb li:hover a, .breadcrumb .active a{
    color: #000;
    font-size: 13px;
    font-weight: 600;
  }
`;
