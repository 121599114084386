import style from 'styled-components';

export const StyledCondicionesSection = style.section`
	background: ${props => (props.background ? `url(${props.background}) no-repeat` : `white`)};
    min-height: 400px;
    padding-top:32px;
    width: 100%;
	h2{  
        margin-bottom: 16px;
    }
    h1{  
        margin-bottom: 8px;
    }
    p{
      font-size: 12px;
    }
    .fa-angle-right{
        color: ${props => props.theme.colors.primary}
    }
    .condiciones h3{
        margin-bottom:22px;
    }
    .list-unstyled li{
        font-size:14px;
        line-height:1.8rem;
    }
    .condiciones{
        padding:0px 40px;
    }
    div.condiciones.highlight{
        background: ${props => props.highlightColor || props.theme.colors.highlight};
        padding: 2rem 3rem;
        max-height: fit-content;
    }
    .conditions-container-wrapper {
        margin-bottom: 3rem;
        padding: 0;
    }
`;
