import React from 'react';
import { props, defaultProps } from './NavBar.props';
import { StyledNavBar } from './NavBar.styled';
import { Link, withRouter } from 'react-router-dom';
import { LangDropdown, Logo, SocialList, TranslatedLink, CurrenciesDropdown } from '../';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TranslatedComponent, MyAccountNavLink } from 'components/';
import { tlink } from 'utils/translationHelper';
import { NavDropdown } from 'react-bootstrap/';
import { StyledNavBarModern } from './NavBar.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { changeCurrencyAction } from 'actions/global.actions';

class NavBar extends TranslatedComponent {
  constructor() {
    super();

    this.state = {
      showDropdown: true,
    };
  }
  static propTypes = props;
  static defaultProps = defaultProps;

  handleStickyNavbar = stickyNav => {
    const mainNav = document.getElementById('mainNav') || null;
    if (!mainNav) return;
    if (window.pageYOffset > stickyNav) {
      mainNav.classList.add('sticky');
    } else {
      mainNav.classList.remove('sticky');
    }
  };

  componentDidMount() {
    const mainNav = document.getElementById('mainNav') || null;

    if (mainNav) {
      window.onscroll = () => {
        this.handleStickyNavbar(100);
      };
    }
  }

  isActive(navItem) {
    const { location, t, i18n, settings } = this.props;

    if (
      location.pathname === tlink(navItem.path, t, i18n, null, settings.configurations.langConfig)
    )
      return 'active';

    return null;
  }

  isDropdownActive(navItem) {
    const { location, t, i18n, settings } = this.props;

    var pathMatch = navItem.subItems.find(
      subItem =>
        tlink(subItem.path, t, i18n, null, settings.configurations.langConfig) ===
        location.pathname,
    );

    if (pathMatch != null) return 'active';

    return null;
  }

  showMenu = event => {
    event.preventDefault();

    this.setState({ showDropdown: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = event => {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showDropdown: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledNavBarModern;
    }
    return StyledNavBar;
  }

  render = () => {
    const {
      background,
      active,
      color,
      iconSize,
      hover,
      fontSize,
      fontWeight,
      textTransform,
      height,
      settings: {
        configurations: {
          sitemap: { links },
        },
        myAccountConfiguration,
        featureFlags,
      } = [],
      settings: { configurations: { socialNetworks } } = [],
      mobileLinks = [],
      padding,
      width,
      logo,
      mobileLogo,
      comm,
      dropDownborderRadius,
      subItemColor,
      subItemHover,
      dropdownBkgHover,
    } = this.props;

    const showMyAccount = myAccountConfiguration && myAccountConfiguration.showMyAccount;
    const showCurrenciesDropdown = featureFlags && featureFlags.showCurrenciesDropdown;

    const StyledSection = this.getTemplateStyledComponent();

    return (
      <StyledSection
        id="mainNav"
        className={`${comm.isNavBarOpen() === true ? 'mobile-open' : ''} row row-p`}
        fontSize={fontSize}
        textTransform={textTransform}
        fontWeight={fontWeight}
        height={height}
        padding={padding}
        width={width}
        color={color}
        hover={hover}
        background={background}
        active={active}
        iconSize={iconSize}
        dropDownborderRadius={dropDownborderRadius}
        subItemColor={subItemColor}
        subItemHover={subItemHover}
        dropdownBkgHover={dropdownBkgHover}
      >
        <ul className="nav-list">
          <span className="navLogo">
            <Logo src={mobileLogo || logo} />
          </span>
          <hr className="mobileSeparator" />
          {links.map((navItem, i) => {
            if (navItem && !navItem.noShowInHeader) {
              return (
                <React.Fragment key={i}>
                  {navItem.dropdown === true ? (
                    <NavDropdown
                      title={this.tt(navItem.name)}
                      className={'nav-list-item ' + this.isDropdownActive(navItem)}
                      id="dropdown"
                    >
                      {navItem.subItems.map((subItem, idx) => {
                        return (
                          <li
                            className={'nav-list-subitem ' + this.isActive(subItem)}
                            onClick={comm.toogleNavBar}
                            key={idx}
                          >
                            <TranslatedLink key={i} className="nav-sublink" {...subItem} />
                          </li>
                        );
                      })}
                    </NavDropdown>
                  ) : (
                    <li
                      className={'nav-list-item ' + this.isActive(navItem)}
                      onClick={comm.toogleNavBar}
                    >
                      {navItem.icon && <i className={`${navItem.icon} menuIcons`} />}
                      <TranslatedLink className="nav-link" {...navItem} />
                    </li>
                  )}
                </React.Fragment>
              );
            } else return null;
          })}

          <hr className="mobileSeparator" />

          <SocialList
            bgColor="transparent"
            className="navSocialList"
            links={socialNetworks}
            borderRadius="0"
            margins="0"
            color={color}
            hover={hover}
          />

          {mobileLinks.map((navItem, i) => {
            if (navItem) {
              return (
                <li key={`onlyMobile_${i}`} className="nav-list-item onlyMobile">
                  <Link
                    className="nav-link"
                    to={this.tt(navItem.path)}
                    onClick={() => comm.toogleNavBar()}
                  >
                    <i className="fa fa-angle-right" />
                    {this.tt(navItem.name)}
                  </Link>
                </li>
              );
            } else return null;
          })}

          {showMyAccount && (
            <MyAccountNavLink
              color={color}
              bgColor={background}
              hoverBkg={active}
              hoverColor={hover}
              className="my-account-link"
            />
          )}
          {mobileLinks.length > 0 ? <hr className="mobileSeparator" /> : null}

          {showCurrenciesDropdown && (
            <CurrenciesDropdown
              classNames="navbarLang"
              currencies={this.props.listCurrencies}
              handleOnChange={currency => {
                this.props.changeCurrencyAction(currency);
              }}
              color={color}
              bgColor={background}
              hoverBkg={active}
              hoverColor={hover}
            />
          )}

          <LangDropdown
            classNames="navbarLang"
            langs={this.props.langs}
            handleOnChange={lang => comm.changeLanguage(lang)}
            color={color}
            bgColor={background}
            hoverBkg={active}
            hoverColor={hover}
          />
        </ul>
      </StyledSection>
    );
  };
}

const mapStateToProps = ({ siteConfiguration, global }) => ({
  settings: siteConfiguration.settings,
  langs: global.listLanguages,
  listCurrencies: global.listCurrencies,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
});

const mapDispatchToProps = dispatch => ({
  changeCurrencyAction: newCurrency => dispatch(changeCurrencyAction(newCurrency)),
});

const NavBarComp = withTranslation()(withRouter(NavBar));

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComp);
