// Global types
export const GLOBAL_SET_LIST_PLACES = 'global_set_places';
export const GLOBAL_SET_LIST_CATEGORIES = 'global_set_categories';
export const GLOBAL_SET_LIST_PROMOTIONS = 'global_set_promotions';
export const GLOBAL_SET_LIST_ADDITIONALS = 'global_set_additionals';
export const GLOBAL_SET_LIST_HOLIDAYS = 'global_set_holidays';
export const GLOBAL_SET_LIST_SCHEDULES = 'global_set_schedules';
export const GLOBAL_SET_LIST_LANGUAGES = 'global_set_languages';
export const GLOBAL_SET_LIST_CURRENCIES = 'global_set_currencies';
export const GLOBAL_SET_LIST_LANGUAGES_INFO = 'global_set_languages_info';
export const GLOBAL_SET_LIST_COUNTRIES = 'global_set_countries';
export const GLOBAL_SET_LIST_CITIES = 'global_set_cities';
export const GLOBAL_SET_LIST_DOCUMENT_TYPES = 'global_set_document_types';
export const GLOBAL_SET_LIST_FISCAL_CONDITIONS = 'global_set_fiscal_conditions';
export const GLOBAL_SET_GENERAL_SETTINGS = 'globla_set_general_settings';
export const GLOBAL_SET_SPINNER = 'global_set_spinner';
export const GLOBAL_SET_WEB_CURRENCY = 'global_set_web_currency';

export const CHANGE_CURRENCY = 'change_currency';

// Site Configuration types

export const SITE_CONFIGURATION_SET_SETTINGS = 'site_configuration_set_settings';

// Search Cars types
export const SEARCH_CARS_SET_CARS = 'search_cars_set_cars';
export const SEARCH_CARS_SET_PARAMS = 'search_cars_set_params';
export const SEARCH_CARS_SET_CHECKOUT = 'search_cars_set_checkout';

// Bookings
export const BOOKING_SET_BOOK = 'booking_set_book';
export const BOOKING_SET_ADDITIONALS = 'booking_set_additionals';
export const SET_BOOKING_PAY_INSTALLMENT = 'booking_set_pay_installment';
export const BOOKING_SET_CONFIGURATION = 'booking_set_configuration';
export const BOOKING_GET_ATTACHMENTS = 'booking_get_attachments';
export const BOOKING_ADD_ATTACHMENTS = 'booking_add_attachments';

// Infractions
export const SET_INFRACTION = 'set_infraction';
export const SET_INFRACTION_PAY_INSTALLMENT = 'infraction_set_pay_installment';

// User Account
export const LOG_IN = 'log_in';
export const LOG_OUT = 'log_out';
export const REGISTER = 'register';
export const GET_USER_BOOKINGS = 'get_user_bookings';
export const GET_USER_FILES = 'get_user_files';
export const UPLOAD_USER_FILES = 'upload_user_files';
export const GET_USER_INFRACTIONS = 'get_user_infractions';
export const UPDATE_PROFILE = 'update_profile';
export const RESET_PASSWORD = 'reset_password';
