import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import TranslatedLink from 'components/TranslatedLink';

class ContactList extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    horizontal: PropTypes.bool,
  };

  render() {
    const { items, t, className, horizontal } = this.props;

    return (
      <ul
        className={`list-unstyled contact-list ${className} ${horizontal &&
          'nav nav-pills d-inline-flex text-center'}`}
        style={{ justifyContent: 'center' }}
      >
        {items &&
          items.map((item, i) => (
            <li className={`${horizontal && 'nav-item text-center mr-3'}`} key={i}>
              {item.url && item.url.length > 0 ? (
                <TranslatedLink path={item.url}>
                  <i className={`${item.icon}`} /> {t(item.text)}
                </TranslatedLink>
              ) : (
                <span className={`${item.className || ''}`}>
                  <i className={`${item.icon}`} /> {t(item.text)}
                </span>
              )}
            </li>
          ))}
      </ul>
    );
  }
}
export default withTranslation()(ContactList);
