import React from 'react';
import { Title, Btn, Paragraph } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledTransbankSection } from './TransbankSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import { getTransBankUri } from 'services/api';
import Storage from 'utils/storage';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';

class TransbankSection extends BasePaymentSection {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        qsamount: 0,
        bookingId: '0',
        currencyCode: '',
        exchangeRate: 1,
      },
      formLoading: false,
      tokenLoading: true,
      url: null,
      token: null,
    };
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;

    const parseQs = queryString.parse(location.search);

    if (this.isPayingBooking() && bookings.confirmation != null) {
      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Transbank' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(bookings.confirmation.customerBalance % 1 === 0),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
        },
      }));
      this.GetToken(
        bookings.confirmation.id,
        0,
        this.getTotalToPay(bookings.confirmation.customerBalance % 1 === 0),
      );
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(infraction.amount % 1 === 0),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          branchOfficeId: infraction.booking?.deliveryPlace?.branchOfficeId || null,
          currencyCode: defaultCurrency.isoCode, //Infractions are paid in RMS System Currency.
          exchangeRate: 1,
        },
      }));
      this.GetToken(0, infraction.id, this.getInfractionTotalToPay(infraction.amount % 1 === 0));
    }
  }

  GetToken = (bookingId, infractionId, totalToPay) => {
    const { t, i18n, settings } = this.props;

    let transactionId = bookingId > 0 ? bookingId : infractionId;

    getTransBankUri(transactionId, totalToPay)
      .then(res => {
        Storage.setElement('transbanktoken', res.data.token);
        Storage.setElement('transbankprice', totalToPay);
        if (infractionId > 0) Storage.setElement('transbankisinfraction', 'true');
        this.setState(prevState => ({
          ...prevState,
          url: res.data.url,
          token: res.data.token,
          tokenLoading: false,
        }));
      })
      .catch(error => {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          { error: t('transBankGenericError') },
        );
      });
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getInfractionTotalToPay(useRound = false) {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    let result = 0;
    if (qsamount && Math.abs(infraction.amount) >= Math.abs(qsamount)) {
      result = Math.abs(qsamount);
    } else {
      result = infraction.amount;
    }
    return useRound ? Math.round(result) : result;
  }

  render() {
    const { t, title, subtitle, currentCurrency, defaultCurrency, webCurrency } = this.props;

    const { paymentInfo } = this.state;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    return (
      <StyledTransbankSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                {t('orden')} {paymentInfo.bookingId.toString().padStart(7, '0')}
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form action={this.state.url} method="post">
                <input type="hidden" name="token_ws" value={this.state.token} />
                <Btn
                  type="submit"
                  text={
                    this.state.formLoading || this.state.tokenLoading
                      ? t('loadingTransbank')
                      : t('transbankBtn')
                  }
                  disabled={this.state.formLoading || this.state.tokenLoading}
                  className="mt-5 transbank-pay_button"
                />
              </form>
            </div>
          </div>
        </div>
      </StyledTransbankSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(TransbankSection)));
