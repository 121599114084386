import React from 'react';
import { TranslatedComponent, Btn } from 'components/';
import { withTranslation } from 'react-i18next';

import style from 'styled-components';

const StyledRelatedRoutes = style.div`
    .routes .routes-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .routes-item {
        display: flex;
        flex: 1;
        flex-basis: 25%;
        max-width: 25%;
        align-items: center;
        text-align: center;
        flex-direction: column;

        .km{
            font-size: 1.5rem;
            margin-bottom: 0px;
            font-weight: bold;
            color: ${props => props.kmColor || props.theme.colors.secondary}
        }
        .location {
            font-size: 1rem;
            line-height: 1.1;
        }
    }
    @media only screen and (max-width: 800px) {
        .routes-item {
            max-width: 100%;
            .location{
                min-height: 40px;
            }
        }
    }
`;

class RelatedRoutes extends TranslatedComponent {
  render() {
    const { relatedRoutes, t, kmColor } = this.props;

    return (
      <StyledRelatedRoutes kmColor={kmColor}>
        <div className="container routes pt-5 pb-3">
          <div className="row">
            <div className="w-100">
              <h3 className="text-center w-100">{this.tt(relatedRoutes.title)}</h3>
              <p className="text-center w-100 mb-4">{this.tt(relatedRoutes.subtitle)}</p>
            </div>
            <div className="routes-container w-100">
              {relatedRoutes.routes.map(item => (
                <div className="routes-item mb-5 pl-1 pr-1">
                  <p className="km">{item.km}</p>
                  <p className="location mb-2">
                    {item.customLocation ? this.tt(item.location) : `${t('to')} ${item.location}`}
                  </p>
                  <Btn
                    text={relatedRoutes.btnText || t('seeRoute')}
                    link={item.maplink}
                    fontSize="14px"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </StyledRelatedRoutes>
    );
  }
}

export default withTranslation()(RelatedRoutes);
