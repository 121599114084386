import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as Comps from 'components';
import * as Sects from 'sections';
import * as Pages from 'pages';

import { getGatewayInformation } from 'services/api';
import {
  hasCredentials,
  getPayMethodCode,
  getConfigurationObject,
} from 'utils/PaymentMethodHelper';

const OnlinePaymentSection = ({ settings, history, bookings, infractions }) => {
  const [payMethodObject, setPayMethodObject] = useState(null);

  useEffect(() => {
    const initiate = async () => {
      const newPayMethodObject = getPayMethodConfiguration();
      const credentials = await checkForCredentials(newPayMethodObject);
      setPayMethodObject({ ...newPayMethodObject, credentials });
    };
    initiate();
  }, []);

  const findComponent = type => {
    let ComponentToRender = Pages[type];
    if (!ComponentToRender) ComponentToRender = Sects[type];
    if (!ComponentToRender) ComponentToRender = Comps[type];

    return ComponentToRender;
  };

  const getPayMethodConfiguration = () => {
    let payMethodObject;

    if (history.location.state?.paymentMethod) {
      const component = settings.sharedComponents.find(
        element => element.name === history.location.state.paymentMethod,
      );
      payMethodObject = component;
    } else {
      let paymentMethods = settings.sharedComponents.filter(
        element => element.paymentOption === true,
      );
      if (paymentMethods.length > 1) {
        payMethodObject = settings.sharedComponents.find(
          element => element.component === 'PaymentOptionsSection',
        );
      } else {
        payMethodObject = paymentMethods[0];
      }
    }

    return payMethodObject;
  };

  const checkForCredentials = async payMethodObject => {
    let branchOfficeId = null;

    if (bookings.confirmation) {
      branchOfficeId = bookings.confirmation.deliveryPlace.branchOfficeId;
    } else if (infractions.current) {
      branchOfficeId = infractions.current.booking?.deliveryPlace?.branchOfficeId || null;
    }
    if (bookings.confirmation && !branchOfficeId) return;

    const gatewayId = getPayMethodCode(payMethodObject);

    if (!gatewayId) return;

    if (!hasCredentials(payMethodObject)) {
      const response = await getGatewayInformation(branchOfficeId, gatewayId);
      if (!response.status === 200) return;

      const newCredentials = await getConfigurationObject(payMethodObject, response);

      return newCredentials;
    }
  };

  if (!payMethodObject) return null;

  const PaymentComponent = findComponent(payMethodObject.component);
  return <PaymentComponent {...payMethodObject} />;
};

const mapStateToProps = ({ bookings, infractions, siteConfiguration }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(OnlinePaymentSection)));
