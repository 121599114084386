import PropTypes from 'prop-types';

export const props = {
  items: PropTypes.array,
  className: PropTypes.string,
  logo: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  logoWidth: PropTypes.string,
  rightNav: PropTypes.any,
  variant: PropTypes.string,
  activeColor: PropTypes.string,
  rightBtnColor: PropTypes.string,
};
