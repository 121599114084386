import style from 'styled-components';

export const StyledCondicionesSectionModern = style.section`
	background: ${props => (props.background ? `url(${props.background}) no-repeat` : `white`)};
    min-height: 400px;
    padding-top:32px;
    width: 100%;
    
    .conditionsBG{
        background-color:#F2F5F7;
        border-radius:12px; 
    }

    .conditions-container-wrapper {
        padding: 1.5rem;
    }

	h2{  
        margin-bottom: 16px;
        text-align:left;
    }
    h1{  
        margin-bottom: 8px;
    }
    p{
      font-size: 16px;
    }
    .fa-angle-right{
        color: ${props => props.theme.colors.primary}
    }
   
    .list-unstyled li{
        font-size:14px;
        line-height:1.8rem;
    }
    .condiciones{
        padding:10px 40px;
    }

    .imgFaqs{ 
        .img{ 
            span {
                display:flex!important;
                justify-content:center!important;

                img {
                padding-left:0px;
                margin-bottom:50px;
                }
            }
        }
    }

    .card {
        background:#F4F5F6 !important;
        border-radius: 8px !important;
        border: none !important;
        margin-bottom: 10px;
        box-shadow:0 1px 3px rgb(29 99 71 / 5%), 0 2px 5px rgb(29 99 71 / 4%);
    }

    .card:hover {
        box-shadow:0 1px 3px rgb(29 99 71 / 8%), 0 2px 5px rgb(29 99 71 / 8%);
    }

    .card-header {
        color:#2E2D35;
        font-weight:500;
    }
    .card-header:hover {
        color:${props => props.theme.colors.primary};
        font-weight:500;
    }

    .card-header .fa-info-circle {
        display:none;
    }

    .card-body {
        padding: 0 20px !important;
    }

    .card-body p {
        font-size: 15px !important;
    }

    @media (max-width: 767px){

  .conditionsBG{
        background-color:#F2F5F7;
        padding:0;
        border-radius:12px;
    }

    .p-4{
        padding:0 !important;
    }

    .imgFaqs img{
        padding-left:0;
        margin:40px;
     }
 
    }

    @media(max-width:767px){
        .conditions-container-wrapper {
            padding: 0;
        }
    
    }

`;
