import style from 'styled-components';

export const StyledHeroBannerModern = style.section`
	background: ${props => (props.background ? `url(${props.background}) no-repeat` : `#ccc`)};
	background-size: cover !important;
  background-attachment: initial !important;
	padding: 50px 20px;
  
  
  .home-form{
    min-width:85%;
    margin:0 auto;

    @media only screen and (max-width: 766px) {
    top:0
    }
    
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      top:0
      }
  }

  .home-form {
    
    @media only screen and (max-width: 1800px)
   {
        flex: auto !important;
        max-width: 100%;
    }
    @media only screen and (min-width: ${props => props.theme.screens.xl}){
      max-width: 50%;
    }
  }

  .quoted h3 {
    font-weight: ${props => props.fontWeight || 400};
    font-size: 40px !important;
    color: ${props => props.titleColor || props.theme.font.colors.secondary};
    text-transform: none;
    margin-left: 10px;

    @media only screen and (max-width: 766px) {
      font-size: 29px !important;
    	margin-bottom: 10px;
    	margin-top: 10px;
    }
  }
`;
