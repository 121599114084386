import style from 'styled-components';

export const StyledServiciosSectionModern = style.section`
  background: #FFF !important;
  min-height: 600px;
  width: 100%;

  .service-description-button{
    font-size: 0.8rem;
    text-decoration: none!important;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.secondary}!important;

    &:hover, & > i { 
      text-decoration: none!important; 
    }
  }

  .see-less {
    padding-left: 0px;
  }

  .description {
    min-height: 120px;
  }

  button {
    margin-top: 10px;
  }

  .group-title{
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 20px;
    padding: .75rem 1.25rem;
    display:none;
  
    h5{
        font-size: 17px;
        font-weight: 700;
        color: ${props => props.theme.font.colors.primary};
    } 

    h1{
      font-size: 17px;
      font-weight: 700;
      color: ${props => props.theme.font.colors.primary};
  } 
  }

`;
