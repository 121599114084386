import React from 'react';
import PropTypes from 'prop-types';
import { Btn, TextInput, Icon } from 'components/';
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { sendCompanyContact } from 'services/api';
import { StyledQuoteForm } from './QuoteForm.styled';
import { withRouter } from 'react-router';
import { CheckboxInput } from '/components';

class QuoteForm extends React.Component {
  static propTypes = {
    items: PropTypes.array,
  };

  constructor(props) {
    super(props);

    const { t, items } = props;
    this.submit = this.submit.bind(this);
    this.recaptchaVerify = this.recaptchaVerify.bind(this);
    this.state = {
      companyInfo: {
        formType: 'CompanyContact',
      },
      ReCAPTCHAError: false,
      loading: false,
      isVerified: false,
      step: 0,
      captcha: null,
      ReCAPTCHAErrorMessage: null,
      formErrors: {
        companyName: null,
        companyId: null,
        country: null,
        businessAddress: null,
        fullname: null,
        email: null,
        phone: null,
        termsConditions: null,
      },
    };

    this.companyInfoFormSchema = yup.object().shape({
      companyName: yup.string().required(t('inputIsRequired', { field: t('companyName') })),
      companyId: yup
        .number()
        .required(
          t('inputIsRequired', { field: t('companyId', { companyIdType: items.companyIdType }) }),
        ),
      country: yup.string().required(t('inputIsRequired', { field: t('country') })),
      businessAddress: yup.string().required(t('inputIsRequired', { field: t('businessAddress') })),
      fullname: yup.string().required(t('inputIsRequired', { field: t('enterFullName') })),
      email: yup
        .string()
        .email()
        .required(t('inputIsRequired', { field: t('enterEmail') })),
      phone: yup
        .string()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          t('invalidPhone'),
        ),
      termsConditions: yup
        .boolean()
        .required(t('mustAcceptTermAndConditions'))
        .oneOf([true], t('mustAcceptTermAndConditions')),
    });

    this.formRef = React.createRef(null);
    this.ReCAPTCHARef = React.createRef();
  }

  recaptchaVerify(res) {
    this.setState({ isVerified: true, captcha: res });
  }

  onFullNameChange = fullname => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, fullname },
      formErrors: { ...prevState.formErrors, fullname: null },
    }));
  };

  onCompanyNameChange = companyName => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, companyName },
      formErrors: { ...prevState.formErrors, companyName: null },
    }));
  };

  onCompanyIdChange = companyId => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, companyId },
      formErrors: { ...prevState.formErrors, companyId: null },
    }));
  };

  onCountryChange = country => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, country },
      formErrors: { ...prevState.formErrors, country: null },
    }));
  };

  onBusinessAddressChange = businessAddress => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, businessAddress },
      formErrors: { ...prevState.formErrors, businessAddress: null },
    }));
  };

  onMailChange = email => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, email },
      formErrors: { ...prevState.formErrors, email: null },
    }));
  };

  onTelephoneChange = phone => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, phone },
      formErrors: { ...prevState.formErrors, phone: null },
    }));
  };

  onTermsConditionsChange = termsConditions => {
    this.setState(prevState => ({
      ...prevState,
      companyInfo: { ...prevState.companyInfo, termsConditions },
      formErrors: { ...prevState.formErrors, termsConditions: null },
    }));
  };

  submit = event => {
    event.preventDefault();
    const companyInfo = this.state.companyInfo;

    this.companyInfoFormSchema
      .validate(companyInfo, { abortEarly: false })
      .then(valid => {
        if (valid && this.state.isVerified) {
          let parsedContact = this.state.companyInfo;
          parsedContact.reCAPTCHA = this.state.captcha;

          this.setState({ loading: true });
          sendCompanyContact(parsedContact)
            .then(res => {
              this.setState({ step: 1 });
            })
            .catch(error => {
              if (
                error.response == null ||
                error.response.data == null ||
                error.response.data.code !== 100
              ) {
                this.setState({ step: 2 });
              } else {
                this.setState({
                  loading: false,
                  ReCAPTCHAErrorMessage: error.response.data.message,
                  ReCAPTCHAError: true,
                });
                this.ReCAPTCHARef.current.reset();
              }
            });
        }
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });

    return;
  };

  QuoteForm() {
    const { t, className, i18n, items } = this.props;
    if (!items) return null;

    return (
      <StyledQuoteForm className={`${className || ''}`}>
        <div ref={this.formRef}>
          <TextInput
            inputClass="form-control"
            className="form-control mb-3"
            type="text"
            placeholder={t('companyName')}
            error={this.state.formErrors.companyName}
            onChange={this.onCompanyNameChange}
            options={{ autoComplete: t('companyName') }}
          />
          <div className="row mb-3">
            <div className="col">
              <TextInput
                inputClass="form-control"
                type="text"
                className="form-control"
                error={this.state.formErrors.companyId}
                placeholder={t('companyId', { companyIdType: items.companyIdType })}
                onChange={this.onCompanyIdChange}
                options={{ autoComplete: t('companyId', { companyIdType: items.companyIdType }) }}
              />
            </div>
            <div className="col">
              <TextInput
                inputClass="form-control"
                type="text"
                className="form-control"
                error={this.state.formErrors.country}
                placeholder={t('country')}
                onChange={this.onCountryChange}
                options={{ autoComplete: t('country') }}
              />
            </div>
          </div>
          <TextInput
            inputClass="form-control"
            type="text"
            className="form-control"
            error={this.state.formErrors.businessAddress}
            placeholder={t('businessAddress')}
            onChange={this.onBusinessAddressChange}
            options={{ autoComplete: t('businessAddress') }}
          />
          <TextInput
            inputClass="form-control"
            className="form-control mb-3"
            type="text"
            placeholder={t('fullname')}
            error={this.state.formErrors.fullname}
            onChange={this.onFullNameChange}
            options={{ autoComplete: t('fullname') }}
          />
          <div className="row mb-3">
            <div className="col">
              <TextInput
                inputClass="form-control"
                type="mail"
                className="form-control"
                error={this.state.formErrors.email}
                placeholder={t('mail')}
                onChange={this.onMailChange}
                options={{ autoComplete: t('mail') }}
              />
            </div>
            <br />
            <div className="col">
              <TextInput
                inputClass="form-control"
                type="text"
                className="form-control"
                error={this.state.formErrors.phone}
                placeholder={t('phone')}
                onChange={this.onTelephoneChange}
                options={{ autoComplete: t('phone') }}
              />
            </div>
          </div>
          <div className="form-group conditions">
            <CheckboxInput
              error={this.state.formErrors.termsConditions}
              onChange={this.onTermsConditionsChange}
              checked={this.state.companyInfo.termsConditions}
              text={<span>{t('acceptTermConditions')}</span>}
            />
            <div className="terms-conditions">{items.termsConditionsText}</div>
          </div>
          <ReCAPTCHA
            // TODO: take this from site configuration
            sitekey="6LePeSUTAAAAANnXGXzC52BIeAMN8f7MHhFX86oO"
            onChange={this.recaptchaVerify}
            className="recaptcha"
            ref={this.ReCAPTCHARef}
            hl={i18n.language}
          />
          {this.state.ReCAPTCHAError && (
            <p style={{ color: '#FF0000' }}>{this.state.ReCAPTCHAErrorMessage}</p>
          )}
          <Btn
            onClick={this.submit}
            disabled={!this.state.isVerified || this.state.loading}
            text={this.state.loading ? t('sending') : t('send')}
          />
        </div>
      </StyledQuoteForm>
    );
  }

  errorMessage() {
    const { t } = this.props;
    return (
      <div className="col-md-8 offset-md-2 text-center">
        <Icon className="fas fa-times-circle display-3 mb-5 text-danger"></Icon>
        <h4>{t('contactFormFail')}</h4>
      </div>
    );
  }

  successMessage() {
    const { t } = this.props;
    return (
      <div className="col-md-8 offset-md-2 text-center">
        <Icon className="fas fa-check-circle display-3 mb-5 text-success"></Icon>
        <h4>{t('contactFormSucces')}</h4>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    if (this.state.step === 0) {
      return this.QuoteForm();
    } else if (this.state.step === 1) {
      const thanksUrl = this.props.history.location.pathname + '/' + t('graciasUrl');
      window.history.pushState('', '', thanksUrl);

      return this.successMessage();
    } else {
      const errorUrl = this.props.history.location.pathname + '/error';
      window.history.pushState('', '', errorUrl);
      return this.errorMessage();
    }
  }
}

export default withRouter(withTranslation()(QuoteForm));
