import PropTypes from 'prop-types';

export const props = {
  type: PropTypes.string,
  text: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  fontSize: PropTypes.any,
  noMedia: PropTypes.bool,
  lineHeight: PropTypes.string,
};
