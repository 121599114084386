import React from 'react';
import { props, defaultProps } from './BookingSuccess.props';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { StyledBookingSuccess } from './BookingSuccess.styled';
import { Title, Paragraph, Icon } from 'components/';
import withSettingsContext from 'SettingsContext';
import { Col, Row } from 'react-bootstrap';

const BookingSuccess = ({ profile, whenLoggedAsCompany, whenLoggedAsUser, defaultContent }) => {
  const getTitleText = () => {
    let title = defaultContent.title;

    if (profile) {
      if (profile.isCompany) {
        if (whenLoggedAsCompany && whenLoggedAsCompany.title) {
          title = whenLoggedAsCompany.title;
        }
      } else {
        if (whenLoggedAsUser && whenLoggedAsUser.title) {
          title = whenLoggedAsUser.title;
        }
      }
    }
    return title;
  };

  const getSubtitleText = () => {
    let subtitle = defaultContent.subtitle;

    if (profile) {
      if (profile.isCompany) {
        if (whenLoggedAsCompany && whenLoggedAsCompany.subtitle) {
          subtitle = whenLoggedAsCompany.subtitle;
        }
      } else {
        if (whenLoggedAsUser && whenLoggedAsUser.subtitle) {
          subtitle = whenLoggedAsUser.subtitle;
        }
      }
    }
    return subtitle;
  };

  const getMessageText = () => {
    let message = defaultContent.message;

    if (profile) {
      if (profile.isCompany) {
        if (whenLoggedAsCompany && whenLoggedAsCompany.message) {
          message = whenLoggedAsCompany.message;
        }
      } else {
        if (whenLoggedAsUser && whenLoggedAsUser.message) {
          message = whenLoggedAsUser.message;
        }
      }
    }
    return message;
  };

  return (
    <StyledBookingSuccess className="mt-5 pt-5">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <Icon className="fas fa-check-circle display-3 mb-5 text-success" />
          <Title type="h2" text={getTitleText()} />
          <Paragraph weight={300} fontSize="1.25rem" className="mb-5" text={getSubtitleText()} />
          <Paragraph text={getMessageText()} />
        </Col>
      </Row>
    </StyledBookingSuccess>
  );
};

BookingSuccess.propTypes = props;
BookingSuccess.defaultProps = defaultProps;

export default withSettingsContext(withRouter(withTranslation()(BookingSuccess)));
