import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { START_DATE, END_DATE } from 'react-dates/constants';

export const props = {
  startDateId: PropTypes.string.isRequired,
  endDateId: PropTypes.string.isRequired,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  focusedInput: PropTypes.oneOf([START_DATE, END_DATE]),
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  showDefaultInputIcon: PropTypes.bool.isRequired,
  block: PropTypes.bool.isRequired,
  showInputs: PropTypes.bool,
  noBorder: PropTypes.bool.isRequired,
  inputIconPosition: PropTypes.string,
  settings: PropTypes.shape({}),
};

export const defaultProps = {
  block: false,
  noBorder: false,
  showDefaultInputIcon: false,
  showInputs: true,
};
