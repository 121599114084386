import style from 'styled-components';
import { getBackgroundColor, getBorderColor, getColor } from './Btn.styled';

export const StyledBtnModern = style.button`
  display: flex;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  line-height: 1;
  border-radius: ${props => props.borderRadius || '20'};
  padding-top: ${props => props.padding || '7px'};
  padding-bottom: ${props => props.padding || '7px'};
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border-color:  ${({ secondary, disabled, borderColor, background, theme }) =>
    getBorderColor(secondary, disabled, borderColor, background, theme)};
  border-width: ${({ secondary, borderWidth }) => (borderWidth || secondary ? '1.5px' : 'inherit')};
  background: ${({ secondary, disabled, background, theme }) =>
    getBackgroundColor(secondary, disabled, background, theme)};
  font-weight: 700;
  font-size: ${props => props.fontSize || '15px'};
  text-transform: ${props => props.textTransform || 'none'};
  color:  ${({ color, secondary, disabled, background, theme }) =>
    getColor(color, secondary, disabled, background, theme)};


  &:disabled { 
    color: ${({ color, secondary, disabled, background, theme }) =>
      getColor(color, secondary, disabled, background, theme)};
  }
  
  &:not([disabled]):hover {
    background-color: ${props =>
      props.disabled
        ? props.theme.colors.disabled
        : props.hoverColor || props.theme.colors.buttonPrimaryBgHover};
    color: ${props =>
      props.theme.colors.buttonPrimaryColorHover || props.theme.colors.buttonPrimaryColor};    
    border-color: ${props =>
      props.disabled
        ? props.theme.colors.disabled
        : props.hoverColor || props.theme.colors.buttonPrimaryBorder};
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }

  .customButton {
    flex-direction: column;
    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .button-icon{
        margin: 0px 5px;
      }
    }
  }

  .second-btn{
    .CheckoutLink-icon {
      margin-right: 0.5rem;
      margin-left: -0.5rem;
    }
  }

  .loading {
    color: ${props => props.iconColor || props.theme.font.colors.secondary};
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) and (min-width: ${props =>
  props.theme.screens.md}){
    padding: 7px 13px;
    font-size: 13px;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xxl}) and (min-width: ${props =>
  props.theme.screens.xl}){
    font-size: 0.8rem;
    padding: 7px 15px;
  }

  @media only screen and (max-width: 1740px) and (min-width: ${props => props.theme.screens.xl}){
    white-space: pre-wrap;
    padding: 7px 15px;
  }
`;
