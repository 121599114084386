import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string.isRequired,
  titleSecondLine: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  listPromotions: PropTypes.array,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  interval: PropTypes.number,
  titleStyle: PropTypes.string,
  titleWeight: PropTypes.string,
  buttonBkg: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonBkgHover: PropTypes.string,
  buttonColorHover: PropTypes.string,
  titleTransform: PropTypes.string,
  secondaryBtn: PropTypes.bool,
};
