import PropTypes from 'prop-types';

export const props = {
  date: PropTypes.object,
  height: PropTypes.string,
  id: PropTypes.string.isRequired,
  numberOfMonths: PropTypes.number,
  onDateChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  showDefaultInputIcon: PropTypes.bool,
  settings: PropTypes.shape({}),
};

export const defaultProps = {
  numberOfMonths: 1,
  verticalHeight: 540,
  showDefaultInputIcon: false,
  noBorder: false,
  block: false,
  settings: {},
};
