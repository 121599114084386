import React from 'react';
import { withTranslation } from 'react-i18next';
import { StyledBookingStatus } from './BookingStatus.styled';

function BookingStatus({ t, status }) {
  return (
    <StyledBookingStatus status={status} className="booking-status">
      <span />
      {t(`bookingStatus.${status}`)}
    </StyledBookingStatus>
  );
}
export default withTranslation()(BookingStatus);
