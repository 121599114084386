import style from 'styled-components';
import { INPUT_BORDER_COLOR } from 'styled/constants';

export const StyledInput = style.div`
  .input-wrapper{
    border-width: ${props => props.borderWidth || '1px'};
    border-style: solid;
    border-color: ${props =>
      props.error ? props.theme.colors.alert : props.borderColor || INPUT_BORDER_COLOR};
    display: flex;
    width: 100%;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1em;
    color: ${props => props.color || props.theme.font.colors.secondary};
    .CalendarDay__selected{
      background-color: ${props => props.color || props.theme.colors.buttonPrimaryBg};
      border-color: ${props => props.color || props.theme.colors.buttonPrimaryBg};
    }
    &.small-input-padding{
     height: 40px;
    }
    .DateInput_input__focused {
        border-bottom-color: ${props => props.color || props.theme.colors.buttonPrimaryBg};
    }
    span {
        display: flex;
        width: auto;
        height: 100%;
        color: ${props => props.iconColor || props.theme.colors.buttonPrimaryBg};
        justify-content: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 5px;
        font-size: ${props => props.fontSize || '1.2em'}
     }
    .date-wrapper {
        padding-left: 5px;
        width: 100%;
        height: 100%;
        border: none;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
  }
  .error{
    width: 100%;
    padding-left: 10px;
    color: ${props => props.theme.colors.alert};
    font-style: italic
  }
  .label{
    font-weight: bold;
    margin-bottom: 5px;
  }
`;
