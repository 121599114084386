import React from 'react';
import { PropTypes } from 'prop-types';
import style from 'styled-components';

export const StyledSeparator = style.div`
    width:100%;

    hr{
        width:100%;
        color: ${props => (props.color ? props.color : props.theme.colors.separator)}
    }
`;

export default class SeparatorComponent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  render() {
    return (
      <StyledSeparator color={this.props.color} className={`row ${this.props.className}`}>
        <hr />
      </StyledSeparator>
    );
  }
}
