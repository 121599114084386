import PropTypes from 'prop-types';

export const props = {
  logoUrl: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  hours: PropTypes.array,
  socialNetworks: PropTypes.array,
  color: PropTypes.string,
  logoHeight: PropTypes.string,
};
