import style from 'styled-components';

export const StyledHeroBanner = style.section`
	background: ${props => (props.background ? `url(${props.background}) no-repeat` : `#ccc`)};
	background-size: cover;
	min-height: 600px;
	padding: 30px 20px;
  
  .home-form {
    
    @media only screen and (max-width: 1800px)
   {
        flex: auto !important;
        max-width: fit-content;
    }
    @media only screen and (min-width: ${props => props.theme.screens.xl}){
      max-width: 50%;
    }
  }

  .quoted h3 {
    font-weight: ${props => props.fontWeight || 300};
    font-size: ${props => props.fontSize || '30px'};
    color: ${props => props.titleColor || props.theme.font.colors.secondary};
    text-transform: none;
    margin-bottom: 15px;
    margin-left: 10px;

    @media only screen and (max-width: 766px) {
      font-size: 23px;
    	margin-bottom: 10px;
    	margin-top: 10px;
    }
  }
`;
