import styled from 'styled-components';

export const StyledCurrenciesDropdown = styled.div`
  color: ${props => props.theme.font.colors.primary};
  background: ${({ bgColor }) => bgColor};
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  width: auto;
  height: 100%;
  display: inline-block;
  position: relative;
  font-size: 12px;
  margin-left: 0.5rem;

  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 0;
  }

  @media (min-width: ${props => props.theme.screens.md}) {
    &:hover,
    &.active {
      background-color: ${props => props.bgColor || props.theme.colors.secondary};
    }

    &:hover span.currentCurrency {
      color: ${props => props.theme.font.colors.secondary};
    }
  }

  .currentCurrency {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media (max-width: ${props => props.theme.screens.md}) {
      justify-content: flex-start;
    }

    &::after {
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }

    .fas.fa-dollar-sign {
      display: none;
      color: ${props => props.theme.font.colors.primary};
      margin-right: 10px;

      @media (max-width: ${props => props.theme.screens.md}) {
        display: initial;
      }
    }

    .baseLang {
      @media (min-width: 0px) and (max-width: ${props => props.theme.screens.sm}) {
        display: initial;
      }
    }

    .baseLang {
      white-space: nowrap;
      @media (min-width: ${props => props.theme.screens.md}) {
        display: block;
      }
    }
  }

  .dropdownOptions {
    display: none;
    font-size: 12px;
    position: absolute;
    margin-top: 16px;
    z-index: 99999;
    left: 0;
    min-width: 5rem;
    padding: 0;
    flex-wrap: wrap;
    color: ${props => props.theme.font.colors.primary};
    overflow: hidden;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 3px 8px #00000036;
    background-color: ${({ theme }) => theme.colors.bg};

    &.open {
      display: flex;
    }

    @media (max-width: ${props => props.theme.screens.md}) {
      border-radius: 0.25rem;
    }

    .dropdownItem {
      padding: 5px 10px;
      cursor: pointer;
      width: 100%;

      .fa-dollar-sign {
        color: ${props => props.theme.font.colors.primary};
        margin-right: 10px;
        display: none;

        @media (max-width: ${props => props.theme.screens.md}) {
          display: initial;
        }
      }

      &.active {
        background-color: ${props => props.hoverBkg || props.theme.colors.secondary};
        color: ${props => props.hoverColor || props.theme.font.colors.secondary};
      }

      @media (min-width: ${props => props.theme.screens.md}) {
        &:hover {
          background-color: ${props => props.hoverBkg || props.theme.colors.secondary};
          color: ${props => props.hoverColor || props.theme.font.colors.secondary};
        }
      }

      .baseLang {
        @media (min-width: 0px) and (max-width: ${props => props.theme.screens.sm}) {
          display: initial;
        }
      }

      .baseLang {
        @media (min-width: ${props => props.theme.screens.md}) {
          display: block;
        }
      }
    }
  }
`;
