import PropTypes from 'prop-types';

export const props = {
  booking: PropTypes.object,
  onCancel: PropTypes.func,
};

export const defaults = {
  onCancel: () => {},
};
