import { SET_INFRACTION, SET_INFRACTION_PAY_INSTALLMENT } from 'utils/types';
import { getInfraction } from 'services/api';

export const getCustomerInfraction = (infractionId, customerId) => async dispatch => {
  try {
    const { data } = await getInfraction(infractionId, customerId);

    return Promise.resolve(
      dispatch({
        type: SET_INFRACTION,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setInfractionInstallment = installment => async dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_INFRACTION_PAY_INSTALLMENT,
      payload: installment,
    }),
  );
};
