import style from 'styled-components';

export const StyledAccordionCardModern = style.section`
    background: ${props => (props.background ? `url(${props.background}) no-repeat` : `white`)};
    i.fa-info-circle {
        color: ${props => props.theme.font.colors.third}
    }
    i.fa-angle-down{
        color:#ccc;
    }.card {
        background:#FAFBFC;
        border-radius: 8px;
        border: none;
        margin-bottom: 10px;
        box-shadow:0 1px 3px rgb(29 99 71 / 5%), 0 2px 5px rgb(29 99 71 / 4%);
    }

    .card:hover {
        box-shadow:0 1px 3px rgb(29 99 71 / 8%), 0 2px 5px rgb(29 99 71 / 8%);
    }
    
    h5 {
        font-size: 15px;
    }
    .card-header {
        color:#2E2D35;
        font-weight:500;
        background: none;
        border: none;
        cursor:pointer;
        display: flex;

        .accordion-title {
        flex-direction: row;
        display: flex;
        width: 100%;
        justify-content: space-between;
        }
    }
    .card-header:hover {
        color:${props => props.theme.colors.primary};
        font-weight:500;
    }

    .accordion-title:hover {
        div{
            color:${props => props.theme.colors.primary};
            font-weight:500;
        }
    }

    .card-header .fa-info-circle {
        display:none;
    }

    .card-body {
        padding: 0 20px !important;
    }

    .card-body p {
        font-size: 15px !important;
    }

    @media (max-width: ${({ theme }) => theme.screens.lg}) {
        .card-body {
            padding: 0px !important;
        } 
    }

`;
