export const getPriceWithExchangeRate = (price, currentCurrency, defaultCurrency, webCurrency) => {
  if (currentCurrency == null || defaultCurrency == null || webCurrency == null) return price;

  //selected currency (currentCurrency) is the same as api user currency (webCurrency) -> not apply exchange rate.
  if (currentCurrency?.isoCode == webCurrency?.isoCode) return price;
  //selected currency, is default currency -> apply web currency exchange rate.
  else if (currentCurrency?.isoCode == defaultCurrency?.isoCode)
    return price * (1 / webCurrency?.exchangeRate);
  //selected currency is different from default and web currency -> convert first to default, and then apply current exchange rate.
  else return price * (1 / webCurrency?.exchangeRate) * currentCurrency?.exchangeRate;
};

export const applyCurrencyExchangeRate = (currentCurrency, webCurrency) => {
  const applyCurrencyExchange =
    currentCurrency != null &&
    webCurrency != null &&
    currentCurrency?.isoCode !== webCurrency?.isoCode;
  return applyCurrencyExchange;
};
