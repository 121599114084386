import React from 'react';
import { withTranslation } from 'react-i18next';
import TranslatedComponent from 'components/TranslatedComponent';
import Title from 'components/Title/index';
import { Loading } from '../index';
import { props } from './CustomLoading.props';

class CustomLoadingComponent extends TranslatedComponent {
  render() {
    const { loadingMessage, loadingGif, background, className } = this.props;

    if (loadingGif || loadingMessage) {
      return (
        <div className="row" align="center" style={{ backgroundColor: background }}>
          {loadingGif && (
            <div className="col-md-12 mb-5">
              <img
                className={className}
                width="250"
                height="250"
                src={this.tt(loadingGif, true)}
                alt={this.tt('loading', true)}
              />
            </div>
          )}
          {loadingMessage && (
            <div className="col-md-12 mb-5 pb-5">
              <Title
                type="h2"
                forceTranslate
                text={loadingMessage}
                weight="900"
                fontSize={30}
                className={`${loadingMessage != null ? '' : 'd-none'}`}
              />
            </div>
          )}
        </div>
      );
    } else {
      return <Loading></Loading>;
    }
  }
}

CustomLoadingComponent.propTypes = props;

export default withTranslation()(CustomLoadingComponent);
