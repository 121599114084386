import React from 'react';
import { Btn, TextInput } from 'components/';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import queryString from 'query-string';
import * as yup from 'yup';
import { cancelReservation } from 'services/api';
import { history } from '../../store';
import { tt, tlink } from 'utils/translationHelper';
import { withRouter } from 'react-router-dom';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

class CancelForm extends React.Component {
  constructor(props) {
    super(props);

    const { t } = props;
    this.handleCancel = this.handleCancel.bind(this);
    this.recaptchaVerify = this.recaptchaVerify.bind(this);
    this.state = {
      userInfo: {
        tcAccept: false,
      },
      ReCAPTCHAError: false,
      loading: false,
      isVerified: false,
      captcha: null,
      ReCAPTCHAErrorMessage: null,
      formErrors: {
        lastName: null,
        reservationNumber: null,
      },
    };

    this.userInfoFormSchema = yup.object().shape({
      lastName: yup.string().required(t('inputIsRequired', { field: t('lastname') })),
      reservationNumber: yup
        .number()
        .required(t('inputIsRequired', { field: t('enterReservationNumber') })),
    });

    this.formRef = React.createRef(null);
    this.ReCAPTCHARef = React.createRef();
  }

  static propTypes = {
    t: PropTypes.func,
    className: PropTypes.string,
  };

  componentDidMount() {
    const { location } = this.props;
    const parseQs = queryString.parse(location.search);
    if (parseQs.lastName && parseQs.reservationNumber) {
      this.setState({
        userInfo: {
          lastName: parseQs.lastName,
          reservationNumber: parseQs.reservationNumber,
          isVerified: false,
        },
      });
    }
  }

  onLastNameChange = lastName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, lastName },
      formErrors: { ...prevState.formErrors, lastName: null },
    }));
  };

  onReservationNumberChange = reservationNumber => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, reservationNumber },
      formErrors: { ...prevState.formErrors, reservationNumber: null },
    }));
  };

  handleCancel = event => {
    event.preventDefault();
    const { t, i18n, settings } = this.props;
    const userInfo = this.state.userInfo;

    this.userInfoFormSchema
      .validate(userInfo, { abortEarly: false })
      .then(valid => {
        if (valid && this.state.isVerified) {
          const parsedCancel = {
            BookingId: this.state.userInfo.reservationNumber,
            Lastname: this.state.userInfo.lastName,
            Captcha: this.state.captcha,
          };
          this.setState({ loading: true });
          cancelReservation(parsedCancel)
            .then(res => {
              history.push(
                `${tlink(
                  '__Routes.cancelSuccess',
                  t,
                  i18n,
                  null,
                  settings.configurations.langConfig,
                )}`,
              );
            })
            .catch(error => {
              if (
                error.response == null ||
                error.response.data == null ||
                error.response.data.code !== 100
              ) {
                let errorMessage =
                  error.response && error.response.data
                    ? error.response.data.message
                    : tt('__PageCancelarReserva.genericError', t);
                history.push(
                  `${tlink(
                    '__Routes.cancelFailed',
                    t,
                    i18n,
                    null,
                    settings.configurations.langConfig,
                  )}`,
                  { error: errorMessage },
                );
              } else {
                this.setState({
                  loading: false,
                  ReCAPTCHAErrorMessage: error.response.data.message,
                  ReCAPTCHAError: true,
                });
                this.ReCAPTCHARef.current.reset();
              }
            });
        }
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });

    return;
  };

  recaptchaVerify(res) {
    this.setState({ isVerified: true, captcha: res });
  }

  getBtnStyles() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'pl-5 pr-5 pt-3 pb-3';
    }
    return '';
  }

  render() {
    const { t, className, i18n } = this.props;

    const btnStyles = this.getBtnStyles();

    return (
      <form className={`${className || ''}`}>
        <div className="row mb-3" ref={this.formRef}>
          <TextInput
            inputClass="form-control"
            wrapperClass="col-md-12"
            placeholder={t('lastname')}
            error={this.state.formErrors.lastName}
            onChange={this.onLastNameChange}
            value={this.state.userInfo.lastName}
          />
          <TextInput
            inputClass="form-control"
            wrapperClass="col-md-12"
            placeholder={t('bookingnumber')}
            error={this.state.formErrors.reservationNumber}
            onChange={this.onReservationNumberChange}
            type="number"
            value={this.state.userInfo.reservationNumber}
          />
        </div>
        <ReCAPTCHA
          // TODO: take this from site configuration
          sitekey="6LePeSUTAAAAANnXGXzC52BIeAMN8f7MHhFX86oO"
          onChange={this.recaptchaVerify}
          className="recaptcha"
          ref={this.ReCAPTCHARef}
          hl={i18n.language}
        />
        {this.state.ReCAPTCHAError && (
          <p style={{ color: '#FF0000' }}>{this.state.ReCAPTCHAErrorMessage}</p>
        )}
        <Btn
          className={btnStyles}
          onClick={this.handleCancel}
          disabled={!this.state.isVerified || this.state.loading}
          text={this.state.loading ? t('sending') : t('send')}
        />
      </form>
    );
  }
}

export default withSettingsContext(withTranslation()(withRouter(CancelForm)));
