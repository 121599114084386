import styled from 'styled-components';

export const StyledReviewsCarousel = styled.div`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.primary};
  background-image: ${({ bgImage }) => (bgImage ? ` url(${bgImage})` : 'none')};
  background-size: ${({ bgSize }) => bgSize || 'cover'};
  background-position: ${({ bgPosition }) => bgPosition || 'center'};
  background-attachment: ${({ bgAttachment }) => bgAttachment || 'fixed'};
  background-repeat: ${({ bgRepeat }) => bgRepeat || 'no-repeat'};

  .item-wrapper {
    width: 100%;
    display: flex;
    min-height: ${({ height }) => height || '200px'};
    margin: ${({ itemMargin }) => itemMargin || '2rem 0'};
    justify-content: center;
    align-items: center;
  }

  .carousel-caption {
    position: inherit;
    max-width: 750px;
    margin: auto;

    h3 {
      font-size: ${({ titleSize }) => titleSize || '1.3rem'};
      color: ${({ titleColor }) => titleColor || 'white'};
    }

    p {
      font-size: ${({ descriptionSize }) => descriptionSize || '1rem'};
      color: ${({ descriptionColor }) => descriptionColor || 'white'};
      margin-bottom: 0;
    }
  }

  .stars-wrapper {
    margin-bottom: 1rem;

    i {
      font-size: ${({ iconSize }) => iconSize || '13px'};
      margin: 0 5px;
      color: ${({ iconColor }) => iconColor || 'gold'};
    }
  }

  @media (max-width: ${({ theme }) => theme.screens.lg}) {
    .carousel-caption {
      max-width: 70%;
    }
  }
`;
