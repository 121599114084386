import styled from 'styled-components';

export const StyledModernMyAccountNavLink = styled.div`
  display: flex;
  width: 150px;
  margin: 0 10px;

  #my-account-dropdown {
    width: 100%;
  }

  .login-button {
    border: ${({ theme }) => `1px solid ${theme.font.colors.primary}`};
    border-radius: 8px;
    color: ${({ theme }) => theme.font.colors.primary};
    background: white;
    padding: 7px 16px;
    transition: all 0.3s;
    margin-left: 10px;
    font-size: 0.9rem;
    white-space: nowrap;

    &:hover {
      color: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.secondary};
      border-color: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.secondary};
    }
  }

  div.my-account_mobile {
    margin: 0px 0px 5px 0px;

    .my-account-button {
      background-color: #fff;
    }
  }

  .my-account-button {
    .profile-pic {
      width: 18px;
      aspect-ratio: 1;
      border-radius: 50px;
      margin-right: 8px;
    }

    .show {
      min-width: 7.6rem;
      font-size: 0.8rem;
      .login-type-link {
        display: flex;
        padding: 10px;
      }
    }

    .login-menu {
      padding: 0 20px;
      font-size: 0.9rem;
      color: #ee3a1f;
    }

    .login-dropdown {
      padding: 0 10px 0 0;
    }

    .dropdown-toggle,
    .dropdown-toggle:active {
      background: transparent;
      color: ${({ theme }) => theme.font.colors.primary};
      margin-right: 10px;
      font-size: 0.9rem;
      border: none;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none;
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100px;
        display: inline-block;
      }
    }

    button {
      background: transparent;
      border: none;
    }

    .nav-link {
      font-size: 0.9rem;
      color: ${({ theme }) => theme.font.colors.primary};
      padding: 8px 16px;
      border-bottom: 1px solid #ecf0f5;
      transition: all 0.3s;

      &:hover {
        color: ${({ theme, rightBtnColor }) => rightBtnColor || theme.colors.primary};
      }

      &:last-child {
        border: none;
      }
    }
  }

  .dropdown-menu.show {
    display: block;
    border-radius: 8px;
    padding: 0;
    margin-top: 7px;
    min-width: 100%;

    .login-type-link {
      display: flex;
      padding: 10px;
      font-size: 0.9rem;
      padding: 8px 16px;
      border-top: 0.1px solid #ecf0f5;
      color: #5f6368;
    }
  }

  .notLogged {
    border: 1px solid #000;
    border-radius: 8px;
    color: #000;
    transition: all 0.3s;

    .login-menu {
      color: #000;
      padding: 0 10px;
    }

    .dropdown-toggle {
      margin-right: 0px;
      &::after {
        display: none;
      }
      span.login-menu {
        max-width: 100%;
      }

      &:active {
        margin-right: 0px;
      }
    }

    &:hover {
      border: 1px solid #000;
      border-radius: 8px;
      color: white;
      background-color: #000;
      transition: all 0.3s;
      button {
        span {
          color: white;
        }
      }
    }
  }
`;
