import style from 'styled-components';

export const StyledContactFormModern = style.form`
background-color: #FFF;
padding:30px;
border-radius:12px;
font-size:16px;

@media (max-width: 767px){
padding:0
  }

.form-control {
    font-size: 15px;
}

.contact-form_input::placeholder {
    color: #2E2D35;
    font-size: 15px;
}

.form-control::placeholder {
    color: #2E2D35;
    font-size: 15px;
}

    .recaptcha {
        div {
            margin-top: 10px;
        }
    }

    .recaptcha {
        width:50px;  
        margin-top: 1.5rem;
    }

    textArea{
        resize: none;
        font-size: 15px;
    }
    

    .input-wrapper {
        border:none;
        border-bottom:1px solid #2E2D35;
        margin:5px 0;
    }
    .input-wrapper:hover{
        border-bottom:1px solid ${props => props.theme.colors.primary};
        }

        .input-wrapper textarea {
            padding-left: 10px;
        }

        .btnContact{
            padding:15px 40px;
        }
`;
