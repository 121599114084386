import React from 'react';
import { props } from './CompanyInfo.props';
import { StyledCompanyInfo } from './CompanyInfo.styled';
import { Logo, Title, SocialList, TranslatedLink } from '../';
import { useTranslation } from 'react-i18next';
import { tt } from '../../utils/translationHelper';
import ReactMarkdownn from 'react-markdown';

const CompanyInfo = ({
  logoUrl,
  logoWidth,
  logoHeight,
  title,
  address,
  customText,
  hours = [],
  socialNetworks = [],
  addressPath,
  color,
  showSocialsOnRight,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCompanyInfo className="companyInfo col-md-3" color={color}>
      <Logo alt="Company Logo" src={logoUrl} width={logoWidth} height={logoHeight} />
      {title && (
        <Title
          text={title}
          type="h3"
          fontSize={14}
          noMedia
          className="companyInfoTitle"
          bold={false}
          italic={false}
          weight="400"
        />
      )}
      {address &&
        (addressPath ? (
          <TranslatedLink className="adress" name={address} path={addressPath} />
        ) : (
          <p>{tt(address, t)}</p>
        ))}
      {hours.map((hour, index) => (
        <p key={index}>{tt(hour, t)}</p>
      ))}
      {customText && <ReactMarkdownn className="footerCustomText" source={tt(customText, t)} />}

      {socialNetworks && !showSocialsOnRight && (
        <SocialList className="footerSocial" links={socialNetworks} location="footer" />
      )}
    </StyledCompanyInfo>
  );
};

CompanyInfo.propTypes = props;

export default CompanyInfo;
