import React from 'react';
import { CompositeComponent } from 'components/';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class BaseLayoutComponent extends CompositeComponent {
  renderLayout() {
    const pageSection = 'PageSection';
    const { sections, comm, headers } = this.props;

    var sectionsRendered =
      sections &&
      sections.map((section, i) => {
        if (section.type === pageSection) {
          return this.props.children;
        }

        return this.renderComponent({ ...section, ...headers, ...{ comm: comm } }, i);
      });

    return sectionsRendered;
  }

  shouldComponentUpdate(nextProps, nextState) {
    //never re-render for state
    return false;
  }

  render() {
    return (
      <>
        <Helmet>{this.renderHeaders()}</Helmet>
        {this.renderLayout()}
      </>
    );
  }
}

const BaseLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <BaseLayoutComponent {...rest}>
          <Component {...matchProps} />
        </BaseLayoutComponent>
      )}
    />
  );
};

export default BaseLayout;
