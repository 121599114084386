import style from 'styled-components';

export const StyledServiceItemModern = style.div`
    background-color:#FAFBFC;
    border-radius:12px
    padding:20px 10px;
    box-shadow: -1px 0px 13px 1px rgba(0,0,0,0.06);
    max-width:400px;
    margin:30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        width: 90px;
        height: 90px;
        padding: ${props => props.padding || '10px'};
        border:none;
        border-radius: ${props => props.borderRadius || '100%'};
        transition: all 0.3s;
        filter:none !important;
    }

    img:hover {
        width: 100px;
        height: 100px;
        box-shadow: 0px 0px 5px #0000001c;
        margin-bottom: 1.3rem !important;
    }

    h4{
        font-size: 18px;
        font-weight: 700;
        color: ${props => props.theme.font.colors.primary};
     
    }

    p{
        color: ${props => props.theme.font.colors.primary};
        font-weight: 400;
        font-size: 14px;
        min-height:auto;
    }

    button{
        width:auto;
        background:none;
        border: 1px solid #2E2D35;
        color: #2E2D35;
        text-align: center;
        display: inline-block;
        padding:10px 15px;
    }

    button:hover{
        background-color: ${props => props.theme.colors.primary};
        border: 1px solid ${props => props.theme.colors.primary};
    }


`;
