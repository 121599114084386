import PropTypes from 'prop-types';

export const props = {
  params: PropTypes.shape({}),
  prefixForNumeric: PropTypes.string,
};

export const defaultProps = {
  params: {},
};
