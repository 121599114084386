import React from 'react';
import { TranslatedComponent, Paragraph } from 'components';
import { PropTypes } from 'prop-types';
import style, { css } from 'styled-components';

import { ReactComponent as PersonLogo } from './../../../assets/modern/images/cars/details/person.svg';
import { ReactComponent as LuggaggeLgLogo } from './../../../assets/modern/images/cars/details/luggage-lg.svg';
import { ReactComponent as LuggaggeXsLogo } from './../../../assets/modern/images/cars/details/luggage-xs.svg';
import { ReactComponent as GearBoxLogo } from './../../../assets/modern/images/cars/details/caja.svg';
import { ReactComponent as SpeedLogo } from './../../../assets/modern/images/cars/details/speed.svg';
import { withTranslation } from 'react-i18next';

const StyledDetail = style.div`

  .logo {
    fill: ${props => props.theme.colors.icons || props.theme.font.primary};
    padding: 5px;
    border-radius: 7px;
    margin-bottom: 0;
    width: 100%;
    max-width: 35px;
    height: 100%;
  }

  ${props =>
    props.fleetSection &&
    css`
      justify-content: center;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 1.5rem !important;

      .details-item {
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        min-width: 20% !important;
        max-width: 100% !important;
        width: auto !important;

        p {
          font-size: 0.8rem !important;
          line-height: 1;
        }
      }
    `}

`;

class ModernModelDetail extends TranslatedComponent {
  static propType = {
    model: PropTypes.shape({
      passengers: PropTypes.number,
      bigLuggage: PropTypes.number,
      smallLuggage: PropTypes.number,
      gearbox: PropTypes.string,
      airConditioner: PropTypes.string,
      steering: PropTypes.string,
    }),
    prefixForNumeric: PropTypes.string,
    ilimitedKm: PropTypes.bool,
    maxAllowedDistanceByDay: PropTypes.number,
    fleetSection: PropTypes.bool,
    showModelDetail: PropTypes.bool,
  };

  render() {
    const {
      model,
      prefixForNumeric,
      sanatizeDetailText,
      ilimitedKm,
      maxAllowedDistanceByDay,
      HideCarDetailIcons,
      t,
      fleetSection,
      showModelDetail,
      distanceUnit,
    } = this.props;

    const kmText =
      ilimitedKm === true
        ? t(distanceUnit == 'Kilometers' ? 'KmIlimitados' : 'MillasIlimitados')
        : t(distanceUnit == 'Kilometers' ? 'KmDiarios' : 'MillasDiarios', {
            km: maxAllowedDistanceByDay,
          });

    return showModelDetail ? (
      <StyledDetail className="details" fleetSection={fleetSection}>
        {HideCarDetailIcons && HideCarDetailIcons.passengers === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={`${t('carDetails.passengers')} - ${model.passengers}`}>
              <PersonLogo className="logo" alt={`X${model.passengers}`} />
            </i>
            <Paragraph
              text={`${prefixForNumeric || ''}${model.passengers} ${t('personas')}`}
              nonTranslate
            />
          </div>
        )}
        {HideCarDetailIcons && HideCarDetailIcons.bigLuggage === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={`${t('carDetails.bigLuggage')} - ${model.bigLuggage}`}>
              <LuggaggeLgLogo className="logo" alt={`X${model.bigLuggage}`} />
            </i>
            <Paragraph
              text={`${prefixForNumeric || ''}${model.bigLuggage} ${t('ValijasGrandes')}`}
              nonTranslate
            />
          </div>
        )}
        {HideCarDetailIcons && HideCarDetailIcons.smallLuggage === true ? null : (
          <div className="details-item">
            <i
              data-toggle="tooltip"
              title={`${t('carDetails.smallLuggage')} - ${model.smallLuggage}`}
            >
              <LuggaggeXsLogo className="logo" alt={`X${model.smallLuggage}`} />
            </i>
            <Paragraph
              text={`${prefixForNumeric || ''}${model.smallLuggage} ${t('ValijasChicas')}`}
              nonTranslate
            />
          </div>
        )}
        {HideCarDetailIcons && HideCarDetailIcons.gearbox === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={`${t('carDetails.gearbox')} - ${model.gearbox}`}>
              <GearBoxLogo className="logo" alt={`X${model.gearbox}`} />
            </i>
            <Paragraph text={sanatizeDetailText(model.gearbox)} nonTranslate />
          </div>
        )}
        {HideCarDetailIcons && HideCarDetailIcons.unlimitedKilometers === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={kmText}>
              <SpeedLogo className="logo" alt={kmText} />
            </i>
            <Paragraph text={kmText} nonTranslate />
          </div>
        )}
      </StyledDetail>
    ) : null;
  }
}

export default withTranslation()(ModernModelDetail);
