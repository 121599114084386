import styled from 'styled-components';

export const StyledPage = styled.section`
  min-height: 400px;
  background-color: ${props => props.theme.colors.bg};

  &.searchPage {
    display: flex;
    background-color: ${props => props.theme.colors.searchBg};

    @media only screen and (max-width: ${props => props.theme.screens.md}) {
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  }

  a {
    transition: all 0.2s;
  }
`;

export const StyledH2 = styled.h2`
  background-color: red;
`;

export const StyledDiv = styled.div`
  header {
    .header-item {
      padding: 0;
    }

    .header-slogan {
      white-space: nowrap;
    }
  }
`;
