import React from 'react';
import { ColumnsSection, CustomLoading } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { GetBook } from '../../../actions/bookings.actions';
import { getCustomerInfraction } from '../../../actions/infractions.actions';
import { history } from '../../../store';
import { tlink } from 'utils/translationHelper';
import { BookingStatus, InfractionStatus } from '../../../utils/constants';

class OnlinePaymentWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasPartialAmount: false,
      partialAmount: 0,
      showBreadcrumb: true,
    };
  }

  componentWillMount() {
    const {
      location,
      t,
      i18n,
      settings,
      bookings,
      infractions,
      acceptBooking,
      acceptInfraction,
    } = this.props;
    const parseQs = queryString.parse(location.search);
    const currentBooking = bookings ? bookings.confirmation : null;
    const currentInfraction = infractions ? infractions.current : null;

    if (acceptBooking && this.isPayingBooking()) {
      if (parseQs.bookingid) {
        this.setState({ loading: true });
      } else if (!currentBooking) {
        history.push(
          tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
        );
      }
    }

    if (acceptInfraction && this.isPayingInfraction()) {
      if (parseQs.infractionid && parseQs.customerid) {
        this.setState({ loading: true });
      } else if (!currentInfraction) {
        history.push(
          tlink('__Routes.getInfractionFailed', t, i18n, null, settings.configurations.langConfig),
        );
      }
    }

    if (this.isPayingToll()) {
      if (parseQs.bookingid && parseQs.isToll) {
        this.setState({ loading: true });
      } else if (!currentInfraction) {
        history.push(
          tlink('__Routes.getInfractionFailed', t, i18n, null, settings.configurations.langConfig),
        );
      }
    }

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ hasPartialAmount: true, partialAmount: partial });
        }
      } catch {}
    }

    if (
      (!acceptBooking && this.isPayingBooking()) ||
      (!acceptInfraction && this.isPayingInfraction())
    ) {
      history.push(tlink('/', t, i18n, null, settings.configurations.langConfig));
    }
  }

  componentDidMount() {
    const { isWidget } = this.props;
    if (this.isPayingBooking()) this.getBookingInfo();
    if (this.isPayingInfraction()) this.getInfractionInfo();
    if (this.isPayingToll()) this.getTollInfo();

    if (isWidget) {
      window.top.postMessage(
        {
          height: document.body.scrollHeight,
          width: document.body.scrollWidth,
        },
        '*',
      );
    }
  }

  isPayingBooking = () => {
    const { location, bookings } = this.props;
    const parseQs = queryString.parse(location.search);
    // create booking circuit
    if (bookings && bookings.confirmation && !parseQs.infractionid && !parseQs.isToll) return true;
    // online-payment link
    return parseQs.bookingid && !parseQs.infractionid && !parseQs.customerid && !parseQs.isToll;
  };

  isPayingInfraction = () => {
    const { location } = this.props;
    const parseQs = queryString.parse(location.search);
    return !parseQs.bookingid && parseQs.infractionid && parseQs.customerid;
  };

  isPayingToll = () => {
    const { location } = this.props;
    const parseQs = queryString.parse(location.search);
    return parseQs.bookingid && parseQs.isToll;
  };

  getBookingInfo = () => {
    const { location, GetBook, t, i18n, settings } = this.props;
    const parseQs = queryString.parse(location.search);
    if (!parseQs.bookingid) return;

    GetBook(parseQs.bookingid, parseQs.promotionid)
      .then(x => {
        if (x.payload.currentStatus === BookingStatus.canceled) {
          history.push(
            tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
          );
        } else if (x.payload.currentStatus === BookingStatus.quoted) {
          history.push(
            tlink('__Routes.bookingQuoted', t, i18n, null, settings.configurations.langConfig),
            { bookingId: x.payload.id },
          );
        } else {
          const balance = x.payload.customerBalance || x.payload.balance;
          if (balance < 0) {
            this.setState({ loading: false });
          } else {
            history.push(
              tlink('__Routes.bookingPaid', t, i18n, null, settings.configurations.langConfig),
            );
          }
        }
      })
      .catch(error => {
        history.push(
          tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
        );
      });
  };

  getInfractionInfo = () => {
    const { location, getCustomerInfraction, t, i18n, settings } = this.props;
    const parseQs = queryString.parse(location.search);

    getCustomerInfraction(parseQs.customerid, parseQs.infractionid)
      .then(response => {
        const infractionStatus = response.payload.currentCollectionStatus.status;

        if (infractionStatus === InfractionStatus.collected) {
          history.push(
            tlink('__Routes.InfractionPaid', t, i18n, null, settings.configurations.langConfig),
          );
        } else if (infractionStatus === InfractionStatus.collectionNotPossible) {
          history.push(
            tlink(
              '__Routes.getInfractionFailed',
              t,
              i18n,
              null,
              settings.configurations.langConfig,
            ),
          );
        }

        this.setState({ loading: false });
      })
      .catch(error => {
        history.push(
          tlink('__Routes.getInfractionFailed', t, i18n, null, settings.configurations.langConfig),
        );
      });
  };

  getTollInfo = () => {
    const { location, GetBook, t, i18n, settings } = this.props;
    const parseQs = queryString.parse(location.search);
    if (!parseQs.bookingid) return;

    GetBook(parseQs.bookingid, parseQs.promotionid)
      .then(x => {
        if (x.payload.balance < 0) {
          this.setState({ loading: false });
        } else {
          history.push(
            tlink('__Routes.bookingPaid', t, i18n, null, settings.configurations.langConfig),
          );
        }
      })
      .catch(error => {
        history.push(
          tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
        );
      });
  };

  getBreadcrumbObject = () => {
    return {
      component: 'Breadcrumb',
      className: 'pl-0',
      items: [
        {
          link: '',
          text: 'Search',
        },
        {
          link: '',
          text: 'Checkout',
          isActive: true,
        },
        {
          link: '',
          text: 'Online Payment',
        },
      ],
    };
  };

  render() {
    const {
      loadingMessage,
      loadingGif,
      background,
      acceptBooking,
      acceptInfraction,
      franchiseText,
      detailsLegend,
      showBreadcrumb,
    } = this.props;
    if (!acceptBooking && !acceptInfraction) return null;

    if (this.state.loading === true) {
      return (
        <CustomLoading
          loadingMessage={loadingMessage}
          loadingGif={loadingGif}
          background={background}
        ></CustomLoading>
      );
    }

    let sections = [];
    if (showBreadcrumb) sections.push(this.getBreadcrumbObject());
    sections.push({
      component: 'OnlinePaymentSection',
      partialAmount: this.state.hasPartialAmount ? this.state.partialAmount : 0,
    });

    if (this.isPayingBooking()) {
      sections.push({
        component: 'BookingDetailSection',
        title: '__PageOnlinePayment.BookingDetail.title',
        subtitle: '__PageOnlinePayment.BookingDetail.subtitle',
        franchiseText: franchiseText || undefined,
        detailsLegend: detailsLegend || undefined,
      });
    }

    if (this.isPayingInfraction()) {
      sections.push({ component: 'InfractionDetailSection' });
    }

    if (this.isPayingToll()) {
      sections.push({
        component: 'TollDetailSection',
      });
    }

    const props = { ...this.props, sections };

    return (
      <>
        <ColumnsSection {...props}></ColumnsSection>
      </>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  defaultCurrency: global.defaultCurrency,
});
export default connect(mapStateToProps, {
  GetBook,
  getCustomerInfraction,
})(withRouter(withTranslation()(OnlinePaymentWrapper)));
