import PropTypes from 'prop-types';

export const props = {
  langs: PropTypes.array,
  handleOnChange: PropTypes.func,
  classNames: PropTypes.string,
  bgColor: PropTypes.string,
};

export const defaultProps = {
  langs: [],
  handleOnChange: () => {},
};
