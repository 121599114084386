import style from 'styled-components';

export const StyledQuoteForm = style.form`
padding-right: 20% !important;
padding-left: 20% !important;
margin-bottom: 20px;

.recaptcha {
  div {
    margin-top: 10px;
  }
}

.terms-conditions {
  font-size: 0.8em;
  padding: 5px;
}
`;
