import style from 'styled-components';

export const StyledBackToTopButtonContainer = style.div`
position: fixed;
bottom: 52px;
left: 10px;
width: 60px;
height: 60px;
transition: all 0.3s ease;
z-index: 9999;

img {
  width: 100%;
}

&:hover {
  transform: scale(1.1);
}

img {
  width:100%;
}

@media (min-width: ${props => props.theme.screens.md}){
 display:none;
}
`;
