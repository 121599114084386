import { connect } from 'react-redux';
import SearchItems from './SearchItems';
import { fetchBookeableCars } from 'actions/';

const mapStateToProps = ({ searchCars, router, siteConfiguration }) => ({
  cars: searchCars.cars,
  loading: searchCars.loading,
  error: searchCars.error,
  params: searchCars.params,
  categories: searchCars.params.categories,
  location: router.location,
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { fetchBookeableCars })(SearchItems);
