import {
  BOOKING_SET_BOOK,
  BOOKING_SET_ADDITIONALS,
  SET_BOOKING_PAY_INSTALLMENT,
  BOOKING_SET_CONFIGURATION,
  BOOKING_GET_ATTACHMENTS,
  BOOKING_ADD_ATTACHMENTS,
} from 'utils/types';
import {
  createBooking,
  getBooking,
  getBookingAdditionals,
  getBookingWithPromotion,
  getBookingsConfiguration,
  getBookingAttachments,
  attachBookingFiles,
} from 'services/api';

export const book = (
  booking,
  userInfo,
  bookingParams,
  isQuotation,
  currentLanguage,
) => async dispatch => {
  try {
    const {
      car: {
        model: { id: ModelId },
      },
      ilimitedKm: IlimitedKm,
      additionals,
      promotion,
    } = booking;

    let parsedBooking = {
      Customer: {
        FirstName: userInfo.firstName,
        LastName: userInfo.lastName,
        FiscalConditionId:
          userInfo.fiscalConditionId != null ? parseFloat(userInfo.fiscalConditionId) : null,
        DocumentId: userInfo.id,
        DocumentTypeId: userInfo.typeId,
        EmailAddress: userInfo.email,
        Address: userInfo.address,
        AddressNumber: userInfo.addressNumber,
        AddressDepartment: userInfo.addressDepartment,
        CellPhone: userInfo.phone,
        ZipCode: userInfo.zipCode,
        Country: userInfo.country,
        City: userInfo.city || userInfo.cityInput,
        Region: userInfo.region,
        State: userInfo.state || userInfo.stateInput,
        IsCompany: userInfo.isCompany,
        Age: userInfo.driverAge,
        DriverLicenceNumber: userInfo.driverLicenseNumber,
        DriverLicenceCountry: userInfo.driverLicenseCountry,
        DriverLicenseExpiration: userInfo.driverLicenseExpiration,
      },
      CustomDeliveryCity: userInfo.customDeliveryCity,
      CustomDeliveryAddress: userInfo.customDeliveryAddress,
      CustomReturnCity: userInfo.customReturnCity,
      CustomReturnAddress: userInfo.customReturnAddress,
      IsCustomDeliveryAddress: userInfo.isCustomDeliveryAddress,
      IsCustomReturnAddress: userInfo.isCustomReturnAddress,
      isCustomAddress: userInfo.isCustomAddress,
      From: booking.fromDate,
      To: booking.toDate,
      IsQuotation: isQuotation,
      PickupEndpoint: bookingParams.pickUpEndpoint,
      PickupId: bookingParams.pickUpId,
      ReturnEndPoint: bookingParams.dropOffEndpoint,
      ReturnId: bookingParams.dropOffId || bookingParams.pickUpId,
      ModelId,
      Additionals: additionals.map(a => ({
        Quantity: a.quantity,
        AdditionalId: a.additional.id,
      })),
      IlimitedKm,
      fullResponse: true,
      promotion: promotion,
      promotionCode: bookingParams.promotionCode || null,
      onlinePayment: bookingParams.onlinePayment,
      isOnRequest: booking.onlyQuote || false,
      DeliveryTransportationId: userInfo.flightNumber,
      currentLanguage: currentLanguage || '',
      purchaseOrder: userInfo.purchaseOrder,
      extra: userInfo.notes,
      files: userInfo.companyFiles
        ? userInfo.companyFiles.map(a => ({
            name: a.name,
            content: a.file,
            type: a.type,
          }))
        : null,
      AgencyGuid: userInfo.profileIsCompany
        ? userInfo.profileGlobalId
        : bookingParams.agencyGuid ?? null,
      CommercialAgreementCode:
        userInfo.profileIsCompany || bookingParams.agencyGuid
          ? bookingParams.commercialAgreementCode
          : null,
      MaxAllowedDistance: booking.maxAllowedDistance,
      MaxAllowedDistanceByDay: booking.maxAllowedDistanceByDay,
    };

    if (userInfo.globalId) {
      parsedBooking = {
        ...parsedBooking,
        Customer: {
          ...parsedBooking.Customer,
          GlobalId: userInfo.globalId,
        },
      };
    }

    if (userInfo.isCompany) {
      parsedBooking = {
        ...parsedBooking,
        Driver: {
          FirstName: userInfo.driverFirstName,
          LastName: userInfo.driverLastName,
          DocumentId: userInfo.driverId,
          DocumentTypeId: userInfo.driverTypeId,
          EmailAddress: userInfo.driverEmail,
          Address: userInfo.driverAddress,
          AddressNumber: userInfo.driverAddressNumber,
          AddressDepartment: userInfo.driverAddressDepartment,
          CellPhone: userInfo.driverPhone,
          ZipCode: userInfo.driverZipCode,
          Country: userInfo.driverCountry,
          City: userInfo.driverCity,
          Region: userInfo.driverRegion,
          State: userInfo.driverState,
          Age: userInfo.driverAge,
        },
      };
    }

    let data = await createBooking(parsedBooking);
    return Promise.resolve(
      dispatch({
        type: BOOKING_SET_BOOK,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const SetBook = booking => async dispatch => {
  try {
    return Promise.resolve(
      dispatch({
        type: BOOKING_SET_BOOK,
        payload: booking,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const GetBook = (bookingId, promotionId = null) => async dispatch => {
  try {
    const { data } = promotionId
      ? await getBookingWithPromotion(null, bookingId, promotionId)
      : await getBooking(null, bookingId);

    return Promise.resolve(
      dispatch({
        type: BOOKING_SET_BOOK,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBookingAdditionals = (params, booking, language, globalId) => async dispatch => {
  try {
    const { data } = await getBookingAdditionals(params, booking, language, globalId);

    return Promise.resolve(
      dispatch({
        type: BOOKING_SET_ADDITIONALS,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setBookingInstallment = installment => async dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_BOOKING_PAY_INSTALLMENT,
      payload: installment,
    }),
  );
};

export const fetchBookingsConfiguration = () => async dispatch => {
  try {
    const { data } = await getBookingsConfiguration();
    return Promise.resolve(
      dispatch({
        type: BOOKING_SET_CONFIGURATION,
        payload: data,
      }),
    );
  } catch (error) {
    return { error };
  }
};

export const getAttachments = (bookingId, category) => async dispatch => {
  try {
    const { data } = await getBookingAttachments(bookingId, category);

    return Promise.resolve(
      dispatch({
        type: BOOKING_GET_ATTACHMENTS,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const attachFiles = (bookingId, bookingFiles) => async dispatch => {
  try {
    let files = bookingFiles
      ? bookingFiles.map(a => ({
          name: a.name,
          content: a.file,
          type: a.type,
        }))
      : null;
    const { data } = await attachBookingFiles(bookingId, files);

    return Promise.resolve(
      dispatch({
        type: BOOKING_ADD_ATTACHMENTS,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
