import React from 'react';
import { props, defaultProps } from './CustomAlert.props';
import { StyledCustomAlert } from './CustomAlert.styled';
import { CustomAlertModern } from './CustomAlert.styled.modern';

import withSettingsContext, { useSettingsContext } from 'SettingsContext';

import { MODERN_THEME } from 'styled/constants';
import { Alert } from 'react-bootstrap';

const CustomAlert = ({ icon = 'far fa-clock', children, className = '', onClose }) => {
  const settings = useSettingsContext();

  const getTemplateStyledComponent = () => {
    if (settings.theme.template === MODERN_THEME) {
      return CustomAlertModern;
    }
    return StyledCustomAlert;
  };

  const StyledComponent = getTemplateStyledComponent();

  return (
    <StyledComponent className={`${className} custom-alert`} dismissible={onClose ? true : false}>
      <Alert variant="danger" onClose={onClose} dismissible={onClose ? true : false}>
        <p>
          <i className={icon}></i> {children}
        </p>
      </Alert>
    </StyledComponent>
  );
};

CustomAlert.propTypes = props;
CustomAlert.defaultProps = defaultProps;

export default withSettingsContext(CustomAlert);
