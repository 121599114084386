import React from 'react';
import { StyledSection } from './CallToAction.styled';
import { props } from './CallToAction.props';
import { Title, Btn } from 'components/';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import useWindowDimensions from 'utils/windowHelper';
import { tt } from 'utils/translationHelper';

const CallToAction = props => {

  const {
    title,
    titleFontWeight,
    titleFontSize,
    titleColor,
    subTitle,
    subTitleFontWeight,
    subTitleFontSize,
    subTitleColor,
    buttonText,
    buttonLink,
    selfLink,
    background,
    mobileBackground,
    titleType,
    subtitleType,
    justifyContent,
    alignContent,
    alignMobileContent,
    noParallax,
    justifyMobileContent,
  } = props;

  const { t } = useTranslation();

  const setParalax = noParallax ? 'row' : 'row row-p parallax pb-5';
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <StyledSection
      background={(isMobile && tt(mobileBackground,t)) || tt(background,t)}
      className={`${setParalax} call-to-action-wrapper`}
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignMobileContent={alignMobileContent}
      justifyMobileContent={justifyMobileContent}
    >
      <div className={`call-to-action-content`}>
        <Title
          type={titleType ? titleType : 'h3'}
          text={tt(title,t)}
          italic={false}
          className="quoted title"
          fontSize={titleFontSize}
          color={titleColor}
          weight={titleFontWeight}
        />
        <Title
          type={subtitleType ? subtitleType : 'p'}
          text={tt(subTitle,t)}
          italic={false}
          className="quoted subtitle"
          fontSize={subTitleFontSize}
          color={tt(subTitleColor,t)}
          weight={subTitleFontWeight}
        />
        <Btn className={`btn btn-primary btn-lg call-to-action-button`} link={buttonLink} text={tt(buttonText,t)} selfLink={selfLink}/>
      </div>
    </StyledSection>
  );
};

CallToAction.propTypes = props;

export default withSettingsContext(withTranslation()(CallToAction));
