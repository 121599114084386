import React, { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { StyledCancelConfirmationModal } from './CancelConfirmationModal.styled';
import { Btn } from 'components';
import { props } from './CancelConfirmationModal.props';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { tt } from 'utils/translationHelper';
import { handleGetUserBookings } from 'actions';
import { userCancelReservation } from 'services/userService';

const CancelConfirmationModal = ({ t, show, closeModal, item, handleGetUserBookings }) => {
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [error, setError] = useState('');

  const handleCancelBooking = event => {
    try {
      event.preventDefault();
      setError('');
      const parsedCancel = {
        BookingId: item.id,
        Lastname: item.customer.lastName,
      };
      setLoadingCancel(true);
      userCancelReservation(parsedCancel)
        .then(async () => {
          await handleGetUserBookings();
          closeModal();
        })
        .catch(error => {
          if (
            error.response == null ||
            error.response.data == null ||
            error.response.data.code !== 100
          ) {
            let errorMessage =
              error.response && error.response.data
                ? error.response.data.message
                : tt('__PageCancelarReserva.genericError', t);
            setError(errorMessage);
          } else {
            setError(error.response.data.message);
          }
        })
        .finally(() => {
          setLoadingCancel(false);
        });
    } catch (error) {
      alert(t('cancelError'));
      setLoadingCancel(false);
    }
  };

  return (
    <StyledCancelConfirmationModal>
      <Modal
        show={show}
        size="md"
        aria-labelledby="cancel-booking-modal"
        centered
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="cancel-booking-modal">{t('cancelbooking')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('myAccount.cancelConfirmation', {
              bookingId: item && item.id,
            })}
          </p>
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Btn onClick={closeModal} text={t('goBack')} secondary />
          <Btn
            onClick={handleCancelBooking}
            text={t('myAccount.cancelBooking')}
            loading={loadingCancel}
            iconSize="1rem"
            iconColor="white"
          />
        </Modal.Footer>
      </Modal>
    </StyledCancelConfirmationModal>
  );
};

CancelConfirmationModal.propTypes = props;

const mapStateToProps = ({ global, router }) => ({
  listCategories: global.listCategories,
  router: router,
});

const CancelModalComp = withTranslation()(CancelConfirmationModal);

export default connect(mapStateToProps, { handleGetUserBookings })(CancelModalComp);
