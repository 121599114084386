import React from 'react';
import { Title, Paragraph, ModelDetail, NumberDisplay } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { StyledInfractionDetails } from './InfractionDetails.styled';
import { tt } from 'utils/translationHelper';
import Moment from 'moment';
import { fetchCategories } from 'actions';

class InfractionDetailSection extends React.Component {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t, infractions } = this.props;
    if (!infractions) return null;
    const infraction = infractions.current;
    const installment = infractions.installment;
    const booking = infraction.booking;
    const model = infraction.car.model;

    return (
      <StyledInfractionDetails className="col-md-3" priceBackground={this.props.priceBackground}>
        <div className="checkout-details">
          <div className="row">
            <div className="col-md-12 title-col1">
              <Paragraph>&nbsp;</Paragraph>
              <Paragraph>&nbsp;</Paragraph>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="container-details">
                <div className="infractions-details">
                  {!!infraction.act && (
                    <>
                      <Title
                        type="h4"
                        text={t('acta')}
                        weight="700"
                        fontSize={14}
                        italic={false}
                        noMedia
                      />
                      <p>{infraction.act}</p>
                    </>
                  )}
                  <Title
                    type="h4"
                    text={t('date')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>{infraction.creationDate}</p>
                  <Title
                    type="h4"
                    text={t('description')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>{infraction.description || ' - '}</p>
                  {infraction.attachment && (
                    <>
                      <Title
                        type="h4"
                        text={t('attachedFile')}
                        weight="700"
                        fontSize={14}
                        italic={false}
                        noMedia
                      />
                      <a
                        className="mr-5"
                        href={infraction.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file-pdf" size="3"></i>
                      </a>
                    </>
                  )}
                </div>
                <hr />
                <div className="booking-details">
                  <Title type="h4" text={'Car'} weight="700" fontSize={14} italic={false} noMedia />
                  <p>
                    {model.brand.name} {model.name} ({infraction.car.id})
                  </p>

                  {booking && (
                    <>
                      <Title
                        type="h4"
                        text={t('delivery')}
                        weight="700"
                        fontSize={14}
                        italic={false}
                        noMedia
                      />
                      <p>
                        {booking.deliveryPlace.name} -{' '}
                        <span className="date">
                          {' '}
                          {Moment(booking.fromDate).format('DD-MM-YYYY HH:mm')} hs
                        </span>
                      </p>
                      <Title
                        type="h4"
                        text={t('return')}
                        weight="700"
                        fontSize={14}
                        italic={false}
                        noMedia
                      />
                      <p>
                        {booking.returnPlace.name} -{' '}
                        <span className="date">
                          {Moment(booking.toDate).format('DD-MM-YYYY HH:mm')} hs
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="payment mt-3 mb-3">
                {installment ? (
                  <>
                    <div className="row text-light">
                      <div className="col-md-6">
                        <Title
                          type="h4"
                          fontSize="16"
                          className="text-light"
                          text={t('infraction')}
                        />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="">
                          <NumberDisplay value={infraction.amount} />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Title
                          type="h4"
                          fontSize="16"
                          className="text-light"
                          text={t('totalFinancing')}
                        />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="">
                          <NumberDisplay value={installment.total_amount - infraction.amount} />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Title type="h3" className="total-name text-light" text={t('totalToPay')} />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="total-price">
                          <NumberDisplay value={installment.total_amount} />
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row text-light">
                    <div className="col-md-6">
                      <Title type="h4" className="total-name text-light" text={t('totalToPay')} />
                    </div>
                    <div className="col-md-6 text-right">
                      <p className="total-price">
                        <NumberDisplay value={infraction.amount} />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledInfractionDetails>
    );
  }
}

const mapStateToProps = ({ infractions, siteConfiguration }) => ({
  infractions: infractions,
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps, { fetchCategories })(
  withRouter(withTranslation()(InfractionDetailSection)),
);
