import React from 'react';
import { props } from './PageLoading.props';

const style = {
  backgroundColor: 'white',
  overflow: 'hidden',
  height: '100vh',
  width: '100%',
  margin: 0,
  padding: 0,
  textAlign: 'center',
  paddingTop: '10%',
};

const imgStyle = {
  margin: 'auto',
  width: '100px',
};

const h1Style = {
  textAlign: 'center',
  fontFamily: 'Segoe, Helvetica, Arial, Sans-serif',
  fontWeight: '300',
  color: '#000',
  fontSize: '20px',
};

const PageLoadingComponent = ({ show, message }) => {
  if (!show) return null;

  return (
    <section style={style}>
      <img
        style={imgStyle}
        src="https://rently.azureedge.net/rently/Web/loading-car.gif"
        alt={message}
      ></img>
      {message ? <h1 style={h1Style}>{message}</h1> : null}
    </section>
  );
};

PageLoadingComponent.propTypes = props;

export default PageLoadingComponent;
