import React from 'react';
import { props, defaults } from './SearchItems.prop';
import { StyledSearchItems } from './SearchItems.styled';
import { Breadcrumb, CustomAlert, CustomLoading } from 'components/';
import SearchItemRow from './SearchItemRow/SearchItemRow';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { is_gtag_enabled, gtag_car_impressions } from '../../../utils/googleTagsHelper';
import { StyledSearchItemsModern } from './SearchItems.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { tt } from 'utils/translationHelper';
import { isAgencyUser } from 'utils/dataHelper';

class SearchItems extends React.Component {
  constructor(props) {
    const { profile } = props;
    super(props);
    this.state = {
      error: null,
      loading: true,
      showAlert: true,
      userInfo: {
        loggedAsAgency: isAgencyUser(profile),
      },
    };
  }

  componentWillReceiveProps(prevProps, prevState) {
    if (prevProps.cars !== this.props.cars && prevState.loading) {
      this.setState({ ...prevState, loading: false });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      cars,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      isWidget,
    } = this.props;

    if (prevProps.cars !== this.props.cars) {
      if (!this.props.loading) {
        if (is_gtag_enabled(googleTagManager) && this.props.cars)
          gtag_car_impressions(cars, settings);

        if (isWidget) {
          window.top.postMessage(
            {
              height: document.body.scrollHeight,
              width: document.body.scrollWidth,
            },
            '*',
          );
        }
      }
    }
  };

  hideAlert = () => {
    this.setState({ showAlert: false });
  };

  orderCarsBy = (cars, orderBy = 'price') => {
    if (!cars) return [];

    const sortFunctions = {
      price: function(a, b) {
        return a.price > b.price ? 1 : -1;
      },
      category: function(a, b) {
        return a.category.order > b.category.order ? 1 : -1;
      },
      sipp: function(a, b) {
        return a.car.model.sipp > b.car.model.sipp ? 1 : -1;
      },
      model: function(a, b) {
        return a.model.name > b.model.name ? 1 : -1;
      },
    };

    const orderedPricedCars = cars.filter(item => !item.onlyQuote).sort(sortFunctions[orderBy]);
    const orderedRequirableCars = cars.filter(item => item.onlyQuote).sort(sortFunctions[orderBy]);

    return [orderedPricedCars, orderedRequirableCars].flat();
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledSearchItemsModern;
    }
    return StyledSearchItems;
  }

  getFilteredCarList = () => {
    const {
      cars,
      categories,
      settings: { configurations },
    } = this.props;

    let carList = [...cars];

    if (configurations.filteredCategories) {
      const { includedCategoryIds, excludedCategoryIds } = configurations.filteredCategories;

      if (includedCategoryIds && includedCategoryIds.length > 0) {
        carList = carList.filter(car => includedCategoryIds.includes(car.category.id));
      }
      if (excludedCategoryIds && excludedCategoryIds.length > 0) {
        carList = carList.filter(car => !excludedCategoryIds.includes(car.category.id));
      }
    }

    if (categories && categories.length > 0) {
      carList = carList.filter(car =>
        categories.find(categoryId => categoryId === car.category.id),
      );
    }
    return carList;
  };

  render() {
    window.scrollTo(0, 0);

    const {
      checkoutRoute,
      t,
      selectButtonName,
      selectOnlineButtonName,
      showDailyPrice,
      orderBy,
      showSipp,
      noAvailabilityImage,
      noAvailabilityMessage,
      searchGif,
      searchMessage,
      franquicia,
      franchiseLink,
      franchiseTooltip,
      itemMiddleColumnExtraContent,
      alquilerPor,
      settings: { featureFlags, configurations },
      backgroundItems,
      onlyQuoteBtn,
      partialAvailabilityMessage,
      showModelDetail,
      chargedMsg,
      chargedMsg2,
    } = this.props;
    let { cars, loading, error } = this.props;

    const {
      userInfo: { loggedAsAgency },
    } = this.state;

    cars = this.getFilteredCarList();

    cars = this.orderCarsBy(cars, orderBy);
    const StyledSection = this.getTemplateStyledComponent();

    const fewUnitsText = configurations.fewUnitsText || {};

    return (
      <StyledSection className="col-md-9" background={this.props.background}>
        <div className="col-md-12 row-container">
          <Breadcrumb
            className="row mb-0"
            items={[
              { text: t('search') },
              { text: t('checkout'), disabled: true },
              { text: t('pago'), disabled: true },
            ]}
          />
          {cars &&
          cars.length > 0 &&
          cars.length < (featureFlags.fewCarUnits || 11) &&
          this.state.showAlert === true ? (
            <CustomAlert
              icon="far fa-clock"
              className="mb-4 few-cars_alert"
              onClose={this.hideAlert}
            >
              <span>
                <b>{tt(fewUnitsText.title, t) || t('pocasUnidadesTitle')}</b>{' '}
                {tt(fewUnitsText.description, t) || t('pocasUnidadesParagraph')}
              </span>
            </CustomAlert>
          ) : (
            ''
          )}
          {error ? (
            <span className="error">{t(error.message || error)}</span>
          ) : loading ? (
            <CustomLoading
              loadingMessage={searchMessage || 'searching'}
              loadingGif={searchGif || 'loadingGif'}
            ></CustomLoading>
          ) : cars && cars.length > 0 ? (
            cars.map((car, index) => (
              <SearchItemRow
                key={index}
                car={car}
                checkoutRoute={checkoutRoute}
                selectButtonName={selectButtonName}
                selectOnlineButtonName={selectOnlineButtonName}
                showDailyPrice={showDailyPrice}
                showSipp={showSipp}
                franquicia={franquicia}
                franchiseLink={franchiseLink}
                franchiseTooltip={franchiseTooltip}
                itemMiddleColumnExtraContent={itemMiddleColumnExtraContent}
                alquilerPor={alquilerPor}
                background={backgroundItems}
                onlyQuoteBtn={onlyQuoteBtn}
                partialAvailabilityMessage={partialAvailabilityMessage}
                showModelDetail={showModelDetail}
                currentCurrency={this.props.currentCurrency}
                defaultCurrency={this.props.defaultCurrency}
                webCurrency={this.props.webCurrency}
                chargedMsg={chargedMsg}
                chargedMsg2={chargedMsg2}
                loggedAsAgency={loggedAsAgency}
                agencyPrice={car.agencyPrice}
                customerPrice={car.customerPrice}
                salesCommision={car.salesCommision}
              />
            ))
          ) : (
            <CustomLoading
              loadingMessage={noAvailabilityMessage || 'noAvailabilityMessage'}
              loadingGif={noAvailabilityImage}
            ></CustomLoading>
          )}
        </div>
      </StyledSection>
    );
  }
}

SearchItems.propTypes = props;
SearchItems.defaultProps = defaults;

const mapStateToProps = ({ searchCars, global, user }) => ({
  categories: searchCars.params.categories,
  currentCurrency: global.currentCurrency,
  defaultCurrency: global.defaultCurrency,
  webCurrency: global.webCurrency,
  profile: user.profile,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(SearchItems)));
