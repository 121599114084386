import React from 'react';
import queryString from 'query-string';
import { NumberDisplay } from 'components/';
import { handleGetTotalToPay } from 'utils/dataHelper';

class BasePaymentSection extends React.Component {
  getPayablePrice = () => {
    const { bookings, settings } = this.props;
    if (settings.paymentConfiguration && settings.paymentConfiguration.percentageToPay) {
      return Math.abs(
        (bookings.confirmation.customerBalance / 100) *
          settings.paymentConfiguration.percentageToPay,
      );
    } else {
      return Math.abs(bookings.confirmation.customerBalance);
    }
  };

  showTotalToPay = returnFinalPriceOnly => {
    const { location } = this.props;
    const {
      paymentInfo: { qsamount, price },
    } = this.state;
    const parseQs = queryString.parse(location.search);

    let finalPrice = 0;
    let bookingPayableAmount = 0;

    if (qsamount || parseQs?.infractionid) {
      finalPrice = Math.abs(qsamount || price);

      if (parseQs?.bookingid) {
        bookingPayableAmount = this.getPayablePrice();
      }
    } else {
      finalPrice = this.getPayablePrice();
    }

    if (returnFinalPriceOnly) {
      return finalPrice;
    }

    if (qsamount) {
      return (
        <React.Fragment>
          <NumberDisplay value={qsamount} useWebCurrency={true} />
          {qsamount < bookingPayableAmount && (
            <>
              {` ${this.props.t('de')} `} <NumberDisplay value={bookingPayableAmount} />
            </>
          )}
        </React.Fragment>
      );
    } else {
      return <NumberDisplay value={finalPrice} useWebCurrency={true} />;
    }
  };

  getTotalToPay(useRound = false) {
    const {
      bookings: { confirmation },
      settings: { paymentConfiguration },
    } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;
    const price = handleGetTotalToPay(confirmation.customerBalance, paymentConfiguration, qsamount);

    return useRound ? Math.round(price) : price;
  }
}

export default BasePaymentSection;
