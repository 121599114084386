import style from 'styled-components';

export const StyledFilterModern = style.section`
  min-height: 600px;
  z-index:1;

  .overlay{
    width: 100%;
    height: 100vh;
    background: #00000042;
    position: absolute;
  }

  .categoryBlock{
    position: sticky;
    top: 56px;
    justify-content: flex-start; 
    display:block;
  }

  .categoryBlock p{
    font-size:16px !important;
  }


  .search-category,  .search-form {
    border-radius:12px;
  }
 

  @media (max-width: ${props => props.theme.screens.md}) {
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    min-height: 0;
    margin-left: -2%;
    margin-right: -2%;
    width: 100vw;
    padding: 0;

    .searchFilter, .categoriesFilter {
      display:block !important;
    }

    .search-form, .categoryBlock{ 
      display:none;
    }
  }
  
  @media only screen and (max-width: ${props => props.theme.screens.xl}) 
  and (min-width: ${props => props.theme.screens.md}){
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    min-height: 0;
    flex: 0 0 104%;
    max-width: initial;
    width: 100vw;
    padding: 0;
    margin-left: -2%;
    margin-right: -2%;

    .searchFilter, .categoriesFilter {
      display:block !important;
    }

    .search-form, .categoryBlock{
      display:none;
    }
  }

  @media only screen and (min-width: 1281px) and (max-width: ${props => props.theme.screens.xxl}){
    max-width: 30%;
    margin-right: 0;
    flex: 30%;
  }

  .searchFilter{
    display:none;
    box-shadow: 0 0 20px #00000026;

    section{
      background: transparent;
    }
    .card{
      border: none;
      background: transparent;
    }

    .card-header{
      border-bottom: 1px solid #f2f2f2;
      background: #fff;
      .fa-info-circle{
        display:none;
      }
      .fa-calendar-alt{
        color: ${props => props.theme.colors.buttonPrimaryBgHover};
      }
      .divider{
        color: #c2c2c2;
      }
    }
    .collapse{
      z-index: 1200;
    }
    
    .card-body{
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;

      form{
        box-shadow: none;
        margin: 0 !important;
        background:#FFF;
        padding: 1.25rem 1.25rem 2rem !important;
        .col-md-12{
          margin-bottom: 0;
        }
      }
    }
  }

  .categoriesFilter {
    display:none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 15px 0;
    box-shadow: 0 0 15px #0000003d;
    border-top: 1px solid #f2f2f2;
    border-bottom: none;    
    z-index: -1;

    i{
      color: ${props => props.theme.colors.buttonPrimaryBgHover};
    }
    &:focus{
      outline: none;
    }
  }
  .categoryPopup-content{
    width: 87% !important;
    padding: 0 !important;
    border: none !important;
    border-radius:12px;

    .modal{
      display:block;
      position: relative;

      .header{
        padding: 15px 30px 10px;
        border-bottom: 1px solid #f2f2f2;
        .close i{
          font-size: 14px;
        }
      }
      .content{
        padding: 20px 30px 30px;
      }
      .search-category{
        box-shadow: none;
        padding: 0 !important;
        margin: 0 !important;
        div{
          padding:0;
        }
      }
    }
  }

  .search-form {
    background-color: ${props => props.backgroud || props.theme.colors.bg};
    margin-top:5.2em !important;
    box-shadow: 3px 3px 6px #00000016;
    margin-left: -15px;
    
    .col-md-6{
      width: 100% !important;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-md-3{
      width: 50% !important
      flex: 0 0 50%;
      max-width: 50%;
      margin-top:20px;
    }

    .input-wrapper {
      border-radius:10px;
    }

    .hour-select .input-wrapper {
      border-radius:10px;
    }

    .DateRangePickerInput {
    background: none;
  }

  label {
    font-size: 13px;
    font-weight: 500;
    color:#2E2D35;
}

    span.toggle-drop-place {
      color : ${props => props.theme.colors.buttonPrimaryBgHover};
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    div.drop-place-input {
      margin-bottom: 20px;
    }

    .dates {
      vertical-align: center;

      span {
        font-weight: bold;
      }

      div.date-picker {
        padding: 1em;
        @media only screen and (max-width: ${props => props.theme.screens.md}){
          padding: .7em;
        }
        input {
          font-size: 1em;
          font-weight: bold;
        }
      }

      div.select-hour {
        padding: 1em;
        @media only screen and (max-width: ${props => props.theme.screens.md}){
          padding: .7em;
        }

        select {
          font-weight: bold;
          font-size: 1em;
        }
      }
    }

    .km-checkboxes {
      margin-top: 1em;

      .input-wrapper {
        padding: .3em;
      }
    }

    p{
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 5px;

      @media only screen and (max-width: 1480px) and (min-width: 1271px){
        font-size: 11px;
      }
    }

    button {
      width: 100%;
    }
  }

  @media(max-width:767px){
    .input-wrapper {
      border-radius:10px;
    }

    .hour-select .input-wrapper {
      border-radius:10px;
    }

    .DateRangePickerInput {
    background: none;
  }

  label {
    color:#2E2D35;
}

  }

`;
