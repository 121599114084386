import styled from 'styled-components';

export const StyledPreHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 40px;
  gap: 10px;
  font-size: 14px;
  color: ${({ color }) => color || '#212529'};
  background: ${({ bgColor }) => bgColor || '#EFEFEF'};

  .contact-list {
    display: flex;
    width: 100%;
    overflow: scroll;
    gap: 10px;
    padding-right: 50px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 10px 20px;

    .header-title {
      display: none;
    }
  }
`;

export const StyledContactCard = styled.div`
  display: flex;
  gap: 10px;
  min-width: 160px;

  .contact-icon {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: ${({ defaultIconBg }) => defaultIconBg};
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;

    i {
      color: #fff;
      font-size: 1.4rem;
    }
  }

  .contact-icon:hover {
    opacity: 0.7;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 25%);
  }

  .contact-body {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-overflow: ellipsis;
    white-space: nowrap;

    div {
      font-size: 12px;
    }
  }

  @media (max-width: ${props => props.theme.screens.lg}) {
    min-width: 130px;
    gap: 5px;

    .header-title {
      font-size: 14px;
    }

    .contact-icon {
      width: 32px;
      height: 32px;

      i {
        font-size: 1rem;
      }
    }

    .contact-body {
      font-size: 12px;

      div {
        font-size: 10px;
      }
    }
  }
`;
