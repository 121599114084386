import styled from 'styled-components';

export const StyledLoadingContainer = styled.section`
  display: flex;
  flex-basis: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .loading {
    width: ${props => props.width || '50px'};
    height: ${props => props.height || null};
    z-index: 99999;

    .fa-cog {
      font-size: ${props => props.size || '50px'};
      color: ${props => props.color || props.theme.colors.primary};
    }

    .rotating {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }

    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;
