import style from 'styled-components';

export const SearchItemRowStyledModern = style.div`
  background: ${({ background, theme }) => background || theme.colors.third};


  .car-description{
    display: flex;
  }

  .separator {
    color: #6f6f6f;
  }

  .car-name {
    color:#2E2D35;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .car-image{ 
    height: fit-content;
    text-align: center;
    flex: 0 0 33%;
    max-width: 33%;
  } 

  .franchise {
    font-size: 0.8rem;
    span { 
      color: #1A73E8;
    }

    i {
      color: #1A73E8;
      margin-right: 5px;
    }
  }


.car-image img{
  max-height: 164px; 
}

.car-category{
  font-weight: 600;
}

.car-price {
  text-align: center;

  p.rent-days {
    color:#656970;
    font-weight: 400;
    font-size: 13px;
  }

  p.rent-price {
    color:#2E2D35;
    margin-top: 5px !important;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;

    .long-value{
      font-size: 24px;
    }

    .short-value{
      font-size: 24px;
    }

    span {
      font-size: 28px;
    }
  }

  button {
    width: 100%;
    padding:10px 0;
    float:right
  }

  .customButton{
    flex-direction: column;

    .button-container {
      display: flex;
      width: 10rem;

      .button-icon {
        margin-right: 0.3rem;
        width: 10%;
      }

      .button-text{
        width: 100%;
      }

      .with-icon{
        width: 90%!important;
      }

    }

    .button-subtext {
      font-size: 0.6rem;
      margin-top: 0.2rem;
    }
  }

  .second-btn {
    color: #2E2D35;
    font-size: 14px;
    border: 1px solid #2E2D35;
    padding: 8px;
    float: left;
    width: 100%;
    border-radius:5px;
    text-align:center;

    .CheckoutLink-icon {
      margin-right: 0.5rem;
    }
}
  .second-btn:hover {
    background:#f1f1f1;
  }
    
    span {
      padding-left: 3px;
    }
  }
}


.details {    
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;

  .details-item {  
    display: flex; 
    align-items: center; 
    margin-right: 0.5rem;

    svg {
      padding-left: 0;
    }

    p { 
      white-space: nowrap;
      margin: 0;
    }

    img{
      border: 1px solid #CCCCCC;
      padding: 5px;
      border-radius: 7px;
      margin-bottom: 5px;
      width: 100%;
      max-width: 40px;
    }

    span {
      margin-top: 5px;
      font-weight: 400;
      font-size: .75em;
    }
  }
}

.car-mobile-details {
  display: none;
}

.partial-availability-mobile {
  display: none;
}

.disabled-link {
  cursor: default;
  font-style: italic;
}

@media (max-width: ${({ theme }) => theme.screens.md}){
  flex-direction: column;
  padding: 20px 5px !important;

  .md-hidden{
    display: none;
  }

  .car-mobile-details {
    display: block;
  }
  .details{
    .details-item {  
      width: 45%;
    }
  }
}

@media (max-width: ${({ theme }) => theme.screens.lg}) {
  .car-image{
    margin-right: 1rem !important;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .car-name {
    font-size: 15px;
  }

  .franchise {
    font-size: 12px;
    line-height: 22px;
  }
}

@media (max-width:${({ theme }) => theme.screens.sm}) {
  .partial-availability-desktop {
    display: none;
  }

  .partial-availability-mobile {
    display: block;
  }
}




`;
