import React from 'react';
import { PropTypes } from 'prop-types';
import Slider from 'react-slick';
import { StyledCustomerCarrousel } from './CustomerCarrousel.styled';
import { StyledCustomerCarrouselModern } from './CustomerCarrousel.styled.modern';
import { Title } from 'components/';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

const sliderSettings = {
  dots: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 6,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

class CustomerCarrousel extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    className: PropTypes.string,
    settings: PropTypes.object,
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledCustomerCarrouselModern;
    }
    return StyledCustomerCarrousel;
  }

  render() {
    const { title, items, className } = this.props;

    const settings = { ...sliderSettings, ...this.props.settings };

    const StyledComponent = this.getTemplateStyledComponent();

    return (
      <StyledComponent className={`row-p text-center ${className}`}>
        <Title text={title} type="h4" />
        <Slider {...settings}>
          {items.map((item, i) => (
            <div key={i}>
              <div
                className="customer-image"
                style={{ backgroundImage: 'url(' + item.image + ')' }}
              ></div>
            </div>
          ))}
        </Slider>
      </StyledComponent>
    );
  }
}

export default withSettingsContext(CustomerCarrousel);
