import PropTypes from 'prop-types';

export const props = {
  show: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string,
};

export const defaults = {
  fontAwesomeClass: 'fa fa-cog',
};
