import style from 'styled-components';

export const StyledServiceItem = style.div`
    &:hover img {
        width: ${props => props.sizeHover || '83px'};
        height: ${props => props.sizeHover || '83px'};
        box-shadow: 0px 0px 5px #0000001c;
        margin-bottom: 1.3rem !important;
    }
    img{
        width: ${props => props.size || '80px'};
        height: ${props => props.size || '80px'};
        padding: ${props => props.padding || '20px'};
        border: 2px solid ${props => props.theme.colors.buttonPrimaryBg};
        border-radius: ${props => props.borderRadius || '100%'};
        transition: all 0.3s;
    }

    h4{
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        color: ${props => props.theme.font.colors.primary};
        min-height: 2.4em;
    }

    p{
        color: ${props => props.theme.font.colors.primary};
        font-weight: 400;
        font-size: 12px;
        min-height:100px;
    }

    button{
        width:auto;
        text-align: center;
        display: inline-block;
    }

`;
