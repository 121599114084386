import React from 'react';
import { props } from '../PagaEnCuotas.props';
import { StyledPagaEnCuotas, StyledBanksSection } from './index.styles';
import Slider from 'react-slick';
import { TranslatedComponent, Title } from 'components/';
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ModernPagaEnCuotas extends TranslatedComponent {
  static propTypes = props;

  render() {
    const {
      title,
      titleType,
      subTitle,
      leftLinkText,
      leftLinkUrl,
      items,
      showBorder,
      borderColor,
      leftLinkHover,
      hideTitle,
    } = this.props;

    const sliderSettings = {
      dots: true,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: items.length < 6 ? items.length : 6,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        {hideTitle === true ? (
          ''
        ) : (
          <StyledPagaEnCuotas className="row row-p">
            <div className="home-title text-left ">
              <Title text={this.tt(title)} type={titleType || 'h1'} />
              <p>{this.tt(subTitle)}</p>
              <a target="_blank" rel="noreferrer noopener" href={this.tt(leftLinkUrl)}>
                {this.tt(leftLinkText)}
              </a>
            </div>
          </StyledPagaEnCuotas>
        )}
        <StyledBanksSection
          className="homeBanks row row-p mb-4 pt-4 mt-2 mb-4"
          showBorder={showBorder}
          borderColor={borderColor}
          leftLinkHover={leftLinkHover}
        >
          <div className="col-md-12 centered">
            <Slider {...sliderSettings}>
              {items.map((promo, key) => (
                <div key={key}>
                  <div className="img">
                    <LazyLoadImage
                      src={this.tt(promo.imgSrc)}
                      alt={`promo`}
                      threshold={100}
                      effect="blur"
                      height={'100px'}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </StyledBanksSection>
      </>
    );
  }
}

export default withTranslation()(ModernPagaEnCuotas);
