import style from 'styled-components';

export const StyledSearchFormWhatsApp = style.form`
	background-color: ${props =>
    props.transparent ? props.theme.colors.transparent : props.theme.colors.third};
  box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
	font-size: 0.93em;
  min-height: auto;
  font-size: 13px;

  .diff-drop-place {
    color: ${props => props.theme.colors.buttonPrimaryBgHover};
    cursor: pointer;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input-wrapper {
    height: 48px;
  }

  .fa-arrow-right {
    color: #e5e5e5 !important;
    font-size: 12px !important;
  }

  .range-date-picker {
    .DateInput{
      width: 40%;
    }

    .DateInput_input {
      font-size: 14px;
      padding-right: 0;
    }
  }

  .label{
    font-size: 13px;
    font-weight: 600;
  }

  .hour-select {
    white-space: nowrap;
    .input-wrapper {
      height: 48px;
    }
  }

  p{
    font-size: 13px !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
  }

  .checkboxes {
    display: flex;
    align-items: flex-end;
  }
  .checkboxes > div:first-child {
		padding-right: 10px;
		margin-right: 10px;
  }

  .submit-btn {
    width: 100%;
    white-space: nowrap;
  }

  .label-container {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .form-check-input {
      //position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: white;
      }
    }
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    input:checked ~ .checkmark {
      background-color: ${props => props.theme.colors.buttonPrimaryBgHover};
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    .row > div {
      margin-bottom: 20px;
    }
    .row {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${props => props.theme.screens.xs}) {
    font-size: 13px;
    .checkboxes {
      flex-direction: column;
      align-items: baseline;
      div {
        margin-bottom: 10px;
      }
    }
    .label{
      font-size: 12px;
    }
    .pickup-place-picker, .return-place-picker, .filtro-km, .submit-block, .submit-block .col-md-12{
      margin-bottom: 0 !important;
    }
    .diff-drop-place{
      font-size: 13px;
    }
    .hour-select {
      width: 50%;
      display: inline-block;
      margin-bottom: 0 !important;
    }
    .hour-select-from {
      padding-right: .5rem!important;
    }
    .hour-select-to {
      padding-left: .5rem!important;
    }
  }
`;
