import React from 'react';
import { props } from './calidadServicio.props';
import { StyledCalidadServicio } from './calidadServicio.styled';
import { Title, TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';
import { StyledCalidadServicioModern } from './calidadServicio.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import withSettingsContext from 'SettingsContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class CalidadServicio extends TranslatedComponent {
  static propTypes = props;

  constructor(props) {
    super(props);
    this.state = {
      backgroundLoaded: false,
    };
  }

  componentDidMount() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);
    observer.observe(this.styledSectionRef);
  }

  handleIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // Component is in view, set backgroundLoaded to true
        this.setState({ backgroundLoaded: true });
      }
    });
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledCalidadServicioModern;
    }
    return StyledCalidadServicio;
  }

  getContentColumnWidth() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-lg-5';
    }
    return 'col-lg-6';
  }

  render() {
    const {
      background,
      title,
      titleType,
      text,
      modernImage,
      statement,
      color,
      textContentSize,
      statementSize,
      customContent,
      className = '',
      settings,
    } = this.props;
    const { backgroundLoaded } = this.state;

    const stars = [1, 2, 3, 4, 5];
    const StyledSection = this.getTemplateStyledComponent();

    // Define the placeholder background image
    const placeholderBackground = 'url("")'; // Replace with your placeholder image URL

    // Define the actual background image
    const actualBackground = `url("${background}")`;

    return (
      <StyledSection
        ref={ref => (this.styledSectionRef = ref)}
        className={`${className} CalidadServicio row`}
        style={{
          backgroundImage: backgroundLoaded ? actualBackground : placeholderBackground,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '360px',
        }}
        color={color}
        textContentSize={textContentSize}
        statementSize={statementSize}
      >
        {settings.theme.template === MODERN_THEME && (
          <div className="col-lg-7 imgHighlight">
            <LazyLoadImage src={modernImage} alt="quality service" effect="blur" />
          </div>
        )}
        <div className={`contentBox ${this.getContentColumnWidth()} pb-4 pt-4`}>
          <div className="stars">
            {stars.map(id => (
              <i className="fa fa-star" key={`id_${id}`} />
            ))}
          </div>
          <Title
            className="title mb-1"
            type={titleType || 'h3'}
            italic={false}
            bold={false}
            text={title}
            fontSize={27}
            noMedia
            color="#fff"
          />
          {customContent ? (
            <div dangerouslySetInnerHTML={{ __html: `${this.tt(customContent)}` }} />
          ) : (
            <>
              <p className="textContent mb-4">{this.tt(text)}</p>
              <p className="statement">{this.tt(statement)}</p>
            </>
          )}
        </div>
      </StyledSection>
    );
  }
}

export default withSettingsContext(withTranslation()(CalidadServicio));
