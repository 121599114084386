import { connect } from 'react-redux';
import SearchFilter from './SearchFilter';
import { setSearchCarParams, fetchBookeableCars } from 'actions/';

const mapStateToProps = ({ global, searchCars }) => ({
  listCategories: global.listCategories,
  places: global.listPlaces,
  params: searchCars.params,
  searchCars,
});

export default connect(mapStateToProps, { setSearchCarParams, fetchBookeableCars })(SearchFilter);
