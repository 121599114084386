import React from 'react';
import { withTranslation } from 'react-i18next';
import { props } from './BackToTopButton.props';
import { StyledBackToTopButtonContainer } from './BackToTopButton.styled';

const BackToTopButton = ({ disabled }) => {
  if (disabled) return null;
  return (
    <StyledBackToTopButtonContainer>
      <a href="#mainNav" rel="noopener noreferrer">
        <img
          src="https://rently.blob.core.windows.net/hertz/Web/up-arrow-white.png"
          alt="back-to-top-button"
        />
      </a>
    </StyledBackToTopButtonContainer>
  );
};

BackToTopButton.propTypes = props;

export default withTranslation()(BackToTopButton);
