import PropTypes from 'prop-types';

export const props = {
  links: PropTypes.array,
  className: PropTypes.string,
  borderRadius: PropTypes.string,
  bgColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  hoverColor: PropTypes.string,
  margin: PropTypes.string,
  showTopBarPhone: PropTypes.bool,
  location: PropTypes.string,
};

export const defaults = {
  fontAwesomeClass: 'fa fa-cog',
};
