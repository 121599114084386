import style from 'styled-components';

export const StyledFlotaSection = style.div`
    .card{
        border: none;
    }

    .category-filter{
        @media only screen and (max-width: ${props => props.theme.screens.md}){
            margin-bottom: 35px;
        }
    }

    .car-image-wrapper{
      img{
        height: 100%;
        object-fit: cover;
        min-height: 100%;
      }
    }

    .card-header{
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        background: none;
        border-bottom: 1px solid rgba(0,0,0,.125) !important;
        margin-bottom:0px !important;
        cursor: pointer;
    }

    .card-body li{
        line-height: 2em;
        font-size: 14px;
    }

   .card-body li i{
        font-size: 10px;
        margin-right: 10px;
        position: relative;
        top: -1px;
   }

   .list-unstyled{
       cursor: pointer;
   }
   
   .slick-track{
    margin: auto auto;
    } 

    .slick-next:before, .slick-prev:before{
        color: #ccc;
    }
    .slick-prev{
        left:-5px;
    }
    .slick-next{
        right: -5px;
    }

  .slick-next:before {
    content: "\f105";
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    
  }

  .slick-prev:before {
    content: "\f104" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
  }

`;

export const StyledFlotaGrid = style.div`
    .flex-container{
        display: flex;
        flex-wrap: wrap;
    }

    .nav-item{
        margin: 0 3px 10px;
        a{
            color: #6f6f6f;
            text-transform: uppercase;
            font-size: 13px;
            padding: .3rem 1rem; 
            transition: background .3s;    
            background: #f4f4f4;
            border-radius: 5px;

            &:hover, &.active{
                color: ${props => props.theme.colors.buttonPrimaryColor};
                background: ${props => props.theme.colors.buttonPrimaryBgHover};
            }
        }
        @media only screen and (max-width: ${props => props.theme.screens.lg}){
            margin: 0 8px 15px;

            a{
                font-size: 15px;
            }
        }
    }

    .car-item {
        @media only screen and (max-width: ${props => props.theme.screens.md}){
            padding: 0 !important;
        }
        .car-details {
          .car-name h2 {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
    
            @media only screen and (max-width: ${props =>
              props.theme.screens.xxl}) and (min-width: ${props => props.theme.screens.xl}) {
              font-size: 18px;
            }
          }
          .btn{
            display: ${props => props.btnDisplay || 'flex'};
          }
        }
    
        .car-image {
          border: 1px solid #cbcbcb;
          height: fit-content;
          text-align: center;
          height: 170px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .car-image-wrapper{
          img{
            height: 100%;
            object-fit: cover;
            min-height: 100%;
          }
        }
    
        .price p {
          font-size: 13px;
          text-transform:uppercase;
          span{
            font-weight: bold;
            font-size:15px;
          }
          strong span{
            font-size: 20px;
          }
        }
    
        .car-details {
          .details {
            margin-bottom: 5px;
    
            .details-item {
              display: inline-block;
              text-align: center;
              margin-right: 5px;
              width: 10.5%;
              min-width: 35px;
              max-width: 60px;
    
              @media only screen and (max-width: 330px) {
                  width: 16%;
              }
    
              p{
                margin-bottom:0 ;
              }
    
              img {
                width: 100%;
                display: block;
                border: 1px solid #cccccc;
                padding: 5px;
                border-radius: 7px;
                margin-bottom: 5px;
              }
            }
          }
        }
        
      }
`;
