import style from 'styled-components';

export const StyledLandingSection = style.section`
	.description {
        background-color: #f2f2f2;
    }
    .quoted h2{
        font-weight: 300;
        color: #fff;
        text-transform: none;
        margin-bottom: 15px;
        margin-left: 10px;
    }
    .routes .routes-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .routes-item {
        display: flex;
        flex: 1;
        flex-basis: 25%;
        max-width: 25%;
        align-items: center;
        text-align: center;
        flex-direction: column;

        .km{
            font-size: 1.5rem;
            margin-bottom: 0px;
            font-weight: bold;
            color: ${props => props.kmColor || props.theme.colors.secondary}
        }
        .location {
            font-size: 1rem;
            line-height: 1.1;
        }
    }
    .landing-title-wrapper {
        text-align: center;
    }

    .description-subtitle h1, .description-subtitle h2, .description-subtitle h3 {
        margin-top: 0;
    }

    @media only screen and (max-width: 800px) {
        .routes-item {
            max-width: 100%;
            .location{
                min-height: 40px;
            }
        }
    }
`;
