import style from 'styled-components';

export const StyledMobileDetails = style.div`
    display:none;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    color: ${props => props.theme.font.colors.primary};
    background: #f2f2f2;
    border-top: .3px solid #e6e6e6;
    box-shadow: 0 0 35px #00000012;

    .more-info{
        .button {
            height: 100%;
            width: 100%;
            background: transparent;
            border:none;
            i{
                color: ${props => props.theme.font.colors.primary};
            }
            &:focus{
                outline: none;
            }
        }
    }

    .total-block{
        line-height: 1.2;
        .days{
            margin: 0;
            font-size: 12px;
            margin-top: 15px;
        }
        .total-price{
            margin-bottom: 0;
            font-weight: bold;
            font-size:20px;
        }
    }
    .payment-button button{
        width: 100%;
        height: 100%;
        display: block;
        padding: 15px 7px 15px 7px;
        background: ${props => props.bgColor || props.theme.colors.primary};
        border-color:  ${props => props.bgColor || props.theme.colors.primary};
        text-align: center;
        color: ${props => props.color || 'white'};
        font-weight: ${props => props.weight || 'bold'};
        font-size: ${props => props.size || '15px'};
        line-height: 1.2;
    }    

    .popup-overlay .popup-content {
        width: 90% !important;
        min-height: 100px;
        padding: 0 !important;
        text-align: left;

        .modal{
            display: contents;
            
            .header{
                padding: 22px 20px 14px;
                border-bottom: 2px solid #f2f2f2;
                margin-bottom: -15px!important;
                div{
                    width: 90%;
                    display: inline-block;
                }
                i{
                    font-size:20px;
                }
            }
            .payment-details{
                padding: 0 20px;
                ul li{
                    font-size: 14px;
                    margin-bottom: 8px;
                    i{
                        font-size: 10px;
                    }
                }
            }
            .payment{
                background: #f2f2f2;
                padding: 15px 20px;
                margin-bottom: 0 !important;

                .total-name{
                    h3{
                        font-size: 16px;
                        margin-bottom: 0 !important;
                        margin-top: 7px;
                    }
                }
                .days{
                    font-size: 13px;
                    margin-bottom: 0;
                }
                .total-price span{
                    font-size: 22px;
                    font-weight: bold;
                }
                .diario{
                    font-size: 12px;
                    margin-bottom: 0;
                    .span{
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
            .complete-info a{
                color: red;
                margin-bottom: 0;
                i{
                    font-size: 14px;
                }
            }
        }
    }
      
    @media only screen and (max-width: ${props => props.theme.screens.lg}) {
        display: flex;
    }
`;
