import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoadingWrapperStyled } from './TransbankSection.styled';
import { withTranslation } from 'react-i18next';
import { CustomLoading } from 'components/';
import { tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import { payBooking, payInfraction, getBooking } from 'services/api';
import Storage from 'utils/storage';
import { is_gtag_enabled, gtag_purchase } from '../../../utils/googleTagsHelper';

class Transbank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
      },
      dolarPrice: 0,
      formLoading: false,
      tokenLoading: true,
      url: null,
      token: null,
    };
  }

  async componentDidMount() {
    const {
      location,
      t,
      i18n,
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;
    const parseQs = queryString.parse(location.search);

    if (parseQs.bookingid) {
      const token = parseQs.token || Storage.getElement('transbanktoken');
      const price = parseQs.amount || Storage.getElement('transbankprice');
      const isInfraction = Storage.getElement('transbankisinfraction');

      try {
        Storage.setElement('transbanktoken', '', false); //delete storage
        Storage.setElement('transbankprice', '', false); //delete storage
        Storage.setElement('transbankisinfraction', '', false); //delete storage

        if (isInfraction && isInfraction === 'true') {
          payInfraction({
            infractionId: parseQs.bookingid,
            gatewayId: 'TBANKCL',
            orderID: token,
            price: price,
          });
        } else {
          payBooking({
            bookingId: parseQs.bookingid,
            gatewayId: 'TBANKCL',
            orderID: token,
            price: price,
          });

          if (is_gtag_enabled(googleTagManager)) {
            const booking = await getBooking(null, parseQs.bookingid);
            if (booking && booking.data) gtag_purchase(booking.data, settings);
          }
        }

        history.push(
          `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
        );
      } catch (error) {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          { error: t('paypalGenericError') },
        );
      }
    } else {
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        {
          error: t('paypalGenericError'),
        },
      );
    }
  }

  render() {
    const { t, loadingGif, loadingMessage } = this.props;

    return (
      <LoadingWrapperStyled className="col-md-12">
        <CustomLoading
          loadingGif={loadingGif || t('loadingGif')}
          loadingMessage={loadingMessage}
        ></CustomLoading>
      </LoadingWrapperStyled>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(Transbank)));
