import style from 'styled-components';

export const StyledCalidadServicioModern = style.section`
    background: ${props => (props.background ? `url(${props.background}) no-repeat` : `#FFF`)};
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
	min-height: 360px;
    padding: 0;
    text-align: left;
    color:${props => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-item: center;
    margin:100px 0;



    .title h3{
        color:${props => props.theme.colors.primary};
        font-size:30px
        font-weight:400;
    }

    .imgHighlight{
        position:relative;
        right:60px
    }

    .imgHighlight img{
     border-radius:0 500px 500px 0;
     max-width: 100%;
     height: auto;
    }

    .contentBox {
        display: flex;
        flex-direction: column;

        .stars {
            color: ${props => props.color || props.theme.colors.buttonPrimaryBg};
            font-size: 17px;
            margin-bottom: 18px;
            line-height: 26px;
    
            .fa-star {
                margin: 0 3px;
            }
        }
    
        .textContent{
            text-align: left;
            font-weight: 400;
            font-size: ${props => props.textContentSize || '12px'};
            line-height: 20px;
            text-size-adjust: 100%;
            color:#656970;
            font-size:20px;
        }
        .statement  {
            text-align: left;
            font-weight: 400;
            font-size: ${props => props.statementSize || '12px'};
            line-height: 20px;
            text-size-adjust: 100%;
            color:#2E2D35;
        }
    }

    @media(max-width:767px){
        .title h3{
            font-size:25px !important;
        }
        .contentBox {
          padding:0
          right:0
        }

        .imgHighlight{
            order:2
        }
    
    }

    
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .contentBox {
        right:0
      }

      .imgHighlight{
        order:2
    }
}
`;
