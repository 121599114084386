export const tt = (key, t, forceTranslate, paramsObj = null) => {
  if (key && typeof key === 'string' && key.indexOf('__') === 0) return t(key, paramsObj);

  if (forceTranslate) return t(key, paramsObj);

  return key;
};

export const translate = (key, t, forceTranslate) => {
  if (key && typeof key === 'string' && key.indexOf('__') === 0) return t(key);

  if (forceTranslate) return t(key);

  return key;
};

export const getLanguagePath = (langConfig, lang) => {
  if (langConfig) {
    return (langConfig[lang] && langConfig[lang].url) || lang;
  }
  return lang;
};

export const tlink = (key, t, i18n, lang = null, langConfig = null, params = null) => {
  if (lang) {
    t = i18n.getFixedT(lang);
  }

  lang = lang || i18n.language;

  let base = lang !== i18n.options.fallbackLng[0] ? `/${getLanguagePath(langConfig, lang)}` : '';

  let route = tt(key, t);

  if (params) {
    let stringParams = '';
    Object.entries(params).forEach(([key, value]) => {
      stringParams += `${key}=${value}&`;
    });
    route += `?${stringParams}`;
  }

  if (route === '/' && base !== '') route = '';

  return base + route;
};
