import Logo from 'components/logo';
import TranslatedLink from 'components/TranslatedLink';
import React from 'react';
import { Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { props } from './NavBarHeader.props';
import { StyledHeader } from './NavBarHeader.styled';
import { tlink } from 'utils/translationHelper';
import { LangDropdown, MyAccountNavLink } from 'components';
import useWindowDimensions from 'utils/windowHelper';
import CurrenciesDropdown from 'components/CurrenciesDropdown';
import { changeCurrencyAction } from 'actions/global.actions';

function NavBarHeader({
  t,
  settings,
  location,
  i18n,
  bgColor,
  color,
  logo,
  logoWidth,
  rightNav,
  variant,
  activeColor,
  rightBtnColor,
  langs,
  comm,
  listCurrencies,
  defaultCurrency,
  changeCurrencyAction,
}) {
  const links = settings.configurations.sitemap.links;
  const showMyAccount =
    settings.myAccountConfiguration && settings.myAccountConfiguration.showMyAccount;
  const showCurrenciesDropdown =
    settings.featureFlags && settings.featureFlags.showCurrenciesDropdown;

  const { width } = useWindowDimensions();
  const isMobile = width < 1200;

  const isActive = navItem => {
    if (
      location.pathname === tlink(navItem.path, t, i18n, null, settings.configurations.langConfig)
    )
      return 'active';

    return '';
  };

  const settingsCurrency = settings.currency;

  return (
    <StyledHeader
      className="row"
      color={color}
      bgColor={bgColor}
      activeColor={activeColor}
      rightBtnColor={rightBtnColor}
    >
      <Col>
        <Navbar collapseOnSelect expand="xl" variant={variant || 'light'}>
          {logo && (
            <Logo src={logo} alt={settings.configurations.title || 'logo'} width={logoWidth} />
          )}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto ml-auto">
              {links.map((link, idx) => {
                if (!link.noShowInHeader) {
                  if (link.dropdown) {
                    return (
                      <NavDropdown
                        key={idx}
                        title={t(link.name)}
                        id={`${link.name}-collasible-nav-dropdown`}
                      >
                        {link.subItems.map((item, index) => {
                          return (
                            <NavDropdown.Item className={isActive(item)} key={index}>
                              <TranslatedLink className="nav-link" {...item} />
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                    );
                  }
                  return (
                    <Nav.Item key={idx} className={isActive(link)}>
                      <TranslatedLink className="nav-link" {...link} />
                    </Nav.Item>
                  );
                }
                return null;
              })}
              {showMyAccount && isMobile && <MyAccountNavLink className="my-account_mobile" />}
            </Nav>
            {rightNav && (
              <Nav className="right-navbar">
                {rightNav.links.length > 0 &&
                  (settings.featureFlags && settings.featureFlags.cancelBookingButton ? (
                    rightNav.links.map((item, idx) => {
                      return (
                        <div key={idx} className="cancel-button">
                          <TranslatedLink className="nav-link" {...item}>
                            {t(rightNav.title) || t('manageBooking')}
                          </TranslatedLink>
                        </div>
                      );
                    })
                  ) : (
                    <NavDropdown
                      className="action-button"
                      title={t(rightNav.title) || t('manageBooking')}
                      id="collasible-right-nav-dropdown"
                    >
                      {rightNav.links.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <TranslatedLink className="nav-link" {...item} />
                          </div>
                        );
                      })}
                    </NavDropdown>
                  ))}
              </Nav>
            )}

            <LangDropdown
              classNames="navbarLang"
              langs={langs}
              handleOnChange={lang => comm.changeLanguage(lang)}
              bgColor={bgColor || '#fff'}
            />

            {showCurrenciesDropdown && (
              <CurrenciesDropdown
                classNames="navbarLang"
                currencies={listCurrencies}
                defaultCurrency={defaultCurrency}
                handleOnChange={currency => {
                  changeCurrencyAction(currency);
                }}
                settingsCurrency={settingsCurrency}
              />
            )}

            {showMyAccount && !isMobile && <MyAccountNavLink />}
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </StyledHeader>
  );
}

NavBarHeader.propTypes = props;

const mapStateToProps = ({ siteConfiguration, global }) => ({
  settings: siteConfiguration.settings,
  langs: global.listLanguages,
  listCurrencies: global.listCurrencies,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
});

const mapDispatchToProps = dispatch => ({
  changeCurrencyAction: newCurrency => dispatch(changeCurrencyAction(newCurrency)),
});

const NavBarHeaderComp = withTranslation()(withRouter(NavBarHeader));

export default connect(mapStateToProps, mapDispatchToProps)(NavBarHeaderComp);
