import style from 'styled-components';

export const StyledApp = style.div`
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    position: relative;
    padding-bottom: 32px;
    min-height: 100vh;
    
    .old-version-message{
        background-color: #fff3cd;
        color: #a27a00;
        text-align: center;
        padding:4px;
        margin:0 -15px;
    }

    input, select, textarea {
        border-radius: 0 !important;
    }

    textarea::-webkit-input-placeholder, input::-webkit-input-placeholder, select{ 
        font-size: 12px;
        color: #6f6f6f;
    }

    .pl-0, .px-0 {
        padding-left: 0 !important;
    }
    
    .row-p {
        padding-right: 2% !important;
        padding-left: 2% !important;

        @media (min-width: ${props => props.theme.screens.xxxl}) {
            padding-left: 6% !important; padding-right:6% !important; 
        }
    }

    header.row-p {
        @media (max-width: ${props => props.theme.screens.md}) {
            padding-left: 0 !important; padding-right:0 !important; 
        }
    }

    .recaptcha{
        margin-bottom: 10px;
    }

    .contact-form-centered{
        .recaptcha div, button{
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    a{
        transition: all .2s;
        text-decoration: none;
        color: ${props => props.theme.font.colors.third};
    }

    a:hover{
        text-decoration: none;
        color: ${props => props.theme.font.colors.hover};
    }
    
    ul.contact-list li a {
        color: ${props => props.theme.font.colors.primary};
        line-height: 2em;
        font-size: 14px;

        &:hover{
            color: ${props => props.theme.colors.secondary};
            text-decoration: none;
        }
    }

    ul.contact-list li a i{
        margin-right:10px;
        color: ${props => props.theme.colors.secondary};
    }

    .parallax {
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .top-header{
        padding: 10px 0;
    }

    @media (max-width: ${props => props.theme.screens.md}) {
        margin-left:0px;
        
        &.mobile-navbar-show{
            margin-left:256px;
        }
    }
    .CalendarMonth_caption{
        text-transform: capitalize;
    }
`;

export const StyledError = style.div`

    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
    padding: 0px 20%;
    img{
        width:400px;
        margin-bottom:15px;
    }

    p{
        font-size:2em;
        color: grey;
    }
`;
