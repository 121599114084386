import { connect } from 'react-redux';
import CheckoutDetails from './CheckoutDetails';

const mapStateToProps = ({ global, searchCars, siteConfiguration }) => ({
  listAdditionals: global.listAdditionals,
  settings: siteConfiguration.settings,
  searchCars,
});

export default connect(mapStateToProps)(CheckoutDetails);
