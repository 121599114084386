import style from 'styled-components';

const getStatusColor = status => {
  switch (status) {
    case 0: // Reserved
      return '#5AB9ED';
    case 1: // Confirmed
      return '#FFA400';
    case 2: // Delivered
      return '#2BCE81';
    case 3: // Closed
      return '#858C99';
    case 4: // Canceled
      return '#DE0050';
    case 5: // Quoted
      return '#3257B2';
  }
  return '#858C99';
};

export const StyledBookingStatus = style.p` 
    font-size: 0.8rem;
    color: #656970;
    margin-left: 15px; 

    span {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        display: inline-block;
        background: ${({ status }) => getStatusColor(status)};
        margin-right: 5px;
    } 
`;
