import React from 'react';
import { PropTypes } from 'prop-types';
import * as Comps from 'components';
import * as Sects from 'sections';
import * as Pages from 'pages';
import { TranslatedComponent } from 'components/';
import parse from 'html-react-parser';

export default class CompositeComponent extends TranslatedComponent {
  static propTypes = {
    sections: PropTypes.array,
    sharedComponents: PropTypes.array,
    headers: PropTypes.array,
  };

  findComponent(type) {
    let ComponentToRender = Pages[type];
    if (!ComponentToRender) ComponentToRender = Sects[type];
    if (!ComponentToRender) ComponentToRender = Comps[type];

    return ComponentToRender;
  }

  renderComponent(props, key) {
    if (!props) {
      const { withWrapper } = this.props;

      return <>{this.renderChilds(withWrapper, null)}</>;
    }

    let { component } = props;

    const { sharedComponents } = this.props;

    if (component === 'SharedComponents' && sharedComponents !== undefined) {
      let shared = sharedComponents.find(x => x.name === props.name);
      if (shared) {
        props = { ...shared, ...props, ...{ component: shared.component } };
        component = shared.component;
      }
    }

    let ComponentToRender = this.findComponent(component);

    if (!ComponentToRender) return <h3>Component {component} not found</h3>;

    return <ComponentToRender {...props} key={key} routerProps={this.props.routerProps} />;
  }

  renderChilds(withWrapper, childProps) {
    const { sections } = this.props;

    return (
      <>
        {sections &&
          sections.map((section, i) =>
            withWrapper
              ? withWrapper(section, this.renderComponent(section, i), childProps, i)
              : this.renderComponent(section, i),
          )}
      </>
    );
  }

  renderHeaders() {
    const options = {
      replace: function transform(node, index) {
        if (node.type === 'script') {
          if (node.children.length > 0)
            return (
              <script key={`script-${node.children[0].data.length}`}>
                {node.children[0].data}
              </script>
            );

          return node;
        }
      },
    };

    const { headers } = this.props;

    if (!headers) return null;

    return headers && headers.map((header, i) => parse(header, options));
  }

  render() {
    return this.renderComponent();
  }
}
