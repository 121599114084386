import PropTypes from 'prop-types';

export const props = {
  offices: PropTypes.array,
  description: PropTypes.array,
  formBkg: PropTypes.string,
  formBkgAlt: PropTypes.string,
  formTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
