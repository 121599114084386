import React, { Component } from 'react';
import { StyledNotFound } from './NotFound.styled';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Title } from 'components';

class NotFoundPage extends Component {
  static propTypes = {
    settings: PropTypes.shape({ title: PropTypes.string }),
    t: PropTypes.func,
  };

  componentDidMount() {
    const {
      settings: { configurations },
      t,
    } = this.props;

    document.title = `${t('PageNotFound')} | ${configurations.title}`;
  }

  render() {
    const { t } = this.props;

    return (
      <StyledNotFound>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <div />
              <h1>404</h1>
            </div>
            <Title type="h2" text={t('PageNotFound')} />
            <p>{t('PageNotFoundDescription')}</p>
          </div>
        </div>
      </StyledNotFound>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps)(withTranslation()(NotFoundPage));
