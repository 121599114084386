import PropTypes from 'prop-types';

export const props = {
  whenLoggedAsCompany: PropTypes.object,
  whenLoggedAsUser: PropTypes.object,
  default: PropTypes.object.isRequired,
};

export const defaultProps = {
  default: {
    title: '',
    subtitle: '',
    message: '',
  },
};
