import PropTypes from 'prop-types';

export const props = {
  car: PropTypes.shape({
    imagePath: PropTypes.string,
    brand: PropTypes.shape({ name: PropTypes.string }),
    doors: PropTypes.number,
    category: PropTypes.shape({ name: PropTypes.string }),
    passengers: PropTypes.number,
    bigLuggage: PropTypes.number,
    smallLuggage: PropTypes.number,
    steering: PropTypes.string,
    gearbox: PropTypes.string,
    airConditioner: PropTypes.string,
    dailyPrice: PropTypes.number,
    name: PropTypes.string,
    multimedia: PropTypes.string,
    background: PropTypes.string,
    t: PropTypes.func,
    currentCurrency: PropTypes.object,
    defaultCurrency: PropTypes.object,
    webCurrency: PropTypes.object,
  }),
};
