import style from 'styled-components';

export const StyledTitleRow = style.section`
  background: ${props => props.bg || props.theme.colors.bg};
  text-align: center;
  overflow-x: hidden;

  .home-title {
    margin-top: 5em;
    line-height: 1.5;
    position:relative;
    left:50px;

    h1{
      text-align:left;
      font-size:30px !important;
      font-weight:400;
      color:${props => props.theme.colors.primary};
    }

    p {
      line-height: 22px;
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    @media (max-width: ${props => props.theme.screens.xs}){
      padding:10px;
      left:0;
    }

    @media(max-width:767px){
      h1{
        font-size:25px !important;
      }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      h1 {
           position:relative;
           right:25px !important;
       }
      }
`;

export const StyledTestimoniesWrapper = style.section`

  border-bottom: ${props => (props.showBorder ? '3px' : 0)} solid ${props =>
  props.borderColor || props.theme.colors.primary};
  padding-bottom: 100px;
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  .oneSlide {
    padding: 1rem;
    background-color: ${props => props.theme.colors.primaryTransparent };
    border-radius: 20px;

    .slick-dots {
      bottom: -10px;

      li button:before{
        color: ${props => props.theme.font.colors.secondary};
      }
    } 

    p {
      color: ${props => props.theme.font.colors.secondary};
      text-align:center!important;
    }
  }
  
  h4 {
    margin-bottom:50px;
    text-align:left;
    font-size:20px !important;
    font-weight:400;
    color:${props => props.theme.colors.primary};

    strong {
      font-weight: 700;
    }
  }
  p{
    font-size: 0.9rem;
    padding: 0 30px;
    text-align:left !important;
    color:#656970;
  }

  .source{
    font-style: italic;
    text-align: center!important;
  }

  .textbold{
    font-weight:bold;
    font-size:17px;
    text-align:center !important;
    border-radius: 20px;
    margin: 15px 30px;
    padding:2px 0;
  }

  .textbold:hover{
    color:${props => props.theme.colors.primary};
    transition: 0.2s
  }

  a {
    font-size: 13px !important;
    color: ${props => props.theme.font.colors.third};
    transition: all .2s;
  }

  a:hover {
    text-decoration: none;
    color: ${props => props.leftLinkHover || props.theme.colors.primary} !important; 
  }

  img {
    cursor: grab;
  }

  div.img {
    height: fit-content;
    text-align: center;
    height: 70px;
    width: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
  }
  

  div.face {
    height: fit-content;
    text-align: center;
    height: 90px;
    width: 160px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto 10px;
  }

  .slick-track{
    margin: auto auto;
  }

  .slick-next{
    right: -10px;
  }
  
  .slick-prev{
    left: -10px;
  }

  .slick-next:before {
    content: "\f105" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    color: #ccc
    
  }

  .slick-prev:before {
    content: "\f104" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    color: #ccc
  }

`;
