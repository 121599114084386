import style from 'styled-components';

export const SearchItemRowStyled = style.div`
    background: ${props => props.background || props.theme.colors.third};

    .car-description{
      display: flex;
    }

    .separator {
      color: #6f6f6f;
    }

    .car-name {
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .car-image{
      border: 1px solid #CBCBCB;
      height: fit-content;
      text-align: center;
      flex: 0 0 33%;
      max-width: 33%;
    }
    
    .tachometer {
      color: ${props => props.theme.colors.buttonPrimaryBgHover};
    }

    .car-image img{
      max-height: 164px; 
    }

    .car-category{
      font-weight: 600;
    }
    
    .car-price {
      margin: auto;
      text-align: center;

      p.rent-days {
        font-weight: 400;
      }

      p.rent-price {
        margin-top: 5px !important;
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        white-space: nowrap;

        .long-value{
          font-size: 24px;
        }

        .short-value{
        font-size: 24px;
        }

        span {
          font-size: 28px;
        }
      }

      button {
        width: 100%;
        .Button-icon{
          margin: 0px 5px;
        }
      }

      .customButton{
        flex-direction: column;

        .button-container{
          display: flex;
          width: 100%;

          .button-icon{
            width: 10%;
            margin-right: 0.3rem;
          }
  
          .button-text{
            width: 100%;
          }

          .with-icon{
            width: 90%!important;
          }
        }      

        .button-subtext{
          font-size: 0.5rem;
          margin-top: 0.5rem;
        }
      }

      .second-btn {
        color: #000;
        margin-top: 1em;
        font-size: 13px;
        white-space: pre-wrap;
        display: block;
        
        span {
          padding-left: 3px;

          .CheckoutLink-icon{
            margin: 0px 5px;
          }
        }
      }
    }

    .details {
      width: 100%;

      .details-item {
        text-align: center;
        margin-right: 5px;
        display: inline-block;
        width: 10.5%;

        img{
          border: 1px solid #CCCCCC;
          padding: 5px;
          border-radius: 7px;
          margin-bottom: 5px;
          width: 100%;
          max-width: 40px;
        }

        span {
          margin-top: 5px;
          font-weight: 400;
          font-size: .75em;
        }
      }
    }

    .car-mobile-details {
      display: none;
    }

    .partial-availability-mobile {
      display: none;
    }

    .disabled-link {
      cursor: default;
      font-style: italic;
    }

    @media (max-width: ${props => props.theme.screens.md}){
      flex-direction: column;
      padding: 20px 5px !important;

      .md-hidden{
        display: none;
      }
    
      .car-mobile-details {
        display: block;
      }
    }

    @media (max-width:980px) and (min-width:425px){
      .customButton{
        .button-container{
          width: 40%!important;
        }
      }
    }

    @media (max-width: ${props => props.theme.screens.lg}) {
      .car-image{
        margin-right: 1rem !important;
        flex: 0 0 40%;
        max-width: 40%;
      }

      .car-name {
        font-size: 15px;
      }

      .franchise {
        font-size: 12px;
        line-height: 22px;
      }
    }

    @media (max-width:${props => props.theme.screens.sm}) {
      .partial-availability-desktop {
        display: none;
      }

      .customButton{
        .button-container{
          width: 100%!important;
        }
      }

      .partial-availability-mobile {
        display: block;
      }
    }

`;
