import PropTypes from 'prop-types';

export const props = {
  className: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  fontSize: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  selected: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      }),
    ]),
  ),
};

export const defaultProps = {
  options: [],
  disabled: false,
  placeholder: 'Select',
};
