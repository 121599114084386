import React from 'react';
import { Link } from 'react-router-dom';
import { StyledHeaderItem } from './header.styled';
import { HeaderItemProps, HeaderItemDefaultProps } from './header.props';
import { tt, tlink } from '../../utils/translationHelper';
import { gtag_report_conversion } from '../../utils/googleTagsHelper';
import { useTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { Btn } from 'components';

const HeaderItem = ({
  content,
  label,
  icon,
  className,
  color,
  hover,
  callback,
  iconHover,
  settings,
  location,
}) => {
  const { t, i18n } = useTranslation();

  let iconItem;

  if (icon && icon.includes('fa') && !icon.includes('.')) {
    iconItem = <i className={`font-icon icon ${icon} float-right ml-2`} />;
  } else {
    const faIcon = content.type === 'bphone' ? 'fas fa-phone' : 'fab fa-whatsapp';
    const socialItemFlag = content.type === 'socialLink' ? true : false;
    const faIconFb =
      (content.type === 'socialLink') & (content.name === 'facebook')
        ? 'fab fa-facebook'
        : 'fab fa-instagram';

    iconItem = (
      // React.Fragment
      <>
        <div className="icon icon-img ml-2 d-none d-md-block">
          {icon && <img src={icon} alt={icon} />}
        </div>
        {/* if there is a socialItem with name 'facebook' or 'instagram' in header */}
        {socialItemFlag ? (
          <i className={faIconFb + ` font-icon icon float-right ml-2 d-sm-block d-md-none`} />
        ) : (
          <i className={faIcon + ` font-icon icon float-right ml-2 d-sm-block d-md-none`} />
        )}
      </>
    );
  }

  const contentText = (
    <div className="content-text d-none d-md-block mt-1">
      <span className="label">{tt(label, t)}</span>
      <br />
      <p>{tt(content.text, t)}</p>
    </div>
  );

  let contentType;
  switch (content.type) {
    case 'link': {
      contentType = (
        <Link
          className="content-item"
          to={tlink(content.path, t, i18n, null, settings.configurations.langConfig)}
        >
          {contentText}
          {iconItem}
        </Link>
      );
      break;
    }

    case 'socialLink': {
      contentType = (
        <a
          className="content-item"
          target="_blank"
          rel="noopener noreferrer"
          href={`${content.path}`}
        >
          {contentText}
          {iconItem}
        </a>
      );
      break;
    }

    case 'customHtml': {
      contentType = (
        <a
          className="content-item"
          target="_blank"
          rel="noopener noreferrer"
          href={`${content.path}`}
        >
          <div
            className={`${className ? className : ''} customContent`}
            dangerouslySetInnerHTML={{ __html: content.text }}
          />
        </a>
      );
      break;
    }

    case 'phone': {
      const wpUrl = `https://api.whatsapp.com/send?phone=${content.path}`;

      if (content.GTMEventId) {
        contentType = (
          <a
            className="content-item"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => gtag_report_conversion(wpUrl, content.GTMEventId)}
            id={`whatsapp-button-${location}`}
          >
            {contentText}
            {iconItem}
          </a>
        );
      } else {
        contentType = (
          <a
            className="content-item"
            id={`whatsapp-button-${location}`}
            target="_blank"
            rel="noopener noreferrer"
            href={wpUrl}
          >
            {contentText}
            {iconItem}
          </a>
        );
      }

      break;
    }

    case 'bphone': {
      contentType = (
        <a
          className="content-item"
          target="_blank"
          rel="noopener noreferrer"
          href={`tel:${content.path}`}
        >
          {contentText}
          {iconItem}
        </a>
      );
      break;
    }

    case 'email': {
      contentType = (
        <a className="content-item" href={`mailto:${content.path}`}>
          {contentText}
          {iconItem}
        </a>
      );
      break;
    }

    case 'text': {
      contentType = (
        <div className="content-item">
          {contentText}
          {iconItem}
        </div>
      );
      break;
    }

    case 'button': {
      contentType = (
        <Btn className="header-associates-btn" text={content.text} link={content.path} />
      );
      break;
    }

    default: {
      contentType = (
        <Link
          className="content-item"
          to={tlink(content.path, t, i18n, null, settings.configurations.langConfig)}
        >
          {contentText}
          {iconItem}
        </Link>
      );
      break;
    }
  }
  return (
    <StyledHeaderItem
      className={`${className} header-item`}
      color={color}
      hover={hover}
      onClick={callback}
      iconHover={iconHover}
    >
      {contentType}
    </StyledHeaderItem>
  );
};

HeaderItem.propTypes = HeaderItemProps;
HeaderItem.defaultProps = HeaderItemDefaultProps;
export default withSettingsContext(HeaderItem);
