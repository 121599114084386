import React from 'react';
import { props, defaults } from './Loading.props';
import { StyledLoadingContainer } from './Loading.styled';

const LoadingComponent = ({ show, color, className, fontAwesomeClass, size }) => {
  if (!show) return null;
  return (
    <StyledLoadingContainer size={size} color={color} className={className}>
      <div className="loading">
        <i className={`rotating ${fontAwesomeClass} fa-3x`} />{' '}
      </div>
    </StyledLoadingContainer>
  );
};

LoadingComponent.propTypes = props;
LoadingComponent.defaultProps = defaults;

export default LoadingComponent;
