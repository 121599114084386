import React from 'react';
import { Title, Btn, Paragraph } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledCecabankSection } from './CecabankSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import { getCecabankTransUri } from 'services/api';
import Storage from 'utils/storage';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';

class CecabankSection extends BasePaymentSection {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        qsamount: 0,
        bookingId: '0',
        currencyCode: '',
        exchangeRate: 1,
        branchOfficeId: 0,
      },
      formLoading: false,
      transactionLoading: true,
      url: null,
      merchantID: null,
      acquirerBIN: null,
      terminalID: null,
      urlOK: null,
      urlNOK: null,
      sign: null,
      operationNumber: null,
      amount: null,
      currencyCode: null,
    };
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;

    if (bookings.confirmation != null) {
      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Cecabank' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(bookings.confirmation.customerBalance % 1 === 0),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          currencyCode: bookings.confirmation.currency,
          branchOfficeId: bookings.confirmation.branchOfficeId,
          exchangeRate: 1,
        },
      }));
      this.GetTransactionURL();
    }
  }

  GetTransactionURL = () => {
    const { t, i18n, bookings, settings } = this.props;
    getCecabankTransUri(
      bookings.confirmation.branchOfficeId,
      bookings.confirmation.id,
      this.getTotalToPay(bookings.confirmation.customerBalance % 1 === 0),
      bookings.confirmation.currency,
    )
      .then(res => {
        Storage.setElement('cecabankoperationnumber', res.data.operationNumber);
        Storage.setElement(
          'cecabankprice',
          this.getTotalToPay(bookings.confirmation.customerBalance % 1 === 0),
        );
        this.setState(prevState => ({
          ...prevState,
          url: res.data.urlAction,
          transactionLoading: false,
          merchantID: res.data.merchantID,
          acquirerBIN: res.data.acquirerBIN,
          terminalID: res.data.terminalID,
          urlOK: res.data.urlOK,
          urlNOK: res.data.urlNOK,
          sign: res.data.sign,
          operationNumber: res.data.operationNumber,
          amount: res.data.amount,
          currencyCode: res.data.currency,
        }));
      })
      .catch(error => {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          { error: t('paymentGenericError') },
        );
      });
  };

  render() {
    const { t, title, subtitle, currentCurrency, defaultCurrency, webCurrency } = this.props;
    let returnFinalPriceOnly = true;
    const { paymentInfo } = this.state;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);
    const showExchangeMsg = applyExchangeRate;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    return (
      <StyledCecabankSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                {t('orden')} {paymentInfo.bookingId.toString().padStart(7, '0')}
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form
                action={this.state.url}
                method="post"
                encType="application/x-www-form-urlencoded"
              >
                <input type="hidden" name="MerchantID" value={this.state.merchantID} />
                <input type="hidden" name="AcquirerBIN" value={this.state.acquirerBIN} />
                <input type="hidden" name="TerminalID" value={this.state.terminalID} />
                <input type="hidden" name="URL_OK" value={this.state.urlOK} />
                <input type="hidden" name="URL_NOK" value={this.state.urlNOK} />
                <input type="hidden" name="Firma" value={this.state.sign} />
                <input type="hidden" name="Cifrado" value="SHA2" />
                <input type="hidden" name="Num_operacion" value={this.state.operationNumber} />
                <input type="hidden" name="Importe" value={this.state.amount} />
                <input type="hidden" name="TipoMoneda" value={this.state.currencyCode} />
                <input type="hidden" name="Exponente" value="2" />
                <input type="hidden" name="Pago_soportado" value="SSL" />
                <input type="hidden" name="Idioma" value="1" />
                <input type="hidden" name="datos_acs_20" value="" />
                <input type="hidden" name="firma_acs_20" value="" />
                <Btn
                  type="submit"
                  name="btnSubmit"
                  text={
                    this.state.formLoading || this.state.transactionLoading
                      ? t('loadingTransbank')
                      : t('transbankBtn')
                  }
                  disabled={this.state.formLoading || this.state.transactionLoading}
                  className="mt-5 cecabank-pay_button"
                />
              </form>
            </div>
          </div>
        </div>
      </StyledCecabankSection>
    );
  }
}

const mapStateToProps = ({ bookings, siteConfiguration, global }) => ({
  bookings: bookings,
  settings: siteConfiguration.settings,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(CecabankSection)));
