import React from 'react';
import { props } from '../PagaEnCuotas.props';
import { StyledPagaEnCuotas, StyledBanksSection } from './index.styles';
import Slider from 'react-slick';
import { TranslatedComponent, Title } from 'components/';
import { withTranslation } from 'react-i18next';

const sliderSettings = {
  dots: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 6,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

class DefaultPagaEnCuotas extends TranslatedComponent {
  static propTypes = props;

  render() {
    const {
      title,
      titleType,
      subTitle,
      leftTitle,
      leftStrongTitle,
      leftLinkText,
      leftLinkUrl,
      items,
      showBorder,
      borderColor,
      leftLinkHover,
      hideTitle,
    } = this.props;
    return (
      <>
        {hideTitle === true ? (
          ''
        ) : (
          <StyledPagaEnCuotas className="row row-p">
            <div className="home-title text-center ml-auto mr-auto mt-5 mb-2">
              <Title text={this.tt(title)} type={titleType || 'h1'} />
              <p>{this.tt(subTitle)}</p>
            </div>
          </StyledPagaEnCuotas>
        )}
        <StyledBanksSection
          className="home-banks row row-p mb-4 pt-4 mt-2"
          showBorder={showBorder}
          borderColor={borderColor}
          leftLinkHover={leftLinkHover}
        >
          {leftTitle && (
            <div className="col-md-3 col-lg-2 mb-3 text-center">
              <h4>
                {this.tt(leftTitle)}
                <br />
                <strong>{this.tt(leftStrongTitle)}</strong>
              </h4>
              <a target="_blank" rel="noreferrer noopener" href={this.tt(leftLinkUrl)}>
                <i className="fa fa-angle-right" /> {this.tt(leftLinkText)}
              </a>
            </div>
          )}
          <div className={leftTitle ? 'col-md-9 col-lg-10' : 'col-md-12 centered'}>
            <Slider {...sliderSettings}>
              {items.map((promo, key) => (
                <div key={key}>
                  <div
                    className="img"
                    style={{
                      backgroundImage: 'url(' + this.tt(promo.imgSrc) + ')',
                    }}
                  />
                  {this.tt(promo.name) && <p className="text-center">{this.tt(promo.name)}</p>}
                </div>
              ))}
            </Slider>
          </div>
        </StyledBanksSection>
      </>
    );
  }
}

export default withTranslation()(DefaultPagaEnCuotas);
