import React from 'react';
import { useTranslation } from 'react-i18next';
import { props } from './Testimonies.props';
import { StyledTitleRow, StyledTestimoniesWrapper } from './Testimonies.styled';
import Slider from 'react-slick';
import { Title } from 'components/';
import withSettingsContext from 'SettingsContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import useWindowDimensions from 'utils/windowHelper';

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 4,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Testimonies = ({
  title,
  titleType,
  subTitle,
  items,
  showBorder,
  borderColor,
  leftLinkHover,
  hideTitle,
  sliderCustomSettings,
  backgroundImage,
  mobileBackgroundImage,
}) => {
  const { t } = useTranslation();

  const settings = {
    ...sliderSettings,
    ...(sliderCustomSettings ? sliderCustomSettings : {}),
  };

  const { width } = useWindowDimensions();
  const isMobile = width < 600;

  return (
    <>
      {!hideTitle && (
        <StyledTitleRow className="row row-p">
          <div className="home-title text-left ">
            <Title text={t(title)} type={titleType || 'h1'} />
            <p>{t(subTitle)}</p>
          </div>
        </StyledTitleRow>
      )}
      <StyledTestimoniesWrapper
        className="homeTestimonies row row-p mb-4 pt-4 mt-2 mb-4"
        showBorder={showBorder}
        borderColor={borderColor}
        leftLinkHover={leftLinkHover}
        backgroundImage={isMobile && mobileBackgroundImage || backgroundImage}
      >
        <div className={sliderCustomSettings?.slidesToShow === 1 ? `col-md-${sliderCustomSettings?.columnsNumber || 12} oneSlide` :"col-md-12 centered"}>
          <Slider {...settings}>
            {items.map((promo, key) => (
              <div key={key}>
                <div className="img">
                  <LazyLoadImage
                    src={t(promo.imgSrc)}
                    alt={`promo`}
                    threshold={100}
                    effect="blur"
                    height={'30px'}
                    width={'170px'}
                  />
                </div>
                <div className="face">
                  <LazyLoadImage
                    src={t(promo.faceSrc)}
                    alt={`promo`}
                    threshold={100}
                    effect="blur"
                    height={'100px'}
                  />
                </div>

                {t(promo.customername) && (
                  <p className="text-center textbold">{t(promo.customername)}</p>
                )}
                {t(promo.source) && (
                  <p className="text-center source">{t(promo.source)}</p>
                )}
                {t(promo.name) && <p className="text-center">{t(promo.name)}</p>}
              </div>
            ))}
          </Slider>
        </div>
      </StyledTestimoniesWrapper>
    </>
  );
};

Testimonies.propTypes = props;

export default withSettingsContext(Testimonies);
