const ONE_WAY_DROPOFF_TYPE = 10;
const ADDITIONALS_TYPE = 1;
const INSURANCES_TYPE = 16;
const DELIVERY_PLACE_TYPE = 3;
const RETURN_PLACE_TYPE = 11;
const TAXES_TYPE = 15;

const EVENT_NAME = 'rently.ec';

export function gtag_report_conversion(path, eventId) {
  var callback = function() {
    if (typeof path != 'undefined') {
      // window.location = path;
      window.open(path, '_blank');
    }
  };

  gtag('event', 'conversion', { send_to: eventId, event_callback: callback });
  return false;
}

export function is_gtag_enabled(gtmConfiguration) {
  if (!gtmConfiguration) return false;
  return gtmConfiguration.id && gtmConfiguration.enableGtmEcommerce;
}

export function gtag_additional_impresions(groupedAdditionals, additionalTypes, settings) {
  const { currencyCode } = readSettings(settings);

  const additionals = groupedAdditionals[additionalTypes[0]];
  const insurances = groupedAdditionals[additionalTypes[1]];
  const others = groupedAdditionals[additionalTypes[2]];

  let items = [];

  if (additionals)
    additionals.forEach((additional, index) =>
      items.push(parseAdditionalItem('Additionals', additional, index)),
    );

  if (insurances)
    insurances.forEach((additional, index) =>
      items.push(parseAdditionalItem('Insurances', additional, index)),
    );

  if (others)
    others.forEach((additional, index) =>
      items.push(parseAdditionalItem('Others', additional, index)),
    );

  gtag_product_impressions(items, currencyCode);
}

export function gtag_car_impressions(cars, settings) {
  let items = [];
  const { availabilityGroup, currencyCode } = readSettings(settings);
  cars.forEach((item, index) => items.push(parseCarItem(item, index, availabilityGroup)));

  gtag_product_impressions(items, currencyCode);
}

// Checkout events
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#checkoutstep
export function gtag_checkout_process(step, booking, settings) {
  if (!booking || !booking.priceItems) return;

  let items = [];
  const { availabilityGroup } = readSettings(settings);

  booking.priceItems.forEach(item =>
    items.push(parseItemPriceToProduct(item, booking, availabilityGroup)),
  );
  const layer = window.dataLayer || [];

  layer.push({ ecommerce: null });
  layer.push({
    event: EVENT_NAME,
    eventAction: 'Checkout',
    eventCategory: 'Ecommerce',
    ecommerce: {
      checkout: {
        actionField: { step: step.number, option: step.option },
        products: items.filter(item => item),
      },
    },
  });
}

// Checkout option (Pay Method)
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#checkout_option
export function gtag_checkout_option(step) {
  const layer = window.dataLayer || [];
  layer.push({ ecommerce: null });
  layer.push({
    event: EVENT_NAME,
    eventAction: 'checkoutOption',
    eventCategory: 'Ecommerce',
    ecommerce: {
      checkout_option: {
        actionField: { step: step.number, option: step.option },
      },
    },
  });
}

// Purchase event
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases
export function gtag_purchase(booking, settings) {
  if (!booking || !booking.priceItems) return;

  let items = [];
  const { availabilityGroup } = readSettings(settings);

  booking.priceItems.forEach(item =>
    items.push(parseItemPriceToProduct(item, booking, availabilityGroup)),
  );
  const layer = window.dataLayer || [];

  layer.push({ ecommerce: null });
  layer.push({
    event: EVENT_NAME,
    eventAction: 'purchase',
    eventCategory: 'Ecommerce',
    ecommerce: {
      purchase: {
        actionField: {
          id: booking.id,
          customerId: booking?.customer?.id,
          customerGlobalId: booking?.customer?.globalId || '',
          affiliation: 'Online Store',
          car: booking.car?.model?.name || booking.car?.model?.category?.name || '',
          pickupDate: booking.fromDate,
          dropoffDate: booking.toDate,
          revenue: booking.price,
          coupon: booking.promotionCode ? booking.promotionCode : '',
          tax: getBookingTaxAmount(booking),
          currency: booking.currency,
          shipping: 0,
        },
        products: items.filter(item => item),
      },
    },
  });
}

export function gtag_manage_shopping_cart(additional, actionObject, booking) {
  const eventName = actionObject.type === 'add' ? 'addToCart' : 'removeFromCart';

  const layer = window.dataLayer || [];
  layer.push({ ecommerce: null });

  const object = {
    event: EVENT_NAME,
    eventAction: eventName,
    eventCategory: 'Ecommerce',
    ecommerce: {},
  };

  object['ecommerce'][actionObject.type] = {
    products: [
      {
        name: additional.name,
        id: additional.id,
        price: additional.price,
        category: booking.deliveryPlace.branchOfficeName,
        variant: additional.type,
        quantity: actionObject.amount,
      },
    ],
  };

  layer.push(object);
}

function parseItemPriceToProduct(priceItem, booking, availabilityGroup) {
  let item = null;

  if (priceItem.isBookingPrice) item = parseNightPriceItem(priceItem, booking, availabilityGroup);

  if (priceItem.type === ONE_WAY_DROPOFF_TYPE)
    item = parseOneWayDropOffPriceItem(priceItem, booking);

  if (priceItem.type === ADDITIONALS_TYPE || priceItem.type === INSURANCES_TYPE)
    item = parseAdditionalPriceItem(priceItem, booking);

  if (priceItem.type === DELIVERY_PLACE_TYPE)
    item = parseDeliveryPriceItem(priceItem, booking, 'delivery');

  if (priceItem.type === RETURN_PLACE_TYPE)
    item = parseDeliveryPriceItem(priceItem, booking, 'return');

  return item;
}

function parseNightPriceItem(item, booking, availabilityGroup) {
  return {
    name:
      availabilityGroup === 'category' || booking.car == null
        ? booking.category.name
        : booking.car.model.sipp || booking.car.model.name,
    id:
      availabilityGroup === 'category' || booking.car == null
        ? booking.category.id
        : booking.car.model.id,
    price: item.price,
    category: booking.deliveryPlace.branchOfficeName,
    quantity: 1,
    variant: 'Rents',
  };
}

function parseOneWayDropOffPriceItem(item, booking) {
  return {
    name: 'One Way Dropoff',
    id: 'oneWayDropOff',
    price: item.price,
    category: booking.deliveryPlace.branchOfficeName,
    quantity: 1,
  };
}

function parseAdditionalPriceItem(item, booking) {
  const additional = booking.additionals.find(obj => obj.additional.id === item.typeId);
  if (!additional) return null;

  return {
    name: additional.additional.name,
    id: item.typeId,
    price: item.price / additional.quantity,
    category: booking.deliveryPlace.branchOfficeName,
    quantity: additional.quantity,
    variant: item.type === 1 ? 'Additionals' : 'Insurances',
  };
}

function parseDeliveryPriceItem(item, booking, type) {
  return {
    name: type === 'delivery' ? booking.deliveryPlace.name : booking.returnPlace.name,
    id:
      type === 'delivery'
        ? `Place - ${booking.deliveryPlace.name}`
        : `Place - ${booking.returnPlace.name}`,
    price: item.price,
    category: booking.deliveryPlace.branchOfficeName,
    brand: booking.deliveryPlace.category,
    quantity: 1,
    variant: 'Deliveries',
  };
}

function getBookingTaxAmount(booking) {
  const taxItems = booking.priceItems.filter(pi => pi.type === TAXES_TYPE);
  if (!taxItems) return 0;

  return taxItems.map(i => i.price).reduce((prev, curr) => prev + curr, 0);
}

// Measure products impressions
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-impressions
function gtag_product_impressions(items, currencyCode) {
  if (!items) return;
  const layer = window.dataLayer || [];

  layer.push({ event: EVENT_NAME, ecommerce: null });
  layer.push({
    event: EVENT_NAME,
    eventAction: 'Product Impression',
    eventCategory: 'Ecommerce',
    ecommerce: {
      currencyCode: currencyCode.toUpperCase(),
      impressions: items,
    },
  });
}

// Measure product clicks
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-clicks
export function gtag_product_click(item, settings) {
  const { availabilityGroup } = readSettings(settings);

  const items = [parseCarItem(item, 0, availabilityGroup)];

  const layer = window.dataLayer || [];
  layer.push({ ecommerce: null });
  layer.push({
    event: EVENT_NAME,
    eventAction: 'productClick',
    eventCategory: 'Ecommerce',
    ecommerce: {
      click: {
        actionField: { list: 'PayBtn' },
        products: items,
      },
    },
  });
}

function readSettings(settings) {
  const { featureFlags, currency } = settings;
  const availabilityGroup = featureFlags.availabilityGroup
    ? featureFlags.availabilityGroup
    : 'model';
  const currencyCode = currency && currency.code ? currency.code : '';

  return { availabilityGroup, currencyCode };
}

function parseCarItem(item, order, availabilityGroup) {
  return {
    name:
      availabilityGroup === 'category'
        ? item.category.name
        : item.car.model.sipp || item.car.model.name,
    id: availabilityGroup === 'category' ? item.category.id : item.car.model.id,
    price: item.price,
    list: 'Rents',
    category: item.deliveryPlace.branchOfficeName,
    position: order,
  };
}

function parseAdditionalItem(listType, item, order) {
  return {
    name: item.name,
    id: item.id,
    price: item.price,
    list: listType,
    position: order,
    variant: 'EXTRA',
    //category: TODO agregar el nombre del delivery branchoffice
  };
}
