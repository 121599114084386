import style from 'styled-components';

export const StyledLogo = style.span`
  box-sizing: border-box;
  display: flex;
  height: ${props => props.height || 'auto'};
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  margin: 0;
  width: ${props => props.width || '120px'};

  .logoImage {
    width: 100%;
    img{
      height: auto;
    }
  }
`;
