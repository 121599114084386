import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const props = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  overwrittenSymbol: PropTypes.string,
  smallDecimals: PropTypes.bool,
  useWebCurrency: PropTypes.bool,
};

class NumberDisplay extends React.Component {
  static propTypes = props;

  render() {
    const {
      value,
      displayType,
      settings: { currency, featureFlags },
      generalSettings,
      overwrittenSymbol,
      smallDecimals,
      currentCurrency,
      useWebCurrency,
      webCurrency,
    } = this.props;

    let cur = {
      thousandSeparator: '.',
      decimalSeparator: ',',
      symbol: (useWebCurrency ? webCurrency?.symbol : currentCurrency?.symbol) || '$',
      isoCode: (useWebCurrency ? webCurrency?.isoCode : currentCurrency?.isoCode) || 'ARS',
      decimalScale: 0,
    };

    const numberOfDecimals =
      generalSettings && generalSettings.cashBoxConfiguration
        ? generalSettings.cashBoxConfiguration.numberOfDecimals
        : 0;

    const useCurrenciesDropdown = featureFlags && featureFlags.showCurrenciesDropdown;

    //if not use Currencies Dropdown and section "currency" is configured on json, use it.
    if (currency && !useCurrenciesDropdown) cur = { ...cur, ...currency };

    const decimalScaleOverride =
      currency?.generalOverrides?.[currentCurrency?.isoCode]?.decimalScale;

    const formattedValue = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: cur?.isoCode || 'ARS',
      minimumFractionDigits: decimalScaleOverride || numberOfDecimals,
      maximumFractionDigits: decimalScaleOverride || numberOfDecimals,
    }).format(value);

    const [integerPart, decimalPart] = formattedValue.split(cur.decimalSeparator);

    const currencySymbol = overwrittenSymbol || cur.symbol;

    const getSmallDecimalsPrice = () => {
      return (
        <>
          <div
            className={`${this.props.className || ''} ${
              value && value.toString().length >= 7 ? 'long-value' : 'short-value'
            }`}
          >
            <NumberFormat
              value={value}
              displayType={displayType || 'text'}
              thousandSeparator={cur.thousandSeparator}
              decimalSeparator={cur.decimalSeparator}
              prefix={currency?.showSymbolAsSuffix ? null : `${currencySymbol} `}
              suffix={currency?.showSymbolAsSuffix ? ` ${currencySymbol}` : null}
              decimalScale={decimalScaleOverride ?? numberOfDecimals ?? cur.decimalScale}
              fixedDecimalScale
              renderText={() => (
                <>
                  {integerPart}
                  {decimalScaleOverride > 0 && (
                    <>
                      {decimalPart && cur.decimalSeparator}
                      {decimalPart && <sup style={{ fontSize: '60%' }}>{decimalPart}</sup>}
                    </>
                  )}
                </>
              )}
            />
          </div>
        </>
      );
    };

    const getNormalDecimalsPrice = () => {
      return (
        <>
          <NumberFormat
            className={`${this.props.className || ''} ${
              value && value.toString().length >= 7 ? 'long-value' : ''
            }`}
            value={value}
            displayType={displayType || 'text'}
            thousandSeparator={cur.thousandSeparator}
            decimalSeparator={cur.decimalSeparator}
            prefix={currency?.showSymbolAsSuffix ? null : `${currencySymbol} `}
            suffix={currency?.showSymbolAsSuffix ? ` ${currencySymbol}` : null}
            decimalScale={decimalScaleOverride ?? numberOfDecimals ?? cur.decimalScale}
            fixedDecimalScale
          />
        </>
      );
    };

    return smallDecimals ? getSmallDecimalsPrice() : getNormalDecimalsPrice();
  }
}

const mapStateToProps = ({ siteConfiguration, global, state }) => ({
  settings: siteConfiguration.settings,
  generalSettings: global.generalSettings,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});

export default connect(mapStateToProps, null)(NumberDisplay);
