import React from 'react';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { props, defaultProps } from '../Flota.props';
import { StyledFlotaModern } from './index.styled';
import FlotaItem from '../FlotaItem/FlotaItem';
import { Btn, Title, TranslatedComponent, Loading } from 'components/';
import withSettingsContext from 'SettingsContext';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class ModernFlota extends TranslatedComponent {
  static propTypes = props;
  static defaultProps = defaultProps;
  state = {
    loading: true,
  };

  getSliderSettings() {
    const { slideSpeed, autoplaySpeed, slidesToShow } = this.props;
    let newValues = {};

    if (slideSpeed) newValues.speed = slideSpeed;
    if (autoplaySpeed) newValues.autoplaySpeed = autoplaySpeed;
    if (slidesToShow) newValues.slidesToShow = slidesToShow;

    return {
      ...sliderSettings,
      ...newValues,
    };
  }

  async componentDidMount() {
    const { fetchCategories, i18n } = this.props;

    try {
      await fetchCategories(i18n.language);
    } catch {}
    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }));
  }

  render() {
    const {
      title,
      titleType,
      listCategories,
      t,
      btnDisplay,
      bookLink,
      loading,
      showOnlyDefaultModels,
      getFlotaItems,
      handleButtonClick,
      disableTitleButton,
      disableTitle,
      showModelDetail,
    } = this.props;
    const flotaItems = !loading ? getFlotaItems() : [];
    const sliderSettings = this.getSliderSettings();

    return (
      <StyledFlotaModern className="row row-p pt-5 pb-5" btnDisplay={btnDisplay}>
        <div className="section-flota-title col-lg-6 col-md-5">
          {!disableTitle && <Title text={title} type={titleType || 'h3'} />}
          {!disableTitleButton && <Btn text={t('seeallfleet')} onClick={handleButtonClick} />}
        </div>
        <div className="col-lg-12 col-md-6">
          {loading ? (
            <Loading show />
          ) : (
            <Slider
              {...sliderSettings}
              infinite={
                flotaItems.length < sliderSettings.slidesToShow ? false : sliderSettings.infinite
              }
            >
              {listCategories && showOnlyDefaultModels
                ? flotaItems.map((model, index) => (
                    <FlotaItem
                      key={index}
                      model={model}
                      bookLink={bookLink}
                      showModelDetail={showModelDetail}
                    />
                  ))
                : flotaItems.map(category => {
                    return category.models.map((model, index) => (
                      <FlotaItem
                        key={index}
                        model={model}
                        bookLink={bookLink}
                        showModelDetail={showModelDetail}
                      />
                    ));
                  })}
            </Slider>
          )}
        </div>
      </StyledFlotaModern>
    );
  }
}

export default withSettingsContext(withRouter(withTranslation()(ModernFlota)));
