import React from 'react';
import queryString from 'query-string';
import { tt } from 'utils/translationHelper';
import { withTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { withRouter } from 'react-router-dom';

function PartialPayWarning({ settings, t, location, className = '' }) {
  const parseQs = queryString.parse(location.search);
  const partialPayCustomMessage = settings && settings.paymentConfiguration.partialPayMessage;

  if (
    settings?.paymentConfiguration?.percentageToPay &&
    !parseQs?.amount &&
    !parseQs?.infractionid
  ) {
    return (
      <div className={`alert alert-danger w-100 ${className}`} role="alert">
        <i className="fas fa-exclamation-circle mr-2"></i>
        {partialPayCustomMessage ? tt('__PaymentOptions.PartialPayMessage', t) : t('PartialPay')}
      </div>
    );
  } else {
    return null;
  }
}
export default withTranslation()(withRouter(withSettingsContext(PartialPayWarning)));
