import style from 'styled-components';

export const StyledBottomFooter = style.div`
  background-color: #2C2C2C;
  position: absolute;
  clear: both;
  right: 0;
  left: 0;
  bottom: 0;
  padding-left: 2%;
  padding-right: 2%;
  
  p{  
    font-size: 11px !important;
    color: #888888 !important;

    @media (max-width: ${props => props.theme.screens.md}) {
        text-align: center;
    }
  }
  a{
    color: #ffa300 !important;
    font-weight: 700;

    &:hover{
      text-decoration: none;
    }
  }
`;
