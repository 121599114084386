import style from 'styled-components';

export const StyledWhatsappContainer = style.div`
    position: fixed;
    bottom: 52px;
    right: 10px;
    width: 60px;
    height: 60px;
    transition: all 0.3s ease;
    z-index: 100;

    img {
      width: 100%;
    }

    &:hover {
      transform: scale(1.1);
    }

    img {
      width:100%;
    }
`;
