import styled from 'styled-components';

export const StyledSearchItems = styled.section`
  background: ${props => props.background || props.theme.colors.bg};
  min-height: 600px;
  padding: 0;

  @media only screen and (min-width: ${props => props.theme.screens.xl}) and (max-width: ${props =>
      props.theme.screens.xxl}) {
    flex: 70%;
    max-width: 70%;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) and (min-width: ${props =>
      props.theme.screens.md}) {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  @media only screen and (max-width: ${props => props.theme.screens.md}) {
    .row-container {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .alert {
    width: fit-content;
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
`;
