import PropTypes from 'prop-types';

export const props = {
  children: PropTypes.any,
  className: PropTypes.string,
  padding: PropTypes.string,
  background: PropTypes.string,
};

export const defaults = {
  className: '',
};
