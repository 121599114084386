import PropTypes from 'prop-types';

export const props = {
  cars: PropTypes.array.isRequired,
  background: PropTypes.string,
  noAvailabilityImage: PropTypes.string,
  noAvailabilityMessage: PropTypes.string,
  searchGif: PropTypes.string,
  searchMessage: PropTypes.string,
  backgroundItems: PropTypes.string,
  showModelDetail: PropTypes.bool,
  currentCurrency: PropTypes.object,
  defaultCurrency: PropTypes.object,
  webCurrency: PropTypes.object,
};

export const defaults = {
  cars: [],
  categories: { allCategoriesSelected: true, categoriesSelected: [] },
  showModelDetail: true,
};
