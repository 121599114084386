import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { tt } from '../../../utils/translationHelper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ImageComponent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.string,
  };

  render() {
    const { src, alt, t, className, height, width } = this.props;

    return (
      <LazyLoadImage
        src={tt(src, t)}
        alt={t(alt)}
        threshold={100}
        effect="blur"
        height={height}
        width={width}
        wrapperClassName={className}
      />
    );
  }
}

export default withTranslation()(ImageComponent);
