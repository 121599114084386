import React from 'react';
import { TranslatedComponent, Paragraph } from 'components';
import { withTranslation } from 'react-i18next';
import style from 'styled-components';

import { ReactComponent as PersonLogo } from './../../../assets/images/cars/details/person.svg';
import { ReactComponent as LuggaggeLgLogo } from './../../../assets/images/cars/details/luggage-lg.svg';
import { ReactComponent as LuggaggeXsLogo } from './../../../assets/images/cars/details/luggage-xs.svg';
import { ReactComponent as GearBoxLogo } from './../../../assets/images/cars/details/caja.svg';
import { ReactComponent as AirConditionerLogo } from './../../../assets/images/cars/details/airconditioner.svg';
import { ReactComponent as AbsLogo } from './../../../assets/images/cars/details/abs.svg';
import { ReactComponent as DirectionLogo } from './../../../assets/images/cars/details/direction.svg';
import { ReactComponent as AirbugLogo } from './../../../assets/images/cars/details/airbug.svg';

const StyledDetail = style.div`
  .logo {
    fill: ${props => props.theme.colors.icons || props.theme.font.primary};
    border: 1px solid ${props => props.theme.font.primary || 'black'};
    padding: 5px;
    border-radius: 7px;
    margin-bottom: 5px;
    width: 100%;
    max-width: 40px;
    height: 100%;
  }
`;

class DefaultModelDetail extends TranslatedComponent {
  render() {
    const {
      model,
      t,
      prefixForNumeric,
      sanatizeDetailText,
      HideCarDetailIcons,
      showModelDetail,
    } = this.props;

    return showModelDetail ? (
      <StyledDetail className="details">
        {HideCarDetailIcons && HideCarDetailIcons.passengers === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={`${t('carDetails.passengers')} - ${model.passengers}`}>
              <PersonLogo className="logo" alt={`X${model.passengers}`} />
            </i>
            <Paragraph text={`${prefixForNumeric || ''}${model.passengers}`} nonTranslate />
          </div>
        )}

        {HideCarDetailIcons && HideCarDetailIcons.bigLuggage === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={`${t('carDetails.bigLuggage')} - ${model.bigLuggage}`}>
              <LuggaggeLgLogo className="logo" alt={`X${model.bigLuggage}`} />
            </i>
            <Paragraph text={`${prefixForNumeric || ''}${model.bigLuggage}`} nonTranslate />
          </div>
        )}

        {HideCarDetailIcons && HideCarDetailIcons.smallLuggage === true ? null : (
          <div className="details-item">
            <i
              data-toggle="tooltip"
              title={`${t('carDetails.smallLuggage')} - ${model.smallLuggage}`}
            >
              <LuggaggeXsLogo className="logo" alt={`X${model.smallLuggage}`} />
            </i>
            <Paragraph text={`${prefixForNumeric || ''}${model.smallLuggage}`} nonTranslate />
          </div>
        )}

        {HideCarDetailIcons && HideCarDetailIcons.gearbox === true
          ? null
          : model.gearbox != null &&
            model.gearbox !== undefined && (
              <div className="details-item">
                <i data-toggle="tooltip" title={`${t('carDetails.gearbox')} - ${model.gearbox}`}>
                  <GearBoxLogo className="logo" alt={`X${model.gearbox}`} />
                </i>
                <Paragraph text={sanatizeDetailText(model.gearbox)} nonTranslate />
              </div>
            )}

        {HideCarDetailIcons && HideCarDetailIcons.airConditioner === true
          ? null
          : model.airConditioner != null &&
            model.airConditioner !== undefined && (
              <div className="details-item">
                <i
                  data-toggle="tooltip"
                  title={`${t('carDetails.airConditioner')} - ${model.airConditioner}`}
                >
                  <AirConditionerLogo className="logo" alt={`X${model.airConditioner}`} />
                </i>
                <Paragraph text={sanatizeDetailText(model.airConditioner)} nonTranslate />
              </div>
            )}
        {HideCarDetailIcons && HideCarDetailIcons.abs === true ? null : (
          <div className="details-item">
            <i data-toggle="tooltip" title={`${t('carDetails.abs')}`}>
              <AbsLogo className="logo" alt={t('Si')} />
            </i>
            <Paragraph text="Si" />
          </div>
        )}

        {HideCarDetailIcons && HideCarDetailIcons.steering === true
          ? null
          : model.steering != null &&
            model.steering !== undefined && (
              <div className="details-item">
                <i data-toggle="tooltip" title={`${t('carDetails.steering')}`}>
                  <DirectionLogo className="logo" alt={model.steering} />
                </i>
                <Paragraph text={sanatizeDetailText(model.steering)} nonTranslate />
              </div>
            )}

        {HideCarDetailIcons && HideCarDetailIcons.airBag === true ? null : (
          <div className="details-item">
            <>
              <i data-toggle="tooltip" title={`${t('carDetails.airBag')}`}>
                <AirbugLogo className="logo" alt={t('Si')} />
              </i>
              <Paragraph text="Si" />
            </>
          </div>
        )}
      </StyledDetail>
    ) : null;
  }
}

export default withTranslation()(DefaultModelDetail);
