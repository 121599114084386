import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TextInput, NumberDisplay } from 'components';
import { Collapse, Button } from 'react-bootstrap';
import { MODERN_THEME } from 'styled/constants';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from '../../../utils/priceHelper';

const AdditionalCard = ({
  additional,
  onAdditionalChange,
  seeMore,
  seeLess,
  insuranceImage,
  additionalImage,
  t,
  settings: { theme },
  nameMaxLength,
  nameSplitNumber,
  value,
  currentCurrency,
  defaultCurrency,
  webCurrency,
}) => {
  const [expanded, setExpanded] = useState(false);

  const getPrice = () => {
    if (additional.price > 0) {
      let price = additional.isPriceByDay ? additional.dailyPrice : additional.price;

      if (applyCurrencyExchangeRate(currentCurrency, webCurrency))
        price = getPriceWithExchangeRate(price, currentCurrency, defaultCurrency, webCurrency);

      return (
        <>
          <NumberDisplay value={price} />
          {' ' + (additional.isPriceByDay ? t('perDay') : t('perBooking'))}
        </>
      );
    }
    return null;
  };

  const getAdditionalName = () => {
    const { name, description } = additional;
    const fullName = description ? `${description}` : name;
    const collapsedName =
      fullName.length > nameMaxLength ? fullName.slice(0, nameSplitNumber) + '...' : fullName;

    return { fullName, collapsedName };
  };

  const onNumberChange = newValue => {
    let number = newValue;

    if (newValue < 0) {
      number = 0;
    }

    if (newValue > additional.maxQuantityPerBooking) {
      number = additional.maxQuantityPerBooking;
    }

    onAdditionalChange({ id: additional.id, number, type: additional.type });
  };

  const { fullName, collapsedName } = getAdditionalName();

  const getInsuranceComponent = additional => {
    const renderImage = !insuranceImage ? renderAdditionalWithIcon : renderAdditionalWithImage;
    return (
      <>
        <div
          className={
            `${
              additional.isRequired || additional.availableStock < 1
                ? 'mandatory-additional typeAdditional'
                : 'typeAdditional'
            }  ${insuranceImage ? 'withImage' : ''}` || ''
          }
        >
          <div className="additional-image typeInsurance">{renderImage(additional)}</div>
          <div className={`${additional.maxQuantityPerBooking !== 1 ? 'withQuantity' : ''}`}>
            <div className="additional-name">
              <Collapse in={expanded}>
                <div>{expanded ? fullName : collapsedName}</div>
              </Collapse>
              {!expanded && (fullName.length > nameMaxLength ? collapsedName : fullName)}
              {fullName.length > nameMaxLength && (
                <Button
                  variant="link"
                  onClick={() => setExpanded(!expanded)}
                  aria-controls="additional-name-collapse"
                  aria-expanded={expanded}
                  className={`additional-name-button ${expanded && seeLess ? 'see-less' : ''}`}
                >
                  {expanded ? seeLess : seeMore}
                </Button>
              )}
              {additional.isRequired ? (
                <span className="mandatory-legend">{t('additionalMandatoryLegend')}</span>
              ) : (
                <span className="mandatory-legend"></span>
              )}
              {additional.availableStock < 1 && (
                <span className="mandatory-legend">{t('noStock')}</span>
              )}
            </div>
          </div>
          <div className="text-center adicional-price">{getPrice()}</div>
        </div>
        <div className="quantity-wrapper">
          {additional.maxQuantityPerBooking === 1 ? (
            <label className="special-checkbox d-inline">
              <input
                type="checkbox"
                checked={value && value.number > 0 ? true : false}
                onChange={({ target }) =>
                  onAdditionalChange({
                    id: additional.id,
                    number: target.checked ? 1 : 0,
                    type: additional.type,
                  })
                }
                disabled={additional.isRequired || additional.availableStock < 1}
              />
              <span className={additional.isRequired ? 'checkmark disabled' : 'checkmark'} />
            </label>
          ) : (
            <TextInput
              type="number"
              value={value ? value.number : ''}
              placeholder={''}
              onChange={value => {
                onNumberChange(value ? parseInt(value) : 0);
              }}
              inputClass="form-control number constant-arrows"
              wrapperClass="quantity"
              min={0}
              id={additional.id}
              options={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                min: 0,
                max: additional.maxQuantityPerBooking,
              }}
              disabled={additional.isRequired || additional.availableStock < 1}
            />
          )}
        </div>
      </>
    );
  };

  const renderAdditionalWithIcon = additional => (
    <p>
      {' '}
      <img
        width={theme.template === MODERN_THEME ? '32' : '40'}
        className="d-inline"
        src={additional.imagePath}
        title={additional.name}
        alt={additional.name}
      />
      {additional.name}
    </p>
  );

  const renderAdditionalWithImage = additional => (
    <>
      <img src={additional.imagePath} title={additional.name} alt={additional.name} />

      <p>{additional.name}</p>
    </>
  );

  const getAdditionalComponent = additional => {
    const renderImage = !additionalImage ? renderAdditionalWithIcon : renderAdditionalWithImage;

    return (
      <>
        <div
          className={
            `${
              additional.isRequired || additional.availableStock < 1
                ? 'mandatory-additional typeAdditional'
                : 'typeAdditional'
            }  ${additionalImage ? 'withImage' : ''}` || ''
          }
        >
          <div className={`additional-image typeAdditional`}>{renderImage(additional)}</div>
        </div>
        <div className={`${additional.maxQuantityPerBooking !== 1 ? 'withQuantity' : ''}`}>
          <div className="additional-name">
            <Collapse in={expanded}>
              <div>{expanded ? fullName : collapsedName}</div>
            </Collapse>
            {!expanded && (fullName.length > nameMaxLength ? collapsedName : fullName)}
            {fullName.length > nameMaxLength && (
              <Button
                variant="link"
                onClick={() => setExpanded(!expanded)}
                aria-controls="additional-name-collapse"
                aria-expanded={expanded}
                className={`additional-name-button ${expanded && seeLess ? 'see-less' : ''}`}
              >
                {expanded ? seeLess : seeMore}
              </Button>
            )}
            {additional.isRequired ? (
              <span className="mandatory-legend">{t('additionalMandatoryLegend')}</span>
            ) : (
              <span className="mandatory-legend"></span>
            )}
            {additional.availableStock < 1 && (
              <span className="mandatory-legend">{t('noStock')}</span>
            )}
          </div>
        </div>
        <div className="text-center adicional-price">{getPrice()}</div>

        <div className="quantity-wrapper">
          {additional.maxQuantityPerBooking === 1 ? (
            <label className="special-checkbox d-inline">
              <input
                type="checkbox"
                checked={value && value.number > 0 ? true : false}
                onChange={({ target }) =>
                  onAdditionalChange({
                    id: additional.id,
                    number: target.checked ? 1 : 0,
                    type: additional.type,
                  })
                }
                disabled={additional.isRequired || additional.availableStock < 1}
              />
              <span className={additional.isRequired ? 'checkmark disabled' : 'checkmark'} />
            </label>
          ) : (
            <TextInput
              type="number"
              value={value ? value.number : ''}
              placeholder={''}
              onChange={value => {
                onNumberChange(value ? parseInt(value) : 0);
              }}
              inputClass="form-control number constant-arrows"
              wrapperClass="quantity"
              min={0}
              id={additional.id}
              options={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                min: 0,
                max: additional.maxQuantityPerBooking,
              }}
              disabled={additional.isRequired || additional.availableStock < 1}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div id={`additional-${additional.id}`} className="additional-row-v2">
      {additional.type?.toLowerCase() === 'insurance' && getInsuranceComponent(additional)}
      {additional.type?.toLowerCase() === 'additional' && getAdditionalComponent(additional)}
    </div>
  );
};

AdditionalCard.propTypes = {
  additional: PropTypes.shape({}),
  listAdditionals: PropTypes.array,
  onAdditionalChange: PropTypes.func,
  seeMore: PropTypes.string,
  seeLess: PropTypes.string,
  nameMaxLength: PropTypes.number,
  nameCharsSplit: PropTypes.number,
  insuranceImage: PropTypes.bool,
  additionalImage: PropTypes.bool,
  t: PropTypes.func,
  settings: PropTypes.shape({
    theme: PropTypes.string,
  }),
  value: PropTypes.shape({
    number: PropTypes.number,
  }),
};

const mapStateToProps = ({ bookings, siteConfiguration, global }) => ({
  listAdditionals: bookings.listAdditionals,
  settings: siteConfiguration.settings,
  currentCurrency: global.currentCurrency,
  defaultCurrency: global.defaultCurrency,
  webCurrency: global.webCurrency,
});

export default connect(mapStateToProps)(withTranslation()(AdditionalCard));
