import style from 'styled-components';

export const StyledAssociatesSection = style.section`
	margin: auto;
	margin-top: 20px;
	width: 70%;

	.associate-page {
		background-color: ${props => props.theme.colors.primary};
		padding: 20px !important;
	}

	.associates-header {
		background-color: white;
		padding: 10px;
		
		.header-logo {}
		.header-title {
			margin-left: 50px;
			font-size: 2em;
			font-weight: bold;
			text-transform: uppercase;
		}
		.header-subtitle {
			font-size: 1.2em;
			font-weight: bold;
			text-transform: uppercase;
			display:flex;
			justify-content: center;
			margin-top: 10px;
		}
	}

	.associate-item {
		min-width: 50%;

		.row {
			display:flex;
			justify-content: center;
		}

		.item-number {
			width: 60px;
			height: 60px;
			line-height: 60px;
			border-radius: 50%;

			font-size: 2.5em;
			font-weigth: bold;
			color: ${props => props.theme.colors.primary};
			background-color: white;
			text-align: center;
		}

		.item-icon {

		}

		.item-title {
			font-size: 1.2em;
			font-weight: bold;
			text-transform: uppercase !important;
			display:flex;
			justify-content: center;
		}

		.item-description {

		}
	}

	.associate-footer {
		display:flex;
		justify-content: center;
		background-color: ${props => props.theme.colors.secondary};
		padding: 50px !important;

		p {
			color: white;
			font-size: 1em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
`;
