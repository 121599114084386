import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import styled from 'styled-components';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import './i18n';
import { PageLoading } from 'components/';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

const StyledApp = styled.div`
  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    width: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
  }
`;

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '87007c52-9413-4032-b629-726c522c07ce',
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const rootApp = (
  <StyledApp>
    <Suspense fallback={<PageLoading show={false} />}>
      <App />
    </Suspense>
  </StyledApp>
);

ReactDOM.render(rootApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
