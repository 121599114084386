import React from 'react';
import { props } from './PagaEnCuotas.props';
import { TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';
import DefaultPagaEnCuotas from './Default';
import ModernPagaEnCuotas from './Modern';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

class PagaEnCuotas extends TranslatedComponent {
  static propTypes = props;

  render() {
    return this.props.settings.theme.template === MODERN_THEME ? (
      <ModernPagaEnCuotas {...this.props} />
    ) : (
      <DefaultPagaEnCuotas {...this.props} />
    );
  }
}

export default withSettingsContext(withTranslation()(PagaEnCuotas));
