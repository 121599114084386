import React from 'react';
import InputMask from 'react-input-mask';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import { props } from './TextInput.props';
import { StyledInput } from './TextInput.styled';
import { StyledInputModern } from './TextInput.styled.modern';

const TextInput = ({
  wrapperId,
  wrapperClass,
  inputClass,
  color,
  onChange,
  borderColor,
  fontSize,
  iconColor,
  placeholder,
  icon,
  error,
  label,
  type,
  value,
  options,
  id,
  readOnly,
  legend,
  settings,
  mask,
  disabled,
}) => {
  const getTemplateStyledComponent = () => {
    if (settings.theme.template === MODERN_THEME) {
      return StyledInputModern;
    }
    return StyledInput;
  };

  const StyledComponent = getTemplateStyledComponent();

  return (
    <StyledComponent
      color={color}
      fontSize={fontSize}
      id={wrapperId}
      className={wrapperClass}
      borderColor={borderColor}
      iconColor={iconColor}
      error={error}
    >
      {label && <label className="label">{label}</label>}
      <div className="input-wrapper mb-2">
        {icon && <span className={icon} />}
        {mask ? (
          <InputMask
            className={inputClass}
            mask={mask}
            maskChar={null}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            id={id}
            readOnly={readOnly || false}
            {...options}
          />
        ) : (
          <input
            disabled={disabled}
            className={inputClass}
            type={type || 'text'}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            id={id}
            readOnly={readOnly || false}
            {...options}
          />
        )}
      </div>
      {legend && <small className="form-text text-muted pl-1 pb-1">{legend}</small>}
      {error && <div className="error">{error}</div>}
    </StyledComponent>
  );
};

TextInput.propTypes = props;
export default withSettingsContext(TextInput);
