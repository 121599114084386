import style from 'styled-components';

const getBalanceColor = (status, balance) => {
  if (status === 4) return '#858C99';
  if (Math.sign(balance) === -1) return '#E80C4D';
  return '#2BCE81';
};

export const StyledBookingPreview = style.div`
  border: 1px solid #ECF0F3;
  border-radius: 10px;
  padding: 1rem 1.2rem;
  width: 100%;
  background: white;
  margin-bottom: 8px;
  text-align: left;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 0%);
  transition: box-shadow 0.4s;

  &:hover { 
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  }

  p {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.font.colors.primary};
    margin-bottom: 0;
  }

  .booking-id {
    p {
      color: #1A73E8;
      font-weight: 500; 
    }
  }

  .booking-info {  
    .delivery-info {
      font-size: 0.8rem;
      color: #656970;
      margin-left: 15px;
    }
  }

  .booking-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actions-wrapper {
    display: flex;

    button {
      border: none;
      background: transparent;
      color: #656970;
      transition: all 0.3s;
      padding: 0 10px;

      i { 
        font-size: 1.2rem;
      }

      &:hover {
        color: #9fa6b3;
      }
    }

    .delete {
      color: ${({ theme }) => theme.colors.alert};

      &:disabled {
        color: ${({ theme }) => theme.colors.disabled};
        opacity: 0.5;
      }

      &:not([disabled]):hover {
        color: #da0000;
      }
    }
  }

  .booking-balance {
    text-align: left;
    
    p {
      text-align: left;
    }

    .amount {
      font-weight: 500;
      color: ${({ status, balance }) => getBalanceColor(status, balance)};
    }
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    .booking-status, .delivery-info {
      margin-left: 0;
    } 

    .booking-info {
      margin-bottom: 1rem;
    }
  }
`;
