import React from 'react';
import { Title, Paragraph, ModelDetail, NumberDisplay } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { StyledDetails } from './TollDetailSection.styled';
import { tt } from 'utils/translationHelper';
import Moment from 'moment';
import { fetchCategories } from 'actions';
import { getPriceWithCommission } from 'utils/dataHelper';
import { handleGetTotalToPay } from 'utils/dataHelper';
class BookingDetailSection extends React.Component {
  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      paymentInfo: {
        qsamount: 0,
      },
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  getTotalToPay() {
    const {
      bookings: { confirmation },
      settings: { paymentConfiguration },
    } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    return handleGetTotalToPay(confirmation.customerBalance, paymentConfiguration, qsamount);
  }

  async componentWillMount() {
    const { categories, fetchCategories, i18n } = this.props;
    if (!categories) await fetchCategories(i18n.language);
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      let partial = parseFloat(parseQs.amount);
      if (partial != 0) {
        this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
      }
    }
  }

  showBookingLenght = booking => {
    const { t, diasDeAlquiler } = this.props;
    const bookingLength = Number.isInteger(booking.totalDays)
      ? booking.totalDays
      : booking.totalDaysString;

    return diasDeAlquiler
      ? `Por ${bookingLength} ${diasDeAlquiler}`
      : t('forDaysOfRent', { totalDays: bookingLength });
  };

  getModel = () => {
    const {
      bookings: { confirmation },
      settings: { featureFlags },
      categories,
    } = this.props;
    let model;

    if (featureFlags.availabilityGroup !== 'category') {
      model = confirmation !== null && confirmation.car !== null ? confirmation.car.model : null;
    } else {
      if (!categories) return null;
      const bookingCategory = confirmation.category;
      const categoryFound = categories.find(c => c.id === bookingCategory.id);
      if (!categoryFound) return null;
      model = categoryFound.models.find(model => model.id === bookingCategory.principalModelId);
    }

    return model;
  };

  render() {
    const {
      bookings: { confirmation },
      settings: { featureFlags, paymentConfiguration },
      t,
      prefixForNumeric,
      installment,
      franchiseText,
      detailsLegend,
      bookingsConfiguration,
    } = this.props;

    const {
      paymentInfo: { qsamount },
    } = this.state;

    const booking = confirmation !== null ? confirmation : null;
    const model = this.getModel();
    if (!model) return null;

    const showDeposit = featureFlags.showDeposit === undefined ? true : featureFlags.showDeposit;

    const kmText =
      booking.ilimitedKm === true
        ? t(
            bookingsConfiguration?.distanceUnit == 'Kilometers'
              ? 'KmIlimitados'
              : 'MillasIlimitados',
          )
        : t(bookingsConfiguration?.distanceUnit == 'Kilometers' ? 'KmDiarios' : 'MillasDiarios', {
            km: booking.maxAllowedDistanceByDay,
          });

    return (
      <StyledDetails className="col-md-3" priceBackground={this.props.priceBackground}>
        <div className="checkout-details">
          <div className="row">
            <div className="col-md-12 title-col1">
              <Title
                type="h2"
                text={t('tolls.title')}
                italic={false}
                weight="700"
                fontSize={20}
                className="mt-1"
              />
              <Paragraph>{t('tolls.subtitle', t)}</Paragraph>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="rent-details">
                <div className="car-item">
                  <div className="car-details">
                    <h2 className="car-name mt-1">
                      {model.brand.name} {model.name}
                    </h2>
                    <Paragraph className="car-category mb-0">
                      {t('categoria')} <span>{(model.category || booking.category).name}</span>
                      <div className="d-inline-block">
                        <span className="mr-2 ml-2">|</span>
                        <span className="tachometer text-capitalize">
                          <i className="fas fa-tachometer-alt mr-1"></i>
                          {kmText}
                        </span>
                      </div>
                    </Paragraph>
                    {showDeposit && (
                      <p className="franchise mb-3">
                        <i
                          className="fas fa-info-circle"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={t('franchiseHelpText')}
                        />
                        <strong>{t(franchiseText) || t('franquicia')} </strong>
                        <span>
                          <NumberDisplay value={booking.franchise} />
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="car-image text-center mb-3">
                    <img className="img-fluid" src={model.imagePath} alt={model.name} />
                  </div>
                  <ModelDetail model={model} prefixForNumeric={prefixForNumeric} />
                </div>
                <hr />
                <div className="pickup-details">
                  <Title
                    type="h4"
                    text={t('delivery')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>
                    {booking.deliveryPlace.name} -
                    <span className="date">
                      {' '}
                      {Moment(confirmation.fromDate).format('DD-MM-YYYY HH:mm')} hs
                    </span>
                  </p>
                  <Title
                    type="h4"
                    text={t('return')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>
                    {booking.returnPlace.name} -
                    <span className="date">
                      {' '}
                      {Moment(confirmation.toDate).format('DD-MM-YYYY HH:mm')} hs
                    </span>
                  </p>
                </div>
              </div>
              <div className="payment mt-3 mb-3">
                <div className="row text-light">
                  {installment ? (
                    <>
                      <div className="col-md-6">
                        <Title type="h4" fontSize="16" className="text-light" text={t('booking')} />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="">
                          <NumberDisplay
                            value={getPriceWithCommission(
                              Math.abs(booking.balance),
                              paymentConfiguration,
                            )}
                          />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Title
                          type="h4"
                          fontSize="16"
                          className="text-light"
                          text={t('totalFinancing')}
                        />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="">
                          <NumberDisplay
                            value={
                              installment.total_amount -
                              getPriceWithCommission(
                                Math.abs(booking.balance),
                                paymentConfiguration,
                              )
                            }
                          />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Title type="h3" className="total-name text-light" text={t('totalToPay')} />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="total-price">
                          <NumberDisplay value={installment.total_amount} />
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <Title type="h3" className="total-name text-light" text={t('totalToPay')} />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="total-price">
                          <NumberDisplay
                            value={getPriceWithCommission(Math.abs(qsamount), paymentConfiguration)}
                          />
                        </p>
                      </div>
                    </>
                  )}
                  {detailsLegend && (
                    <div className="col-md-12">
                      <Paragraph className="text-light">{tt(detailsLegend, t)}</Paragraph>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDetails>
    );
  }
}

const mapStateToProps = ({ bookings, siteConfiguration, global }) => ({
  bookings: bookings,
  installment: bookings.installment,
  settings: siteConfiguration.settings,
  categories: global.listCategories,
  bookingsConfiguration: bookings.configuration,
});
export default connect(mapStateToProps, { fetchCategories })(
  withRouter(withTranslation()(BookingDetailSection)),
);
