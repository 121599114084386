import styled from 'styled-components';

export const StyledSocialList = styled.ul`
  display: flex;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  flex-wrap: wrap;

  .socialItem {
    width: 40px;
    padding: 0px 10px;
    border-radius: ${props => props.borderRadius || '50%'};
    overflow: hidden;
    margin: ${props => props.margins || '10px 5px'};

    .socialLink {
      transition: background-color 200ms;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      color: ${props => props.color || props.theme.font.colors.primary};
    }

    .IconContainer {
      margin: 0px;

      .linkedin-logo {
        width: 1.4rem;
      }

      i.fab.fa-instagram {
        position: relative;
        bottom: -2px;
        font-size: 1rem;
      }

      .tripadvisor-logo {
        width: 1.3rem;
      }

      .x-logo,
      .tiktok-logo {
        width: 0.9rem;
      }
    }

    &:hover {
      background-color: ${props => props.theme.colors.secondary};
      fill: ${props => props.hover || props.theme.font.colors.hover};

      svg {
        path {
          fill: ${props => props.hover || props.theme.font.colors.hover};
        }
      }

      .socialLink i {
        color: ${props => props.hover || props.theme.font.colors.hover};
      }
    }
  }
`;
