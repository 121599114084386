import React from 'react';
import { props } from './TextAreaInput.props';
import { StyledInput } from './TextAreaInput.styled';

const TextAreaInput = ({
  wrapperId,
  wrapperClass,
  inputClass,
  color,
  onChange,
  borderColor,
  fontSize,
  iconColor,
  placeholder,
  icon,
  error,
  cols,
  rows,
  label,
  value,
}) => (
  <StyledInput
    color={color}
    fontSize={fontSize}
    id={wrapperId}
    className={wrapperClass}
    borderColor={borderColor}
    iconColor={iconColor}
    error={error}
  >
    {label && <div className="label">{label}</div>}
    <div className="input-wrapper">
      {icon && <span className={icon} />}
      <textarea
        className={inputClass}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
      />
    </div>
    {error && <div className="error">{error}</div>}
  </StyledInput>
);

TextAreaInput.propTypes = props;
export default TextAreaInput;
