import style from 'styled-components';

export const StyledPagaEnCuotas = style.section`
  background: ${(props) => props.bg || props.theme.colors.bg};
  text-align: center;
  overflow-x: hidden;

  .home-title {
    margin-top: 5em;
    line-height: 1.5;

    h1{
      margin-bottom:0px;
    }

    p {
      line-height: 22px;
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
`;

export const StyledBanksSection = style.section`

  border-bottom: ${(props) => (props.showBorder ? '3px' : 0)} solid ${(props) =>
    props.borderColor || props.theme.colors.primary};
  padding-bottom: ${(props) => (props.showBorder ? '30px' : 0)};

  h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-top: 2em;

    strong {
      font-weight: 700;
    }
  }
  p{
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0 30px;
  }

  a {
    font-size: 13px !important;
    color: ${(props) => props.theme.font.colors.third};
    transition: all .2s;
  }

  a:hover {
    text-decoration: none;
    color: ${(props) => props.leftLinkHover || props.theme.font.colors.third} !important; 
  }

  img {
    cursor: grab;
  }

  div.img {
    height: fit-content;
    text-align: center;
    height: 100px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: auto;
  }

  .slick-track{
    margin: auto auto;
  }

  .slick-next{
    right: -10px;
  }
  
  .slick-prev{
    left: -10px;
  }

  .slick-next:before {
    content: "\f105" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    color: #ccc
    
  }

  .slick-prev:before {
    content: "\f104" !important;
    font-family: 'Font Awesome 5 Free', 'FontAwesome';
    color: #ccc
  }

`;
