import style from 'styled-components';

export const StyledCheckoutFilterModern = style.section`
  min-height: 600px;
  z-index:2;
  display: none;
  box-shadow: 0 0 20px #00000017;
  margin-bottom: 20px;

  &.hidden{
    z-index: 0;
  }

  .overlay{
    width: 100%;
    height: 100vh;
    background: #00000042;
    position: absolute;
    z-index: -1;
  }
  
 .details-item {
    text-align: center;
    margin-right: 10px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
}

  @media only screen (max-width: ${props => props.theme.screens.lg}) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    min-height: 0;
    margin-left: -2%;
    margin-right: -2%;
    width: 100vw;
    padding: 0;
    display:block !important;
  }

  @media only screen and (max-width: ${props => props.theme.screens.lg})
  and (min-width: ${props => props.theme.screens.md}){
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    min-height: 0;
    flex: 0 0 104%;
    max-width: initial;
    width: 100vw;
    padding: 0;
    margin-left: -2%;
    margin-right: -2%;
    display: block !important;
  }

  @media only screen and (min-width: ${props => props.theme.screens.lg})
  and (max-width: ${props => props.theme.screens.xxl}){
    max-width: 30%;
    margin-right: 0;
    flex: 30%;
  }

  .searchFilter{
    box-shadow: 0 0 20px #00000026;

    section{
      background: transparent;
    }
    .card{
      border: none;
      margin-bottom: 0;
      background: transparent;
    }

    .card-header{
      border-bottom: 1px solid #f2f2f2;
      background: #fff;
      .fa-info-circle{
        display:none;
      }
      .fa-calendar-alt{
        color: ${props => props.theme.colors.buttonPrimaryBgHover};
      }
      .divider{
        color: #c2c2c2;
      }
    }
    .collapse{
      border-bottom: 1px solid #f9f7f7;
    }

    .card-body{
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;

      form{
        box-shadow: none;
        margin: 0 !important;
        padding: 1.25rem 1.25rem 2rem !important;
        .col-md-12{
          margin-bottom: 0;
        }
      }
    }
  }

  .car-block{
    display: flex;
    justify-content: space-between;
    padding: 10px 19px;
    background: #fff;
    z-index: 2;

    .car-image{
      flex: 0 0 80px;
      height: fit-content;
      border: 1px solid #eeeeee;
    }

    .car-details{
      display:flex;
      min-width: 90%;

      p{
        padding: 3px 20px 0 12px;
        font-size: 13px;
        font-weight: bold;
        line-height: 13px;
        margin-bottom: 0;
      }
      span{
        font-weight: normal;
        font-size: 12px;
        margin-top: 3px;
        display: block;
      }
    }
    .othercar{
      height: fit-content;
      margin: auto;

      i {
        color: ${props => props.theme.colors.buttonPrimaryBgHover};
      }
    }

  }

`;
