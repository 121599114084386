import React from 'react';
import CompositeComponent from 'components/CompositeComponent/CompositeComponent';
import { PropTypes } from 'prop-types';

export default class ColumnComponent extends CompositeComponent {
  static propTypes = {
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    className: PropTypes.string,
    order: PropTypes.number,
    mobileOrder: PropTypes.number,
  };

  getColStyle(col, style) {
    return col ? `${style}${col}` : '';
  }

  render() {
    const { lg, md, sm, className, order, mobileOrder } = this.props;

    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const orderStyle = order !== undefined ? { order: Number(order) } : {};
    const orderMobileStyle = mobileOrder !== undefined ? { order: Number(mobileOrder) } : {};

    return (
      <div
        style={isMobile ? orderMobileStyle : orderStyle}
        className={`${className || ''} ${this.getColStyle(sm, 'col-sm-')} ${this.getColStyle(
          md,
          'col-md-',
        )} ${this.getColStyle(lg, 'col-lg-')}`}
      >
        {this.renderChilds()}
        {this.props.children}
      </div>
    );
  }
}
