import PropTypes from 'prop-types';

export const props = {
  links: PropTypes.array,
  certificates: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  collapsible: PropTypes.bool,
};
