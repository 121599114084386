import React from 'react';
import { StyledHeroBanner } from './HeroBanner.styled';
import { props } from './HeroBanner.prop';
import { Title, CompositeComponent } from 'components/';
import { withTranslation } from 'react-i18next';
import { StyledHeroBannerModern } from './HeroBanner.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import withSettingsContext from 'SettingsContext';
import useWindowDimensions from 'utils/windowHelper';
import { tt } from 'utils/translationHelper';

const HeroBanner = props => {
  const setContentPosition = () => {
    switch (props.contentPosition) {
      case 'right':
        return 'justify-content-end';
      case 'center':
        return 'justify-content-center';
      default:
        return 'justify-content-start';
    }
  };

  const getTemplateStyledComponent = () => {
    if (props.settings.theme.template === MODERN_THEME) {
      return StyledHeroBannerModern;
    }
    return StyledHeroBanner;
  };

  const {
    title,
    background,
    backgroundAlt,
    mobileBackground,
    type,
    t,
    titleColor,
    fontWeight,
    fontSize,
    noParallax,
    showSecondColumn,
    secondColumnConfig,
  } = props;

  const columnSeparation = showSecondColumn ? 'col-md-6' : 'col-md-5';
  const setParallax = noParallax ? 'row row-p pb-5' : 'row row-p parallax pb-5';
  const contentPosition = setContentPosition();
  const StyledSection = getTemplateStyledComponent();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <StyledSection
      background={(isMobile && t(mobileBackground)) || t(background)}
      className={`${setParallax} ${contentPosition}`}
      titleColor={titleColor}
      fontWeight={fontWeight}
      fontSize={fontSize}
      title={tt(backgroundAlt, t)}
    >
      <div className={`home-form ${columnSeparation}`}>
        <Title
          type={type ? type : 'h3'}
          text={title}
          italic={false}
          className="quoted"
          fontSize={fontSize}
        />
        <CompositeComponent sections={props.sections} />
      </div>
      {showSecondColumn && (
        <div className={`book-form ${columnSeparation}`}>
          <Title
            type={type ? type : 'h3'}
            text={secondColumnConfig.title}
            italic={false}
            className="quoted"
            fontSize={fontSize}
          />
          <CompositeComponent sections={secondColumnConfig.sections} />
        </div>
      )}
    </StyledSection>
  );
};

HeroBanner.propTypes = props;

export default withSettingsContext(withTranslation()(HeroBanner));
