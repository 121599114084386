import PropTypes from 'prop-types';

export const props = {
  src: PropTypes.string.isRequired,
  config: PropTypes.shape({
    portalId: PropTypes.string,
    formId: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};
