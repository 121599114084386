import styled from 'styled-components';

export const StyledSocialListModern = styled.ul`
  display: flex;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  flex-wrap: wrap;

  .socialItem {
    width: 40px;
    padding: 2px 10px;
    border-radius: ${props => props.borderRadius || '50%'};
    overflow: hidden;
    margin: ${props => props.margins || '10px 5px'};
    transition: all 0.4s;

    .socialLink {
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      color: ${props => props.color || props.theme.font.colors.primary};

      .IconContainer {
        font-size: 1.1rem;
        svg {
          width: 1.2rem;
          color: ${props => props.color || props.theme.colors.primary};
          path {
            fill: ${props => props.color || props.theme.colors.primary};
          }
          circle {
            fill: ${props => props.color || props.theme.colors.primary};
          }
        }

        svg.linkedin-logo {
          width: 1.4rem !important;
        }
      }
    }

    &:hover {
      background-color: ${props => props.theme.colors.secondary};

      .socialLink i {
        color: ${props => props.hover || props.theme.font.colors.secondary};
        fill: ${props => props.color || props.theme.font.colors.primary};
      }

      .socialLink {
        .IconContainer {
          svg {
            path {
              fill: ${props => props.theme.font.colors.secondary};
            }
            circle {
              fill: ${props => props.theme.font.colors.secondary};
            }
          }
        }
      }
    }
  }
`;
