import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { props } from './LoginForm.props';
import { StyledLoginForm } from './LoginForm.styled';
import { StyledModernLoginForm } from './LoginForm.styled.modern';

import { Card, TextInput, Btn, CheckboxInput, Logo } from 'components/';
import { MODERN_THEME } from 'styled/constants';
import { handleLogIn, handleLoginWithMicrosoft, handleGetMicrosoftLoginUrl } from 'actions';
import { isValidEmail } from 'utils/dataHelper';
import { tlink } from 'utils/translationHelper';

function LoginForm({
  t,
  settings: { myAccountConfiguration, theme, configurations },
  isAuthenticated,
  modal,
  handleLogIn,
  handleLoginWithMicrosoft,
  loginCallBack,
  profile,
  history,
  i18n,
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState('');
  const logo = myAccountConfiguration && myAccountConfiguration.logo;
  const hideLoginForm =
    myAccountConfiguration && myAccountConfiguration.hideLoginForm != undefined
      ? myAccountConfiguration.hideLoginForm
      : false;
  const hasMsLogin =
    myAccountConfiguration && myAccountConfiguration.hasMsLogin != undefined
      ? myAccountConfiguration.hasMsLogin
      : false;
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams ? queryParams.get('code') : '';
  const state = queryParams ? queryParams.get('state') : '';
  const error_code = queryParams ? queryParams.get('error') : '';
  const error_description = queryParams ? queryParams.get('error_description') : '';

  const StyledComponent = theme.template === MODERN_THEME ? StyledModernLoginForm : StyledLoginForm;

  const getLink = path => {
    return tlink(path, t, i18n, null, configurations.langConfig);
  };

  useEffect(() => {
    // If it's modal, user is in checkout flow
    if (isAuthenticated && !modal) {
      if (myAccountConfiguration?.privateSite) {
        return history.replace(history.location?.state?.referrer || '/');
      }
      if (!profile.documentId) {
        return history.replace(getLink('/my-account'));
      }
      return history.replace(getLink('/my-account/bookings'));
    }
  }, [isAuthenticated, modal]);

  useEffect(() => {
    const handleMicrosoft = async () => {
      try {
        setError('');
        setLoading(true);
        await handleLoginWithMicrosoft(code, state);
        loginCallBack && loginCallBack();
      } catch (error) {
        const message = error.response && error.response.data.message;
        setError(message || t('registerError'));
      } finally {
        setLoading(false);
      }
    };

    if (code && state.startsWith('microsoft')) {
      handleMicrosoft(code, state);
    } else if (error_code && error_description) {
      const errorMessage =
        'Error Code: ' + error_code + ' / Error Description: ' + error_description;
      setError(errorMessage);
    }
  }, [code, state]);

  const logIn = async e => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const { error } = await handleLogIn(email, password, remember);
    if (error) {
      setError(t('loginError'));
      setLoading(false);
      return;
    }
    loginCallBack && loginCallBack();
    setLoading(false);
  };

  const loginWithMicrosoft = async () => {
    window.location.href = await handleGetMicrosoftLoginUrl();
  };

  const disableLogin = !isValidEmail(email) || password.length < 6;

  const form = (
    <>
      {!hideLoginForm && (
        <>
          <Form>
            {logo && <Logo className="ml-auto mr-auto mb-4" src={logo} />}
            <TextInput
              inputClass="form-control"
              type="mail"
              className="form-control"
              placeholder={t('myAccount.email')}
              value={email}
              onChange={setEmail}
            />
            <TextInput
              inputClass="form-control"
              type="password"
              className="form-control"
              placeholder={t('myAccount.password')}
              value={password}
              onChange={setPassword}
            />
            <CheckboxInput
              className="mb-3"
              text={t('myAccount.rememberMe')}
              checked={remember}
              onChange={setRemember}
            />
            <Btn
              text={t('myAccount.logIn')}
              onClick={logIn}
              disabled={disableLogin || loading}
              loading={loading}
              iconColor="white"
              iconSize="1.2rem"
            />
          </Form>
          <button
            className="forgotPassword"
            onClick={() => history.push(getLink('/forgot-password'))}
          >
            {t('myAccount.forgotPassword')}
          </button>
        </>
      )}

      {hasMsLogin && (
        <button className="social-btn" onClick={loginWithMicrosoft}>
          <i className="fab fa-microsoft"></i>
          {t('myAccount.loginWithMicrosoft')}
        </button>
      )}
    </>
  );

  return (
    <StyledComponent>
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible className="error-alert">
          {error}
        </Alert>
      )}
      {modal ? <div>{form}</div> : <Card>{form}</Card>}
    </StyledComponent>
  );
}

LoginForm.propTypes = props;

const mapStateToProps = ({ user, siteConfiguration }) => ({
  isAuthenticated: user.isAuthenticated,
  settings: siteConfiguration.settings,
  profile: user.profile,
});

export default connect(mapStateToProps, {
  handleLogIn,
  handleLoginWithMicrosoft,
})(withRouter(withTranslation()(LoginForm)));
