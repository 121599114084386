import React from 'react';
import { props } from './PageTitle.props';
import { Title } from 'components/';
import { StyledPageTitle } from './PageTitle.styled';

class PageTitle extends React.Component {
  static propTypes = props;

  render() {
    const {
      title,
      subTitle,
      titleType,
      subtitleType,
      subTitleColor,
      titleColor,
      subTitleSize,
      titleSize,
    } = this.props;
    return (
      <StyledPageTitle
        className={`page-title col-lg-6 offset-lg-3 text-center mb-4 claim ${this.props.className}`}
      >
        <Title
          type={titleType ? titleType : 'h2'}
          text={title}
          weight="900"
          fontSize={titleSize || 30}
          color={titleColor}
        />
        <Title
          type={subtitleType ? subtitleType : 'h1'}
          text={subTitle}
          weight="500"
          fontSize={subTitleSize || 16}
          italic={false}
          color={subTitleColor}
        />
      </StyledPageTitle>
    );
  }
}

export default PageTitle;
