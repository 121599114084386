import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import { props, defaultProps } from './SelectInput.props';
import { StyledInput } from './SelectInput.styled';
import { StyledInputModern } from './SelectInput.styled.modern';

const createOption = (option, key, selectedValue) => {
  if (option.value === selectedValue) {
    return (
      <option value={option.value} key={key} selected>
        {option.text}
      </option>
    );
  } else {
    return (
      <option value={option.value} key={key}>
        {option.text}
      </option>
    );
  }
};

const SelectInput = ({
  wrapperId,
  wrapperClass,
  inputClass,
  color,
  onChange,
  borderColor,
  fontSize,
  iconColor,
  placeholder,
  icon,
  error,
  options,
  label,
  selected,
  disabled,
  name,
  defaultOption,
  settings,
  props,
  t,
}) => {
  const getTemplateStyledComponent = () => {
    if (settings.theme.template === MODERN_THEME) {
      return StyledInputModern;
    }
    return StyledInput;
  };

  const StyledComponent = getTemplateStyledComponent();

  return (
    <StyledComponent
      color={color}
      fontSize={fontSize}
      id={wrapperId}
      className={wrapperClass}
      borderColor={borderColor}
      iconColor={iconColor}
      error={error}
      options={options}
      disabled={disabled}
    >
      {label && (
        <label htmlFor={name} className="label">
          {label}
        </label>
      )}
      <div className="input-wrapper mb-2">
        {icon && <span className={icon} />}
        <select
          className={inputClass}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          value={selected || 0}
          id={name}
          name={name}
          {...props}
        >
          <option value={null}>{defaultOption ? defaultOption : t('select')}</option>
          {options.map((option, key) => {
            if (option.options) {
              return (
                <optgroup label={option.title} value={option.title} key={key}>
                  {option.options.map(opt => {
                    return (
                      <option value={opt} key={opt}>
                        {opt}
                      </option>
                    );
                  })}
                </optgroup>
              );
            }
            if (option.text && option.value) {
              return createOption(option, key, selected);
            }
            return (
              <option value={option} key={key}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
      {error && <div className="error">{error}</div>}
    </StyledComponent>
  );
};

SelectInput.propTypes = props;
SelectInput.defaultProps = defaultProps;
export default withSettingsContext(withTranslation()(SelectInput));
