import style from 'styled-components';

export const StyledAccordionCard = style.section`
    background: ${props => (props.background ? `url(${props.background}) no-repeat` : `white`)};
    i.fa-info-circle {
        color: ${props => props.theme.font.colors.third}
    }
    i.fa-angle-down{
        color:#ccc;
    }
    .card{
        background: ${props => (props.background ? `url(${props.background}) no-repeat` : `white`)};
        border-radius: 0;
        border: 1px solid rgba(0,0,0,.125);
        margin-bottom: 10px;
    }
    .card-header{
        background: none;
        border: none;
        display: flex;

        .accordion-title {
        flex-direction: row;
        display: flex;
        width: 100%;
        justify-content: space-between;
        }
    }

    .accordion-title:hover {
        div{
            color:${props => props.theme.colors.primary};
            font-weight:500;
        }
    }
    
    h5 {
        font-size: 15px;
    }
    .card-body{
        font-size: 13px;
        flex: 1 1 auto;
        padding: 1.25rem;
    }
    .card-body p{
        font-size: 13px;
    }
    .card-body a{
        color: ${props => props.theme.colors.primary};
        transition: all .2s;
    }
`;
