import React from 'react';
import { PropTypes } from 'prop-types';
import style from 'styled-components';

const StyledI = style.i`
  color: ${props => props.color || props.theme.font.primary};
`;

export default class IconComponent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    return (
      <StyledI className={this.props.className} title={this.props.title} color={this.props.color} />
    );
  }
}
