import React from 'react';
import { TranslatedComponent } from 'components/';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { tlink } from 'utils/translationHelper';
import withSettingsContext from '../../SettingsContext';

class TranslatedLink extends TranslatedComponent {
  static propTypes = {
    name: PropTypes.string,
    path: PropTypes.string,
  };

  isExternal(link) {
    return link.indexOf('://') >= 0 || link.indexOf('tel:') >= 0 || link.indexOf('mailto:') >= 0;
  }

  getLink() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const { t, i18n, settings } = this.props;

    return tlink(this.props.path, t, i18n, null, settings.configurations.langConfig);
  }

  render() {
    return (
      <>
        {this.isExternal(this.props.path || '/') ? (
          <a
            className={this.props.className || 'linkItem'}
            href={this.props.path}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.children || this.tt(this.props.name)}
          </a>
        ) : (
          <Link className={this.props.className || 'linkItem'} to={this.getLink()}>
            {this.props.children || this.tt(this.props.name)}
          </Link>
        )}
      </>
    );
  }
}

export default withSettingsContext(withTranslation()(TranslatedLink));
