import style from 'styled-components';

export const StyledLandingSectionModern = style.section`

    .quoted h1{
        font-weight: 400;
        font-size: 40px !important;
        color: #fff;
        text-transform: none;
        margin-left: 10px;
     }

     .titleInfo{
        color:${props => props.theme.colors.primary};
     }

	.description {
        background-color: #F2F5F7;
        margin:40px;
        border-radius:12px;
        padding:20px;
    }

    .description p {
        font-size:16px;
        color:#656970;
        margin-top:20px;
    }

    .imgText img{
        border-radius:500px 0 0 500px;
        width:100%;
        position:relative;
        left:105px;
    }

    h2{
        font-weight: 400 !important;
        color:${props => props.theme.colors.primary};
        text-transform: none;
        margin-bottom: 15px;
        margin-left: 10px;
    }

    .routes .routes-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .routes-item {
        display: flex;
        flex: 1;
        flex-basis: 25%;
        max-width: 25%;
        align-items: center;
        text-align: center;
        flex-direction: column;

        .km{
            font-size: 1.5rem;
            margin-bottom: 0px;
            font-weight: bold;
            color: ${props => props.kmColor || props.theme.colors.secondary}
        }
        .location {
            font-size: 1rem;
            line-height: 1.1;
        }
    }
    .landing-title-wrapper {
        text-align: right;
    }

    .description-subtitle h1, .description-subtitle h2, .description-subtitle h3 {
        margin-top: 0;
    }
    
    @media (max-width: 767px) {
            .description {
                margin:0;
                padding:0;
            }
    
            .description p {
                padding-right:0;
            }

            .imgText img{
                 left:51px;
            }

            .map{
                padding:0;
            }

`;
