import userFetcher from 'utils/userFetcher';

const api = new userFetcher();
const apiUrl = process.env.REACT_APP_BACKEND_API;

export const logIn = async (email, password, remember) => {
  return await api.post(`${apiUrl}/User/authenticate`, {
    email,
    password,
    remember,
  });
};

export const logOut = async () => {
  return await api.get(`${apiUrl}/User/logout`);
};

export const register = async (name, lastname, email, password) => {
  return await api.post(`${apiUrl}/User/register`, {
    name,
    lastname,
    email,
    password,
  });
};

export const getMicrosoftAuthorizationUrl = async () => {
  return api.get(`${apiUrl}/User/microsoft/get-authorization-url`);
};

export const loginWithMicrosoft = async (code, state) => {
  return await api.post(`${apiUrl}/User/microsoft/login`, { code, state });
};

export const getUserProfile = async () => {
  return await api.get(`${apiUrl}/User/profile`);
};

export const getUserBookings = async (offset = 0, limit = 6) => {
  return await api.get(`${apiUrl}/User/bookings?offset=${offset}&limit=${limit}`);
};

export const getUserInfractions = async (offset = 0, limit = 30) => {
  return await api.get(`${apiUrl}/User/infractions?offset=${offset}&limit=${limit}`);
};

export const getUserFiles = async (offset = 0, limit = 30) => {
  return await api.get(`${apiUrl}/User/profile/attachments?offset=${offset}&limit=${limit}`);
};

export const attachUserFile = async file => {
  return await api.post(`${apiUrl}/User/profile/attachments`, file);
};

export const updateUserProfile = async profile => {
  return await api.post(`${apiUrl}/User/profile`, { ...profile });
};

export const userCancelReservation = async reservation =>
  api.post(`${apiUrl}/User/cancel`, reservation);

export const forgotPassword = async email => {
  return await api.post(`${apiUrl}/User/forgot-password?email=${email}`);
};

export const forgotPasswordValidation = async token => {
  return await api.post(`${apiUrl}/User/forgot-password-validation?token=${token}`);
};

export const resetPassword = async (email, password, token) => {
  return await api.post(`${apiUrl}/User/reset-password`, {
    email,
    password,
    token,
  });
};

export const validateCustomerGuidToken = async token => {
  return await api.post(`${apiUrl}/User/validate-customer-guid-token?token=${token}`);
};
