export const getAllCategoryIdsInCollection = categoryGroupCollection => {
  return categoryGroupCollection.flatMap(cg => cg.categories.flatMap(c => c.ids));
};

export const getSippByCategory = (categoryGroupCollection, category) => {
  let categoryGroupName = '';

  categoryGroupCollection.every(cg => {
    const includedCategories = cg.categories.flatMap(c => c.ids);
    if (includedCategories.includes(category.id)) {
      categoryGroupName = cg.name;
      return false;
    }
    return true;
  });

  return categoryGroupName;
};
