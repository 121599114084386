import React from 'react';
import { props } from 'components/Title/Title.props';
import style from 'styled-components';
import { withTranslation } from 'react-i18next';
import { TranslatedComponent } from 'components/';

const StyledParagraph = style.p`
    color: ${props => props.color || props.theme.font.colors.primary};
    ${props => props.italic && `font-style: italic !important;`}
    ${props => `font-weight: ${props.bold ? '900' : props.weight || '400'}`}
    ${props => `font-size: ${props.fontSize || '12px'};`}
    ${props => `line-height: ${props.lineHeight || '22px'};`}

    &.centered {
      text-align: center;
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
`;

class ParagraphComponent extends TranslatedComponent {
  static propTypes = props;

  render() {
    const {
      text,
      bold,
      italic,
      className,
      color,
      weight,
      fontSize,
      noMedia,
      nonTranslate,
      lineHeight,
      style,
    } = this.props;
    const hasText = text !== undefined;

    let varText = text;
    if (hasText) {
      //TODO: Sacar esto a una clase y agregar soporte para navegar en las propiedades del objeto
      if (text.indexOf('ROUTERPROPS') === 0) {
        try {
          varText = this.props.routerProps.state[text.replace('ROUTERPROPS.', '')];
        } catch {
          varText = text;
        }
      }

      return (
        <StyledParagraph
          className={className}
          bold={bold}
          italic={italic}
          color={color}
          weight={weight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          noMedia={noMedia}
          style={style}
          dangerouslySetInnerHTML={{ __html: nonTranslate ? varText : this.tt(varText) }}
        />
      );
    } else {
      return (
        <StyledParagraph
          className={className}
          bold={bold}
          italic={italic}
          color={color}
          weight={weight}
          lineHeight={lineHeight}
          fontSize={fontSize}
          noMedia={noMedia}
          style={style}
        >
          {this.props.children}
        </StyledParagraph>
      );
    }
  }
}

export default withTranslation()(ParagraphComponent);
