import React from 'react';
import ReactDOM from 'react-dom';
import { ScriptTagComponent } from 'components/';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.state = {
      showButton: false,
      isRendered: false,
    };
    this.onPaypalLoaded = this.onPaypalLoaded.bind(this);
  }

  onPaypalLoaded = event => {
    this.setState({ showButton: true });
  };

  render() {
    const { script } = this.props;
    let total = this.props.total;
    let currencyCode = this.props.currencyCode;
    let onSuccess = this.props.onSuccess;

    if (this.state.showButton && !this.state.isRendered) {
      paypal
        .Buttons({
          createOrder: function(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: total,
                    currency_code: currencyCode,
                  },
                },
              ],
            });
          },
          onApprove: function(data, actions) {
            return actions.order.capture().then(function(details) {
              onSuccess(details);
            });
          },
        })
        .render('#paypal-button-container');
      this.setState({ isRendered: true });
    }
    return (
      <>
        <div id="paypal-button-container" className="paypal-pay_button"></div>
        <ScriptTagComponent
          url={script}
          loadLibrary={true}
          async={true}
          onLoaded={this.onPaypalLoaded}
        />
      </>
    );
  }
}

PaypalButton.propTypes = {
  script: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

PaypalButton.defaultProps = {
  env: 'sandbox',
  onSuccess: payment => {
    // console.log('The payment was succeeded!', payment);
  },
  onCancel: data => {
    // console.log('The payment was cancelled!', data);
  },
  onError: err => {
    // console.log('Error loading Paypal script!', err);
  },
};

const mapStateToProps = ({ bookings }) => ({
  bookings: bookings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(PaypalButton)));
