import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import AppContainer from 'App.container';
import { Switch, Route } from 'react-router';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import AuthService from 'auth/AuthService';

const AsyncAdminContainer = React.lazy(() => import('pages/Admin'));

class App extends Component {
  handleAuthentication = ({ location }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      AuthService.handleAuthentication();
    }
  };

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route
              path="/callback"
              render={props => {
                this.handleAuthentication(props);
                return <div style={{ height: `100%` }} />;
              }}
            />
            <PrivateRoute path="/admin" component={AsyncAdminContainer} />
            <Route component={AppContainer} {...this.props} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
