import React from 'react';
import { props } from './ReviewsCarousel.props';
import { StyledReviewsCarousel } from './ReviewsCarousel.styled';
import withSettingsContext from 'SettingsContext';
import { Carousel, Col } from 'react-bootstrap';

function ReviewsCarousel({
  bgColor,
  bgImage,
  bgSize,
  bgPosition,
  bgAttachment,
  bgRepeat,
  titleSize,
  titleColor,
  descriptionSize,
  descriptionColor,
  iconSize,
  iconColor,
  height,
  itemMargin,
  items,
  className = '',
  showControls = true,
  showIndicators = false,
  interval,
  fade,
}) {
  const getStarIcons = rate => {
    const rating = Math.round(rate * 2) / 2;
    let output = [];

    // Append all the filled whole stars
    for (var i = rating; i >= 1; i--) {
      output.push('<i class="fas fa-star"></i>');
    }

    // If there is a half a star, append it
    if (i === 0.5) {
      output.push('<i class="fas fa-star-half-alt"></i>');
    }

    // Fill the empty stars
    for (let i = 5 - rating; i >= 1; i--) {
      output.push('<i class="far fa-star"></i>');
    }

    return output.join('');
  };

  return (
    <StyledReviewsCarousel
      className={`row reviews-carousel ${className}`}
      bgColor={bgColor}
      bgImage={bgImage}
      bgSize={bgSize}
      bgPosition={bgPosition}
      bgAttachment={bgAttachment}
      bgRepeat={bgRepeat}
      titleSize={titleSize}
      titleColor={titleColor}
      descriptionSize={descriptionSize}
      descriptionColor={descriptionColor}
      iconSize={iconSize}
      iconColor={iconColor}
      height={height}
      itemMargin={itemMargin}
    >
      <Col>
        <Carousel
          fade={fade}
          controls={showControls}
          indicators={showIndicators}
          interval={interval}
        >
          {items.map((item, idx) => {
            return (
              <Carousel.Item key={idx}>
                <div className="item-wrapper">
                  <Carousel.Caption>
                    {item.rate && (
                      <div
                        className="stars-wrapper"
                        dangerouslySetInnerHTML={{
                          __html: getStarIcons(item.rate),
                        }}
                      />
                    )}
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Col>
    </StyledReviewsCarousel>
  );
}

ReviewsCarousel.propTypes = props;

export default withSettingsContext(ReviewsCarousel);
