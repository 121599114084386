import React from 'react';
import { StyledCondicionesSection } from './CondicionesSection.styled';
import { StyledCondicionesSectionModern } from './CondicionesSection.styled.modern';
import { props } from './CondicionesSection.props';
import { AccordionCard, Title, TranslatedComponent } from 'components/';
import { Accordion } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Paragraph from 'components/CMS/Paragraph/Paragraph';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class CondicionesSection extends TranslatedComponent {
  static propTypes = props;

  accordionContent(faqs, i, theme, modernImage) {
    const { titleType, fontWeight, fontSize, showImage } = this.props;

    if (theme === MODERN_THEME && showImage !== false) {
      return (
        <>
          <div className="col-lg-6">
            <Accordion>
              {faqs.map((faq, key) => (
                <AccordionCard
                  title={this.tt(faq.question)}
                  body={this.tt(faq.answer)}
                  key={key}
                  keyValue={key}
                  titleType={titleType}
                  fontWeight={fontWeight}
                  fontSize={fontSize}
                />
              ))}
            </Accordion>
          </div>
          <div className="col-lg-6 imgFaqs">
            <div className="img">
              <LazyLoadImage
                src={modernImage}
                alt="faq-questions-img"
                threshold={100}
                effect="blur"
                height={'100%'}
                width={'100%'}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-lg-6">
          <Accordion>
            {faqs.slice(0, i).map((faq, key) => (
              <AccordionCard
                title={this.tt(faq.question)}
                body={this.tt(faq.answer)}
                key={key}
                keyValue={key}
                titleType={titleType}
                fontWeight={fontWeight}
                fontSize={fontSize}
              />
            ))}
          </Accordion>
        </div>

        <div className="col-lg-6">
          <Accordion>
            {faqs.length - i > 0 &&
              faqs
                .slice(i, faqs.length)
                .map((faq, key) => (
                  <AccordionCard
                    title={this.tt(faq.question)}
                    body={this.tt(faq.answer)}
                    key={i + key}
                    keyValue={i + key}
                    titleType={titleType}
                    fontWeight={fontWeight}
                    fontSize={fontSize}
                  />
                ))}
          </Accordion>
        </div>
      </>
    );
  }

  faqsSection(faqs, modernImage) {
    if (faqs && faqs.length > 0) {
      let i = Math.ceil(faqs.length / 2);
      if (i === 0) i = faqs.length;

      var faqTag = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faqs.map(q => {
          return {
            '@type': 'Question',
            name: this.tt(q.question),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.tt(q.answer),
            },
          };
        }),
      };

      const defaultLang = this.props.settings.configurations.defaultLang;
      const conditionsPageSchemaTags = this.props.settings.translations[defaultLang][
        '__PageCondiciones'
      ].schemaTags;
      const theme = this.props.settings.theme.template;

      return (
        <>
          {conditionsPageSchemaTags === null ||
            (conditionsPageSchemaTags === undefined && (
              <Helmet>
                <script type="application/ld+json">{`${JSON.stringify(faqTag)}`}</script>
              </Helmet>
            ))}

          {this.props.onlyFaq || theme === MODERN_THEME ? null : <hr />}
          <div className="row">
            {this.props.onlyFaq ? null : (
              <div className="col-md-12 text-center mt-5 mb-4">
                <Title type="h2" text={this.props.titleFaq || 'Faq'} weight="900" fontSize={30} />
              </div>
            )}
            {this.accordionContent(faqs, i, theme, modernImage)}
          </div>
        </>
      );
    }

    return null;
  }

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledCondicionesSectionModern;
    }
    return StyledCondicionesSection;
  }

  render() {
    const { conditions, faqs, conditionsColClass, settings, modernImage } = this.props;
    const modernTheme = settings.theme.template === MODERN_THEME;
    const StyledSection = this.getTemplateStyledComponent();

    const conditionCol = modernTheme ? 'col-lg-12' : 'col-lg-6';
    const conditionMargin = modernTheme ? 'mt-3 ' : 'mb-5 ';
    const fontSize = modernTheme ? 21 : 18;

    return (
      <StyledSection highlightColor={this.props.highlightColor}>
        <div className="col-md-12 conditions-container-wrapper">
          <div className="container-fluid conditionsBG">
            <div className="row mb-5">
              {conditions &&
                conditions.map((condition, key) => (
                  <div
                    className={
                      (conditionsColClass || conditionCol) +
                      ' condiciones ' +
                      conditionMargin +
                      (condition.highlight ? 'highlight' : '')
                    }
                    key={`condition-${key}`}
                  >
                    <Title
                      type={'h3'}
                      weight="600"
                      fontSize={fontSize}
                      text={condition.name}
                      italic={false}
                    />
                    <ul className="list-unstyled">
                      {condition.items &&
                        condition.items.map((cItem, cKey) => (
                          <li key={cKey}>
                            <i className="fas fa-angle-right" />{' '}
                            <Paragraph text={cItem} style={{ display: 'inline' }} />
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          {this.faqsSection(faqs, modernImage)}
        </div>
      </StyledSection>
    );
  }
}

export default withSettingsContext(withTranslation()(CondicionesSection));
