import style from 'styled-components';

export const StyledFranchiseSection = style.div`
	h5 {
		font-size: 14px !important;
	}

	td {
		text-align: center;
	}
`;
