import style from 'styled-components';

export const StyledSection = style.section`
background: ${props => (props.background ? `url(${props.background}) no-repeat` : `#fff`)};
background-size: cover;
min-height: 600px;
display: flex;
justify-content: ${props => (props.justifyContent || `center`)};
align-items: ${props => (props.alignContent || `center`)};

    .call-to-action-content {
        align-items: ${props => (props.justifyContent || `center`)};
        padding: 1.5rem;
        flex-direction: column;
        display: flex;
    }

    @media only screen and (max-width: 780px)
    {
    align-items: ${props => (props.alignMobileContent || `center`)};
    justify-content: ${props => (props.justifyMobileContent || `center`)};

    .call-to-action-content {
        align-items: ${props => (props.justifyMobileContent || `center`)};
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

`;