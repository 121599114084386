import PromocionesSection from './PromocionesSection';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchPromotions } from 'actions';

const mapStateToProps = ({ global }) => ({
  listPromotions: global.listPromotions,
});

export default connect(mapStateToProps, {
  fetchPromotions,
})(withTranslation()(PromocionesSection));
