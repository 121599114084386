import React from 'react';
import { StyledWhatsappContainer } from './FloatingWhatsapp.styled';
import { props } from './FloatingWhatsapp.props';
import { withTranslation } from 'react-i18next';
import { tt } from 'utils/translationHelper';

const FloatingWhatsapp = ({ phoneNumber, whatsappImage, messageText, disabled, t }) => {
  if (disabled) return null;
  const defaultImg = 'https://rently.blob.core.windows.net/rently/Web/wt.png';
  return (
    <StyledWhatsappContainer>
      {tt(messageText, t).includes('__FloatingWhatsapp.messageText') ? (
        <a
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          id="whatsapp-button-floating"
        >
          <img src={whatsappImage || defaultImg} alt="logo-whatsapp" />
        </a>
      ) : (
        <a
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${tt(messageText, t)}`}
          target="_blank"
          rel="noopener noreferrer"
          id="whatsapp-button-floating"
        >
          <img src={whatsappImage || defaultImg} alt="logo-whatsapp" />
        </a>
      )}
    </StyledWhatsappContainer>
  );
};

FloatingWhatsapp.propTypes = props;

export default withTranslation()(FloatingWhatsapp);
