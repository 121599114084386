import React from 'react';
import { withTranslation } from 'react-i18next';
import { StyledCategoriesFilter } from './CategoriesFilter.styled';
import { StyledCategoriesFilterModern } from './CategoriesFilter.styled.modern';
import { CheckboxInput } from '/components';
import { PropTypes } from 'prop-types';
import { getAllCategoryIdsInCollection } from '../../utils/categoryGroupHelper';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

class CategoriesFilter extends React.Component {
  state = {
    loading: true,
    workWithCategoryGroups: false,
  };

  static propTypes = {
    listCategories: PropTypes.array,
    t: PropTypes.func,
    onCategoriesChanged: PropTypes.func,
  };

  handleCategoryChange = entity => {
    const {
      onCategoriesChanged,
      listCategories,
      params,
      setSearchCarParams,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;

    const filterEntities = this.state.workWithCategoryGroups ? categoryGroups : listCategories;
    let categories = params.categories;
    const entityIdentifier = this.getEntityUniqueId(entity);

    if (this.state.workWithCategoryGroups) {
      const categoriesInGroup = entity.categories.flatMap(c => c.ids);
      categoriesInGroup.forEach(categoryId => {
        if (!categories.includes(categoryId)) {
          categories.push(categoryId);
        } else {
          const index = categories.indexOf(categoryId);
          if (index !== -1) categories.splice(index, 1);
        }
      });

      // check if all categories are included If so, we empty the filters.
      const allCategoriesInGroup = getAllCategoryIdsInCollection(categoryGroups);
      if (categories.length === allCategoriesInGroup.length) {
        categories = [];
      }
    }

    if (!this.state.workWithCategoryGroups) {
      const exists = categories.find(category => entityIdentifier === category);
      if (!exists) categories.push(entityIdentifier);
      else {
        const index = categories.indexOf(entityIdentifier);
        if (index !== -1) categories.splice(index, 1);
      }

      if (categories.length === filterEntities.length) categories = [];
    }

    this.setState(prevState => ({ ...prevState }));
    let newParams = { ...params, ...{ categories: categories } };
    setSearchCarParams(newParams);

    if (onCategoriesChanged) onCategoriesChanged(categories);
  };

  handleAllCategoriesChange = value => {
    if (value) {
      const { onCategoriesChanged, params, setSearchCarParams } = this.props;

      this.setState(prevState => ({
        ...prevState,
      }));

      let newParams = { ...params, ...{ categories: [] } };

      setSearchCarParams(newParams);

      if (onCategoriesChanged) onCategoriesChanged([]);
    }
  };

  async componentDidMount() {
    const {
      fetchCategories,
      i18n,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;

    await fetchCategories(i18n.language);

    this.setState(prevState => ({
      ...prevState,
      loading: false,
      workWithCategoryGroups: categoryGroups && categoryGroups.length > 0,
    }));
  }

  getEntityUniqueId = entity => {
    return this.state.workWithCategoryGroups ? entity.name : entity.id;
  };

  isItemSelected = entity => {
    const {
      categories,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;

    if (categoryGroups) {
      const categoriesInGroup = entity.categories.flatMap(c => c.ids);
      return categoriesInGroup.every(c => categories.includes(c));
    }

    return categories.indexOf(this.getEntityUniqueId(entity)) !== -1;
  };

  isAllChecked = () => {
    const {
      listCategories,
      categories,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    if (!categories || categories.length === 0) return true;

    let categoriesToCheck = [];
    if (this.state.workWithCategoryGroups) {
      categoriesToCheck = getAllCategoryIdsInCollection(categoryGroups);
    } else {
      categoriesToCheck = listCategories;
    }

    return categoriesToCheck.length === categories.length;
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledCategoriesFilterModern;
    }
    return StyledCategoriesFilter;
  }

  render() {
    if (this.state.loading) return null;

    const {
      listCategories,
      t,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    const filterEntities = this.state.workWithCategoryGroups ? categoryGroups : listCategories;

    let i = Math.floor(filterEntities.length / 2);
    if (i === 0) i = filterEntities.length;

    const StyledSection = this.getTemplateStyledComponent();

    return (
      <StyledSection className="row search-category w-100 mt-3 pt-4 pb-4 pl-4 pr-4">
        <div className="col-md-12">
          <p>{t('categorias')}</p>
        </div>
        <div className="col-md-6">
          <CheckboxInput
            className="special-checkbox"
            text={t('all')}
            checked={this.isAllChecked()}
            onChange={this.handleAllCategoriesChange}
          />
          {filterEntities.slice(0, i).map((entity, index) => (
            <CheckboxInput
              className="special-checkbox"
              key={index}
              text={entity.name.toLowerCase()}
              checked={this.isItemSelected(entity)}
              onChange={() => this.handleCategoryChange(entity)}
            />
          ))}
        </div>
        <div className="col-md-6">
          {filterEntities.slice(i, filterEntities.length).map((entity, index) => (
            <CheckboxInput
              className="special-checkbox"
              key={index}
              text={entity.name.toLowerCase()}
              checked={this.isItemSelected(entity)}
              onChange={() => this.handleCategoryChange(entity)}
            />
          ))}
        </div>
      </StyledSection>
    );
  }
}

export default withSettingsContext(withTranslation()(CategoriesFilter));
