import style from 'styled-components';

export const StyledCancelSection = style.div`

    margin-left: auto;
     margin-right: auto;
    h3{
        font-weight: 600;
        font-style: normal;
        font-size: 18px;
        margin-top: 10px;
    }

    .recaptcha {
        div {
            
        }
    }
`;
