import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  background: PropTypes.string,
  backgroundAlt: PropTypes?.string,
  mobileBackground: PropTypes?.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.any,
  noParallax: PropTypes.bool,
  t: PropTypes.func,
  showSecondColumn: PropTypes.bool,
  secondColumnConfig: PropTypes.any,
};
