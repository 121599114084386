import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  t: PropTypes.func,
  slideSpeed: PropTypes.number,
  autoplaySpeed: PropTypes.number,
  slidesToShow: PropTypes.number,
  showModelDetail: PropTypes.bool,
};

export const defaultProps = {
  showModelDetail: false,
};
