import { connect } from 'react-redux';
import CategoriesFilter from './CategoriesFilter';
import { fetchCategories, setSearchCarParams } from 'actions';

const mapStateToProps = ({ global, searchCars, siteConfiguration }) => ({
  listCategories: global.listCategories,
  categories: searchCars.params.categories,
  params: searchCars.params,
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, {
  fetchCategories,
  setSearchCarParams,
})(CategoriesFilter);
