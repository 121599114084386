import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { StyledSearchMap } from './SearchMap.styled';

class SearchMap extends React.Component {
  propTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.props;

    return (
      <StyledSearchMap className="row map w-100 mt-3 pt-4 pb-4 pl-4 pr-4">
        <div className="col-md-12">
          <p>{t('mapa')}</p>
          <div id="mapPlaces" />
        </div>
      </StyledSearchMap>
    );
  }
}

export default withTranslation()(SearchMap);
