import PropTypes from 'prop-types';

export const props = {
  headers: PropTypes.array,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.string.isRequired,
      title: PropTypes.string,
      subTitle: PropTypes.string,
      metaTitle: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};
