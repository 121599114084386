import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StyledSearchFormWhatsApp } from './SearchFormWhatsApp.styled';
import {
  SelectInput,
  TextInput,
  Btn,
  RangeDateInput,
  SearchBoxPlace,
  CheckboxInput,
} from 'components/';
import { withTheme } from 'styled-components';
import queryString from 'query-string';
import moment from 'moment';
import TranslatedComponent from 'components/TranslatedComponent';
import { tlink } from 'utils/translationHelper';

import { getValidPromotionCode } from '../../services/api';

import {
  getSchedule,
  calculateStartDate,
  calculateEndDate,
  isDayAvailable,
  _calculatePickDropHours,
} from 'utils/branchOfficeHelper';
import { connect } from 'react-redux';

const DEFAULT_MINIMUM_STAY = 2; // nights

const transformPlaceToSelectOption = place => ({
  ...place,
  label: place && place.name,
  value: place && place.id,
});

class SearchFormWhatsApp extends TranslatedComponent {
  static propTypes = {
    setSearchCarParams: PropTypes.func.isRequired,
    fetchBookeableCars: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.object,
    searchText: PropTypes.string,
    i18n: PropTypes.object,
    categories: PropTypes.array,
    className: PropTypes.string,
    showCity: PropTypes.bool,
    phone: PropTypes.string.isRequired,
    useBackendSort: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { router, t, i18n, searchCars, featureFlags, settings, phone } = props;
    const searchQueryStrings = queryString.parse(router.location.search);
    const {
      dateFrom,
      hourFrom,
      dateTo,
      hourTo,
      ilimitedKm,
      categories,
      promotionCode,
      customPromotionId,
    } = searchQueryStrings;

    this.state = {
      isHome:
        tlink('__Routes.search', t, i18n, null, settings.configurations.langConfig) !==
        router.location.pathname,
      searchQueryStrings,
      rangeDatePicker: {
        orientation:
          window.screen.width < parseFloat(props.theme.screens.md.replace('px', ''))
            ? 'vertical'
            : 'horizontal',
        withFullScreenPortal:
          window.screen.width < parseFloat(props.theme.screens.md.replace('px', '')),
        focus: null,
      },
      pickUp: {
        date: dateFrom || searchCars.params.dateFrom || null,
        hour: hourFrom || searchCars.params.hourFrom,
        place: null,
        moment: dateFrom
          ? this.parseDate(dateFrom)
          : searchCars.params.dateFrom
          ? this.parseDate(searchCars.params.dateFrom)
          : null,
      },
      dropOff: {
        date: dateTo || searchCars.params.dateTo || null,
        hour: hourTo || searchCars.params.hourTo,
        place: null,
        moment: dateTo
          ? this.parseDate(dateTo)
          : searchCars.params.dateTo
          ? this.parseDate(searchCars.params.dateTo)
          : null,
      },
      showDifferentDropPlace: false,
      kmFilter: {
        selected:
          ilimitedKm && ilimitedKm.length > 0
            ? ilimitedKm === 'true'
            : featureFlags.activeIlimitedKm || searchCars.params.ilimitedKm || false,
      },
      categories: categories ? categories : searchCars.params.categories || [],
      showInvalidPromotionMessage: false,
      promotionCode: promotionCode || null,
      customPromotionId: customPromotionId || null,
      phone,
    };
  }

  async componentDidMount() {
    const {
      i18n,
      fetchBookeableCars,
      fetchPlaces,
      fetchHolidays,
      fetchSchedules,
      settings: { featureFlags },
      profile,
    } = this.props;

    const { searchQueryStrings: params, kmFilter } = this.state;

    await fetchPlaces(i18n.language);
    await fetchHolidays();
    await fetchSchedules();

    const globalId = profile ? profile.globalId : null;

    if (!this.state.isHome) {
      if (params != null && Object.keys(params).length > 0) {
        //querystring has info
        let ilimitedKm = kmFilter.selected;
        const showFinalPrice = featureFlags.showFinalPrice ? featureFlags.showFinalPrice : false;
        params['onlyFullAvailability'] = featureFlags.onlyFullAvailability
          ? featureFlags.onlyFullAvailability
          : false;
        await fetchBookeableCars(params, i18n.language, ilimitedKm, showFinalPrice, globalId);
      } else {
        this.submitSearch();
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.places || !props.schedules || !props.holidays) return null;
    if (state.pickUp.place === null && props.places && props.places.length) {
      const {
        searchQueryStrings: {
          pickUpId,
          pickUpEndpoint,
          dropOffId,
          dropOffEndpoint,
          categories,
          customPromotionId,
        },
        kmFilter,
      } = state;

      const { searchCars } = props;

      let selectedPlace = pickUpId || props.placeId || searchCars.params.pickUpId;
      let selectedEndpoint =
        pickUpEndpoint || props.pickUpEndpoint || searchCars.params.pickUpEndpoint;

      let selectedDropPlace = props.placeId || dropOffId || searchCars.params.dropOffId;
      // pickUpEndpoint is not a typo. prop is receive through landing and we want same drop place by default
      let selectedDropEndpoint =
        props.pickUpEndpoint || dropOffEndpoint || searchCars.params.dropOffEndpoint;

      const pickUpPlace = selectedPlace
        ? props.places.find(
            p => p.id === Number(selectedPlace) && p.rentlyEndpointName === selectedEndpoint,
          ) || props.places[0]
        : props.places[0];
      const dropOffPlace = selectedDropPlace
        ? props.places.find(
            p =>
              p.id === Number(selectedDropPlace) && p.rentlyEndpointName === selectedDropEndpoint,
          ) || props.places[0]
        : pickUpPlace;

      const pickUpPlaceOption = transformPlaceToSelectOption(pickUpPlace);
      const dropOffPlaceOption = transformPlaceToSelectOption(dropOffPlace);
      const minStayLength =
        props.configurations.minBookingLength === undefined
          ? DEFAULT_MINIMUM_STAY
          : props.configurations.minBookingLength;

      const defaultDateFrom =
        state.pickUp.moment ||
        calculateStartDate(
          props.schedules,
          props.holidays,
          pickUpPlace.branchOfficeId,
          state.pickUp.moment,
        );
      const defaultDateTo =
        state.dropOff.moment ||
        calculateEndDate(
          defaultDateFrom,
          minStayLength,
          props.schedules,
          props.holidays,
          pickUpPlace.branchOfficeId,
        );

      const { dropOffHours, pickUpHours } = _calculatePickDropHours({
        pickUpDate: defaultDateFrom,
        dropOffDate: defaultDateTo,
        pickUpBranchOfficeId: pickUpPlace.branchOfficeId,
        dropOffBranchOfficeId: dropOffPlace.branchOfficeId,
        schedules: props.schedules,
        holidays: props.holidays,
      });

      const defaultKmFilter = kmFilter.selected;

      const filteredCategories = categories ? categories : props.categories || [];
      const cat = Array.isArray(filteredCategories)
        ? filteredCategories.map(x => parseInt(x))
        : !isNaN(parseInt(filteredCategories))
        ? [parseInt(filteredCategories)]
        : [];

      const params = {
        dateFrom: defaultDateFrom.format('DD-MM-YYYY'),
        hourFrom: state.pickUp.hour,
        pickUpId: pickUpPlace.id,
        pickUpEndpoint: pickUpPlace.rentlyEndpointName,
        dateTo: defaultDateTo.format('DD-MM-YYYY'),
        hourTo: state.dropOff.hour,
        dropOffId: dropOffPlace.id,
        dropOffEndpoint: dropOffPlace.rentlyEndpointName,
        categories: cat || [],
        ilimitedKm: defaultKmFilter,
        customPromotionId: customPromotionId || searchCars.params.customPromotionId,
      };

      props.setSearchCarParams(params);

      return {
        ...state,
        pickUp: {
          ...state.pickUp,
          place: pickUpPlaceOption,
          hours: pickUpHours,
          hour: state.pickUp.hour || pickUpHours[0],
          date: defaultDateFrom,
          moment: defaultDateFrom,
        },
        dropOff: {
          ...state.dropOff,
          place: dropOffPlaceOption,
          hours: dropOffHours,
          hour:
            state.dropOff.hour ||
            (dropOffHours.includes(pickUpHours[0]) ? pickUpHours[0] : dropOffHours[0]),
          date: defaultDateTo,
          moment: defaultDateTo,
        },
        showDifferentDropPlace:
          pickUpPlaceOption.id !== dropOffPlaceOption.id ||
          pickUpPlaceOption.rentlyEndpointName !== dropOffPlaceOption.rentlyEndpointName,
        kmFilter: {
          selected: defaultKmFilter,
        },
      };
    } else if (state.pickUp.place === null) return null;
    return null;
  }

  getDropOffPlaceAvailable = (rentlyEndpointName, tentativePlace) => {
    const { places } = this.props;
    const filteredPlaces = places.filter(p => p.rentlyEndpointName === rentlyEndpointName);

    const placeIsPresent = filteredPlaces.find(p => p.id === tentativePlace.id);
    return placeIsPresent ? tentativePlace : transformPlaceToSelectOption(filteredPlaces[0]);
  };

  pickUpSelect = selectedOption => {
    if (!selectedOption) return;

    const { schedules, holidays, configurations } = this.props;
    const { showDifferentDropPlace, dropOff } = this.state;

    const minStayLength =
      configurations.minBookingLength === undefined
        ? DEFAULT_MINIMUM_STAY
        : configurations.minBookingLength;
    const branchOfficeIdForEnd = showDifferentDropPlace
      ? dropOff.place.branchOfficeId
      : selectedOption.branchOfficeId;

    const newStartDate = calculateStartDate(
      schedules,
      holidays,
      selectedOption.branchOfficeId,
      this.state.pickUp.moment,
    );
    const newEndDate = calculateEndDate(
      newStartDate,
      minStayLength,
      schedules,
      holidays,
      branchOfficeIdForEnd,
    );

    const { pickUpHours } = this.calculatePickDropHours({
      pickUpDate: newStartDate,
      pickUpBranchOfficeId: selectedOption.branchOfficeId,
    });

    const { dropOffHours } = showDifferentDropPlace
      ? this.calculatePickDropHours({
          dropOffBranchOfficeId: dropOff.place.branchOfficeId,
          dropOffDate: newEndDate,
        })
      : this.calculatePickDropHours({
          dropOffBranchOfficeId: selectedOption.branchOfficeId,
          dropOffDate: newEndDate,
        });

    this.setState(prevState => {
      const pickUp = {
        ...prevState.pickUp,
        place: selectedOption,
        hours: pickUpHours,
        date: newStartDate,
        moment: newStartDate,
      };

      const dropOff = {
        ...prevState.dropOff,
        place: showDifferentDropPlace
          ? this.getDropOffPlaceAvailable(
              selectedOption.rentlyEndpointName,
              prevState.dropOff.place,
            )
          : selectedOption,
        date: newEndDate,
        moment: newEndDate,
        hours: dropOffHours,
      };

      return { ...prevState, pickUp, dropOff };
    });
  };

  // when dropOff place was removed. we set it equal to pickup place.
  dropOffSelect = selectedOption => {
    const { pickUp } = this.state;
    const { configurations, schedules, holidays } = this.props;
    const place = selectedOption || pickUp.place;
    const minStayLength =
      configurations.minBookingLength === undefined
        ? DEFAULT_MINIMUM_STAY
        : configurations.minBookingLength;

    const newEndDate = calculateEndDate(
      pickUp.moment,
      minStayLength,
      schedules,
      holidays,
      place.branchOfficeId,
    );
    const { dropOffHours } = this.calculatePickDropHours({
      dropOffBranchOfficeId: place.branchOfficeId,
      dropOffDate: newEndDate,
    });

    this.setState(prevState => ({
      ...prevState,
      dropOff: {
        ...prevState.dropOff,
        place: place,
        hours: dropOffHours,
        date: newEndDate,
        moment: newEndDate,
      },
    }));
  };

  toggleDropInput = () => {
    this.setState(prevState => ({
      ...prevState,
      showDifferentDropPlace: !this.state.showDifferentDropPlace,
    }));
  };

  handleDateSelect = (startDate, endDate) => {
    if (!startDate) return false;
    const pickUpDate = startDate.format('DD-MM-YYYY');
    let dropOffDate = '';
    if (endDate) dropOffDate = endDate.format('DD-MM-YYYY');
    const { pickUpHours, dropOffHours } = this.calculatePickDropHours({
      pickUpDate: startDate,
      dropOffDate: endDate,
    });
    this.setState(prevState => ({
      ...prevState,
      pickUp: {
        ...prevState.pickUp,
        date: pickUpDate,
        moment: startDate,
        hours: pickUpHours,
        hour: pickUpHours.find(v => v === prevState.pickUp.hour) || pickUpHours[0],
      },
      dropOff: {
        ...prevState.dropOff,
        date: dropOffDate,
        moment: endDate || prevState.dropOff.moment,
        hours: dropOffHours,
        hour: dropOffHours.find(v => v === prevState.dropOff.hour) || dropOffHours[0],
      },
    }));
  };

  calculatePickDropHours = ({
    pickUpDate,
    dropOffDate,
    pickUpBranchOfficeId,
    dropOffBranchOfficeId,
  } = {}) => {
    const { schedules, holidays } = this.props;
    const {
      dropOff: {
        moment: oldDropOffDate,
        place: { branchOfficeId: oldDropOffBranchOfficeId },
      },
      pickUp: {
        moment: oldPickUpDate,
        place: { branchOfficeId: oldPickUpBranchOfficeId },
      },
    } = this.state;

    return _calculatePickDropHours({
      pickUpDate: pickUpDate || oldPickUpDate,
      dropOffDate: dropOffDate || oldDropOffDate,
      pickUpBranchOfficeId: pickUpBranchOfficeId || oldPickUpBranchOfficeId,
      dropOffBranchOfficeId: dropOffBranchOfficeId || oldDropOffBranchOfficeId,
      schedules,
      holidays,
    });
  };

  handleDateFocus = focus => {
    this.setState(prevState => ({
      ...prevState,
      rangeDatePicker: { ...prevState.rangeDatePicker, focus },
    }));
  };

  handleKmFilter = value => {
    const selected = (this.state.kmFilter.selected = value);

    this.setState(prevState => ({
      kmFilter: { ...prevState.kmFilter, selected },
    }));
  };

  pickUpHourSelection = hour => {
    const { dropOff } = this.state;
    const dropOffHour = dropOff.hours && dropOff.hours.includes(hour) ? hour : dropOff.hours[0];

    this.setState(prevState => ({
      ...prevState,
      pickUp: { ...prevState.pickUp, hour },
      dropOff: { ...prevState.dropOff, hour: dropOffHour },
    }));
  };

  dropOffHourSelection = hour => {
    this.setState(prevState => ({
      ...prevState,
      dropOff: { ...prevState.dropOff, hour },
    }));
  };

  onPromotionCodeChange = value => {
    this.setState(prevState => ({
      ...prevState,
      promotionCode: value,
      showInvalidPromotionMessage: false,
    }));
  };

  disableSubmit = () => {
    const { pickUp, dropOff } = this.state;
    return !(pickUp.place && pickUp.date && pickUp.hour && dropOff.date && dropOff.hour);
  };

  isDayBlocked = (day, focusedInput) => {
    const { holidays, schedules, places } = this.props;
    const { pickUp, dropOff } = this.state;
    const placeId = focusedInput === 'startDate' ? pickUp.place.id : dropOff.place.id;
    const dateType = focusedInput === 'startDate' ? 'start' : 'end';

    const selectedPlace = places.find(p => p.id === placeId);
    const branchOfficeId = selectedPlace.branchOfficeId;
    const scheduleConf = getSchedule(schedules, branchOfficeId);

    return !isDayAvailable(day, scheduleConf, holidays, dateType, false);
  };

  isDayBlockedByHoliday = (day, holidays, branchOfficeId, scheduledDays) => {
    const holiday = holidays.find(h => h.date === day.format('YYYY-MM-DD'));
    if (!holiday) return false;

    if (holiday.ids.length === 0 || holiday.ids.includes(branchOfficeId)) {
      return scheduledDays ? scheduledDays['holiday'].length === 0 : true;
    } else {
      return false;
    }
  };

  async submitSearch() {
    const { categories, settings, t } = this.props;

    const { pickUp, dropOff, kmFilter, promotionCode, phone } = this.state;
    const { featureFlags } = settings;

    let customPromotionId = null;
    if (promotionCode) {
      customPromotionId = (
        await getValidPromotionCode(
          pickUp.place.rentlyEndpointName,
          encodeURIComponent(promotionCode),
        )
      ).data;

      if (customPromotionId === -1) {
        this.setState({ showInvalidPromotionMessage: true });
        return;
      }
    }

    let promos = [];
    if (
      settings.paymentConfiguration &&
      settings.paymentConfiguration.promotions &&
      settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName] &&
      settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName].onlinePromotion
    )
      promos.push(
        settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName].onlinePromotion,
      );
    if (
      settings.paymentConfiguration &&
      settings.paymentConfiguration.promotions &&
      settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName] &&
      settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName].counterPromotion
    )
      promos.push(
        settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName].counterPromotion,
      );

    const params = {
      dateFrom: pickUp.moment.format('DD-MM-YYYY'),
      hourFrom: pickUp.hour,
      pickUpId: pickUp.place.id,
      pickUpEndpoint: pickUp.place.rentlyEndpointName,
      dateTo: dropOff.moment.format('DD-MM-YYYY'),
      hourTo: dropOff.hour,
      dropOffId: dropOff.place.id,
      dropOffEndpoint: dropOff.place.rentlyEndpointName,
      categories: categories || [],
      ilimitedKm: kmFilter.selected,
      onlyFullAvailability: featureFlags.onlyFullAvailability
        ? featureFlags.onlyFullAvailability
        : false,
      promotionCode: promotionCode || null,
      promotions: promotionCode ? [customPromotionId] : promos,
      customPromotionId: customPromotionId || null,
    };

    if (!this.disableSubmit) return false;
    let text = `${t('Quote')}\n`;
    text += `${t('deliveryPlace')}: ${pickUp.place.name}\n`;
    text += `${t('LugarDeDevolucion')}: ${dropOff.place.name}\n`;
    text += `${t('deliveryDate')}: ${pickUp.moment.format('DD-MM-YYYY')}\n`;
    text += `${t('deliveryTime')}: ${pickUp.hour}\n`;
    text += `${t('returnDate')}: ${dropOff.moment.format('DD-MM-YYYY')}\n`;
    text += `${t('returnTime')}: ${dropOff.hour}\n`;
    text += `${t('KmIlimitados')}: ${kmFilter.selected ? 'Y' : 'N'} \n`;

    const wpUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURI(text)}`;

    window.open(wpUrl, '_blank');
  }

  filteredDropPlaces = () => {
    const { pickUp } = this.state;
    const { places } = this.props;
    if (!pickUp.place) return places;

    const availablePlaces = this.getAvailableDropOffPlaces(pickUp);
    return availablePlaces.filter(p => p.rentlyEndpointName === pickUp.place.rentlyEndpointName);
  };

  getAvailableDropOffPlaces = pickUp => {
    const { places } = this.props;
    if (!places || !pickUp.place) return [];

    if (!pickUp.place.availableReturnPlaces || pickUp.place.availableReturnPlaces.length === 0)
      return [pickUp.place];

    let possiblePlaces = [];
    possiblePlaces.push(pickUp.place);

    pickUp.place.availableReturnPlaces.forEach(placeId => {
      const place = places.find(p => p.id === placeId);
      if (place) possiblePlaces.push(place);
    });

    return possiblePlaces;
  };

  parseDate = stringDate => moment(stringDate, 'DD-MM-YYYY');

  render() {
    const {
      places,
      t,
      searchText,
      className,
      featureFlags,
      configurations,
      promotionCodeText,
      transparent,
      useBackendSort,
    } = this.props;
    const {
      showDifferentDropPlace,
      rangeDatePicker: { orientation, focus, withFullScreenPortal },
    } = this.state;

    return (
      <StyledSearchFormWhatsApp
        onSubmit={e => e.preventDefault(false)}
        className={'pt-4 pb-4 pl-4 pr-4 ' + (className || '')}
        transparent={transparent}
      >
        <div className="row mt-2">
          <div className="col-md-12 pickup-place-picker">
            <label htmlFor="selectFromPlace" className="label">
              {t('deliveryPlace')}
            </label>
            <SearchBoxPlace
              name="selectFromPlace"
              data={places}
              placeChangeHandler={this.pickUpSelect}
              placeSelected={this.state.pickUp.place}
              placeholder={t('selectPickUpPlace')}
              showCity={this.props.showCity || false}
              useBackendSort={useBackendSort}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 return-place-picker">
            <span className="diff-drop-place" onClick={this.toggleDropInput}>
              {t('differentreturnplace')}
            </span>
            {showDifferentDropPlace && (
              <>
                <label htmlFor="selectToPlace" className="label">
                  {t('LugarDeDevolucion')}
                </label>
                <div className="col-md-12 p-0 mb-4">
                  <SearchBoxPlace
                    name="selectToPlace"
                    className="search-box-place"
                    data={this.filteredDropPlaces()}
                    placeChangeHandler={this.dropOffSelect}
                    placeSelected={this.state.dropOff.place}
                    placeholder={t('SeleccionarLugarDeDevolucion')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex row mt-1">
          <div className="col-xs-12 col-md-6 range-date-picker">
            <RangeDateInput
              label={t('deliveryReturnDate')}
              onDatesChange={this.handleDateSelect}
              onFocusChange={this.handleDateFocus}
              startDateId="drop-up-date-start"
              startDate={this.state.pickUp.moment}
              endDate={this.state.dropOff.moment}
              icon="far fa-calendar-alt"
              iconColor={this.props.theme.colors.buttonPrimaryBgHover}
              isDayBlocked={this.isDayBlocked}
              datePickerOptions={{
                minimumNights:
                  configurations.minBookingLength === undefined
                    ? DEFAULT_MINIMUM_STAY
                    : configurations.minBookingLength,
                focusedInput: focus,
                orientation: orientation,
                withFullScreenPortal: withFullScreenPortal,
                customArrowIcon: <span className="fas fa-arrow-right p-0 mr-2" />,
                startDatePlaceholderText: t('deliveryDate'),
                endDatePlaceholderText: t('returnDate'),
                hideKeyboardShortcutsPanel: true,
              }}
            />
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6 hour-select hour-select-from">
            <SelectInput
              onChange={this.pickUpHourSelection}
              label={t('deliveryTime')}
              icon="far fa-clock"
              selected={this.state.pickUp.hour}
              iconColor={this.props.theme.colors.buttonPrimaryBgHover}
              options={this.state.pickUp.hours}
              name="pickupTime"
            />
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6 hour-select hour-select-to">
            <SelectInput
              name="returnTime"
              onChange={this.dropOffHourSelection}
              label={t('returnTime')}
              icon="far fa-clock"
              selected={this.state.dropOff.hour}
              iconColor={this.props.theme.colors.buttonPrimaryBgHover}
              options={this.state.dropOff.hours}
            />
          </div>
        </div>
        <div className="row">
          {featureFlags.showPromotionCode && (
            <div className="col-md-6">
              <TextInput
                className="promotion-code-input"
                label={t('CodigoDePromocion')}
                icon="fas fa-star"
                iconColor={this.props.theme.colors.buttonPrimaryBgHover}
                placeholder={t(promotionCodeText) || t('EscribiTuCodigo')}
                onChange={this.onPromotionCodeChange}
                value={this.state.promotionCode}
                error={
                  this.state.showInvalidPromotionMessage ? t('CodigoDePromocionInvalido') : false
                }
              />
            </div>
          )}
          {featureFlags && featureFlags.kilometerFilters && (
            <div className="col-md-6 mb-4 mt-3 filtro-km">
              <CheckboxInput
                className="special-checkbox"
                text={t('KmIlimitados')}
                checked={this.state.kmFilter.selected === true}
                onChange={e => this.handleKmFilter(e)}
              />
            </div>
          )}
        </div>
        <div className="row mt-4 mb-3 submit-block">
          <div className="col-md-12">
            <Btn
              className="submit-btn"
              text={this.tt(searchText) || t('search')}
              onClick={() => this.submitSearch()}
              disabled={this.disableSubmit()}
            />
          </div>
        </div>
      </StyledSearchFormWhatsApp>
    );
  }
}

const mapStateToProps = ({ user, siteConfiguration }) => ({
  profile: user.profile,
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps)(withTheme(withTranslation()(SearchFormWhatsApp)));
