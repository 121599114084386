import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { StyledRegisterCompanyForm } from './RegisterCompanyForm.styled';
import { StyledModernRegisterCompanyForm } from './RegisterCompanyForm.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { TextInput, Card, Btn, Logo } from 'components/';
import { handleRegisterCompany } from 'actions';
import { history } from 'store';

function RegisterCompanyForm({
  t,
  i18n,
  settings: { theme, myAccountConfiguration },
  isAuthenticated,
  handleRegisterCompany,
}) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordBlur, setPasswordBlur] = useState(false);
  const [repeatPasswordBlur, setRepeatPasswordBlur] = useState(false);
  const [error, setError] = useState('');
  const logo = myAccountConfiguration && myAccountConfiguration.logo;

  const queryParams = new URLSearchParams(window.location.search);
  const globalId = queryParams ? queryParams.get('guid') : '';

  const StyledComponent =
    theme.template === MODERN_THEME ? StyledModernRegisterCompanyForm : StyledRegisterCompanyForm;

  useEffect(() => {
    if (isAuthenticated) history.replace('/my-account');
  }, [isAuthenticated]);

  const register = async e => {
    e.preventDefault();
    setError('');
    try {
      setLoading(true);
      await handleRegisterCompany({
        globalId,
        password,
      });
    } catch (error) {
      if (!error.success) {
        setError(t(error.data));
      } else {
        const message = error.response && error.response.data.message;
        setError(message || t('registerError'));
      }
    } finally {
      setLoading(false);
    }
  };

  const disableRegister = password.length < 6 || password !== repeatPassword;

  return (
    <StyledComponent>
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible className="error-alert">
          {error}
        </Alert>
      )}
      <Card>
        <Form>
          {logo && <Logo className="ml-auto mr-auto mb-4" src={logo} />}
          <Row>
            <Col>
              <h2 className="title">{t('myAccount.companyRegistration')}</h2>
            </Col>
            <Col lg={12} className="right-col subtitle">
              <span className="">{t('myAccount.companyRegistrationValidated')}</span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <TextInput
                inputClass="form-control"
                type="password"
                className="form-control"
                placeholder={t('myAccount.password')}
                value={password}
                onChange={setPassword}
                options={{ onBlur: () => setPasswordBlur(true) }}
                error={passwordBlur && password.length < 6 && t('passwordError')}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <TextInput
                inputClass="form-control"
                type="password"
                className="form-control"
                placeholder={t('myAccount.repeatPassword')}
                value={repeatPassword}
                onChange={setRepeatPassword}
                options={{ onBlur: () => setRepeatPasswordBlur(true) }}
                error={
                  repeatPasswordBlur &&
                  (!repeatPassword || password !== repeatPassword) &&
                  t('passwordMatch')
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Btn
                className="m-auto submit_btn"
                text={t('myAccount.register')}
                disabled={disableRegister || loading}
                loading={loading}
                iconColor="white"
                iconSize="1.2rem"
                onClick={register}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </StyledComponent>
  );
}

const mapStateToProps = ({ siteConfiguration, global, user }) => ({
  settings: siteConfiguration.settings,
  isAuthenticated: user.isAuthenticated,
});

export default connect(mapStateToProps, {
  handleRegisterCompany,
})(withTranslation()(RegisterCompanyForm));
