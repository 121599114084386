import PropTypes from 'prop-types';

export const props = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  contactLinks:  PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  socialHoverColor: PropTypes.string,
  showTopBarPhone: PropTypes.bool,
};
