import styled from 'styled-components';

export const StyledSearchItemsModern = styled.section`
  min-height: 600px;
  padding: 0;

  .few-cars_alert,
  .few-cars_alert .alert {
    width: 100%;

    i {
      font-size: 25px;
    }

    b {
      display: block;
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: ${props => props.theme.screens.xl}) and (max-width: ${props =>
      props.theme.screens.xxl}) {
    flex: 70%;
    max-width: 70%;
  }

  @media only screen and (max-width: ${props => props.theme.screens.xl}) and (min-width: ${props =>
      props.theme.screens.md}) {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  @media only screen and (max-width: ${props => props.theme.screens.md}) {
    .row-container {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .alert {
    width: fit-content;
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .car-item {
    background: #fff;
    border-radius: 12px;
  }

  .car-item:hover {
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);
    transition: 0.1s;
  }

  @media (max-width: 767px) {
    .home-form {
      display: none;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .home-form {
      display: none;
    }
  }
`;
