import React from 'react';
import { connect } from 'react-redux';
import { props } from 'sections/home/Flota/FlotaItem/FlotaItem.props';
import { Btn, ModelDetail, NumberDisplay } from 'components/';
import { withTranslation } from 'react-i18next';
import { StyledFlotaBigItem } from './FlotaBigItem.styled';
import { tlink } from 'utils/translationHelper';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setSearchCarParams } from 'actions';
import { getSippByCategory } from '../../../utils/categoryGroupHelper';
import { StyledFlotaBigItemModern } from './FlotaBigItem.styled.modern';
import { MODERN_THEME } from 'styled/constants';

class FlotaBigItem extends React.Component {
  static propTypes = props;

  handleButtonClick = () => {
    const { history, bookLink, t, setSearchCarParams, model, i18n } = this.props;
    setSearchCarParams({ categories: [model.category.id] });

    history.push(tlink(bookLink, t, i18n, null, this.props.settings.configurations.langConfig));
    window.scrollTo(0, 0);
  };

  getCategoryOrGroupName = carModel => {
    const {
      showSipp,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    if (!categoryGroups) return carModel.category.name;

    const categoryGroupName = getSippByCategory(categoryGroups, carModel.category).toUpperCase();
    return showSipp && carModel.sipp
      ? `${categoryGroupName} - ${carModel.sipp}`
      : categoryGroupName;
  };

  getCarImage = model => {
    if (model.imagePath) return model.imagePath;
    return model.category.imagePath;
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledFlotaBigItemModern;
    }
    return StyledFlotaBigItem;
  }

  render() {
    const {
      model,
      t,
      settings: { currency, theme, featureFlags },
      prefixForNumeric,
      oneSlide,
      showModelDetail,
    } = this.props;

    const StyledSection = this.getTemplateStyledComponent();

    return (
      <StyledSection className="container-fluid">
        <div className="row">
          <div className={`col-lg-7 flota-car-img ${oneSlide ? 'one-slide' : ''}`}>
            <div className="car-image mb-3">
              <LazyLoadImage className="img-fluid" src={this.getCarImage(model)} alt={model.name} />
            </div>
          </div>
          <div className="col-lg-5 car-item">
            <h2 className="car-name mt-1">
              {model.brand.name} {model.name}
            </h2>
            <p className="car-category">
              {t('category')}&nbsp;
              <span>{this.getCategoryOrGroupName(model)}</span>
            </p>
            {theme.template !== MODERN_THEME ? (
              <>
                <ModelDetail
                  model={model}
                  prefixForNumeric={prefixForNumeric}
                  showModelDetail={showModelDetail}
                />
                <p className="car-description">{model.description}</p>
              </>
            ) : (
              <>
                <p className="car-description">{model.description}</p>
                <ModelDetail model={model} prefixForNumeric={prefixForNumeric} />
              </>
            )}

            <div className="price">
              {featureFlags &&
                featureFlags.showFleetPrice &&
                (!featureFlags.fleetUseLowerPrice && model.dailyPrice > 0 ? (
                  <p className="text-uppercase">
                    {t('from')} <span>{currency != null ? currency.prefix : 'ARS'} </span>
                    <strong>
                      <NumberDisplay value={model.dailyPrice} />
                    </strong>{' '}
                    {t('perday')}
                  </p>
                ) : (
                  featureFlags.showFleetPrice &&
                  featureFlags.fleetUseLowerPrice &&
                  model.lowerPrice > 0 && (
                    <p className="text-uppercase">
                      {t('from')} <span>{currency != null ? currency.prefix : 'ARS'} </span>
                      <strong>
                        <NumberDisplay value={model.lowerPrice} />
                      </strong>{' '}
                      {t('perday')}
                    </p>
                  )
                ))}
            </div>
            <Btn
              text={(featureFlags && t(featureFlags.bookingButtonText)) || t('booknow')}
              onClick={this.handleButtonClick}
            />
          </div>
        </div>
      </StyledSection>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { setSearchCarParams })(
  withRouter(withTranslation()(FlotaBigItem)),
);
