import PropTypes from 'prop-types';

export const props = {
  className: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.any.isRequired,
  textTransform: PropTypes.string,
  onClick: PropTypes.func,
  borderColor: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  style: PropTypes.shape({}),
  selfLink: PropTypes.bool,
  noShadow: PropTypes.bool,
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.string,
  iconSize: PropTypes.string,
  icon: PropTypes.string,
  secondary: PropTypes.bool,
};

export const defaultProps = {
  disabled: false,
  secondary: false,
};
