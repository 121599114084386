import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string,
  background: PropTypes.string,
  text: PropTypes.string,
  statement: PropTypes.string,
  textContentSize: PropTypes.string,
  statementSize: PropTypes.string,
};
