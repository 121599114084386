import PropTypes from 'prop-types';

export const props = {
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export const defaultProps = {
  icon: 'far fa-clock',
  className: '',
};
