import PropTypes from 'prop-types';

export const props = {
  model: PropTypes.shape({
    imagePath: PropTypes.string,
    brand: PropTypes.shape({ name: PropTypes.string }),
    doors: PropTypes.number,
    category: PropTypes.shape({ name: PropTypes.string }),
    passengers: PropTypes.number,
    bigLuggage: PropTypes.number,
    smallLuggage: PropTypes.number,
    steering: PropTypes.string,
    gearbox: PropTypes.string,
    airConditioner: PropTypes.string,
    dailyPrice: PropTypes.number,
    name: PropTypes.string,
    multimedia: PropTypes.string,
    t: PropTypes.func,
    lowerPrice: PropTypes.number,
  }),
  prefixForNumeric: PropTypes.string,
  btnDisplay: PropTypes.string,
  oneSlide: PropTypes.bool,
};

export const defaultProps = {
  showModelDetail: true,
};
