import ColumnsSectionComponent from './Columns/ColumnsSection';
import ImageComponent from './Image/Image';
import ParagraphComponent from './Paragraph/Paragraph';
import SeparatorComponent from './Separator/SeparatorComponent';
import ColumnComponent from './Columns/ColumnComponent';
import IconComponent from './Icon/Icon';
import ScriptComponent from './ScriptComponent/ScriptComponent';
import CustomHtmlComponent from './CustomHTML/CustomHTML';

export const ColumnsSection = ColumnsSectionComponent;
export const Image = ImageComponent;
export const Paragraph = ParagraphComponent;
export const Separator = SeparatorComponent;
export const Column = ColumnComponent;
export const Icon = IconComponent;
export const ScriptTagComponent = ScriptComponent;
export const CustomHtml = CustomHtmlComponent;
