import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { MODERN_THEME } from 'styled/constants';
import { StyledBottomFooter } from './RentlyFooter.styled';
import { StyledBottomFooterModern } from './RentlyFooter.styled.modern';

class RentlyFooter extends React.Component {
  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledBottomFooterModern;
    }
    return StyledBottomFooter;
  }

  render() {
    const {
      t,
      settings: {
        configurations: { hideRentlyFooter },
      },
    } = this.props;
    const StyledComponent = this.getTemplateStyledComponent();

    if (hideRentlyFooter) return null;

    return (
      <StyledComponent className="">
        <p className="pt-2 pb-2 mb-0">
          {this.props.companyName || (
            <a href="https://www.rentlysoft.com" target="_blank" rel="noopener noreferrer">
              Powered by Rently
            </a>
          )}{' '}
          © {new Date().getFullYear()} | {t('AllRightsReserved')}
        </p>
      </StyledComponent>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, null)(withTranslation()(RentlyFooter));
