import React from 'react';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { props } from '../Flota.props';
import { StyledFlota } from './index.styled';
import FlotaItem from '../FlotaItem/FlotaItem';
import { Btn, Title, TranslatedComponent, Paragraph, Loading } from 'components/';
import withSettingsContext from 'SettingsContext';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class DefaultFlota extends TranslatedComponent {
  static propTypes = props;

  getSliderSettings() {
    const { slideSpeed, autoplaySpeed, slidesToShow } = this.props;
    let newValues = {};

    if (slideSpeed) newValues.speed = slideSpeed;
    if (autoplaySpeed) newValues.autoplaySpeed = autoplaySpeed;
    if (slidesToShow) newValues.slidesToShow = slidesToShow;

    return {
      ...sliderSettings,
      ...newValues,
    };
  }

  render() {
    const {
      title,
      subTitle,
      listCategories,
      t,
      loading,
      btnDisplay,
      showOnlyDefaultModels,
      bookLink,
      getFlotaItems,
      handleButtonClick,
      titleType,
      disableTitleButton,
      disableTitle,
      slidesToShow,
    } = this.props;
    const flotaItems = !loading ? getFlotaItems() : [];
    const sliderSettings = this.getSliderSettings();

    return (
      <StyledFlota className="row row-p pt-5 pb-5" btnDisplay={btnDisplay}>
        <div className="section-flota-title col-lg-4 col-md-5 mt-5">
          {!disableTitle && (
            <>
              <Title text={title} type={titleType || 'h3'} />{' '}
              <Paragraph className="mb-4 lead" text={subTitle} />
            </>
          )}
          {!disableTitleButton && <Btn text={t('seeallfleet')} onClick={handleButtonClick} />}
        </div>
        <div className="col-lg-7 col-md-6 offset-md-1">
          {loading ? (
            <Loading show />
          ) : (
            <Slider
              {...sliderSettings}
              infinite={
                flotaItems.length < sliderSettings.slidesToShow ? false : sliderSettings.infinite
              }
            >
              {listCategories && showOnlyDefaultModels
                ? flotaItems.map((model, index) => (
                    <FlotaItem
                      key={index}
                      model={model}
                      bookLink={bookLink}
                      oneSlide={slidesToShow && slidesToShow === 1}
                    />
                  ))
                : flotaItems.map(category => {
                    return category.models.map((model, index) => (
                      <FlotaItem
                        key={index}
                        model={model}
                        bookLink={bookLink}
                        oneSlide={slidesToShow && slidesToShow === 1}
                      />
                    ));
                  })}
            </Slider>
          )}
        </div>
      </StyledFlota>
    );
  }
}

export default withSettingsContext(withRouter(withTranslation()(DefaultFlota)));
