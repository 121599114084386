import { connect } from 'react-redux';
import CheckoutAdditionals from './CheckoutAdditionals';
import { fetchPrices, fetchBookingAdditionals } from 'actions/';

const mapStateToProps = ({ bookings, searchCars }) => ({
  listAdditionals: bookings.listAdditionals,
  searchCars,
});

export default connect(mapStateToProps, { fetchBookingAdditionals, fetchPrices })(
  CheckoutAdditionals,
);
