import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { CancelModal, RentlyFooter, AccountPrivateRoute } from 'components/';
import {
  fetchSettings,
  fetchLanguages,
  fetchCurrencies,
  handleLogIn,
  fetchBookingsConfiguration,
  fetchGeneralSettings,
  setWebCurrencyAction,
  changeCurrencyAction,
} from 'actions';
import { ThemeProvider } from 'styled-components';
import BaseTheme from './styled/theme';
import queryString from 'query-string';
import { history } from '/store';
import TagManager from 'react-gtm-module';

// Pages
import NotFound from 'pages/NotFound';

import * as Components from 'components';
import * as Sections from 'sections';
import * as PageComponents from './pages';
import { StyledApp, StyledError } from './App.container.styled';
import { StyledModernApp } from './App.container.styled.modern';
import { withTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AuthService from 'auth/AuthService';
import { getLanguagePath } from 'utils/translationHelper';
import { SettingsContext } from './SettingsContext';
import { MODERN_THEME } from 'styled/constants';
import Storage from 'utils/storage';

const LoginSection = React.lazy(() => import('./sections/myAccount/login/LoginSection.js'));
const RegisterSection = React.lazy(() =>
  import('./sections/myAccount/register/RegisterSection.js'),
);
const CompanyValidateInvitationSection = React.lazy(() =>
  import('./sections/myAccount/loginCompany/CompanyValidateInvitationSection.js'),
);
const ForgotPasswordSection = React.lazy(() =>
  import('./sections/myAccount/forgotPassword/ForgotPasswordSection.js'),
);
const ForgotPasswordValidationSection = React.lazy(() =>
  import('./sections/myAccount/forgotPassword/ForgotPasswordValidationSection.js'),
);
const ProfileSection = React.lazy(() => import('./sections/myAccount/profile/ProfileSection.js'));

const BookingsSection = React.lazy(() =>
  import('./sections/myAccount/bookings/BookingsSection.js'),
);

const BookingDetails = React.lazy(() =>
  import('./sections/myAccount/bookingDetails/BookingDetails.js'),
);

const InfractionsSection = React.lazy(() =>
  import('./sections/myAccount/infractions/InfractionsSection.js'),
);

const BlogList = React.lazy(() => import('./pages/Blog/BlogList/BlogList.js'));
const BlogPage = React.lazy(() => import('./pages/Blog/BlogPost/BlogPost.js'));

const AxleVerificationSection = React.lazy(() =>
  import('./sections/axleVerification/AxleVerificationSection.js'),
);

class AppContainer extends Components.TranslatedComponent {
  static propTypes = {
    fetchSettings: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.createLayoutRoute = this.createLayoutRoute.bind(this);
    this.createLayoutPageComponent = this.createLayoutPageComponent.bind(this);
  }

  state = {
    error: null,
    loading: true,
    mobileNavBarShow: false,
    pathComponents: {},
  };
  pathComponents = {};

  async componentDidMount() {
    this.removeLoading();
    await this.fetchApiData();
    //Inicializo Tag Manager
    const {
      settings: { configurations },
    } = this.props;
    if (configurations?.googleTagManager?.id) {
      const tagManagerArgs = {
        gtmId: configurations.googleTagManager.id,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }

  removeLoading() {
    var loading = document.getElementById('loading');
    if (loading) {
      loading.parentNode.removeChild(loading);
    }
  }

  reactDatesCss(theme) {
    return `
      .CalendarDay__selected_span {
        background-color: ${theme.colors.buttonPrimaryBgHover};
        border-color: ${theme.colors.buttonPrimaryBg};
      }
      .CalendarDay__selected_start, .CalendarDay__selected_end {
        background-color: ${theme.colors.buttonPrimaryBg};
        border-color: ${theme.colors.buttonPrimaryBg};
      }
    `;
  }

  header(theme) {
    const {
      settings: { configurations },
    } = this.props;

    return (
      <Helmet>
        {configurations.fontLink && <link rel="stylesheet" href={configurations.fontLink} />}
        {configurations.favicon && <link rel="shortcut icon" href={configurations.favicon} />}
        <link
          rel="preload"
          as="stylesheet"
          href="/fonts/font-awesome/css/font-awesome.min.css"
        ></link>
        <style type="text/css">{theme && theme.customcss}</style>
        <style type="text/css">{this.reactDatesCss(theme)}</style>
      </Helmet>
    );
  }

  async fetchApiData(pushHistory = true) {
    try {
      const {
        i18n,
        fetchSettings,
        fetchLanguages,
        fetchCurrencies,
        fetchBookingsConfiguration,
        fetchGeneralSettings,
        setWebCurrencyAction,
        changeCurrencyAction,
        router,
      } = this.props;

      for (let retry = 0; retry < 3; retry++) {
        try {
          await fetchLanguages();
          continue;
        } catch (err) {
          retry++;
          if (retry === 3) throw err;
        }
      }

      for (let retry = 0; retry < 3; retry++) {
        try {
          await fetchCurrencies();
          continue;
        } catch (err) {
          retry++;
          if (retry === 3) throw err;
        }
      }

      let isOldVersion = false;
      let fetch = async () => fetchSettings();

      if (AuthService.isAuthenticated()) {
        const searchQueryStrings = queryString.parse(router.location.search);
        const { version, isdraft } = searchQueryStrings;
        fetch = async () => fetchSettings(true, version, isdraft);
        if (version) isOldVersion = true;
      }

      // fetchSettings
      await fetch();
      await fetchBookingsConfiguration();
      await fetchGeneralSettings();

      if (this.props.settings.translations) {
        for (let prop in this.props.settings.translations) {
          i18n.addResourceBundle(prop, 'translation', this.props.settings.translations[prop]);
        }
      }

      if (this.props.settings?.configurations?.defaultLang) {
        i18n.options.fallbackLng[0] = this.props.settings.configurations.defaultLang;
      }

      const defaultLang = this.props.settings?.configurations?.defaultLang;

      if (!i18n.language) {
        if (defaultLang) await i18n.changeLanguage(defaultLang);
        else await i18n.changeLanguage(this.props.listLanguages[0]);
      }

      if (!this.props.listLanguages.find(lng => lng === i18n.language)) {
        let currentLanguage = this.props.listLanguages.find(
          lng => lng === i18n.language.substring(0, 2),
        );
        if (!currentLanguage) {
          currentLanguage = this.props.listLanguages.find(lng => lng.startsWith(i18n.language));
        }

        if (currentLanguage) await i18n.changeLanguage(currentLanguage);
        else {
          if (defaultLang) await i18n.changeLanguage(defaultLang);
          else await i18n.changeLanguage(this.props.listLanguages[0]);
        }
      }

      const langPath =
        i18n.language !== defaultLang
          ? `/${getLanguagePath(this.props.settings.configurations.langConfig, i18n.language)}`
          : '/';

      if (!router.location.pathname.startsWith(langPath) && pushHistory) {
        history.push(langPath);
      }

      if (
        this.props.settings.myAccountConfiguration &&
        this.props.settings.myAccountConfiguration.showMyAccount
      )
        await this.props.handleLogIn();

      if (
        this.props.settings.featureFlags &&
        this.props.settings.featureFlags.showCurrenciesDropdown &&
        this.props.settings.currency &&
        this.props.settings.currency.isoCode
      ) {
        const matchingCurrency = this.props.listCurrencies?.find(
          currency => currency?.isoCode === this.props.settings.currency.isoCode,
        );
        //set web currency
        await setWebCurrencyAction(matchingCurrency);
        //set current currency
        const userCurrency = Storage.getElement('currency');
        if (userCurrency) {
          await changeCurrencyAction(JSON.parse(userCurrency));
        } else {
          await changeCurrencyAction(matchingCurrency);
        }
      }

      this.setState(prevState => ({
        ...prevState,
        error: null,
        loading: false,
        isOldVersion: isOldVersion,
      }));
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        error: error.message,
        loading: false,
      }));
    }
  }

  findComponent(type) {
    let ComponentToRender = PageComponents[type];
    if (!ComponentToRender) ComponentToRender = Sections[type];
    if (!ComponentToRender) ComponentToRender = Components[type];

    return ComponentToRender;
  }

  getCommObject() {
    const commObj = {
      changeLanguage: this.changeLanguage.bind(this),
      toogleNavBar: this.toogleNavBar.bind(this),
      isNavBarOpen: this.isNavBarOpen.bind(this),
    };

    return commObj;
  }

  toogleNavBar = () => this.setState({ mobileNavBarShow: !this.state.mobileNavBarShow });

  isNavBarOpen = () => this.state.mobileNavBarShow;

  createLayoutRoute(page, index, Component, translatePath = true) {
    const {
      settings: { layouts, sharedComponents },
      i18n,
      t,
    } = this.props;

    let layout = layouts.find(l => l.name === page.layout) || layouts[0];

    const LayoutRoute = this.findComponent(layout.type);

    for (let i = 0; i < layout.sections.length; i++) {
      const section = layout.sections[i];
      if (section.type === 'SharedComponent') {
        var comp = sharedComponents && sharedComponents.find(c => c.name === section.name);
        layout.sections[i] = comp || layout.sections[i];
      }
    }

    const compProps = {
      ...page,
      ...{ comm: this.getCommObject(), routerProps: this.props.location },
    };
    const layoutProps = { ...layout, ...{ comm: this.getCommObject() } };

    const defaultLang =
      this.props.settings.configurations.defaultLang || i18n.options.fallbackLng[0];

    let langPath =
      i18n.language !== defaultLang
        ? `/${getLanguagePath(this.props.settings.configurations.langConfig, i18n.language)}`
        : '';

    let path = '';

    if (!translatePath) {
      path = `${langPath}${page.path}`;
    } else {
      if (!Array.isArray(page.path)) {
        path = `${langPath}${this.tt(page.path, t)}`;
      } else {
        path = page.path.map(x => `${langPath}${this.tt(x, t)}`);
      }
    }

    return (
      <LayoutRoute
        key={index}
        exact
        path={path}
        {...layoutProps}
        component={() => <Component {...compProps} />}
      />
    );
  }

  createLayoutPageComponent(page, index, path) {
    const {
      settings: { layouts, sharedComponents },
    } = this.props;

    const Component = this.findComponent(page.type);

    let layout = layouts.find(l => l.name === page.layout) || layouts[0];

    const LayoutComponent = this.findComponent(layout.type);

    for (let i = 0; i < layout.sections.length; i++) {
      const section = layout.sections[i];
      if (section.type === 'SharedComponent') {
        var comp = sharedComponents && sharedComponents.find(c => c.name === section.name);
        layout.sections[i] = comp || layout.sections[i];
      }
    }

    const compProps = {
      ...page,
      ...{ comm: this.getCommObject(), routerProps: this.props.location },
    };
    const layoutProps = { ...layout, ...{ comm: this.getCommObject() } };

    const comppp = <Component {...compProps} />;

    const res = <LayoutComponent key={index} {...layoutProps} component={() => comppp} />;

    this.pathComponents[path] = res;

    return res;
  }

  renderRoutes() {
    const {
      settings: { pages },
      i18n,
    } = this.props;

    const defaultLang =
      this.props.settings.configurations.defaultLang || i18n.options.fallbackLng[0];
    const privateSite = this.props.settings.myAccountConfiguration?.privateSite;
    const CustomRoute = privateSite ? AccountPrivateRoute : Route;

    let langPath =
      i18n.language !== defaultLang
        ? `/${getLanguagePath(this.props.settings.configurations.langConfig, i18n.language)}`
        : '';

    return pages.map((page, index) => {
      let path = !Array.isArray(page.path)
        ? `${langPath}${this.tt(page.path)}`
        : page.path.map(x => `${langPath}${this.tt(x)}`);

      if (!Array.isArray(path)) {
        return (
          <CustomRoute
            key={index}
            exact
            path={path}
            component={() =>
              this.pathComponents[path] || this.createLayoutPageComponent(page, index, path)
            }
          />
        );
      } else {
        return path.map(x => (
          <CustomRoute
            key={index + 10000}
            exact
            path={x}
            component={() =>
              this.pathComponents[x] || this.createLayoutPageComponent(page, index, x)
            }
          />
        ));
      }
    });
  }

  setTheme(base) {
    const {
      settings: { theme },
    } = this.props;
    if (theme)
      return {
        colors: { ...base.colors, ...theme.colors },
        screens: { ...base.screens, ...theme.screens },
        font: {
          colors: { ...base.font.colors, ...theme.font.colors },
        },
        customcss: theme.customcss,
      };

    return base;
  }

  async changeLanguage(lng) {
    const { i18n, history, location, settings, listLanguages } = this.props;

    this.setState(prevState => ({
      ...prevState,
      error: null,
      loading: true,
    }));

    if (!listLanguages?.some(l => l.startsWith(lng))) return;

    const currentLang = !settings.translations[i18n.language] ? lng : i18n.language;
    const routes = settings.translations[currentLang]?.__Routes;
    let currentRouteName =
      routes &&
      Object.keys(routes).find(r =>
        location.pathname.endsWith(settings.translations[currentLang].__Routes[r]),
      );
    i18n.changeLanguage(lng);

    const defaultLang = settings.configurations.defaultLang || i18n.options.fallbackLng[0];

    let newRoute =
      lng !== defaultLang ? `/${getLanguagePath(settings.configurations.langConfig, lng)}` : '/';

    if (currentRouteName) {
      if (newRoute.endsWith('/')) newRoute = newRoute.substr(0, newRoute.length - 1);

      newRoute = newRoute + settings.translations[lng].__Routes[currentRouteName];
    }

    await this.fetchApiData(false);

    history.push(newRoute);
  }

  errorPage(error) {
    // eslint-disable-next-line no-console
    console.error('Error on initialization: ' + error);
    const { t } = this.props;

    return (
      <StyledError>
        <img src="images/carani.gif" alt="" />
        <p>
          {t('errorText')} <a href="support@rentlysoft.com">support@rentlysoft.com</a>.
        </p>
      </StyledError>
    );
  }

  getOldVersionNavigationMessage() {
    if (this.state.isOldVersion) {
      const {
        settings: { version },
      } = this.props;

      return (
        <div className="old-version-message">
          <Trans i18nKey="oldVersionNavigationMessage">
            Atencion! Estas navegando una version del sitio que no es la publicada.{' '}
            <strong>{{ version }}</strong> - <a href="/">Volver al sitio publicado</a>
          </Trans>
        </div>
      );
    }

    return null;
  }

  getGoogleLocalBusiness() {
    const {
      settings: {
        configurations: { googleLocalBusiness },
      },
      settings: { configurations },
    } = this.props;

    var tag = {
      '@context': 'http://schema.org',
      '@type': 'AutoRental',
      name: configurations.title,
      url: window.location.origin,
    };

    return { ...tag, ...googleLocalBusiness };
  }

  render() {
    if (this.state.loading) return null;
    if (!this.state.loading && this.state.error) return this.errorPage(this.state.error);

    const theme = this.setTheme(BaseTheme);
    const {
      settings: {
        configurations: {
          facebookDomainVerification,
          blogSettings,
          googleSearchConsole,
          defaultLang,
        },
        myAccountConfiguration,
      },
    } = this.props;

    const StyledTemplatedApp = theme.template === MODERN_THEME ? StyledModernApp : StyledApp;

    const companyLogin =
      myAccountConfiguration != undefined && myAccountConfiguration.companyLoginEnable;

    const agencyLogin =
      myAccountConfiguration != undefined && myAccountConfiguration.agencyLoginEnable;

    return (
      <SettingsContext.Provider value={this.props.settings}>
        <ThemeProvider theme={theme}>
          <StyledTemplatedApp
            id="app-container"
            className={`container-fluid ${this.state.mobileNavBarShow ? 'mobile-navbar-show' : ''}`}
          >
            <Helmet>
              {googleSearchConsole && googleSearchConsole.content && (
                <meta name="google-site-verification" content={googleSearchConsole.content} />
              )}
              ,
              {facebookDomainVerification && (
                <meta name="facebook-domain-verification" content={facebookDomainVerification} />
              )}
              ,{<meta http-equiv="content-language" content={defaultLang || 'es-es'} />},
              <script type="application/ld+json">
                {`
                  ${JSON.stringify(this.getGoogleLocalBusiness())}
                `}
              </script>
            </Helmet>

            {this.header(theme)}
            {this.getOldVersionNavigationMessage()}

            <Switch>
              {this.renderRoutes()}
              {this.createLayoutRoute({ path: '/login' }, 'login', LoginSection, false)}
              {this.createLayoutRoute({ path: '/register' }, 'register', RegisterSection, false)}
              {this.createLayoutRoute(
                { path: '/forgot-password' },
                'forgot-password',
                ForgotPasswordSection,
                false,
              )}
              {this.createLayoutRoute(
                { path: '/forgot-password-validation' },
                'forgot-password',
                ForgotPasswordValidationSection,
                false,
              )}
              {(companyLogin || agencyLogin) &&
                this.createLayoutRoute(
                  { path: '/company-validate-invitation' },
                  'company-validate-invitation',
                  CompanyValidateInvitationSection,
                  false,
                )}
              {(companyLogin || agencyLogin) &&
                this.createLayoutRoute(
                  { path: '/register-company' },
                  'register-company',
                  RegisterSection,
                  false,
                )}

              {this.createLayoutRoute({ path: '/my-account' }, 'my-account', ProfileSection, false)}
              {this.createLayoutRoute(
                { path: '/my-account/bookings' },
                'bookings',
                BookingsSection,
                false,
              )}
              {this.createLayoutRoute(
                { path: '/my-account/bookings/:id' },
                'booking-details',
                BookingDetails,
                false,
              )}
              {this.createLayoutRoute(
                { path: '/my-account/infractions' },
                'infractions',
                InfractionsSection,
                false,
              )}

              {blogSettings &&
                blogSettings.enabled &&
                this.createLayoutRoute(
                  { path: '/blog', layout: blogSettings.layout },
                  'blog',
                  BlogList,
                  false,
                )}
              {blogSettings &&
                blogSettings.enabled &&
                this.createLayoutRoute(
                  { path: '/blog/:url', layout: blogSettings.layout },
                  'blog-post',
                  BlogPage,
                  false,
                )}
              {this.createLayoutRoute(
                { path: '/axle-verification-request' },
                'axle-verification-request',
                AxleVerificationSection,
                false,
              )}
              <Route component={AxleVerificationSection} path="/axle-verification-response" />

              {this.createLayoutRoute({ path: '*' }, 1000, NotFound)}
            </Switch>
            <CancelModal />
            <RentlyFooter />
          </StyledTemplatedApp>
        </ThemeProvider>
      </SettingsContext.Provider>
    );
  }
}

const mapStateToProps = ({ siteConfiguration, global, router }) => ({
  settings: siteConfiguration.settings,
  listLanguages: global.listLanguages,
  currentLanguage: global.currentLanguage,
  listCurrencies: global.listCurrencies,
  router: router,
});

const AppContainerComp = withTranslation()(AppContainer);

export default withRouter(
  connect(mapStateToProps, {
    fetchSettings,
    fetchLanguages,
    fetchCurrencies,
    handleLogIn,
    fetchBookingsConfiguration,
    fetchGeneralSettings,
    setWebCurrencyAction,
    changeCurrencyAction,
  })(AppContainerComp),
);
