import styled from 'styled-components';

export const StyledPromotionsModern = styled.section`
  background-color: ${props => props.bgColor || '#f4f4f4'};
  color: ${props => props.color || '#6f6f6f'} !important;
  margin: 50px;
  border-radius: 12px;

  h3,
  div h3 {
    font-weight: ${props => props.titleWeight || '400'};
    font-style: ${props => props.titleStyle || 'normal'};
    color: ${props => props.color || props.theme.colors.primary} !important;
    margin-bottom: 50px;
  }

  p.lead {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 3rem;
    color: ${props => props.color || '#656970'} !important;

    @media (max-width: ${props => props.theme.screens.lg}) {
      font-size: 1rem;
    }
  }

  button {
    color: ${({ buttonColor, secondaryBtn, theme }) =>
      buttonColor || secondaryBtn ? theme.colors.primary : '#fff'};
    background: ${({ buttonBkg, secondaryBtn, theme }) =>
      buttonBkg || secondaryBtn ? 'transparent' : theme.colors.primary};
    border: ${({ secondaryBtn, theme }) =>
      secondaryBtn ? `1px solid ${theme.colors.primary}` : ''};
    &:hover {
      background: ${({ buttonBkgHover, secondaryBtn, theme }) =>
        buttonBkgHover || secondaryBtn ? theme.colors.primary : 'inhearit'};
      color: ${({ buttonColorHover, secondaryBtn }) =>
        buttonColorHover || secondaryBtn ? '#fff' : '#inhearit'};
    }
  }

  .promo-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .carousel-wrapper {
    display: flex;
    align-items: center;

    .carousel {
      width: 100%;
    }
  }

  .carousel-inner {
    border-radius: 15px;
  }

  .carousel-item span img {
    width: 100%;
  }

  .section-promo-carousel-container {
    margin: 3rem 0;
  }

  .promo-image {
    max-height: 500px;
    border-radius: 12px;
  }

  button {
    display: ${props => props.btnDisplay || 'flex'};
    padding: 15px;
    border-radius: 8px;
  }

  @media (max-width: ${props => props.theme.screens.xs}) {
    margin: 25px 5px;

    h3,
    div h3 {
      font-size: 25px !important;
    }
    .promo-image {
      border-radius: 5px;
    }
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding-top: 2rem !important;

    div.promo-title {
      padding: 4% !important;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    margin: 25px 0;
  }
`;
