import React from 'react';
import { PropTypes } from 'prop-types';
import style from 'styled-components';

const StyledMcAfeeLogo = style.span`
  div{
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
  }

  .mfesecure-ts-image{

  }
`;

export default class McAfeeLogo extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  componentDidMount() {
    const script = document.createElement('script');

    script.src = this.props.url;
    script.async = true;

    document.getElementById('macAfee-siteseal').appendChild(script);
  }

  render() {
    return (
      <StyledMcAfeeLogo id="macAfee-siteseal" width={this.props.width} height={this.props.height} />
    );
  }
}
