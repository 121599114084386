import React from 'react';
import { Title, ContactForm } from 'components/';
import { PropTypes } from 'prop-types';
import { StyledContactSection } from './ContactSection.styled';
import { withTranslation } from 'react-i18next';
import { tt } from '../../../utils/translationHelper';
import ReactMarkdownn from 'react-markdown';
import withSettingsContext from 'SettingsContext';
import { StyledContactSectionModern } from './ContactSection.styled.modern';
import { MODERN_THEME } from 'styled/constants';

class ContactSection extends React.Component {
  static propTypes = {
    offices: PropTypes.array,
    t: PropTypes.func,
    leaveamessage: PropTypes.array,
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledContactSectionModern;
    }
    return StyledContactSection;
  }

  getMapClassNames(form, theme) {
    if (form !== false) return 'col-lg-4 mb-3 map';
    if (theme === MODERN_THEME) return 'col-lg-5 offset-lg-1 mb-5 map';
    return 'col-lg-4 offset-lg-2 mb-5 map';
  }

  getOfficeDetailsClassNames(form, theme) {
    if (form !== false) return 'col-lg-3 office-details';
    if (theme === MODERN_THEME) return 'col-lg-5 office-details';
    return 'col-lg-4 office-details';
  }

  getIframeStyles(theme) {
    if (theme === MODERN_THEME) return { borderRadius: '20px', width: '100%' };
    return { border: '0', width: '100%' };
  }

  render() {
    const { offices, t, leaveamessage, form, className, customText, settings } = this.props;

    const StyledSection = this.getTemplateStyledComponent();

    return (
      <StyledSection className={`col-md-12 ${className ? className : `p-0 mb-5`}`}>
        <div className="container-fluid">
          {offices.map((office, index) => (
            <div className="row mb-5" key={index}>
              <div className={this.getMapClassNames(form, settings.theme.template)}>
                <iframe
                  title="street cars map"
                  src={office.mapUrl}
                  height="400"
                  frameBorder="0"
                  style={this.getIframeStyles(settings.theme.template)}
                  allowFullScreen
                />
              </div>
              <div className={this.getOfficeDetailsClassNames(form, settings.theme.template)}>
                <Title text={`${office.name}, ${office.city}`} type="h3" />
                <p>
                  <span>{tt(office.address, t)}</span> <br />
                  <span>{tt(office.hours.first, t)}</span> <br />
                  <span>{tt(office.hours.second, t)}</span>
                </p>
                {customText && (
                  <ReactMarkdownn className="footerCustomText" source={tt(customText, t)} />
                )}
                <ul className="list-unstyled contact-list">
                  {office.contacts &&
                    office.contacts.map((contact, i) => (
                      <li key={i}>
                        <a href={tt(contact.url, t)} target={contact.target}>
                          <i className={`${contact.icon}`} /> {tt(contact.text, t)}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              {index === 0 && form !== false ? (
                <div className="col-lg-5 form-div">
                  <ContactForm leaveamessage={leaveamessage} />
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </StyledSection>
    );
  }
}

export default withSettingsContext(withTranslation()(ContactSection));
